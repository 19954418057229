// border builder

@mixin border-builder($color) {
    &.border {
        border-color: $color;
    }
    &.border-top {
        border-top-color: $color;
    }
    &.border-right {
        border-right-color: $color;
    }
    &.border-bottom {
        border-bottom-color: $color;
    }
    &.border-left {
        border-left-color: $color;
    }
}