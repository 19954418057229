// Site Selector

.sf-site-selector {
	a, span, strong {
		display: inline-block;
		//font-size: 14px;
		@include convertPixelsToRem_fontSize(14); // output: font-size: ($value / 16) + rem;
		padding: 6px 10px;
		border-radius: 8px;

		&.selected {
			background-color: #f2f2f2;
			color: #333;
        }
    }
}