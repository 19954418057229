// Event Detail
//


.event-detail {
    .event-article {
        @extend .uk-article;

        .event-article-title {
            @extend .uk-article-title;
        }

        .event-meta {
            @extend .uk-article-meta;

            > div {
                margin-bottom: 3px;
            }
        }

        .event-meta-link,
        .sf-Comment-count {
            a {
                @extend .uk-button;
                @extend .uk-button-text;
            }
        }

        .event-content {
            .event-content-summary {
                @extend .uk-text-lead;
                padding-left: calc(5px + 0.2em);
                /*border-left: calc(5px + 0.1em) solid #e5e5e5;*/
                border-left: 5px solid $red;
            }
        }

        p.lead {
            @extend .uk-text-lead;
        }

        .sf-icon-comment {
            display: none !important;
        }

        i {
            margin-right: 5px;
        }

        .event-info-title,
        .event-contacts-title,
        .event-parking-info-title,
        .event-more-info-title {
            @extend .uk-h3;
            font-family: $font-family-condensed;
            margin-bottom: 15px;
        }

        .event-add-to-cal {
            color: #333;
            display: block;
            font-family: $font-family-condensed;
            font-size: .95rem;
            margin-top: 20px;

            ul.sf-event-addToList {
                list-style-type: none;
                margin: 5px 0 0 0;
                padding: 0;

                li {
                    float: left;
                    margin-right: 7px;

                    a {
                        @extend .uk-button;
                        @extend .uk-button-default;
                        @extend .uk-button-small;
                        //margin-left: 15px;
                        //text-transform: none;
                    }
                }
            }
        }

        .event-contacts {
            clear:both !important;
            padding-top: 30px;
        }

        a[data-sf-role="comments-count-anchor"] {
            display: none;
        }

        .sf-Comments-header {
            margin-top: 30px;
        }


        img {
            //@extend .uk-border-rounded;
            //@extend .uk-border-circle;
            @extend .uk-card;
            @extend .uk-card-default;
            //margin-top: 5px;
            padding: 5px;
        }
    }

    .event-featured-image {
        margin-bottom: 30px;
    }
    .event-location {
        padding-top: 20px;
    }
    .event-registration {
        padding-top: 20px;
    }
}





/*.utmbsf-event-detail {

    .event-info {
        // border-top: 1px solid #eee;
        // margin-top: 30px;
        padding-top: 30px;
    }

    .event-contacts {
        // border-top: 1px solid #eee;
        // margin-top: 30px;
        padding-top: 30px;
    }

    .event-add-to-cal {
        border-bottom: 1px solid #eee;
        border-top: 1px solid #eee;
        margin: 15px 0 0 0;
        padding: 15px 0;

        .btn,
        .btn-default,
        .btn-sm {
            margin-bottom: 0;
        }
    }

    .utmbsf-event-meta {
        color: $brand-primary; //$blue;
        font-size: 0.9em;
        line-height: 2em;

        i {
            margin-right: 4px;
            color: #999;
        }

        .meta-label {
            color: $gray;
            color: #121212;
        }
    }

    // hide icons
    &.icon-hide {
        .utmbsf-event-meta {
            i {
                display: none;
            }
        }
    }

    // hide info labels
    &.label-hide {
        .utmbsf-event-meta {
            // Label (sr-only)
            .meta-label {
                border: 0 none;
                clip: rect(0px, 0px, 0px, 0px);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }
        }
    }

    // display info in horizontal grid
    &.event-info-grid {
        .event-info,
        .event-contacts {
            float: left;
            padding-top: 30px;
            padding-right: 10px;
            width: 50%;
        }
    }
    
    
}*/