.form-group {
    padding-top: 15px;
}


div.form-group[data-sf-role="multiple-choice-field-container"] > div.radio {
    padding-top: 15px;
}


div.form-group[data-sf-role="checkboxes-field-container"] > div.checkbox {
    padding-top: 15px;
}