pre {
	padding: 0;
	border: 0;
	background: transparent;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	//font-size: 14px;
	@include convertPixelsToRem_fontSize(14); // output: font-size: ($value / 16) + rem;
}

// Code
.sf-Code {
	pre {
		color: #666;
    }
}

// Code Mirror
.CodeMirror {
	&.CodeMirror-wrap {
        line-height: normal !important;
        
		.CodeMirror-gutter {
            line-height: normal !important;
            
			.CodeMirror-gutter-text {
				pre {
                    line-height: normal !important;
				}
			}
		}
		.CodeMirror-lines {
            line-height: normal !important;
            
			pre.CodeMirror-cursor {
	            line-height: normal !important;
			}
			pre span.cm-tag {
	            line-height: normal !important;
			}
		}
	}
}

// Feed
.sf-icon-feed {
	margin-right: 3px;
	display: inline-block;
	vertical-align: middle;
}

// Langauge Selector
.sf-lang-selector {
	
	&.list-inline {
		  > li {
			  padding-right: 0;
	    }
	}
	
	a {
		  display: block;
		  //font-size: 14px;
		  @include convertPixelsToRem_fontSize(14); // output: font-size: ($value / 16) + rem;
		  padding: 8px 10px;
		  border-radius: 8px;
	    
	    &.selected {
			  background-color: #f2f2f2;
			  color: #333;
	    }
	}
	
	  label {
		  select {
			  display: block;
			  font-weight: normal;
	    }
	}
  }


  a {
    text-decoration: underline;

    &:hover,
    &:active {
        color: $global-link-hover-color;
        text-decoration: underline;
        border-bottom: 1px solid $red;
    }

    &.link {
        color: $global-link-hover-color;
        //text-decoration: underline;
        //border-bottom: 1px dashed $red;

        // &:hover,
        // &:active,
        // &:focus {
        //     //color: $global-link-hover-color;
        //     text-decoration: none;
        // }
    }
}