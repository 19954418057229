// 
// UTMB Navigate Menu: "Main" & "Main Expanded" for Classic Design Mode
// 


// Design Mode: Modern -- below Desktop view (991px-)
body.design-mode-modern {
    #navigate {
        display: none;
    }
}

// Design Mode: Modern -- Navigate "Main" Menu -- Sweetspot" Desktop view and up (992px+)
@media only screen and (min-width: 992px) {
    body.design-mode-modern {
        #navigate {
            background-color: transparent;
            display: block;
            right: 0;
            position: absolute;
            right: 0;
            top: 30px;
            z-index: 1;

            // Tandem nav inner wrapper / collapse toggle
            // show collapsible tandem nav menu
            #menu-navigate {
                display: block;
                height: auto !important; // *required so mobile nav js doesn't cause unwanted overflow - it has style="height:800px" thing
                
                // Title
                .title-area {
                    border: 0 none;
                    clip: rect(0px, 0px, 0px, 0px);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }

                // Menu Container Section
                .menu-container {
                    @include page-container-classic();
                    
                    // Site Nav Menu
                    ul.nav-menu {
                        float: left;
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        
                        // Tier 1
                        > li {
                            //list-style-type: none;
                            float: left;
                            margin: 0;
                            padding: 0;

                            > a {
                                background-color: transparent;
                                color: #111111;
                                display: block;
                                font-family: $font-family-condensed;
                                font-size: 1.05em; //1.1em;
                                height: 38px;
                                line-height: 38px;
                                padding-top: 0 !important;
                                padding-bottom: 0 !important;
                                
                                position: relative;
                                padding: 9px 13px;
                                text-decoration: none;

                                // Animated Hovers
                                // see: -http://tobiasahlin.com/blog/css-trick-animating-link-underlines/
                                &:before {
                                    content: "";
                                    position: absolute;
                                    width: 100%;
                                    height: 3px;
                                    bottom: 0;
                                    left: 0;
                                    background-color: $red;
                                    visibility: hidden;
                                    -webkit-transform: scaleX(0);
                                    transform: scaleX(0);
                                    -webkit-transition: all 0.3s ease-in-out 0s;
                                    transition: all 0.3s ease-in-out 0s;
                                }

                                &:hover,
                                &:active {
                                    background-color: transparent;
                                    border-bottom: none;
                                }

                                &:hover:before,
                                &:hover:active,
                                &:hover:focus {
                                    visibility: visible;
                                    -webkit-transform: scaleX(1);
                                    transform: scaleX(1);
                                }

                                .caret {
                                    color: $red;
                                    margin-left: 5px;
                                }
                                
                                &.active {
                                    color: $red;
                                    // &:before {
                                    //     visibility: visible;
                                    //     -webkit-transform: scaleX(1);
                                    //     transform: scaleX(1);
                                    // }
                                }

                                &.current,
                                &[aria-expanded="true"] {
                                    background: $gray-1;
                                    color: $white;
                                    &:before {
                                        visibility: hidden;
                                        -webkit-transform: scaleX(0);
                                        transform: scaleX(0);
                                    }
                                    .caret {
                                        color: $white;
                                    }
                                }
                            }
                            
                            // li.has-submenu
                            &.has-submenu {
                                position: relative;
                            }
                            
                            // Tier 2
                            // First div.submenu (nested child menus)
                            > div.submenu {
                                background: $gray-1;
                                box-shadow: 0 6px 12px rgba(0,0,0,.175); // match Bootstrap dropdowns
                                clear: both;
                                color: #fff;
                                display: none;
                                float: none;
                                max-width: 230px;
                                position: absolute;
                                top: 38px;
                                width: 230px;
                                right: 0; // @mgcooper 4/19/21
                                z-index: $zindex-explore-submenu; //1

                                &.active {
                                    display: block;
                                }
                                
                                ul.nav-menu {
                                    clear: both;
                                    display: block;
                                    float: none;
                                    list-style: none;
                                    line-height: normal;
                                    margin: 0;
                                    padding: 0px; //0 10px 0 0;

                                    li {
                                        clear: both;
                                        float: none;
                                        color: $white;
                                        margin: 0;
                                        padding: 0;

                                        a {
                                            border-left: 3px solid transparent;
                                            border-top: 1px solid #222222;
                                            color: $white;
                                            display: block;
                                            font-size: .8em;
                                            height: auto;
                                            line-height: normal; //1.618em; //auto;
                                            padding: 9px 5px 9px 15px !important;
                                            text-decoration: none;

                                            &:before {
                                                visibility: hidden;
                                                -webkit-transform: scaleX(0);
                                                transform: scaleX(0);
                                            }

                                            &:active,
                                            &:focus,
                                            &:hover {
                                                background: $black;
                                                border-left: 3px solid $red;
                                                border-bottom: 1px solid $red;
                                                color: $white;
                                            } 
                                            
                                            // caret right
                                            .caret {
                                                border-left: 4px dashed;
                                                border-left: 4px solid;
                                                border-top: 4px solid transparent;
                                                border-bottom: 4px solid transparent;
                                                border-right: 0;
                                                color: $gray-9;
                                                position: absolute;
                                                right: 15px;
                                                top: 16px;
                                            }

                                            // flip caret
                                            &:active,
                                            //&:focus,
                                            &.active,
                                            &.current,
                                            &[aria-expanded="true"] {

                                                // current / active / expanded
                                                background-color: $black;

                                                // caret down
                                                .caret {
                                                    border-top: 4px dashed;
                                                    border-top: 4px solid;
                                                    border-right: 4px solid transparent;
                                                    border-left: 4px solid transparent;
                                                    border-bottom: 0;
                                                    top: 18px;
                                                }
                                            }
                                        }


                                        // The Deep Nest...
                                        // Tier 3
                                        //&.has-submenu {
                                        div.submenu {
                                            position: relative;
                                            top: 0;
                                            width: auto;

                                            ul.nav-menu {
                                                padding: 7px 0 10px 7px;

                                                li {
                                                    a {
                                                        border-top: 1px solid #323232;
                                                        background-color: #222222;

                                                        &:hover {
                                                            background-color: #323232;
                                                        }
                                                    }

                                                    // Tier 4
                                                    div.submenu {
                                                        background-color: #222222;

                                                        ul.nav-menu {
                                                            li {
                                                                a {
                                                                    background-color: #323232;
                                                                    border-top: 1px solid #222222;

                                                                    &:hover {
                                                                        background-color: #424242;
                                                                    }
                                                                }

                                                                // Tier 5
                                                                div.submenu {
                                                                    background-color: #323232;

                                                                    ul.nav-menu {
                                                                        li {
                                                                            a {
                                                                                border-top: 1px solid #525252;
                                                                                background-color: #424242;

                                                                                &:hover {
                                                                                    background-color: #525252;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    } // end/ li

                                    li a.nav-menu-title,
                                    li.nav-menu-spacer a {
                                        background-image: none;
                                        color: $white; //$orange; //#EC7A08;
                                        font-weight: bold;
                                        font-style: normal;
                                        padding: 9px 0 9px 0;
                                    }

                                    li.no-link a,
                                    li.no-link a:hover,
                                    li.nav-menu-spacer a:hover {
                                        cursor: default;
                                        text-decoration: none;
                                    }

                                }
                            }
                        } // end/ Tier 1
                    } // end/ .nav-menu
                } // end/ .menu-container
            }

        }
    }
}

// Design Mode: Modern -- Navigate "Main Expanded" Menu -- Sweetspot" Desktop view and up (992px+)
@media only screen and (min-width: 992px) {
    body.design-mode-modern {
        #navigate.nav-expanded {
            background-color: transparent;
            display: block;
            right: 0;
            position: absolute;
            right: 0;
            top: 30px;
            z-index: 1;

            // Tandem nav inner wrapper / collapse toggle
            // show collapsible tandem nav menu
            #menu-navigate {
                display: block;
                height: auto !important; // *required so mobile nav js doesn't cause unwanted overflow - it has style="height:800px" thing
                
                // Title
                .title-area {
                    border: 0 none;
                    clip: rect(0px, 0px, 0px, 0px);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }

                // Menu Container Section
                .menu-container {
                    @include page-container-classic();
                    
                    // Site Nav Menu
                    ul.nav-menu {
                        float: left;
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        
                        // Tier 1
                        > li {
                            //list-style-type: none;
                            float: left;
                            margin: 0;
                            padding: 0;

                            > a {
                                background-color: transparent;
                                color: #111111;
                                display: block;
                                font-family: $font-family-condensed;
                                font-size: 1.05em; //1.1em;
                                height: 38px;
                                line-height: 38px;
                                padding-top: 0 !important;
                                padding-bottom: 0 !important;
                                
                                position: relative;
                                padding: 9px 13px;
                                text-decoration: none;

                                // Animated Hovers
                                // see: -http://tobiasahlin.com/blog/css-trick-animating-link-underlines/
                                &:before {
                                    content: "";
                                    position: absolute;
                                    width: 100%;
                                    height: 3px;
                                    bottom: 0;
                                    left: 0;
                                    background-color: $red;
                                    visibility: hidden;
                                    -webkit-transform: scaleX(0);
                                    transform: scaleX(0);
                                    -webkit-transition: all 0.3s ease-in-out 0s;
                                    transition: all 0.3s ease-in-out 0s;
                                }

                                &:hover,
                                &:active,
                                &:focus {
                                    background-color: transparent;
                                }

                                &:hover:before,
                                &:hover:active,
                                &:hover:focus {
                                    visibility: visible;
                                    -webkit-transform: scaleX(1);
                                    transform: scaleX(1);
                                }

                                .caret {
                                    color: $red;
                                    margin-left: 5px;
                                }
                                
                                &.active {
                                    color: $red;
                                    // &:before {
                                    //     visibility: visible;
                                    //     -webkit-transform: scaleX(1);
                                    //     transform: scaleX(1);
                                    // }
                                }

                                &.current,
                                &[aria-expanded="true"] {
                                    background: $gray-1;
                                    color: $white;
                                    &:before {
                                        visibility: hidden;
                                        -webkit-transform: scaleX(0);
                                        transform: scaleX(0);
                                    }
                                    .caret {
                                        color: $white;
                                    }
                                }
                            }
                            
                            // li.has-submenu
                            &.has-submenu {
                                position: relative;
                            }
                            
                            // Tier 2
                            // First div.submenu (nested child menus)
                            > div.submenu {
                                background: $gray-1;
                                box-shadow: 0 6px 12px rgba(0,0,0,.175); // match Bootstrap dropdowns
                                clear: both;
                                color: #fff;
                                //display: none;
                                float: none;
                                max-width: 230px;
                                position: absolute;
                                top: 38px;
                                width: 230px;
                                z-index: $zindex-explore-submenu; //1

                                &.active {
                                    display: block;
                                }
                                
                                ul.nav-menu {
                                    clear: both;
                                    display: block;
                                    float: none;
                                    list-style: none;
                                    line-height: normal;
                                    margin: 0;
                                    padding: 0px; //0 10px 0 0;

                                    li {
                                        clear: both;
                                        float: none;
                                        color: $white;
                                        margin: 0;
                                        padding: 0;

                                        a {
                                            border-left: 3px solid transparent;
                                            border-top: 1px solid #222222;
                                            color: $white;
                                            display: block;
                                            font-size: .8em;
                                            height: auto;
                                            line-height: normal; //1.618em; //auto;
                                            padding: 9px 5px 9px 15px !important;
                                            text-decoration: none;

                                            &:before {
                                                visibility: hidden;
                                                -webkit-transform: scaleX(0);
                                                transform: scaleX(0);
                                            }

                                            &:active,
                                            &:focus,
                                            &:hover {
                                                background: $black;
                                                border-left: 3px solid $red;
                                                color: $white;
                                            } 
                                            
                                            // caret right
                                            .caret {
                                                border-left: 4px dashed;
                                                border-left: 4px solid;
                                                border-top: 4px solid transparent;
                                                border-bottom: 4px solid transparent;
                                                border-right: 0;
                                                color: $gray-9;
                                                position: absolute;
                                                right: 15px;
                                                top: 16px;
                                            }

                                            // flip caret
                                            &:active,
                                            //&:focus,
                                            &.active,
                                            &.current,
                                            &[aria-expanded="true"] {

                                                // current / active / expanded
                                                background-color: $black;

                                                // caret down
                                                .caret {
                                                    border-top: 4px dashed;
                                                    border-top: 4px solid;
                                                    border-right: 4px solid transparent;
                                                    border-left: 4px solid transparent;
                                                    border-bottom: 0;
                                                    top: 18px;
                                                }
                                            }
                                        }


                                        // The Deep Nest...
                                        // Tier 3
                                        //&.has-submenu {
                                        div.submenu {
                                            position: relative;
                                            top: 0;
                                            width: auto;

                                            ul.nav-menu {
                                                padding: 7px 0 10px 7px;

                                                li {
                                                    a {
                                                        border-top: 1px solid #323232;
                                                        background-color: #222222;

                                                        &:hover {
                                                            background-color: #323232;
                                                        }
                                                    }

                                                    // Tier 4
                                                    div.submenu {
                                                        background-color: #222222;

                                                        ul.nav-menu {
                                                            li {
                                                                a {
                                                                    background-color: #323232;
                                                                    border-top: 1px solid #222222;

                                                                    &:hover {
                                                                        background-color: #424242;
                                                                    }
                                                                }

                                                                // Tier 5
                                                                div.submenu {
                                                                    background-color: #323232;

                                                                    ul.nav-menu {
                                                                        li {
                                                                            a {
                                                                                border-top: 1px solid #525252;
                                                                                background-color: #424242;

                                                                                &:hover {
                                                                                    background-color: #525252;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    } // end/ li

                                    li a.nav-menu-title,
                                    li.nav-menu-spacer a {
                                        background-image: none;
                                        color: $white; //$orange; //#EC7A08;
                                        font-weight: bold;
                                        font-style: normal;
                                        padding: 9px 0 9px 0;
                                    }

                                    li.no-link a,
                                    li.no-link a:hover,
                                    li.nav-menu-spacer a:hover {
                                        cursor: default;
                                        text-decoration: none;
                                    }

                                }
                            }
                        } // end/ Tier 1
                    } // end/ .nav-menu
                } // end/ .menu-container
            }

        }
    }
}