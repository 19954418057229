﻿// Nav Mini Bar
// Overlay Design for UIKit Horizontal Nav Bar
// *See ./src/assets/scss/vendor/uikit/_utmb-uikit-variables.scss


.nav-large {
    // .uk-navbar-container:not(.uk-navbar-transparent) {
    //     background: transparent;
    //     border: 0;
    // }

    // .uk-navbar-item, .uk-navbar-nav > li > a, .uk-navbar-toggle {
    //     margin: 5px;
    //     min-height: 1px;
    //     padding: 5px 5px;
    // }

    .uk-navbar-nav > li > a {
        //background-color: #ffffff;
        //border: 1px solid #eeeeee;
        font-size: 1rem;
        text-transform: none;

        &:active,
        &:focus,
        &:hover {
            color: $red;
        }
    }

    .uk-navbar-nav > li.uk-active {
        > a {
            //background-color: $red;
            //border: 1px solid $red;
            //color: #ffffff;
            color: $red;
        }
        //.uk-navbar-dropdown {
        .uk-nav,
        .uk-nav-sub {
                li {
                    a {
                        font-size: 1rem !important;
                    }
                }
            }
        //}
    }
}
