// UTMB Web
// theme: "UTMB Dark" (Mobile-First) - for Classic Design Mode
// UTMB Dark (body.utmb-dark) overrides UTMB Light (body.utmb-light) + base style inheritance
// author: Mike Cooper <mgcooper@utmb.edu>
// usage: body.utmb-dark

body.design-mode-classic {

    &.utmb-dark {

        // Header
        #header {
            background: $utmb-dark-header-bg;
            border-bottom: $utmb-dark-header-border;
            
            //branding (override to use white logo)
            a.branding {
                span {
                    //background-image: $utmb-logo-white-png;
                    background-image: $utmb-logo-white-svg;
                    color: #ffffff;
                }
                //svg support flagged (html.svg) by Modernizr feature detection
                .svg & span {
                    background-image: $utmb-logo-white-svg; // use SVG if supported
                }
            }
            
            // hgroup
            .hgroup,
            hgroup {
                background-color: transparent;
                border-top: $utmb-dark-hgroup-border;
                //min-width: auto;
                    
                h1 { 
                    color: $white; 
                    .accent { color: $gray-a;}
                }
                h3, 
                p { 
                    color: $gray-a; 
                }
            }

            // Override .hgroup border treatments from utmb-light + base on tablet view and greater
            @media only screen and (min-width: $utmb-small) {
                .hgroup {
                    border-left: $utmb-dark-hgroup-border;
                    border-top: 0 !important;
                }
            }
        } 


        // Explore Mega Menu
        &.exp-mega {
            // Desktop
            @media only screen and (min-width: $utmb-sweetspot) {
                #explore {
                    background-color: $utmb-dark-header-bg;
                    border-top: 1px solid #222;
                    border-bottom: 1px solid #222;

                    #menu-explore {
                        // Menu Container
                        .menu-container {
                            // ul.nav.navbar-nav (menu)
                            #nav-utmb.nav-menu {
                                > li {
                                    > a {
                                        color: $white;

                                        .caret {
                                            color: $gray-a;
                                            //display: none;
                                        }

                                        &:focus,
                                        &:hover {
                                            background-color: $red; //$gray-1;
                                            color: $white;

                                            .caret {
                                                color: #ffffff;
                                            }
                                        }
                                        //&:focus,
                                        &:active,
                                        &.current,
                                        &.active,
                                        &[aria-expanded="true"] {
                                            background-color: $gray-1;
                                            color: $white;

                                            .caret {
                                                color: #ffffff;
                                            }
                                        }
                                    }
                                }
                            }
                            // end/ ul.nav.navbar-nav (menu)
                        }
                    }
                }
            }
        }


        // Navigate Main Menu
        &.nav-main {
            // Desktop
            @media only screen and (min-width: $utmb-sweetspot) {
                #navigate {
                    background-color: $utmb-dark-header-bg;
                    //border-bottom: #{$head-nav-bot-border};
                    border-top: 1px solid #444444;
                    border-bottom: 2px solid #313131;

                    #menu-explore {
                        // Menu Container
                        .menu-container {
                            // ul.nav.navbar-nav (menu)
                            ul.nav-menu {
                                > li {
                                    > a {
                                        color: $white;

                                        .caret {
                                            color: $gray-a;
                                            //display: none;
                                        }

                                        &:focus,
                                        &:hover {
                                            background-color: $gray-1;
                                            color: $white;
                                        }
                                        //&:focus,
                                        &:active,
                                        &.current,
                                        &.active,
                                        &[aria-expanded="true"] {
                                            background-color: $gray-1;
                                            color: $white;
                                        }
                                    }
                                }
                            }
                            // end/ .nav
                        }
                    }
                    // end/ .collapse.tandem-nav
                }
            }
        }


        // Search
        // Desktop
        @media only screen and (min-width: $utmb-sweetspot) {
            #search {
                #menu-search {
                    .menu-container {
                        
                        // input form control
                        input {
                            border-color: #222;
                            @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work
                            
                            &:focus {
                                border-color: darken(#222, 10%);
                                $dark-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten(#000, 20%);
                                @include box-shadow($dark-shadow);
                            }
                        }
                        
                        // input button group
                        .search-input-group-btn {
                            
                            // button group
                            .search-btn-group {
                                
                                // Search button (.btn .btn-default .search-button)
                                .search-button {
                                    @include button-variant(#fff, #555, #222);
                                }
                                
                                // // Search Options Toggle Button (.btn .btn-default .dropdown-toggle)
                                // .search-dropdown-toggle {
                                //     @include button-variant(#fff, #555, #222);
                                //     border-left: 1px solid #222;
                                // }
                                
                            }
                        }
                    }
                }
            }
        }


        // Mobi Button
        // Tablet View and Below
        @media only screen and (max-width: $utmb-sweetspot-max) {
            .mobi-btn {
                a {
                    .bar1,
                    .bar2,
                    .bar3 {
                        background-color: $white;
                    }
                }
            }
        }


        // Nav Top Bar
        // Desktop
        @media only screen and (min-width: $utmb-sweetspot) {
            #topbarcontent {
                .nav-topbar {
                    ul.nav-menu {
                        li {
                            a {
                                color: #fff;
        
                                &:hover,
                                &:active,
                                &:focus {
                                    color: #eee;
                                }
                            }
                        }
                    }
                }
            }
        }

    }

}