﻿// Nav Horizontal
// Overlay Design for UIKit Horizontal Nav Bar
// *See ./src/assets/scss/vendor/uikit/_utmb-uikit-variables.scss

/*
    *Dear future self,
    The tabs work backwords... err... let me explain...
    UIKit has a modern tab design that doesn't look like the traditional tab.
    While it's cool, we want to deliver tab-looking tabs by default, so...
    In the MVC widget, we do a lookup for CSS ClassName "tabs-modern" and if that doesn't exist,
    we add the "tabs-buttons" CSS Class Name to the widget for the classic tab look.
    SO if you want the UIKit modern tabs, set "tabs-modern" on the in-SF-page widget.
*/


.nav-tab {
    &.nav-tabs {
        border-bottom: 0; // override bootstrap bottom border (just in case)
    }

    a {
        text-decoration: none;
    }
    // Tabs: Buttons - Classic Folder Tab Style
    &.tabs-buttons {
        .uk-tab {
            margin-left: 0;
            
            li {
                padding-left: 0px;
                padding-right: 5px;

                a {
                    border: 1px solid #e5e5e5;
                    //border-radius: 4px 4px 0 0;
                    padding: 10px 13px 7px;
                    text-transform: none;
                    font-size: 1em;
                    background-color: $teal;
                    color: #fff;
                    padding: 15px;

                    &:hover,
                    &:active {
                        background: $gray;
                    }

                    &.active {
                        border-bottom: 1px solid #ffffff;
                        background-color: #fff !important;
                        color: $red;
                        font-weight: bold;
                    }
                }
            }
            // &:before {
            //     border-bottom: transparent;
            // }
        }
    }
    // Tabs: Large
    &.tabs-large {
        .uk-tab {
            li {
                a {
                    font-size: 1.125em;
                }
            }
        }
    }
    // Tabs: Small
    &.tabs-small {
        .uk-tab {
            li {
                a {
                    font-size: 0.8em;
                }
            }
        }
    }
    // Tabs Colors
    &.tabs-red {
        &.tabs-modern .uk-tab > .uk-active > a {
            color: $red;
            border-color: $red;
        }
        // Tabs: Buttons - Classic Folder Tab Style
        &.tabs-buttons .uk-tab li a {
            background-color: $red;
            &:hover,
            &:active {
                background: $gray;
            }
        }
    }
    &.tabs-gray {
        &.tabs-modern .uk-tab > .uk-active > a {
            color: $gray;
            border-color: $gray;
        }
        // Tabs: Buttons - Classic Folder Tab Style
        &.tabs-buttons .uk-tab li a {
            background-color: $gray;
            &:hover,
            &:active {
                background: $gray-1;
            }
        }
    }
    &.tabs-gray-light {
        &.tabs-modern .uk-tab > .uk-active > a {
            color: $gray;
            border-color: $gray-light;
        }
        // Tabs: Buttons - Classic Folder Tab Style
        &.tabs-buttons .uk-tab li a {
            color: $gray;
            background-color: $gray-light;
            &:hover,
            &:active {
                background: $gray;
            }
        }
    }
    &.tabs-teal {
        &.tabs-modern .uk-tab > .uk-active > a {
            color: $teal;
            border-color: $teal;
        }
        // Tabs: Buttons - Classic Folder Tab Style
        &.tabs-buttons .uk-tab li a {
            background-color: $teal;
            &:hover,
            &:active {
                background: $gray;
            }
        }
    }
    &.tabs-teal-dark {
        &.tabs-modern .uk-tab > .uk-active > a {
            color: $teal-dark;
            border-color: $teal-dark;
        }
        // Tabs: Buttons - Classic Folder Tab Style
        &.tabs-buttons .uk-tab li a {
            background-color: $teal-dark;
            &:hover,
            &:active {
                background: $gray;
            }
        }
    }
    &.tabs-teal-light {
        &.tabs-modern .uk-tab > .uk-active > a {
            color: $teal;
            border-color: $teal-light;
        }
        // Tabs: Buttons - Classic Folder Tab Style
        &.tabs-buttons .uk-tab li a {
            color: $gray;
            background-color: $teal-light;
            &:hover,
            &:active {
                background: $gray;
            }
        }
    }
    &.tabs-blue {
        &.tabs-modern .uk-tab > .uk-active > a {
            color: $blue;
            border-color: $blue;
        }
        // Tabs: Buttons - Classic Folder Tab Style
        &.tabs-buttons .uk-tab li a {
            background-color: $blue;
            &:hover,
            &:active {
                background: $gray;
            }
        }
    }
    &.tabs-blue-dark {
        &.tabs-modern .uk-tab > .uk-active > a {
            color: $blue-dark;
            border-color: $blue-dark;
        }
        // Tabs: Buttons - Classic Folder Tab Style
        &.tabs-buttons .uk-tab li a {
            background-color: $blue-dark;
            &:hover,
            &:active {
                background: $gray;
            }
        }
    }
    &.tabs-blue-light {
        &.tabs-modern .uk-tab > .uk-active > a {
            color: $blue;
            border-color: $blue-light;
        }
        // Tabs: Buttons - Classic Folder Tab Style
        &.tabs-buttons .uk-tab li a {
            color: $gray;
            background-color: $blue-light;
            &:hover,
            &:active {
                background: $gray;
            }
        }
    }
    &.tabs-orange {
        &.tabs-modern .uk-tab > .uk-active > a {
            color: $orange;
            border-color: $orange;
        }
        // Tabs: Buttons - Classic Folder Tab Style
        &.tabs-buttons .uk-tab li a {
            background-color: $orange;
            &:hover,
            &:active {
                background: $gray;
            }
        }
    }
    &.tabs-orange-dark {
        &.tabs-modern .uk-tab > .uk-active > a {
            color: $orange-dark;
            border-color: $orange-dark;
        }
        // Tabs: Buttons - Classic Folder Tab Style
        &.tabs-buttons .uk-tab li a {
            background-color: $orange-dark;
            &:hover,
            &:active {
                background: $gray;
            }
        }
    }
    &.tabs-orange-mid {
        &.tabs-modern .uk-tab > .uk-active > a {
            color: $orange;
            border-color: $orange-mid;
        }
        // Tabs: Buttons - Classic Folder Tab Style
        &.tabs-buttons .uk-tab li a {
            color: $gray;
            background-color: $orange-mid;
            &:hover,
            &:active {
                background: $gray;
            }
        }
    }
    &.tabs-orange-light {
        &.tabs-modern .uk-tab > .uk-active > a {
            color: $orange;
            border-color: $orange-light;
        }
        // Tabs: Buttons - Classic Folder Tab Style
        &.tabs-buttons .uk-tab li a {
            color: $gray;
            background-color: $orange-light;
            &:hover,
            &:active {
                background: $gray;
            }
        }
    }
    &.tabs-green {
        &.tabs-modern .uk-tab > .uk-active > a {
            color: $green;
            border-color: $green;
        }
        // Tabs: Buttons - Classic Folder Tab Style
        &.tabs-buttons .uk-tab li a {
            background-color: $green;
            &:hover,
            &:active {
                background: $gray;
            }
        }
    }
    &.tabs-green-dark {
        &.tabs-modern .uk-tab > .uk-active > a {
            color: $green-dark;
            border-color: $green-dark;
        }
        // Tabs: Buttons - Classic Folder Tab Style
        &.tabs-buttons .uk-tab li a {
            background-color: $green-dark;
            &:hover,
            &:active {
                background: $gray;
            }
        }
    }
    &.tabs-green-light {
        &.tabs-modern .uk-tab > .uk-active > a {
            color: $green;
            border-color: $green-light;
        }
        // Tabs: Buttons - Classic Folder Tab Style
        &.tabs-buttons .uk-tab li a {
            color: $gray;
            background-color: $green-light;
            &:hover,
            &:active {
                background: $gray;
            }
        }
    }
}


// Mobile Handling
// A Dropdown menu is created for each instance
// Dropdown is displayed for small screens (sweetspot-max) and hidden at desktop (sweetspot)
// Horizontal menu is hidden for small screens (sweetspot-max) and displayed at desktop (sweetspot)

@media only screen and (max-width: $utmb-sweetspot-max) {
    .nav-tab {
        .nav-mobile-dropdown {
            display: block;
            padding: 15px 0;
        }

        ul {
            display: none;
        }
        // mobile override
        &.no-mobile-menu {
            .nav-mobile-dropdown {
                display: none;
            }

            ul {
                //display: block;
                display: flex;
            }
        }
    }
}

@media only screen and (min-width: $utmb-sweetspot) {
    .nav-tab {
        .nav-mobile-dropdown {
            display: none;
        }

        // ul {
        //     //display: block;
        // }
    }
}

