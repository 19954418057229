﻿// Layout / Page Container Width

// Deprecated:
// $page-max-width: 1100px; //1170px; //see: /D:/UTMB Web/Master_UTMB_Web/app/demos/bootstrap.html
// $utmb-page-max-width: 1100px;
// $container-large-desktop: 1100px;


// *NEW
$container-classic: 1100px; // as of v4.0
$container-modern: 1400px; // as of v4.0

// Desktop Sticky Footer
$sticky-footer-height: 180px; // #footer height
$sticky-footer-margin: -180px; // #page bottom margin

// Header and Nav Borders
$head-nav-bot-border: 2px solid #eeeeee;

// Content
$content-gutter: 0.5em;
$content-padding: 0 $content-gutter;

// Radius & Rounded
$global-radius: 3px;
$global-round: 1000px;
