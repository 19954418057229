.mobi-btn {
    display: inline-block;
    position: absolute;
    top: 15px;
    right: 20px;
    /* z-index: 7777777; */
}
.mobi-btn a {
    display: inline-block;
    cursor: pointer;
}
/* hamburger */
.mobi-btn a .bar1, 
.mobi-btn a .bar2, 
.mobi-btn a .bar3 {
    display: block;
    width: 35px;
    height: 3px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
}
/* hamburger.change = X */
.mobi-btn a.active .bar1,
.mobi-btn a.active .bar2,
.mobi-btn a.active .bar3 {
    background-color: #fff;
}
.mobi-btn a.active .bar1 {
    -webkit-transform: rotate(-45deg) translate(-8px, 5px);
    transform: rotate(-45deg) translate(-8px, 5px);
}
.mobi-btn a.active .bar2 {opacity: 0;}
.mobi-btn a.active .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -7px);
    transform: rotate(45deg) translate(-6px, -7px);
}
.mobi-btn a .mobi-btn-text {
    border: 0 none;
    clip: rect(0px, 0px, 0px, 0px);
    color: #111;
    font-size: 0.9em;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// dark mode
body.utmb-dark {
    /* hamburger */
    .mobi-btn a .bar1, 
    .mobi-btn a .bar2, 
    .mobi-btn a .bar3 {
        display: block;
        width: 35px;
        height: 3px;
        background-color: #fff;
        margin: 6px 0;
        transition: 0.4s;
    }
    /* hamburger.change = X */
    .mobi-btn a.active .bar1,
    .mobi-btn a.active .bar2,
    .mobi-btn a.active .bar3 {
        background-color: #333;
    }
}



/* "Sweetspot" - hide for Desktop view and up (992px+) */
@media only screen and (min-width: 992px) {
    .mobi-btn {
        border: 0 none;
        clip: rect(0px, 0px, 0px, 0px);
        color: #111;
        font-size: 0.9em;
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}