﻿//
// Jumbotron
// --------------------------------------------------

$jumbotron-padding: 30px;
$jumbotron-color: inherit !default;
$jumbotron-bg: $gray-lighter !default;
$jumbotron-heading-color: inherit !default;
$jumbotron-font-size: ceil(($font-size-base * 1.5)) !default;
$jumbotron-heading-font-size: ceil(($font-size-base * 4.5)) !default;



.jumbotron {
    padding: $jumbotron-padding;
    padding-top: calc($jumbotron-padding * 2.25);
    padding-bottom: calc($jumbotron-padding * 1.5);
    padding-left: calc($jumbotron-padding * 2);
    padding-right: calc($jumbotron-padding * 2);
    border-radius: $border-radius-large;
    line-height: 1.45em;

    h1, p {
        line-height: 1.45em;
    }

    h1, .h1 {
        padding: 0px !important;
        margin: 0px !important;
    }

    p {
        margin: 0;
        margin-bottom: calc($jumbotron-padding / 2); //15 !important;
        padding: 0 !important;
    }

    @media screen and (max-width: $screen-sm-max) {
        padding: $jumbotron-padding;
    }
}



//
//.jumbotron {
//    padding:        $jumbotron-padding;
//    padding-top:    $jumbotron-padding;
//    padding-bottom: $jumbotron-padding;
//
//    margin-bottom: $jumbotron-padding;
//    color: $jumbotron-color;
//    background-color: $red; //$jumbotron-bg;
//
//    h1,
//    .h1 {
//        color: $jumbotron-heading-color;
//        margin-bottom: 0px !important;
//    }
//
//    p {
//        margin-bottom: ($jumbotron-padding / 2);
//        font-size: $jumbotron-font-size;
//        font-weight: 200;
//    }
//
//    > hr {
//        border-top-color: darken($jumbotron-bg, 10%);
//    }
//
//    .container &,
//    .container-fluid & {
//        border-radius: $border-radius-large; // Only round corners at higher resolutions if contained in a container
//    }
//
//    .container {
//        max-width: 100%;
//    }
//
//    @media screen and (min-width: $screen-sm-min) {
//        padding-top:    60px; //($jumbotron-padding * 1.8);
//        padding-bottom: 45px; //($jumbotron-padding * 1.5);
//        border-radius: $border-radius-large; //@mgcooper
//
////        .container &,
////        .container-fluid & {
////            padding-left:  ($jumbotron-padding * 2);
////            padding-right: ($jumbotron-padding * 2);
////        }
//
//        h1,
//        .h1 {
//            font-size: $jumbotron-heading-font-size;
//        }
//    }
//}
