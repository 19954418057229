// Breadcrumb
// *See ./src/assets/scss/vendor/uikit/_utmb-uikit-variables.scss


.nav-breadcrumb {
    li {
        a {
            &:hover, 
            &:active {
                color: $red;
                text-decoration: underline;
            }
        }
    }
    li.active {
        font-size: $breadcrumb-item-font-size;
    }
    /*ul.sf-breadscrumb.breadcrumb {
        background-color: transparent; //#f5f5f5;
        border-radius: 0; //4px;
        font-size: .7em;
        line-height: 1.618em;
        list-style: none;
        margin: 0;
        margin-bottom: 25px;
        padding: 0; //8px 15px;
        li {
            margin: 0;
            padding: 0;

            a {
                text-decoration: none;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: underline;
                }
            }
        }
    }*/
}
