// Typography

html, body, p {
    font-size: 1rem; //$global-font-size;
    line-height: 1.5;
    font-family: $font-family-base;
}

strong, b {
    font-weight: 800;
}


// Web Fonts

.serif {
    font-family: $font-family-serif !important;
}

.sans-serif {
    font-family: $font-family-base !important;
}

.condensed {
    font-family: $font-family-condensed !important;
}

.monospace {
    font-family: $font-family-monospace !important;
}

.script {
    font-family: $font-family-script !important;
}

// Font weight

.thin,
.type-thin {
    font-weight: 100;
}
.light,
.type-light {
    font-weight: 300;
}
.regular,
.type-regular {
    font-weight: 400;
}
.medium,
.type-medium {
    font-weight: 600;
}
.bold,
.type-bold {
    font-weight: 700;
}

.type-black,
.text-black {
    font-weight: 900;
}

// HTML Base Elements

 

.uk-h1, .uk-h3 {
    line-height: 1.5;
}

h1, .h1 {
    @extend .uk-h1;
    font-family: $font-family-condensed;
    line-height: 1.5;
    a {
        color: inherit;
        text-decoration: inherit;
    }
}
h2, .h2 {
    @extend .uk-h2;
    font-family: $font-family-condensed;
    line-height: 1.5;
    a {
        color: inherit;
        text-decoration: inherit;
    }
}
h3, .h3 {
    @extend .uk-h3;
    font-family: $font-family-condensed;
    line-height: 1.5;
    a {
        color: inherit;
        text-decoration: inherit;
    }
}
h4, .h4 {
    @extend .uk-h4;
    font-family: $font-family-condensed;
    a {
        color: inherit;
        text-decoration: inherit;
    }
}
h5, .h5 {
    @extend .uk-h5;
    font-family: $font-family-condensed;
    a {
        color: inherit;
        text-decoration: inherit;
    }
}
h6, .h6 {
    @extend .uk-h6;
    font-family: $font-family-condensed;
    a {
        color: inherit;
        text-decoration: inherit;
    }
}
.subtitle {
    display: block;
    color: $gray; //$gray-light;
    font-family: $font-family-headline;
    font-size: 72%;
    line-height: 1.125em;
    font-weight: normal;
    margin-top: 5px;
    margin-bottom: 0;

    .serif & {
        font-size: 90%;
    }
}

p {
    font-family: $font-family-base;

    strong, b {
        font-weight: bold;
        font-weight: 800;
    }

    &.lead {
        //font-size: 1.61em;
        // UIKit Lead
        font-size: 1.25rem; //22px; //24px;
        line-height: 1.639; //1.5;
        color: #222;
        font-weight: 300;

        strong, b {
            font-weight: bold;
            font-weight: 700;
        }
    }
}

a {
    text-decoration: underline;

    &:hover,
    &:active {
        color: $global-link-hover-color;
        text-decoration: underline;
        border-bottom: 1px solid $red;
    }

    &.link {
        color: $global-link-hover-color;
        //text-decoration: underline;
        //border-bottom: 1px dashed $red;

        // &:hover,
        // &:active,
        // &:focus {
        //     //color: $global-link-hover-color;
        //     text-decoration: none;
        // }
    }
}

blockquote {
    font-style: normal;
}

i {
    font-family: inherit;
    font-style: normal;
}

i, em, b, strong {
    color: inherit;
}

code {
    background-color: #f8f8f8;
    color: $pink;
    font-family: $font-family-monospace;
}

img {
    max-width: 100%; // Fluid Images
}

ol, ul {
    margin-bottom: 0;
    margin-top: 0;
    //font-size: $global-font-size;
    @include convertPixelsToRem_fontSize(18); // output: font-size: ($value / 16) + rem;

    &.list {
        @extend .uk-list;
        //font-size: $global-font-size;
        @include convertPixelsToRem_fontSize(18); // output: font-size: ($value / 16) + rem;

        &.list-bullet {
            // @extend .uk-list-bullet;
            list-style-type:disc;
            padding-left: 30px;
        }

        &.list-numbered {
            @extend .uk-list-decimal;
            //@extend .uk-list-bullet;
            //list-style-type: decimal;
            padding-left: 30px;

            li {
                //margin: 0;
                padding-left: 9px;

                &::before {
                    background-image: none;
                }
            }
        }

        &.list-divider {
            @extend .uk-list-divider;
        }

        &.list-striped {
            @extend .uk-list-striped;
        }

        &.list-large {
            @extend .uk-list-large;
        }

        &.list-small {
            //@extend .uk-list-large;
            font-size: .85rem;
            line-height: 1.125rem;
        }

        li {
            a {
                text-decoration: underline;
            
                &:hover,
                &:active {
                    color: $global-link-hover-color;
                    text-decoration: underline;
                    border-bottom: 1px solid $red;
                }
            }
        }
    }

    // Bootstrap
    // &.list-unstyled {
    //     margin: 0 0 20px 0;
    //     padding: 0;
    // }
}

// *div.list (for grid component layouts)
dl {
    dt {
        //color: $red;
        margin-bottom: 3px;
    }

    dd {
        font-size: 0.9em;
        margin-bottom: 15px;
    }

    // static
    @media (min-width: 768px) {
        &.dl-horizontal,
        &.list-inline {
            //@extend .row;

            dt {
                float: left;
                width: 160px;
                clear: left;
            }

            dd {
                display: block;
                margin-inline-start: 40px;
                margin-left: 180px;
            }

            &.dt-right {
                dt {
                    text-align: right;
                }
            }
        }
    }
}

// SF component
@media (min-width: 768px) {
    div.dl-horizontal,
    div.list-inline {
        dl {

            dt {
                float: left;
                width: 160px;
                clear: left;
            }
    
            dd {
                display: block;
                margin-inline-start: 40px;
                margin-left: 180px;
            }
    
            
        }

        &.dt-right {
            dl dt {
                text-align: right;
            }
        }
    }
}

button {
    @extend .uk-button;

    &:focus {
        outline: 1px solid $red;
    }

    &.button-link {
        text-decoration: underline;

        &:hover,
        &:active {
            color: $global-link-hover-color;
            text-decoration: underline !important;
            border-bottom: 1px solid $red;
        }
    }

    &.button-text {
        @extend .uk-button-text;
    }

    &.button-default {
        @extend .uk-button-default;
    }
}

input {

    &[type="text"],
    &[type="email"] {
        @extend .uk-input;
    }

    &[type="radio"] {
        @extend .uk-radio;
    }

    &[type="checkbox"] {
        @extend .uk-checkbox;
    }

    &[type="range"] {
        @extend .uk-range;
    }
}

select {
    @extend .uk-select;
}

textarea {
    @extend .uk-textarea;
    margin-top: 15px;
}

.required-field-label::before {
    content: '*';
    color: $red;
    font-family: Verdana,Arial,Helvetica,sans-serif;
}

// Alignment
.text-left,
.left {
    text-align: left;
}
.text-center,
.center {
    text-align: center;
}
.text-right,
.right {
    text-align: right;
}

// decoration
.text-none,
.text-decoration-none {
    text-decoration: none;
}
.text-underline,
.text-decoration-underline {
    text-decoration: underline;
}

// font size
.text-tiny {
    font-size: .7rem;
}
.text-small {
    font-size: .8rem;
}
.text-medium {
    font-size: .9rem;
}
.text-muted {
    color: #555;
}



.uk-text-default,
.uk-nav-secondary {
    //font-size: 18px;
    @include convertPixelsToRem_fontSize(18); // output: font-size: _rem;
    line-height: 1.639;
  }
  .uk-dropdown-nav .uk-nav-subtitle,
  .uk-navbar-dropdown-nav .uk-nav-subtitle {
    //font-size: 12px;
    @include convertPixelsToRem_fontSize(12); // output: font-size: _rem;
  }
 
  .uk-tooltip {
    //font-size: 12px;
    @include convertPixelsToRem_fontSize(12); // output: font-size: _rem;
  }


// Changed in UIKIT Node Module scss
// .uk-heading-small {
//     //font-size: 38px;
//     @include convertPixelsToRem_fontSize(38); // output: font-size: _rem;
//     line-height: 1.5;
//   }
  
//   .uk-heading-medium {
//     //font-size: 40px;
//     @include convertPixelsToRem_fontSize(40); // output: font-size: _rem;
//     line-height: 1.5;
//   }
  
//   .uk-heading-large {
//     //font-size: 50px;
//     @include convertPixelsToRem_fontSize(50); // output: font-size: _rem;
//     line-height: 1.5;
//   }
  
//   .uk-heading-xlarge {
//     //font-size: 64px;
//     @include convertPixelsToRem_fontSize(64); // output: font-size: _rem;
//     line-height: 1.5;
//   }
  
//   .uk-heading-2xlarge {
//     //font-size: 96px;
//     @include convertPixelsToRem_fontSize(96); // output: font-size: _rem;
//     line-height: 1.5;
//   }
  
// /* Tablet Landscape and bigger */
// @media (min-width: 960px) {
//     .uk-heading-small {
//         //font-size: 52px;
//         @include convertPixelsToRem_fontSize(52); // output: font-size: _rem;
//     }
//     .uk-heading-medium {
//         //font-size: 56px;
//         @include convertPixelsToRem_fontSize(56); // output: font-size: _rem;
//     }
//     .uk-heading-large {
//         //font-size: 64px;
//         @include convertPixelsToRem_fontSize(64); // output: font-size: _rem;
//     }
//     .uk-heading-xlarge {
//         //font-size: 96px;
//         @include convertPixelsToRem_fontSize(96); // output: font-size: _rem;
//     }
//     .uk-heading-2xlarge {
//         //font-size: 128px;
//         @include convertPixelsToRem_fontSize(128); // output: font-size: _rem;
//     }
// }
// /* Laptop and bigger */
// @media (min-width: 1200px) {
//     .uk-heading-medium {
//       font-size: 4rem;
//     }
//     .uk-heading-large {
//       font-size: 6rem;
//     }
//     .uk-heading-xlarge {
//       font-size: 8rem;
//     }
//     .uk-heading-2xlarge {
//       font-size: 11rem;
//     }
// }