// UTMB Page Title

.utmb-page-title {
    margin-top: 30px;

    h1, h2, h3, h4, h5, h6 {
        @extend .uk-heading-bullet;

        &:before {
            border-left: 2px solid $red;
        }
    }
}