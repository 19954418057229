﻿// News Detail
//


.news-detail {
    .news-article {
        @extend .uk-article;

        .news-article-title {
            @extend .uk-article-title;
        }

        .news-meta {
            @extend .uk-article-meta;
        }

        .news-meta-link,
        .sf-Comment-count {
            a {
                @extend .uk-button;
                @extend .uk-button-text;
            }
        }

        .news-content {
            .news-content-summary {
                @extend .uk-text-lead;
                padding-left: calc(5px + 0.2em);
                /*border-left: calc(5px + 0.1em) solid #e5e5e5;*/
                border-left: 5px solid $red;
            }
        }

        p.lead {
            @extend .uk-text-lead;
        }

        .sf-icon-comment {
            display: none !important;
        }

        img {
            //@extend .uk-border-rounded;
            //@extend .uk-border-circle;
            @extend .uk-card;
            @extend .uk-card-default;
            //margin-top: 5px;
            padding: 5px;
        }
    }
}
