// Margin

.margin-0 {
    margin: 0 !important;
}

.margin-5 {
    margin: 5px !important;
}

.margin-10 {
    margin: 10px !important;
}

.margin-15 {
    margin: 15px !important;
}

.margin-20 {
    margin: 20px !important;
}

.margin-25 {
    margin: 25px !important;
}

.margin-30 {
    margin: 30px !important;
}

.margin-35 {
    margin: 35px !important;
}

.margin-40 {
    margin: 40px !important;
}

.margin-45 {
    margin: 45px !important;
}

.margin-50 {
    margin: 50px !important;
}

.margin-55 {
    margin: 55px !important;
}

.margin-60 {
    margin: 60px !important;
}

.margin-65 {
    margin: 65px !important;
}

.margin-70 {
    margin: 70px !important;
}

.margin-75 {
    margin: 75px !important;
}

.margin-80 {
    margin: 80px !important;
}

.margin-85 {
    margin: 85px !important;
}

.margin-90 {
    margin: 90px !important;
}

.margin-95 {
    margin: 95px !important;
}

.margin-100 {
    margin: 100px !important;
}

.margin-105 {
    margin: 105px !important;
}

.margin-110 {
    margin: 110px !important;
}

.margin-115 {
    margin: 115px !important;
}

.margin-120 {
    margin: 120px !important;
}

.margin-125 {
    margin: 125px !important;
}

.margin-130 {
    margin: 130px !important;
}

.margin-135 {
    margin: 135px !important;
}

.margin-140 {
    margin: 140px !important;
}

.margin-145 {
    margin: 145px !important;
}

.margin-150 {
    margin: 150px !important;
}




// Margin Top

.margin-top-0 {
    margin-top: 0 !important;
}
.margin-top-5 {
    margin-top: 5px !important;
}
.margin-top-10 {
    margin-top: 10px !important;
}
.margin-top-15 {
    margin-top: 15px !important;
}
.margin-top-20 {
    margin-top: 20px !important;
}
.margin-top-25 {
    margin-top: 25px !important;
}
.margin-top-30 {
    margin-top: 30px !important;
}
.margin-top-35 {
    margin-top: 35px !important;
}
.margin-top-40 {
    margin-top: 40px !important;
}
.margin-top-45 {
    margin-top: 45px !important;
}
.margin-top-50 {
    margin-top: 50px !important;
}
.margin-top-55 {
    margin-top: 55px !important;
}
.margin-top-60 {
    margin-top: 60px !important;
}
.margin-top-65 {
    margin-top: 65px !important;
}
.margin-top-70 {
    margin-top: 70px !important;
}
.margin-top-75 {
    margin-top: 75px !important;
}
.margin-top-80 {
    margin-top: 80px !important;
}
.margin-top-85 {
    margin-top: 85px !important;
}
.margin-top-90 {
    margin-top: 90px !important;
}
.margin-top-95 {
    margin-top: 95px !important;
}
.margin-top-100 {
    margin-top: 100px !important;
}
.margin-top-105 {
    margin-top: 105px !important;
}
.margin-top-110 {
    margin-top: 110px !important;
}
.margin-top-115 {
    margin-top: 115px !important;
}
.margin-top-120 {
    margin-top: 120px !important;
}
.margin-top-125 {
    margin-top: 125px !important;
}
.margin-top-130 {
    margin-top: 130px !important;
}
.margin-top-135 {
    margin-top: 135px !important;
}
.margin-top-140 {
    margin-top: 140px !important;
}
.margin-top-145 {
    margin-top: 145px !important;
}
.margin-top-150 {
    margin-top: 150px !important;
}



// Margin Right

.margin-right-0 {
    margin-right: 0 !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}

.margin-right-10 {
    margin-right: 10px !important;
}

.margin-right-15 {
    margin-right: 15px !important;
}

.margin-right-20 {
    margin-right: 20px !important;
}

.margin-right-25 {
    margin-right: 25px !important;
}

.margin-right-30 {
    margin-right: 30px !important;
}

.margin-right-35 {
    margin-right: 35px !important;
}

.margin-right-40 {
    margin-right: 40px !important;
}

.margin-right-45 {
    margin-right: 45px !important;
}

.margin-right-50 {
    margin-right: 50px !important;
}

.margin-right-55 {
    margin-right: 55px !important;
}

.margin-right-60 {
    margin-right: 60px !important;
}

.margin-right-65 {
    margin-right: 65px !important;
}

.margin-right-70 {
    margin-right: 70px !important;
}

.margin-right-75 {
    margin-right: 75px !important;
}

.margin-right-80 {
    margin-right: 80px !important;
}

.margin-right-85 {
    margin-right: 85px !important;
}

.margin-right-90 {
    margin-right: 90px !important;
}

.margin-right-95 {
    margin-right: 95px !important;
}

.margin-right-100 {
    margin-right: 100px !important;
}

.margin-right-105 {
    margin-right: 105px !important;
}

.margin-right-110 {
    margin-right: 110px !important;
}

.margin-right-115 {
    margin-right: 115px !important;
}

.margin-right-120 {
    margin-right: 120px !important;
}

.margin-right-125 {
    margin-right: 125px !important;
}

.margin-right-130 {
    margin-right: 130px !important;
}

.margin-right-135 {
    margin-right: 135px !important;
}

.margin-right-140 {
    margin-right: 140px !important;
}

.margin-right-145 {
    margin-right: 145px !important;
}

.margin-right-150 {
    margin-right: 150px !important;
}


// Margin Bottom

.margin-bottom-0 {
    margin-bottom: 0 !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.margin-bottom-20 {
    margin-bottom: 20px !important;
}

.margin-bottom-25 {
    margin-bottom: 25px !important;
}

.margin-bottom-30 {
    margin-bottom: 30px !important;
}

.margin-bottom-35 {
    margin-bottom: 35px !important;
}

.margin-bottom-40 {
    margin-bottom: 40px !important;
}

.margin-bottom-45 {
    margin-bottom: 45px !important;
}

.margin-bottom-50 {
    margin-bottom: 50px !important;
}

.margin-bottom-55 {
    margin-bottom: 55px !important;
}

.margin-bottom-60 {
    margin-bottom: 60px !important;
}

.margin-bottom-65 {
    margin-bottom: 65px !important;
}

.margin-bottom-70 {
    margin-bottom: 70px !important;
}

.margin-bottom-75 {
    margin-bottom: 75px !important;
}

.margin-bottom-80 {
    margin-bottom: 80px !important;
}

.margin-bottom-85 {
    margin-bottom: 85px !important;
}

.margin-bottom-90 {
    margin-bottom: 90px !important;
}

.margin-bottom-95 {
    margin-bottom: 95px !important;
}

.margin-bottom-100 {
    margin-bottom: 100px !important;
}

.margin-bottom-105 {
    margin-bottom: 105px !important;
}

.margin-bottom-110 {
    margin-bottom: 110px !important;
}

.margin-bottom-115 {
    margin-bottom: 115px !important;
}

.margin-bottom-120 {
    margin-bottom: 120px !important;
}

.margin-bottom-125 {
    margin-bottom: 125px !important;
}

.margin-bottom-130 {
    margin-bottom: 130px !important;
}

.margin-bottom-135 {
    margin-bottom: 135px !important;
}

.margin-bottom-140 {
    margin-bottom: 140px !important;
}

.margin-bottom-145 {
    margin-bottom: 145px !important;
}

.margin-bottom-150 {
    margin-bottom: 150px !important;
}


// Margin Left

.margin-left-0 {
    margin-left: 0 !important;
}

.margin-left-5 {
    margin-left: 5px !important;
}

.margin-left-10 {
    margin-left: 10px !important;
}

.margin-left-15 {
    margin-left: 15px !important;
}

.margin-left-20 {
    margin-left: 20px !important;
}

.margin-left-25 {
    margin-left: 25px !important;
}

.margin-left-30 {
    margin-left: 30px !important;
}

.margin-left-35 {
    margin-left: 35px !important;
}

.margin-left-40 {
    margin-left: 40px !important;
}

.margin-left-45 {
    margin-left: 45px !important;
}

.margin-left-50 {
    margin-left: 50px !important;
}

.margin-left-55 {
    margin-left: 55px !important;
}

.margin-left-60 {
    margin-left: 60px !important;
}

.margin-left-65 {
    margin-left: 65px !important;
}

.margin-left-70 {
    margin-left: 70px !important;
}

.margin-left-75 {
    margin-left: 75px !important;
}

.margin-left-80 {
    margin-left: 80px !important;
}

.margin-left-85 {
    margin-left: 85px !important;
}

.margin-left-90 {
    margin-left: 90px !important;
}

.margin-left-95 {
    margin-left: 95px !important;
}

.margin-left-100 {
    margin-left: 100px !important;
}

.margin-left-105 {
    margin-left: 105px !important;
}

.margin-left-110 {
    margin-left: 110px !important;
}

.margin-left-115 {
    margin-left: 115px !important;
}

.margin-left-120 {
    margin-left: 120px !important;
}

.margin-left-125 {
    margin-left: 125px !important;
}

.margin-left-130 {
    margin-left: 130px !important;
}

.margin-left-135 {
    margin-left: 135px !important;
}

.margin-left-140 {
    margin-left: 140px !important;
}

.margin-left-145 {
    margin-left: 145px !important;
}

.margin-left-150 {
    margin-left: 150px !important;
}