﻿// UTMB UIKit Variables


/*
    Mike's UIKit Quick Notes:

    // Heading Sizes
    - .uk-heading-small
        - 2.5rem
        - 3.25red (960px+)
    - .uk-heading-medium
        - 2.8875rem
        - 3.5rem (960px+)
        - 4rem (1200px+)
    - .uk-heading-large
        - 3.4rem
        - 4rem (960px)
        - 6rem (1200px)
    - .uk-heading-xlarge
        - 4rem
        - 6rem (960px+)
        - 9rem (1200px+)
    - .uk-heading-2xlarge
        - 6rem
        - 8 rem (960px+)
        - 11rem (1200px+)
*/



// Global
$global-font-family: $font-family-base;

// Font
$global-large-font-size: 1.618rem !default;

// Heading Fonts
$heading-small-font-size:                        38px; // 38px 0.73
$heading-medium-font-size:                       40px; // 40px 0.714
$heading-large-font-size:                        50px; // 50px 0.78
$heading-xlarge-font-size:                       64px; // 4rem / 64px
$heading-2xlarge-font-size:                      96px; // 6rem / 96px

$heading-small-font-size-m:                      52px; // 3.25rem / 52px
$heading-medium-font-size-m:                     56px; // 3.5rem / 56px
$heading-large-font-size-m:                      64px; // 4rem / 64px
$heading-xlarge-font-size-m:                     96px; // 6rem / 96px
$heading-2xlarge-font-size-m:                    128px; // 8rem / 128px

$heading-medium-font-size-l:                     4rem !default; // 64px
$heading-large-font-size-l:                      6rem !default; // 96px
$heading-xlarge-font-size-l:                     8rem !default; // 128px
$heading-2xlarge-font-size-l:                    11rem !default; // 176px

// Color
$global-color: $gray;
//$global-emphasis-color: $red;
$global-muted-color: $gray-5;

// Link
$global-link-color: $blue;
$global-link-hover-color: $red; //darken($global-link-color, 4%) !default; //#0f6ecd !default;

// Background
$global-primary-background: $red; // #1e87f0 !default;
$global-secondary-background: $gray; // #222 !default;
$global-tertiary-background: $blue;
$global-success-background: $green; // #32d296 !default;
$global-warning-background: $orange; // #faa05a !default;
$global-danger-background: $red; // #f0506e !default;

// Base
$base-selection-background: $red;
$base-selection-color: #ffffff;

// Breadcrumb
$breadcrumb-item-font-size: .9em;
$breadcrumb-divider-margin-horizontal: 7px;

// Buttons
$button-text-transform: none !default; //capitalize !default; //uppercase !default;

// Label
$label-text-transform: none !default; //capitalize !default; //uppercase !default;

// Modal
$modal-dialog-width: 800px;

// Nav
$nav-default-item-hover-color: $red;
$nav-default-item-active-color: $red;
//$nav-default-sublist-item-hover-color: $red;
$nav-default-sublist-item-active-color: $red;
$nav-header-text-transform: capitalize !default; //uppercase !default;
$navbar-nav-item-text-transform: capitalize !default; //uppercase !default;
$subnav-item-text-transform: capitalize !default; //uppercase !default;

// Navbar
$navbar-background: #ffffff;
$navbar-nav-item-height: 65px;
$navbar-nav-item-color: $gray;
$navbar-nav-item-active-color: $red;
$navbar-toggle-hover-color: $red;
$navbar-dropdown-nav-item-active-color: $red;
$navbar-toggle-hover-color: $red;

// Tab
$tab-item-active-color: $red;
$tab-item-active-border: $red;
$tab-item-text-transform: capitalize !default; //uppercase !default;

// Alert
// $alert-margin-vertical: $global-margin !default;
// $alert-padding: $global-small-gutter !default;
// $alert-padding-right: $alert-padding + 14px !default;
// $alert-background: $global-muted-background !default;
// $alert-color: $global-color !default;
// $alert-close-top: $alert-padding + 5px !default;
// $alert-close-right: $alert-padding !default;
// $alert-primary-background: lighten(mix(white, $global-primary-background, 40%), 20%) !default;
// $alert-primary-color: $global-primary-background !default;
// $alert-success-background: lighten(mix(white, $global-success-background, 40%), 25%) !default;
// $alert-success-color: $global-success-background !default;
// $alert-warning-background: lighten(mix(white, $global-warning-background, 45%), 15%) !default;
// $alert-warning-color: $global-warning-background !default;
// $alert-danger-background: lighten(mix(white, $global-danger-background, 40%), 20%) !default;
// $alert-danger-color: $global-danger-background !default;
// $alert-close-opacity: 0.4 !default;
// $alert-close-hover-opacity: 0.8 !default;