//
// Content: Classic Design Mode - for OLD IE
//

.lt-ie9 {
    body.design-mode-classic {

        // DESKTOP VIEW ONLY
        // ----------------------------------

        @include classic-content-desktop-view;
    
        #breadcrumbs,
        #primarycontent {
            width: $container-classic;
        }

        // UTMB Themes
    
        body.utmb-light,
        body.utmb-dark {
            &.exp-mega #header,
            &.exp-mega-full #header,
            &.exp-dropdown #header,
            &.nav-main #header{
                border-bottom: 0 !important;   
            }
            #maincontent {
                background-image: url(#{$utmb-light-content-bg-img});
                background-repeat: repeat-x;
                background-position: top;
            }
            #breadcrumbs {
                color: #ffffff;
                a { color: #ffffff; }
                .sep { color: Yellow; }
            }
            #main {
                background: #ffffff !important;
                min-height: 400px;
            }
        }
        
        // UTMB Light Theme
        
        body.utmb-light {
            background-color: $utmb-light-body-bg !important;
            #header {
                background-color: $utmb-light-header-bg !important;
                border-bottom: $utmb-light-header-border;
            }
        }
        
        // UTMB Dark Theme
        
        body.utmb-dark {
            background-color: $utmb-dark-body-bg !important;
            #header {
                background-color: $utmb-dark-header-bg !important;
                border-bottom: $utmb-dark-header-border;
            }
        }

    }
}