﻿// Themes
// ===========================================


// UTMB Light Theme (.utmb-light)

$utmb-light-body-bg: #eeeeee; //#dbd5cd; // color only
$utmb-light-content-bg-img: '../img/backgrounds/content-bg.gif';
$utmb-light-content-bg: $utmb-light-body-bg url($utmb-light-content-bg-img) 0 0 repeat-x; // fade

// // -- NEW Pattern Options -- 2018/03/09
// // ***IMPORTANT!: MUST OVERIDE CONTENT REGION BACKGROUND FOR PAGE BACKGROUND PATTERNS***
// $utmb-light-content-bg:             transparent;
// $utmb-light-body-bg:                $gray-e url('../img/backgrounds/billie_holiday.png') 0 0 fixed;
// $utmb-light-body-bg:                $gray-e url('../img/backgrounds/fabric_of_squares_gray.png') 0 0 fixed;
// $utmb-light-body-bg:                $gray-e url('../img/backgrounds/gray_jean.png') 0 0 fixed;
// $utmb-light-body-bg:                $gray-e url('../img/backgrounds/grid.png') 0 0 fixed; // Grid - good, on the darker side of light gray
// $utmb-light-body-bg:                $gray-e url('../img/backgrounds/low_contrast_linen.png') 0 0 fixed; // Low Contrast Linen - good, dark page background w/ light header is nice
// $utmb-light-body-bg:                $gray-e url('../img/backgrounds/mochaGrunge.png') 0 0 fixed;
// $utmb-light-body-bg:                $gray-e url('../img/backgrounds/p4.png') 0 0 fixed;
// $utmb-light-body-bg:                $gray-e url('../img/backgrounds/paper_fibers.png') 0 0 fixed;
// $utmb-light-body-bg:                $gray-e url('../img/backgrounds/redox_01.png') 0 0 fixed;
// $utmb-light-body-bg:                $gray-e url('../img/backgrounds/satinweave.png') 0 0 fixed;
// $utmb-light-body-bg:                $gray-e url('../img/backgrounds/squared_metal.png') 0 0 fixed; // Squared Metal - good
// // -- end/ NEW Pattern Options

$utmb-light-header-bg: #ffffff;
$utmb-light-header-border: 1px solid #777777;
$utmb-light-header-h2-color: #333333; //#616365;
$utmb-light-header-h3-color: #555555;




// UTMB Dark Theme (.utmb-dark)

$utmb-dark-body-bg: #eeeeee; //#dbd5cd;
$utmb-dark-content-bg-img: '../img/backgrounds/content-bg.gif';
$utmb-dark-content-bg: $utmb-dark-body-bg url($utmb-dark-content-bg-img) 0 0 repeat-x;
$utmb-dark-header-bg: #333333; //#5b5b5b url(../img/themes/utmb-dark/header-bg.jpg) 0 0 repeat-x;
$utmb-dark-hgroup-border: 1px solid #555555;
$utmb-dark-header-border: 1px solid #222222;
$utmb-dark-header-h2-color: #ffffff;
$utmb-dark-header-h3-color: #cccccc;
$utmb-dark-breadcrumbs-color: #ffffff;
$utmb-dark-breadcrumbs-sep-color: yellow;
