// 
// Alert Bar Buttons
// 


@mixin alert-bar-button($alertColor, $textColor) {
    em {
        color:$alertColor;
    }
    
    &:hover,
    &:active,
    &:focus {
        background: $alertColor;
        color: $textColor;
        
        em {
            color: $textColor;
        }
    }
}


// 
// Alert Bar Messages
// 

@mixin alert-bar-message($alertColor, $textColor) {
    .alert-cat-title {
        background: $alertColor;
        color: $textColor;
        
        em {
            color: $textColor;
        }
    }
}