﻿// Tabs
// -------------------------------------------


.nav-tabs {
    > li {
        > a {
            background-color: #eee;
            color: #616365;
            text-decoration: none;

            &:hover {
                background-color: #ccc;
            }
        }
    }
}

.tab-pane {
    background-color: #fff;
    padding: 15px;
}
