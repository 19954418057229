// 
// UTMB Navigate Menu: "Side Nav" for Classic Design Mode
// 

// Design Mode: Classic -- below Desktop view (991px-)
body.design-mode-classic.nav-side #navigate,
body.design-mode-classic.nav-side-right #navigate {
    display: none;
}

// Design Mode: Classic -- "Sweetspot" Desktop view and up (992px+)
@media only screen and (min-width: 992px) {
    body.design-mode-classic.nav-side,
    body.design-mode-classic.nav-side-right {
        
        #navigate {
            display: block;
            margin: 20px 0; //15px; //*NEW mgcooper 12/23/2015
            
            // Tandem nav inner wrapper / collapse toggle
            // show collapsible tandem nav menu
            #menu-navigate {
                display: block;
                height: auto !important; // *required so mobile nav js doesn't cause unwanted overflow - it has style="height:800px" thing
                
                // Title
                .title-area {
                    border: 0 none;
                    clip: rect(0px, 0px, 0px, 0px);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }

                // Menu Container Section
                .menu-container {
                    
                    // Site Nav Menu
                    ul.nav-menu {
                        list-style: none;
                        list-style-type: none;
                        margin: 0;
                        padding: 0;
                        
                        // All Tiers
                        li {
                            border-radius: 0;
                            margin: 0;
                            padding: 0;

                            a {
                                color: $nav-link-color;
                                display: block;
                                font-family: $font-family-condensed;
                                font-size: .97em;
                                padding: 9px 15px;
                                position: relative;
                                text-decoration: none;
                                
                                // caret right
                                .caret {
                                    border-left: 4px dashed;
                                    border-left: 4px solid;
                                    border-top: 4px solid transparent;
                                    border-bottom: 4px solid transparent;
                                    border-right: 0;
                                    color: $gray-9;
                                    position: absolute;
                                    right: 15px;
                                    top: 16px;
                                }

                                &:hover {
                                    background-color: #eeeeee;
                                }
                                
                                // flip caret
                                &:active,
                                &:focus,
                                &.active,
                                &[aria-expanded="true"] {
                                    background-color: #e1e1e1;

                                    // caret down
                                    .caret {
                                        border-top: 4px dashed;
                                        border-top: 4px solid;
                                        border-right: 4px solid transparent;
                                        border-left: 4px solid transparent;
                                        border-bottom: 0;
                                        top: 18px;
                                    }
                                }
                                
                                // current
                                &.current,
                                &.current.active,
                                &.current[aria-expanded="true"],
                                &.current.active[aria-expanded="true"] {
                                    background-color: $gray-1;
                                    color: $white;
                                }
                                
                            }

                            &.divider {
                                height: 1px;
                                margin: 9px 1px;
                                overflow: hidden;
                                background-color: $gray;
                                border-bottom: 1px solid $gray;
                            }
                        }

                        > li:nth-child(1) > a {
                            border-top: 0px solid $gray-e;
                        }
                        
                        // First Tier
                        > li {

                            > a {
                                border-top: 1px solid $gray-e;
                                color: $nav-link-color;
                            }
                            
                            // Nested Tiers
                            // Tier 2
                            div.submenu {
                                background-color: #f2f2f2;
                                display: none;

                                &.active {
                                    display: block;
                                }

                                ul.nav-menu {
                                    list-style: none;
                                    list-style-type: none;
                                    margin: 0;
                                    padding: 7px 0 10px;

                                    li:first-child > a {
                                        border-top: 0px solid $gray-e;
                                    }

                                    li {
                                        a {
                                            border-top: 1px solid #e1e1e1;
                                            font-size: .9em;
                                            padding: 7px 15px;
                                            .caret {
                                                top: 13px;
                                            }

                                            // hover
                                            &:hover {
                                                background-color: #e9e9e9;
                                            }

                                            // flip caret
                                            &.active,
                                            &[aria-expanded="true"] {
                                                background-color: #f2f2f2;
                                                
                                                // caret down
                                                .caret {
                                                    top: 15px;
                                                }
                                            }
                                            
                                            // current
                                            &.current,
                                            &.current.active,
                                            &.current[aria-expanded="true"],
                                            &.current.active[aria-expanded="true"] {
                                                background-color: $gray-1;
                                                color: $white;
                                            }
                                            
                                        }

                                        // The Deep Nest...
                                        // Tier 3
                                        div.submenu {
                                            ul.nav-menu {
                                                list-style: none;
                                                list-style-type: none;
                                                margin: 0;
                                                padding: 7px 0 10px;
                                                padding-left: 6px; //10px;

                                                li {

                                                    &:first-child {
                                                        a {
                                                            border-top: 1px solid #e1e1e1;
                                                        }
                                                    }

                                                    &:last-child {
                                                        a {
                                                            border-bottom: 1px solid #e1e1e1;
                                                        }
                                                    }

                                                    a {
                                                        background-color: #fff;
                                                        border-top: 1px solid $gray-e;

                                                        // hover
                                                        &:hover {
                                                            background-color: #eee;
                                                        }

                                                        // current
                                                        &.current,
                                                        &.current.active,
                                                        &.current[aria-expanded="true"],
                                                        &.current.active[aria-expanded="true"] {
                                                            background-color: $gray-1;
                                                            color: $white;
                                                        }
                                                    }

                                                    // Tier 4
                                                    div.submenu {
                                                        
                                                        ul.nav-menu {
                                                            background-color: #fff;
                                                            list-style: none;
                                                            list-style-type: none;
                                                            margin: 0;
                                                            padding-left: 6px;

                                                            li {
                                                                a {
                                                                    border-top: 1px solid #e1e1e1;
                                                                    background-color: #f2f2f2;

                                                                    // hover
                                                                    &:hover {
                                                                        background-color: #e9e9e9;
                                                                    }

                                                                    // current
                                                                    &.current,
                                                                    &.current.active,
                                                                    &.current[aria-expanded="true"],
                                                                    &.current.active[aria-expanded="true"] {
                                                                        background-color: $gray-1;
                                                                        color: $white;
                                                                    }
                                                                }

                                                                // Tier 5
                                                                div.submenu {
                                                                    ul.nav-menu {
                                                                        background-color: #f2f2f2;
                                                                        list-style: none;
                                                                        list-style-type: none;
                                                                        margin: 0;
                                                                        padding-left: 6px;

                                                                        li {
                                                                            a {
                                                                                border-top: 1px solid #fff;
                                                                                background-color: #e9e9e9;
                                                                                background-color: #e3e3e3;
                                                                                background-color: #e1e1e1;
                                                                                
                                                                                &:hover {
                                                                                    background-color: #e3e3e3;
                                                                                    background-color: #e9e9e9;
                                                                                }

                                                                                // current
                                                                                &.current,
                                                                                &.current.active,
                                                                                &.current[aria-expanded="true"],
                                                                                &.current.active[aria-expanded="true"] {
                                                                                    background-color: $gray-1;
                                                                                    color: $white;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } 
                            } // end Tier 2
                        } // end Tier 1
                    } // end ul.nav (#nav-site)
                } // end .menu-container
            } // end .collapse.tandem-nav - now #menu-navigate
        }
    }




    // Desktop View
    // .nav-side and .nav-side-right menu positioning/refinement
    @media only screen and (min-width: $utmb-sweetspot) {
        body.nav-side {
            #navigate {
                #menu-navigate {
                    margin: 0;
                    padding-left: 1.3em;
                    padding-top: 1.618em;
                }
            }
        }
        
        body.nav-side-right {
            #navigate {
                #menu-navigate {
                    margin: 0;
                    padding-right: 1.618em;
                    padding-top: 1.618em;
                }
            }
        }
    }







    // Main Grid w/out Aside
    body.nav-side {
        
        // *Note: used to be #main
        #primarycontent {

            #article {
                display: block;
                float: left;
                left: 22% !important;
                position: relative;
                right: auto;
                top: 0;
                width: 78% !important;
                
                #bodycontent,
                .padding {
                    //padding: 25px 30px 15px 30px;
                    padding: 25px 40px 40px 40px;
                }
                
                #bodycontent {
                    min-height: 600px;
                }
            }

            #aside {
                display: none;
            }

            #navigate {
                background: transparent;
                display: block;
                float: left;
                left: auto;
                //left: 0;
                position: relative;
                right: 78% !important;
                top: 0;
                width: 22% !important;
            }
        }
    }






    // Main Grid w/ Aside

    body.nav-side.aside {
        
        // *Note: used to be #main
        #primarycontent {
            
            #article {
                display: block;
                float: left;
                left: 22% !important;
                position: relative;
                right: auto;
                top: 0;
                width: 53% !important;

                #bodycontent,
                .padding {
                    //padding: 25px 30px 15px 30px;
                    padding: 25px 35px 35px 35px;
                }
            }

            #aside {
                display: block;
                float: left;
                position: relative;
                right: auto;
                top: 0;
                width: 25% !important;
            }

            #navigate {
                background: transparent;
                display: block;
                float: left;
                position: relative;
                right: 53% !important;
                left: auto;
                top: 0;
                width: 22% !important;
            }
        }	// end/ Main Grid Changes for .nav-side(.aside)
    }




    // Main Grid w/out Aside

    body.nav-side-right {
        
        // *Note: used to be #main
        #primarycontent {

            #article {
                position: relative;
                display: block;
                float: left;
                width: 75% !important;
                left: auto; //22% !important;
                right: auto;
                top: 0;

                #bodycontent,
                .padding {
                    //padding: 25px 30px 15px 30px;
                    padding: 25px 40px 40px 40px;
                }
            }

            #aside {
                display: none;
            }

            #navigate {
                background: transparent;
                position: relative;
                display: block;
                float: left;
                width: 25% !important;
                right: auto; //78% !important;
                left: auto;
                top: 0;
            }
        }
    }






    // Main Grid w/ Aside

    body.nav-side-right.aside {
        
        // *Note: used to be #main
        #primarycontent {
            
            #article {
                position: relative;
                display: block;
                float: left;
                width: 53% !important;
                left: 25% !important;
                right: auto;
                top: 0;

                #bodycontent,
                .padding {
                    //padding: 25px 30px 15px 30px;
                    padding: 25px 35px 35px 35px;
                }
            }

            #aside {
                position: relative;
                display: block;
                float: left;
                width: 25% !important;
                left: -53% !important;
                right: auto;
                top: 0;

                .padding {
                    padding: 15px;
                }
            }

            #navigate {
                background: transparent;
                position: relative;
                display: block;
                float: right;
                width: 22% !important;
                right: auto; //53% !important;
                left: auto;
                top: 0;
            }
        }	// end/ Main Grid Changes for .nav-side(.aside)
    }
}