// UTMB Card

.utmb-card {
    @extend .uk-card;
    @extend .uk-card-default;
    @extend .uk-card-body;

    .utmb-page-title {
        margin-top: 0;
    }
}


.utmb-card-small {
    @extend .uk-card;
    @extend .uk-card-default;
    @extend .uk-card-body;
    padding: 20px;

    .utmb-page-title {
        margin-top: 0;
    }
}


.utmb-card-base {
    @extend .uk-card;
    @extend .uk-card-default;
    @extend .uk-card-body;
    padding: 0;

    .utmb-page-title {
        margin-top: 0;
    }
}