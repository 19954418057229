/* hero overlay content */

.hero-overlay {
    @extend .uk-overlay;
    padding-bottom: 222px;
    padding-top: 111px;

    h2 {
        color: #ffffff;
        font-size: 3.33em;
        max-width: 555px;

        .subtitle {
            color: #ffffff;
            font-size: 55%;

            &.meta {
                color: #ffffff;
                font-size: 33%;
                margin-bottom: 8px;
            }
        }
    }

    p {
        color: #ffffff;
        max-width: 639px;

        &.lead {
            color: #ffffff;
            margin-bottom: 30px;
        }
    }

    .btn-default {
        color: #ffffff;

        &:hover,
        &:active,
        &:focus {
            background-color: #da1f12;
        }
    }

    .hero-overlay-actions {
        text-align: center;

        .btn-default {
            margin: 1%;
            padding-top: 20px;
            padding-bottom: 10px;
            width: 46%;

            .btn-label {
                display: block;
            }
        }
    }


    // size - top and bottom padding

    &.hero-overlay-medium {
        padding-bottom: 111px;
        padding-top: 111px;
    }

    &.hero-overlay-small {
        padding-bottom: 30px;
        padding-top: 30px;

        h2 {
            color: #ffffff;
            font-size: 2.22em;
            max-width: 555px;
    
            .subtitle {
                color: #ffffff;
                font-size: 55%;
    
                &.meta {
                    color: #ffffff;
                    font-size: 33%;
                    margin-bottom: 8px;
                }
            }
        }
    }
}

