// Padding

.padding-0 {
    padding: 0 !important;
}

.padding-5 {
    padding: 5px !important;
}

.padding-10 {
    padding: 10px !important;
}

.padding-15 {
    padding: 15px !important;
}

.padding-20 {
    padding: 20px !important;
}

.padding-25 {
    padding: 25px !important;
}

.padding-30 {
    padding: 30px !important;
}

.padding-35 {
    padding: 35px !important;
}

.padding-40 {
    padding: 40px !important;
}

.padding-45 {
    padding: 45px !important;
}

.padding-50 {
    padding: 50px !important;
}

.padding-55 {
    padding: 55px !important;
}

.padding-60 {
    padding: 60px !important;
}

.padding-65 {
    padding: 65px !important;
}

.padding-70 {
    padding: 70px !important;
}

.padding-75 {
    padding: 75px !important;
}

.padding-80 {
    padding: 80px !important;
}

.padding-85 {
    padding: 85px !important;
}

.padding-90 {
    padding: 90px !important;
}

.padding-95 {
    padding: 95px !important;
}

.padding-100 {
    padding: 100px !important;
}

.padding-105 {
    padding: 105px !important;
}

.padding-110 {
    padding: 110px !important;
}

.padding-115 {
    padding: 115px !important;
}

.padding-120 {
    padding: 120px !important;
}

.padding-125 {
    padding: 125px !important;
}

.padding-130 {
    padding: 130px !important;
}

.padding-135 {
    padding: 135px !important;
}

.padding-140 {
    padding: 140px !important;
}

.padding-145 {
    padding: 145px !important;
}

.padding-150 {
    padding: 150px !important;
}




// Padding Left

.padding-top-0 {
    padding-top: 0 !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-top-10 {
    padding-top: 10px !important;
}

.padding-top-15 {
    padding-top: 15px !important;
}

.padding-top-20 {
    padding-top: 20px !important;
}

.padding-top-25 {
    padding-top: 25px !important;
}

.padding-top-30 {
    padding-top: 30px !important;
}

.padding-top-35 {
    padding-top: 35px !important;
}

.padding-top-40 {
    padding-top: 40px !important;
}

.padding-top-45 {
    padding-top: 45px !important;
}

.padding-top-50 {
    padding-top: 50px !important;
}

.padding-top-55 {
    padding-top: 55px !important;
}

.padding-top-60 {
    padding-top: 60px !important;
}

.padding-top-65 {
    padding-top: 65px !important;
}

.padding-top-70 {
    padding-top: 70px !important;
}

.padding-top-75 {
    padding-top: 75px !important;
}

.padding-top-80 {
    padding-top: 80px !important;
}

.padding-top-85 {
    padding-top: 85px !important;
}

.padding-top-90 {
    padding-top: 90px !important;
}

.padding-top-95 {
    padding-top: 95px !important;
}

.padding-top-100 {
    padding-top: 100px !important;
}

.padding-top-105 {
    padding-top: 105px !important;
}

.padding-top-110 {
    padding-top: 110px !important;
}

.padding-top-115 {
    padding-top: 115px !important;
}

.padding-top-120 {
    padding-top: 120px !important;
}

.padding-top-125 {
    padding-top: 125px !important;
}

.padding-top-130 {
    padding-top: 130px !important;
}

.padding-top-135 {
    padding-top: 135px !important;
}

.padding-top-140 {
    padding-top: 140px !important;
}

.padding-top-145 {
    padding-top: 145px !important;
}

.padding-top-150 {
    padding-top: 150px !important;
}



// Padding Right

.padding-right-0 {
    padding-right: 0 !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-right-10 {
    padding-right: 10px !important;
}

.padding-right-15 {
    padding-right: 15px !important;
}

.padding-right-20 {
    padding-right: 20px !important;
}

.padding-right-25 {
    padding-right: 25px !important;
}

.padding-right-30 {
    padding-right: 30px !important;
}

.padding-right-35 {
    padding-right: 35px !important;
}

.padding-right-40 {
    padding-right: 40px !important;
}

.padding-right-45 {
    padding-right: 45px !important;
}

.padding-right-50 {
    padding-right: 50px !important;
}

.padding-right-55 {
    padding-right: 55px !important;
}

.padding-right-60 {
    padding-right: 60px !important;
}

.padding-right-65 {
    padding-right: 65px !important;
}

.padding-right-70 {
    padding-right: 70px !important;
}

.padding-right-75 {
    padding-right: 75px !important;
}

.padding-right-80 {
    padding-right: 80px !important;
}

.padding-right-85 {
    padding-right: 85px !important;
}

.padding-right-90 {
    padding-right: 90px !important;
}

.padding-right-95 {
    padding-right: 95px !important;
}

.padding-right-100 {
    padding-right: 100px !important;
}

.padding-right-105 {
    padding-right: 105px !important;
}

.padding-right-110 {
    padding-right: 110px !important;
}

.padding-right-115 {
    padding-right: 115px !important;
}

.padding-right-120 {
    padding-right: 120px !important;
}

.padding-right-125 {
    padding-right: 125px !important;
}

.padding-right-130 {
    padding-right: 130px !important;
}

.padding-right-135 {
    padding-right: 135px !important;
}

.padding-right-140 {
    padding-right: 140px !important;
}

.padding-right-145 {
    padding-right: 145px !important;
}

.padding-right-150 {
    padding-right: 150px !important;
}


// Padding Bottom

.padding-bottom-0 {
    padding-bottom: 0 !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.padding-bottom-10 {
    padding-bottom: 10px !important;
}

.padding-bottom-15 {
    padding-bottom: 15px !important;
}

.padding-bottom-20 {
    padding-bottom: 20px !important;
}

.padding-bottom-25 {
    padding-bottom: 25px !important;
}

.padding-bottom-30 {
    padding-bottom: 30px !important;
}

.padding-bottom-35 {
    padding-bottom: 35px !important;
}

.padding-bottom-40 {
    padding-bottom: 40px !important;
}

.padding-bottom-45 {
    padding-bottom: 45px !important;
}

.padding-bottom-50 {
    padding-bottom: 50px !important;
}

.padding-bottom-55 {
    padding-bottom: 55px !important;
}

.padding-bottom-60 {
    padding-bottom: 60px !important;
}

.padding-bottom-65 {
    padding-bottom: 65px !important;
}

.padding-bottom-70 {
    padding-bottom: 70px !important;
}

.padding-bottom-75 {
    padding-bottom: 75px !important;
}

.padding-bottom-80 {
    padding-bottom: 80px !important;
}

.padding-bottom-85 {
    padding-bottom: 85px !important;
}

.padding-bottom-90 {
    padding-bottom: 90px !important;
}

.padding-bottom-95 {
    padding-bottom: 95px !important;
}

.padding-bottom-100 {
    padding-bottom: 100px !important;
}

.padding-bottom-105 {
    padding-bottom: 105px !important;
}

.padding-bottom-110 {
    padding-bottom: 110px !important;
}

.padding-bottom-115 {
    padding-bottom: 115px !important;
}

.padding-bottom-120 {
    padding-bottom: 120px !important;
}

.padding-bottom-125 {
    padding-bottom: 125px !important;
}

.padding-bottom-130 {
    padding-bottom: 130px !important;
}

.padding-bottom-135 {
    padding-bottom: 135px !important;
}

.padding-bottom-140 {
    padding-bottom: 140px !important;
}

.padding-bottom-145 {
    padding-bottom: 145px !important;
}

.padding-bottom-150 {
    padding-bottom: 150px !important;
}


// Padding Left

.padding-left-0 {
    padding-left: 0 !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-left-10 {
    padding-left: 10px !important;
}

.padding-left-15 {
    padding-left: 15px !important;
}

.padding-left-20 {
    padding-left: 20px !important;
}

.padding-left-25 {
    padding-left: 25px !important;
}

.padding-left-30 {
    padding-left: 30px !important;
}

.padding-left-35 {
    padding-left: 35px !important;
}

.padding-left-40 {
    padding-left: 40px !important;
}

.padding-left-45 {
    padding-left: 45px !important;
}

.padding-left-50 {
    padding-left: 50px !important;
}

.padding-left-55 {
    padding-left: 55px !important;
}

.padding-left-60 {
    padding-left: 60px !important;
}

.padding-left-65 {
    padding-left: 65px !important;
}

.padding-left-70 {
    padding-left: 70px !important;
}

.padding-left-75 {
    padding-left: 75px !important;
}

.padding-left-80 {
    padding-left: 80px !important;
}

.padding-left-85 {
    padding-left: 85px !important;
}

.padding-left-90 {
    padding-left: 90px !important;
}

.padding-left-95 {
    padding-left: 95px !important;
}

.padding-left-100 {
    padding-left: 100px !important;
}

.padding-left-105 {
    padding-left: 105px !important;
}

.padding-left-110 {
    padding-left: 110px !important;
}

.padding-left-115 {
    padding-left: 115px !important;
}

.padding-left-120 {
    padding-left: 120px !important;
}

.padding-left-125 {
    padding-left: 125px !important;
}

.padding-left-130 {
    padding-left: 130px !important;
}

.padding-left-135 {
    padding-left: 135px !important;
}

.padding-left-140 {
    padding-left: 140px !important;
}

.padding-left-145 {
    padding-left: 145px !important;
}

.padding-left-150 {
    padding-left: 150px !important;
}