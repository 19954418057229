// 
// Alert Tab
//

#utmb-alert-tab {

    #alert-side-tab {
        background-color: $red;
        border-top: 2px solid $red;
        border-bottom: 2px solid $red;
        border-left: 2px solid $red;
        //border-top-left-radius: 3px;
        //border-bottom-left-radius: 3px;
        display: inline-block;
        position: absolute;
        text-decoration: none;
        top: 234px;
        right: 0;
        z-index: 963963;
        //transition: all .3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        -webkit-animation-name: alert-tab-slide; /* Safari 4.0 - 8.0 */
        -webkit-animation-duration: .5s; /* Safari 4.0 - 8.0 */
        animation-name: alert-tab-slide;
        animation-duration: .5s;

        #alert-side-tab-contents {
            background-color: $red;
            color: #fff;
            display: inline-block;
            font-family: $font-family-condensed;
            font-size: .96em;
            padding: 11px 15px 7px 13px;
            position: relative;
            text-align: center;
            //text-transform: uppercase;
            -webkit-animation-name: alert-tab-pulse; /* Safari 4.0 - 8.0 */
            -webkit-animation-duration: 4s; /* Safari 4.0 - 8.0 */
            -webkit-animation-iteration-count: infinite; /* Safari 4.0 - 8.0 */
            animation-name: alert-tab-pulse;
            animation-duration: 4s;
            animation-iteration-count: infinite;
            //font-size: 3rem;

            .uk-icon {
                display: block;
            }

            .alert-tab-title-text {
                display: block;
                //font-size: 1rem;
                //padding-left: 2px;
                //padding-right: 2px;
                //margin-top: 2px;
            }

            .badge {
                background-color: black;
                font-size: .75em;
                position: absolute;
                top: 5px;
                right: 7px;
                z-index: 1;
            }
        }
    }
}

// Alert Tab Modal
#alert-tab-modal {
    .alert-modal {
        background: #222;
        //color: #fff;

        .uk-close {
            color: $gray-c;

            &:hover {
                color: $gray-9;
            }
        }

        // Alert Header (alert tab only)
        .alert-modal-header {
            border-bottom: 0;
            color: #fff;
            font-family: $font-family-condensed;
            font-size: 1.618em;
            padding: 10px 0;
            text-align: center;
        }
        
        // Alerts
        .alerts-container {
            ul#alertsList {
                list-style: none;
                margin: 0 15px;
                padding: 0;

                li {
                    margin: 0;
                    padding: 0;

                    .alert-category {
                        display: block;
                        margin: 0 0 0 0;
                        padding: 10px;
                        text-align: right;

                        .alert-category-text {
                            display: inline-block;
                            font-family: $font-family-condensed;
                            font-weight: bold;
                            margin: 0 5px 0 3px;
                        }

                        .badge {
                            background-color: #000;
                        }
                    }

                    .alert-msg-container {
                        background-color: #fff;
                        margin: 0 0 1px 0;
                        padding: 20px;

                        .alert-msg-title {
                            font-family: $font-family-condensed;
                            font-size: 1.5em;
                            margin: 10px 0 0 0;
                            padding: 0;

                            &:first-child {
                                margin: 0;
                            }
                        }

                        .alert-msg-meta {
                            font-family: $font-family-condensed;
                            margin: 0 0 10px;
                            padding: 0;
                        }

                        .alert-msg {
                            font-family: $font-family-base;
                            font-size: .85em;
                            margin: 0;
                            padding: 0;
                        }
                    }

                    // Yellow Alert
                    &.yellow-alert {
                        .alert-category {
                            background-color: yellow;
                        }
                        .alert-msg-container {
                            border-left: 4px solid yellow;
                            border-right: 4px solid yellow;
                        }
                    }

                    // Red Alert
                    &.red-alert {
                        .alert-category {
                            background-color: $red;
                            color: #fff;
                        }
                        .alert-msg-container {
                            border-left: 4px solid $red;
                            border-right: 4px solid $red;
                        }
                    }

                    // Orange Alert
                    &.orange-alert {
                        .alert-category {
                            background-color: $orange;
                            color: #fff;
                        }
                        .alert-msg-container {
                            border-left: 4px solid $orange;
                            border-right: 4px solid $orange;
                        }
                    }

                    // Green Alert
                    &.green-alert {
                        .alert-category {
                            background-color: $green-mid;
                            color: #fff;
                        }
                        .alert-msg-container {
                            border-left: 4px solid $green-mid;
                            border-right: 4px solid $green-mid;
                        }
                    }

                    // No Alert Category Set
                    &.no-alert {
                        .alert-category {
                            background-color: #99ccff;
                            color: $black;
                        }
                        .alert-msg-container {
                            border-left: 4px solid #99ccff;
                            border-right: 4px solid #99ccff;
                        }
                    }

                }
            }
        }

        // Alert Footer
        .alert-modal-footer {
            text-align: center;
            padding: 12px 0;
            
            a {
                font-family: $font-family-condensed;
                font-size: 0.85em;
                color: $gray-c;
                text-decoration: none;
                
                &:hover {
                    color: #fff;
                    text-decoration: underline;
                }
            }
        }
    }
} 


/* Safari 4.0 - 8.0 */
@-webkit-keyframes alert-tab-slide {
    from {right: -100px}
    to {right: 0;}
}

/* Standard syntax */
@keyframes alert-tab-slide {
    from {right: -100px;}
    to {right: 0;}
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes alert-tab-pulse {
    //from {background-color: red;}
    //to {background-color: $red;}
    0% {background-color: $red;}
    50% {background-color: darken($red, 11%);}
    100% {background-color: $red;}
}

/* Standard syntax */
@keyframes alert-tab-pulse {
    //from {background-color: red;}
    //to {background-color: $red;}
    0% {background-color: $red;}
    50% {background-color: darken($red, 11%);}
    100% {background-color: $red;}
}