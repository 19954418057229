// 
// UTMB Explore (Insitutional) Menu: "Mega Menu" for Classic Design Mode
// 


// Design Mode: Classic -- below Desktop view (991px-)
body.design-mode-classic #explore {
    display: none;
}

// Design Mode: Classic -- "Sweetspot" Desktop view and up (992px+)
@media only screen and (min-width: 992px) {
    body.design-mode-classic.exp-mega {
        #explore {
            @include clearfix;
            box-sizing: border-box;
            background-color: #ffffff;
            border-bottom: 2px solid #eeeeee;
            border-top: 1px solid #eee;
            display: block;

            .tandem-nav {
                display: block;
                // height: auto !important;
            }

            .title-area {
                border: 0 none;
                clip: rect(0px, 0px, 0px, 0px);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            .menu-container {
                @include clearfix;
                max-width: 1100px;
                margin: 0 auto;
            }

            .nav-menu {
                display: flex;
                float: left;
                // line-height: 1.33333;
                list-style: none;
                margin: 0;
                padding: 0;

                > li {
                    box-sizing: border-box;
                    display: block;
                    float: left;
                    // line-height: 1.33333;
                    margin: 0;
                    padding: 0;

                    &.has-submenu {
                        position: relative;
                    }

                    > a {
                        background-color: transparent;
                        box-sizing: border-box;
                        color: $gray;
                        display: block;
                        font-family: $font-family-condensed;
                        /* font-size: .825em; */
                        font-size: .963em;
                        //font-weight: 500;
                        height: 45px;
                        line-height: 45px;
                        margin: 0;
                        padding: 0 10px 0 12px;
                        padding-top: 0 !important;
                        padding-bottom: 0 !important;
                        position: relative;
                        text-decoration: none;
                        transition: background-color 0.5s ease;

                        .caret {
                            color: #999;
                            display: inline-block;
                            width: 0;
                            height: 0;
                            margin-left: 2px;
                            vertical-align: middle;
                            border-top: 4px dashed;
                            border-top: 4px solid\9;
                            border-right: 4px solid transparent;
                            border-left: 4px solid transparent;
                        }

                        &:hover {
                            background-color: $red;
                            color: #ffffff;

                            .caret {
                                color: #ffffff;
                            }
                        }

                        &.active {
                            background-color: #111111;
                            color: #ffffff;

                            .caret {
                                color: #ffffff;
                            }
                        }
                    }

                    // Last Nav Item
                    &.nav-last .submenu {
                        right: -111px;
                        left: auto;     
                    }

                }

                .submenu {
                    background: #111111;
                    box-shadow: 0 6px 12px rgba(0,0,0,0.175);
                    display: none;
                    max-width: auto;
                    padding: 20px 10px 25px;
                    position: absolute;
                    top: 42px;
                    width: auto;
                    z-index: 9000;

                    &.active {
                        display: inline-block;
                    }

                    .mega {
                        display: inline-block;
                    }

                    ul {
                        display: block;
                        float: left;
                        list-style: none;
                        line-height: normal;
                        margin: 5px;
                        padding: 0px;
                        width: 170px;

                        li {
                            clear: both;
                            color: #ffffff;
                            float: none;
                            margin: 0;
                            padding: 0;

                            a {
                                color: #cccccc;
                                display: block;
                                font-family: $font-family-condensed;
                                font-size: .85em; /* $font-size-small; */
                                height: auto;
                                line-height: normal;
                                padding: 7px 5px 7px 15px !important;
                                text-decoration: none;

                                &:hover {
                                    background: #000000;
                                    color: #ffffff;
                                    text-decoration: underline;
                                }

                                &.nav-title {
                                    background-image: none;
                                    color: #ffffff;
                                    padding: 7px 0 7px 0;
                                    //font-weight: bold;
                                    //font-family: $font-family-condensed;
                                    //font-style: normal;
                                    font-family: $font-family-base;
                                    font-size: .85em;
                                }
                            }

                            &.nav-spacer a {
                                background-image: none;
                                color: #ffffff;
                                padding: 7px 0 7px 0;
                                //font-weight: bold;
                                //font-style: normal;
                            }

                            &.no-link span {
                                color: #cccccc;
                                display: block;
                                font-family: $font-family-base;
                                font-size: .85em; // $font-size-small;
                                height: auto;
                                line-height: normal;
                                padding: 7px 5px 7px 15px !important;
                                text-decoration: none;

                                &.nav-title,
                                &.nav-spacer {
                                    background-image: none;
                                    color: #ffffff;
                                    //font-weight: bold;
                                    //font-style: normal;
                                    padding: 7px 0 7px 0;
                                }
                            }
                        }
                    }
                }

                
            }
        }
    }
}




// @media only screen and (min-width: 992px)
// body.exp-mega #explore .nav>li.nav-last .submenu {
//     right: -153px;
//     left: auto;
// }