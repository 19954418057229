﻿// UTMB Section Box
// Subcontent section boxes w/ arrow down design
// ------------------------------------------------


// <div class="section-box arrow-white bg-gray arrow-below">
//     <div class="arrow"></div>
// </div>


// .section-box w/ .arrow-below option
// *NEW 4/16/21
.section-box.arrow-below {
    position: relative;

    &::after {
        content: '';
        margin: auto auto;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #cccccc;
        position: absolute;
        bottom: -20px;
        z-index: 1;
        left: 50%;
        margin-left: -20px;
    }
}


// Classic Design Mode Defaults
.design-mode-classic {
    .section-box {
        background-color: #cccccc;

        .section-container {
            max-width: $container-classic !important;
        }

        // arrow below option
        &.arrow-below::after {
            border-top: 20px solid #cccccc;
        }
    }
}

// Modern Design Mode Defaults
.design-mode-modern {
    .section-box {
        background-color: #eeeeee;

        .section-container {
            max-width: $container-modern !important;
        }

        // arrow below option
        &.arrow-below::after {
            border-top: 20px solid #eeeeee;
        }
    }
}


// override negative -15px styling
section[data-sf-element="Row"].row,
.section-box-row.row {
    margin: 0 !important;
}


// remove styling
.section-box.none {
    background-color: transparent;
    .arrow {
        display: none;
    }

    // arrow below option
    &.arrow-below::after {
        border-left: 0;
        border-right: 0;
        border-top: 0;
    }
}

// Hide Inner Arrow 
// *Does not affect .arrow-below option
.section-box.arrow-hide .arrow {
    display: none;
    padding-top: 30px;
}


// styles
.section-box {
    box-sizing: border-box;
    padding: 0 30px 30px 30px;
    width: 100%;
    margin: 0;

    .arrow {
        margin: auto auto;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid $white;
    }

    .section-container {
        margin: 0 auto;
    }

    // legacy default styling
    &.default {
        background-color: #cccccc;
        // padding: 0 30px 30px 30px;

        .arrow {
            display: block;
        }
    }

    // Mobile View
    @media only screen and (max-width: $utmb-small-max) {
        padding: 0 16px 16px 16px;

        &.arrow-hide {
            padding-top: 16px;
        }
    }
    



    // 
    // Section Box Background + Arrow Colors
    // 

    // red

    &.bg-red,
    &.red-bg {
        background-color: $red;
        @include bg-gradiant-builder($red);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $red;
        }
    }
    &.arrow-red .arrow {
        border-top: 20px solid $red;
    }

    // gray

    &.bg-gray,
    &.gray-bg {
        background-color: $gray;
        @include bg-gradiant-builder($gray);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $gray;
        }
    }
    &.arrow-gray .arrow {
        border-top: 20px solid $gray;
    }

    // gray light

    &.bg-gray-light,
    &.gray-light-bg {
        background-color: $gray-light;
        @include bg-gradiant-builder($gray-light);
        &.arrow-below::after {
            border-top: 20px solid $gray-light;
        }
    }
    &.arrow-gray-light .arrow {
        border-top: 20px solid $gray-light;
    }

    // gray mid

    &.bg-gray-mid,
    &.gray-mid-bg {
        background-color: $gray-mid;
        @include bg-gradiant-builder($gray-mid);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $gray-mid;
        }
    }
    &.arrow-gray-mid .arrow {
        border-top: 20px solid $gray-mid;
    }

    // gray dark

    &.bg-gray-dark,
    &.gray-dark-bg {
        background-color: $gray-dark;
        @include bg-gradiant-builder($gray-dark);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $gray-dark;
        }
    }
    &.arrow-gray-dark .arrow {
        border-top: 20px solid $gray-dark;
    }

    // blue

    &.bg-blue,
    &.blue-bg {
        background-color: $blue;
        @include bg-gradiant-builder($blue);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $blue;
        }
    }
    &.arrow-blue .arrow {
        border-top: 20px solid $blue;
    }

    // blue light

    &.bg-blue-light,
    &.blue-light-bg {
        background-color: $blue-light;
        @include bg-gradiant-builder($blue-light);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $blue-light;
        }
    }
    &.arrow-blue-light .arrow {
        border-top: 20px solid $blue-light;
    }

    // blue mid

    &.bg-blue-mid,
    &.blue-mid-bg {
        background-color: $blue-mid;
        @include bg-gradiant-builder($blue-mid);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $blue-mid;
        }
    }
    &.arrow-blue-mid .arrow {
        border-top: 20px solid $blue-mid;
    }

    // blue navy

    &.bg-blue-navy,
    &.blue-navy-bg {
        background-color: $blue-navy;
        @include bg-gradiant-builder($blue-navy);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $blue-navy;
        }
    }
    &.arrow-blue-navy .arrow {
        border-top: 20px solid $blue-navy;
    }

    // blue dark

    &.bg-blue-dark,
    &.blue-dark-bg {
        background-color: $blue-dark;
        @include bg-gradiant-builder($blue-dark);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $blue-dark;
        }
    }
    &.arrow-blue-dark .arrow {
        border-top: 20px solid $blue-dark;
    }

    // teal

    &.bg-teal,
    &.teal-bg {
        background-color: $teal;
        @include bg-gradiant-builder($teal);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $teal;
        }
    }
    &.arrow-teal .arrow {
        border-top: 20px solid $teal;
    }

    // teal light

    &.bg-teal-light,
    &.teal-light-bg {
        background-color: $teal-light;
        @include bg-gradiant-builder($teal-light);
        &.arrow-below::after {
            border-top: 20px solid $teal-light;
        }
    }
    &.arrow-teal-light .arrow {
        border-top: 20px solid $teal-light;
    }

    // teal dark

    &.bg-teal-dark,
    &.teal-dark-bg {
        background-color: $teal-dark;
        @include bg-gradiant-builder($teal-dark);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $teal-dark;
        }
    }
    &.arrow-teal-dark .arrow {
        border-top: 20px solid $teal-dark;
    }

    // orange

    &.bg-orange,
    &.orange-bg {
        background-color: $orange;
        @include bg-gradiant-builder($orange);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $orange;
        }
    }
    &.arrow-orange .arrow {
        border-top: 20px solid $orange;
    }

    // orange light

    &.bg-orange-light,
    &.orange-light-bg {
        background-color: $orange-light;
        @include bg-gradiant-builder($orange-light);
        &.arrow-below::after {
            border-top: 20px solid $orange-light;
        }
    }
    &.arrow-orange0light .arrow {
        border-top: 20px solid $orange-light;
    }

    // orange mid

    &.bg-orange-mid,
    &.orange-mid-bg {
        background-color: $orange-mid;
        @include bg-gradiant-builder($orange-mid);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $orange-mid;
        }
    }
    &.arrow-orange-mid .arrow {
        border-top: 20px solid $orange-mid;
    }

    // orange dark

    &.bg-orange-dark,
    &.orange-dark-bg {
        background-color: $orange-dark;
        @include bg-gradiant-builder($orange-dark);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $orange-dark;
        }
    }
    &.arrow-orange-dark .arrow {
        border-top: 20px solid $orange-dark;
    }

    // green

    &.bg-green,
    &.green-bg {
        background-color: $green;
        @include bg-gradiant-builder($green);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $green;
        }
    }
    &.arrow-green .arrow {
        border-top: 20px solid $green;
    }

    // green light

    &.bg-green-light,
    &.green-light-bg {
        background-color: $green-light;
        @include bg-gradiant-builder($green-light);
        &.arrow-below::after {
            border-top: 20px solid $green-light;
        }
    }
    &.arrow-green-light .arrow {
        border-top: 20px solid $green-light;
    }

    // green bright

    &.bg-green-bright,
    &.green-bright-bg {
        background-color: $green-bright;
        @include bg-gradiant-builder($green-bright);
        &.arrow-below::after {
            border-top: 20px solid $green-bright;
        }
    }
    &.arrow-green-bright .arrow {
        border-top: 20px solid $green-bright;
    }

    // green mid

    &.bg-green-mid,
    &.green-mid-bg {
        background-color: $green-mid;
        @include bg-gradiant-builder($green-mid);
        &.arrow-below::after {
            border-top: 20px solid $green-mid;
        }
    }
    &.arrow-green-mid .arrow {
        border-top: 20px solid $green-mid;
    }

    // green dark

    &.bg-green-dark,
    &.green-dark-bg {
        background-color: $green-dark;
        @include bg-gradiant-builder($green-dark);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $green-dark;
        }
    }
    &.arrow-green-dark .arrow {
        border-top: 20px solid $green-dark;
    }

    // purple

    &.bg-purple,
    &.purple-bg {
        background-color: $purple;
        @include bg-gradiant-builder($purple);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $purple;
        }
    }
    &.arrow-purple .arrow {
        border-top: 20px solid $purple;
    }

    // yellow

    &.bg-yellow,
    &.yellow-bg {
        background-color: $yellow;
        @include bg-gradiant-builder($yellow);
        &.arrow-below::after {
            border-top: 20px solid $yellow;
        }
    }
    &.arrow-yellow .arrow {
        border-top: 20px solid $yellow;
    }

    // pink

    &.bg-pink,
    &.pink-bg {
        background-color: $pink;
        @include bg-gradiant-builder($pink);
        &.arrow-below::after {
            border-top: 20px solid $pink;
        }
    }
    &.arrow-pink .arrow {
        border-top: 20px solid $pink;
    }

    // cool gray 3

    &.bg-coolgray3,
    &.coolgray3-bg {
        background-color: $coolgray3;
        @include bg-gradiant-builder($coolgray3);
        &.arrow-below::after {
            border-top: 20px solid $coolgray3;
        }
    }
    &.arrow-coolgray3 .arrow {
        border-top: 20px solid $coolgray3;
    }

    // tan

    &.bg-tan,
    &.tan-bg {
        background-color: $tan;
        @include bg-gradiant-builder($tan);
        &.arrow-below::after {
            border-top: 20px solid $tan;
        }
    }
    &.arrow-tan .arrow {
        border-top: 20px solid $tan;
    }

    // beige

    &.bg-beige,
    &.beige-bg {
        background-color: $beige;
        @include bg-gradiant-builder($beige);
        &.arrow-below::after {
            border-top: 20px solid $beige;
        }
    }
    &.arrow-beige .arrow {
        border-top: 20px solid $beige;
    }

    // black

    &.bg-black,
    &.black-bg {
        background-color: $black;
        @include bg-gradiant-builder($black);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $black;
        }
    }
    &.arrow-black .arrow {
        border-top: 20px solid $black;
    }

    // white

    &.bg-white,
    &.white-bg {
        background-color: $white;
        @include bg-gradiant-builder($white);
        &.arrow-below::after {
            border-top: 20px solid $white;
        }
    }
    &.arrow-white .arrow {
        border-top: 20px solid $white;
    }

    // gray-1

    &.bg-gray-1,
    &.gray-1-bg {
        background-color: $gray-1;
        @include bg-gradiant-builder($gray-1);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $gray-1;
        }
    }
    &.arrow-gray-1 .arrow {
        border-top: 20px solid $gray-1;
    }

    // gray-2

    &.bg-gray-2,
    &.gray-2-bg {
        background-color: $gray-2;
        @include bg-gradiant-builder($gray-2);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $gray-2;
        }
    }
    &.arrow-gray-2 .arrow {
        border-top: 20px solid $gray-2;
    }

    // gray-3

    &.bg-gray-3,
    &.gray-3-bg {
        background-color: $gray-3;
        @include bg-gradiant-builder($gray-3);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $gray-3;
        }
    }
    &.arrow-gray-3 .arrow {
        border-top: 20px solid $gray-3;
    }

    // gray-4

    &.bg-gray-4,
    &.gray-4-bg {
        background-color: $gray;
        @include bg-gradiant-builder($gray-4);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $gray-4;
        }
    }
    &.arrow-gray-4 .arrow {
        border-top: 20px solid $gray-4;
    }

    // gray-5

    &.bg-gray-5,
    &.gray-5-bg {
        background-color: $gray-5;
        @include bg-gradiant-builder($gray-5);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $gray-5;
        }
    }
    &.arrow-gray-5 .arrow {
        border-top: 20px solid $gray-5;
    }

    // gray-6

    &.bg-gray-6,
    &.gray-6-bg {
        background-color: $gray-6;
        @include bg-gradiant-builder($gray-6);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $gray-6;
        }
    }
    &.arrow-gray-6 .arrow {
        border-top: 20px solid $gray-6;
    }

    // gray-7

    &.bg-gray-7,
    &.gray-7-bg {
        background-color: $gray;
        @include bg-gradiant-builder($gray-7);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $gray-7;
        }
    }
    &.arrow-gray-7 .arrow {
        border-top: 20px solid $gray-7;
    }

    // gray-8

    &.bg-gray-8,
    &.gray-8-bg {
        background-color: $gray-8;
        @include bg-gradiant-builder($gray-8);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $gray-8;
        }
    }
    &.arrow-gray-8 .arrow {
        border-top: 20px solid $gray-8;
    }

    // gray-9

    &.bg-gray-9,
    &.gray-9-bg {
        background-color: $gray-9;
        @include bg-gradiant-builder($gray-9);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $gray-9;
        }
    }
    &.arrow-gray-9 .arrow {
        border-top: 20px solid $gray-9;
    }

    // gray-a

    &.bg-gray-a,
    &.gray-a-bg {
        background-color: $gray-a;
        @include bg-gradiant-builder($gray-a);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $gray-a;
        }
    }
    &.arrow-gray-a .arrow {
        border-top: 20px solid $gray-a;
    }

    // gray-b

    &.bg-gray-b,
    &.gray-b-bg {
        background-color: $gray-b;
        @include bg-gradiant-builder($gray-b);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $gray-b;
        }
    }
    &.arrow-gray-b .arrow {
        border-top: 20px solid $gray-b;
    }

    // gray-c

    &.bg-gray-c,
    &.gray-c-bg {
        background-color: $gray-c;
        @include bg-gradiant-builder($gray-c);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $gray-c;
        }
    }
    &.arrow-gray-c .arrow {
        border-top: 20px solid $gray-c;
    }

    // gray-d

    &.bg-gray-d,
    &.gray-d-bg {
        background-color: $gray-d;
        @include bg-gradiant-builder($gray-d);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $gray-d;
        }
    }
    &.arrow-gray-d .arrow {
        border-top: 20px solid $gray-d;
    }

    // gray-e

    &.bg-gray-e,
    &.gray-e-bg {
        background-color: $gray-e;
        @include bg-gradiant-builder($gray-e);
        .list-title {
            color: $gray !important;
        }
        // .content-toggle a {
        //     color: $gray !important;
        // }
        &.arrow-below::after {
            border-top: 20px solid $gray-e;
        }
    }
    &.arrow-gray-e .arrow {
        border-top: 20px solid $gray-e;
    }

    // gray-bs

    &.bg-gray-bs,
    &.gray-bs-bg {
        background-color: $gray;
        @include bg-gradiant-builder($gray-bs);
        .list-title {
            color: $white !important;
        }
        .content-toggle a {
            color: $white !important;
        }
        &.arrow-below::after {
            border-top: 20px solid $gray-bs;
        }
    }
    &.arrow-gray-bs .arrow {
        border-top: 20px solid $gray-bs;
    }

    
}



// Old IE

.lt-ie9 .section-box {
    padding: 30px;
}

.lt-ie9 .design-mode-classic .section-box .section-container {
    width: $container-classic !important;
    margin: 0 auto !important;
}

.lt-ie9 .design-mode-modern .section-box .section-container {
    width: $container-modern !important;
    margin: 0 auto !important;
}

.lt-ie9 .section-box .arrow {
    display: none;
}
