﻿// Boostrap Responsive Tables
// Firefox Fix
// see: http://getbootstrap.com/css/#tables
// see: https://stackoverflow.com/questions/17408815/fieldset-resizes-wrong-appears-to-have-unremovable-min-width-min-content/17863685#17863685

@-moz-document url-prefix() {
    fieldset {
        display: table-cell;
    }
}
