﻿//
// Wells: UTMB Branding Color Palette
// --------------------------------------------------


//.well {
//  min-height: 20px;
//  padding: 19px;
//  margin-bottom: 20px;
//  background-color: $well-bg;
//  border: 1px solid $well-border;
//  border-radius: $border-radius-base;
//  @include box-shadow(inset 0 1px 1px rgba(0,0,0,.05));
//  blockquote {
//    border-color: #ddd;
//    border-color: rgba(0,0,0,.15);
//  }
//}




//lighten($border, 25%);

// UTMB Wells
// -----------------------------

.well {
    background-color: $white;
    border: 1px solid $gray-d;
    border-radius: 0 !important;
    margin-top: 20px;
    padding: 20px;

    strong, b {
        font-weight: bold;
    }

    a {
        text-decoration: underline;

        &:active,
        &:focus,
        &:hover {
            text-decoration: underline;
            border-bottom: 1px solid $red;
            color: $red;
        }
    }

    &.callout {
        background-color: $gray-2;
        color: $white;
        font-weight: bold;

        h1, h2, h3, h4, h5, p, strong, b {
            color: $white;
        }

        a {
            text-decoration: underline;
            color: $white;
    
            &:active,
            &:focus,
            &:hover {
                text-decoration: underline;
                border-bottom: 1px solid $white;
                color: $white;
            }
        }
    }
    

    // red
    &.well-red {
        //@include utmb-wells($black, lighten($red, 40%), lighten($red, 20%));
        background-color: $white; //lighten($red, 44%);
        border-color: $red;
        //color: $red;

        a {
            color: $red; //darken($red, 30%);
    
            &:active,
            &:focus,
            &:hover {
                color: $red;
                border-bottom: 1px solid $red;
            }
        }

        &.callout {
            background-color: $red;

            a {
                color: $white;
        
                &:active,
                &:focus,
                &:hover {
                    color: $white;
                    border-bottom: 1px solid $white;
                }
            }
        }
    }

    // gray
    &.well-gray {
        //@include utmb-wells($black, lighten($gray, 40%), lighten($gray, 20%));
        background-color: $gray-light;
        border-color: $gray;
        //color: $gray;

        a {
            color: $gray;
    
            &:active,
            &:focus,
            &:hover {
                color: $gray;
                border-bottom: 1px solid $gray;
            }
        }

        &.callout {
            background-color: $gray;

            a {
                color: $white;
        
                &:active,
                &:focus,
                &:hover {
                    color: $white;
                    border-bottom: 1px solid $white;
                }
            }
        }
    }

    // blue
    &.well-blue {
        //@include utmb-wells($black, lighten($blue, 40%), lighten($blue, 20%));
        background-color: $blue-light;
        border-color: $blue;
        //color: $blue;

        a {
            color: $blue;
    
            &:active,
            &:focus,
            &:hover {
                color: $blue;
                border-bottom: 1px solid $blue;
            }
        }

        &.callout {
            background-color: $blue;

            a {
                color: $white;
        
                &:active,
                &:focus,
                &:hover {
                    color: $white;
                    border-bottom: 1px solid $white;
                }
            }
        }
    }

    // green
    &.well-green {
        //@include utmb-wells($black, lighten($green, 40%), lighten($green, 20%));
        background-color: $green-light;
        border-color: $green;
        //color: $green;

        a {
            color: darken($green, 30%);
    
            &:active,
            &:focus,
            &:hover {
                color: $green;
                border-bottom: 1px solid $green;
            }
        }

        &.callout {
            background-color: $green;

            a {
                color: $white;
        
                &:active,
                &:focus,
                &:hover {
                    color: $white;
                    border-bottom: 1px solid $white;
                }
            }
        }
    }

    // orange
    &.well-orange {
        //@include utmb-wells($black, lighten($orange, 40%), lighten($orange, 20%));
        background-color: $orange-light;
        border-color: $orange-dark;
        //color: $orange;

        a {
            color: darken($orange-dark, 30%);
    
            &:active,
            &:focus,
            &:hover {
                color: $orange-dark;
                border-bottom: 1px solid $orange-dark;
            }
        }

        &.callout {
            background-color: $orange-dark;

            a {
                color: $white;
        
                &:active,
                &:focus,
                &:hover {
                    color: $white;
                    border-bottom: 1px solid $white;
                }
            }
        }
    }

    // purple
    &.well-purple {
        //@include utmb-wells($black, lighten($purple, 50%), lighten($purple, 20%));
        background-color: lighten($purple, 44%);
        border-color: $purple;
        //color: $purple;

        a {
            color: darken($purple, 30%);
    
            &:active,
            &:focus,
            &:hover {
                color: $purple;
                border-bottom: 1px solid $purple;
            }
        }

        &.callout {
            background-color: $purple;

            a {
                color: $white;
        
                &:active,
                &:focus,
                &:hover {
                    color: $white;
                    border-bottom: 1px solid $white;
                }
            }
        }
    }

    // teal
    &.well-teal {
        //@include utmb-wells($black, lighten($teal, 40%), lighten($teal, 20%));
        background-color: $teal-light;
        border-color: $teal;
        //color: $teal;

        a {
            color: darken($teal, 30%);
    
            &:active,
            &:focus,
            &:hover {
                color: $teal;
                border-bottom: 1px solid $teal;
            }
        }

        &.callout {
            background-color: $teal;

            a {
                color: $white;
        
                &:active,
                &:focus,
                &:hover {
                    color: $white;
                    border-bottom: 1px solid $white;
                }
            }
        }
    }

    // coolgray3 -- DEPRECATED 4x
    &.well-coolgray3 {
        //@include utmb-wells($black, lighten($coolgray3, 15%), lighten($coolgray3, 20%));
        background-color: $gray-light;
        border-color: $gray;
        color: $gray;

        a {
            color: $gray;
    
            &:active,
            &:focus,
            &:hover {
                color: $gray;
                border-bottom: 1px solid $gray;
            }
        }
    }

    // black
    &.well-black {
        //@include utmb-wells($white, lighten($black, 40%), lighten($black, 20%));
        background-color: $black;
        border-color: $gray-2;
        color: $white;

        h1, h2, h3, h4, h5, p {
            color: $white;
        }

        a {
            color: $white;
    
            &:active,
            &:focus,
            &:hover {
                color: $white;
                border-bottom: 1px solid $white;
            }
        }

        &.callout {
            background-color: $black;

            a {
                color: $white;
        
                &:active,
                &:focus,
                &:hover {
                    color: $white;
                    border-bottom: 1px solid $white;
                }
            }
        }
    }

    // white
    &.well-white {
        //@include utmb-wells($black, $white, #dddddd);
        background-color: $white;
        border-color: $gray-c;
        //color: $gray;

        a {
            color: $gray;
    
            &:active,
            &:focus,
            &:hover {
                color: $red;
                border-bottom: 1px solid $red;
            }


        }

        &.callout {
            background-color: $white;
        }
    }

}


