// Embeds responsive
//


// Flex Video
// c/o Foundaiton


// .utmb-video,
// .flex-video {
//     position: relative;
//     padding-top: 25px;
//     padding-bottom: 67.5%;
//     height: 0;
//     margin-bottom: 16px;
//     overflow: hidden;
// }

// .utmb-video.widescreen,
// .flex-video.widescreen {
//     padding-bottom: 56.34%;
// }

// .utmb-video.vimeo,
// .flex-video.vimeo {
//     padding-top: 0;
// }

// .utmb-video iframe,
// .flex-video iframe,
// .utmb-video object,
// .flex-video object,
// .utmb-video embed,
// .flex-video embed,
// .utmb-video video,
// .flex-video video {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
// }




// Credit: Nicolas Gallagher and SUIT CSS + Boostrap 4

.video-embed,
.flex-video {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }
  
  .video-embed::before,
  .flex-video::before {
    display: block;
    content: "";
    padding-top: 56.25%; // 16x9
  }
  
  .video-embed .embed-responsive-item,
  .video-embed iframe,
  .video-embed embed,
  .video-embed object,
  .video-embed video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  
  .video-embed-21by9::before {
    padding-top: 42.857143%;
  }
  
  .video-embed-16by9::before {
    padding-top: 56.25%; // default
  }
  
  .video-embed-4by3::before {
    padding-top: 75%;
  }
  
  .video-embed-1by1::before {
    padding-top: 100%;
  }


 