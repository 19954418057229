//
// UTMB Sitefinity Pagination 
// for content lists - i.e. news, events, etc
// ----------------------------------------------

// legacy SF
ul.pagination {

    li {

        &:first-child a {
            //font-size: 0.85em;
            color: #b2b2b2;
        }

        &:last-child a {
            //font-size: 0.85em;
            color: #b2b2b2;
        }

        a {
            font-size: 0.8em;
        }

        &.current {
            a {
                background: $gray;
                color: #fff !important;
            }
        }
    }
    
}


// new SF
.pagination > .active > a, 
.pagination > .active > a:focus, 
.pagination > .active > a:hover, 
.pagination > .active > span, 
.pagination > .active > span:focus, 
.pagination > .active > span:hover {
    z-index: 3;
    background: #fff; //$gray;
    color: $red; // #fff !important;
    border-color: $gray-d;
    cursor: default;

    -moz-box-shadow:    inset 0 0 10px $gray;
    -webkit-box-shadow: inset 0 0 10px $gray;
    box-shadow:         inset 0 0 10px $gray;
}

.pagination > li.active.page-item > a.page-link {
    background: #fff; //$gray;
    color: $red;
    border-color: $gray-d;
    -moz-box-shadow:    inset 0 0 10px $gray-e;
    -webkit-box-shadow: inset 0 0 10px $gray-e;
    box-shadow:         inset 0 0 10px $gray-e;
}
.pagination > li.active.page-item > a.page-link::before {
    color: $red;
}



// New Truncated Pagination - 10/2024

.utmbsf-pagination-truncated {
    margin: 30px 0;
    font-size: 0;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: inline-block;

        li {
            display: inline-block;
            margin: 0 2px;

            .ellipses {
                text-decoration: none;
                color: #444;
                display: inline-block;
                border-radius: 0; //3px;
                padding: 5px 5px;
                font-size: .9rem;

                height: 44px;
                line-height: 44px;
                padding: 0;
                text-align: center;
                font-size: 1rem;
            }

            a {
                text-decoration: none;
                color: #000;
                display: inline-block;
                background: #fff;
                border: 1px solid $gray-d;
                border-radius: 0; //3px;
                padding: 5px 10px;
                font-size: .9rem;

                width: 44px;
                height: 44px;
                line-height: 44px;
                padding: 0;
                text-align: center;
                font-size: 1rem;

                span {
                    padding:0;
                }
                
                &:hover,
                &:active,
                &:focus {
                    text-decoration: underline;
                    color: $red;
                }
            }
            
            &.active {
                a {
                background: $red;
                border: 1px solid $red;
                color: $white;
            
                &:hover {
                    text-decoration: none;
                    cursor: default;
                }
                }
            }
        }
    }

    // &.small {
    //   margin: 20px 0;

    //   ul li a {
    //     padding: 5px 10px;
    //     font-size: .9rem;
    //     height: auto;
    //     width: auto;
    //     min-height: 22px;
    //     min-width: 22px;
    //     line-height: normal;
    //   }

    //   ul li .ellipses {
    //     padding: 5px 5px;
    //     font-size: .9rem;
    //     height: auto;
    //     width: auto;
    //     min-height: 22px;
    //     min-width: 22px;
    //     line-height: normal;
    //   }
    // }
    
}

// parent-level styling for smalle pager
.pager-small {
  .utmbsf-pagination-truncated {
    margin: 20px 0;

    ul li a {
      padding: 5px 10px;
      font-size: .9rem;
      height: auto;
      width: auto;
      min-height: 22px;
      min-width: 22px;
      line-height: normal;
    }

    ul li .ellipses {
      padding: 5px 5px;
      font-size: .9rem;
      height: auto;
      width: auto;
      min-height: 22px;
      min-width: 22px;
      line-height: normal;
    }
  }
}


// .utmbsf-pagination-truncated {
//   margin: 30px 0;
// }

//   .utmbsf-pagination-truncated ul li a {
//       width: 44px;
//       height: 44px;
//       line-height: 44px;
//       padding: 0;
//       text-align: center;
//       font-size: 1rem;
//   }

//   .utmbsf-pagination-truncated ul li .ellipses {
//       height: 44px;
//       line-height: 44px;
//       padding: 0;
//       text-align: center;
//       font-size: 1rem;
//   }