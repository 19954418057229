// 
// Alert Bar
// ----------------------------------------

#utmbAlertSystem {
    background: $gray-4; //#444;
    border-top: 1px solid $gray-1;
    border-bottom: 1px solid $gray-1;
    display: none;
    margin: 0;
    padding: 0;
    @include clearfix;

    &.is-active {
        display: block;
    }
}

#utmb-alert-bar {
    padding: 0 0 4px 0;
    @include page-container-classic();
    
    // Alert Bar Title
    a.alert-bar-title {
        color: orange;
        color: $white;
        display: block;
        float: left;
        // font-weight: bold;
        font-size: .85em;
        text-transform: uppercase;
        text-decoration: none;
        margin: 10px 13px 0 0;
        padding: 0 0 0 20px;
        position: relative;
        
        // icon
        i {
            color: $orange;
            color: $gray-9;
            display: inline-block;
            //font-size: 18px;
            @include convertPixelsToRem_fontSize(18); // output: font-size: ($value / 16) + rem;
            position: absolute;
            top: 1px;
            left: 0px;
        }
        
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            color: $white;
            
            i {
                color: $white;
            }
        }
    }
    
    // Button List
    ul {
        display: inline; 
        list-style: none; 
        margin: 0;
        padding: 0;
    }
    
    // Alert Bar Buttons
    .btn-alert-bar {
        color: $white;
        background-color: $black;
        background-image: none;
        border-color: $gray-c;
        // border-radius: 3px;
        border: 0;
        cursor: pointer;
        display: block;
        float: left;
        // font-weight: bold;
        //font-size: 12px;
        @include convertPixelsToRem_fontSize(12); // output: font-size: ($value / 16) + rem;
        line-height: 1.5;
        margin: 4px 4px 0 0;
        padding: 5px 10px;
        text-align: center;
        text-decoration: none;
        touch-action: manipulation;
        user-select: none;
        // vertical-align: middle;
        white-space: normal !important;
        word-wrap: break-word;
        
        &:hover,
        &:active,
        &:focus {
            color: $gray-3;
            background-color: #e6e6e6;
            border-color: #adadad;
            background-image: none;
        }
        
        .badge { 
            background-color: $gray-3; //#333;
            border-radius: 10px;
            color: $white;
            display: inline-block;
            font-size: 12px;
            @include convertPixelsToRem_fontSize(12); // output: font-size: ($value / 16) + rem;
            // font-weight: 700;
            line-height: 1;
            min-width: 10px;
            padding: 3px 7px;
            position: relative;
            text-align: center;
            top: -1px;
            white-space: nowrap;
            vertical-align: middle;
        }
        
        em {
            margin-right: 4px;
            font-size: 125%;
        }
        
        // Red Alert Button
        &.red-alert { 
            @include alert-bar-button($red, $white); 
        }
        
        // Yellow Alert Button
        &.yellow-alert { 
            @include alert-bar-button(Yellow, $black); 
        }
        
        // Orange Alert Button
        &.orange-alert {
            @include alert-bar-button($orange, $white); 
        }
        
        // Green Alert Button
        &.green-alert {
            @include alert-bar-button($green-bright, $white);
        }
        
        // No Alert Button
        &.no-alert { 
            @include alert-bar-button(#99ccff, $black);
        }
    }
}



// Alert Bar Modals
// ----------------------------------------

.alert-modal {
    //display: none; //================================================================== TEMPORARY =============!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    
    // Alert Header Title
    .alert-cat-title {
        font-size: 1.45em;
        padding:7px;
        margin: 0 0 10px 0;
        -webkit-top-left-radius: 4px;
        -webkit-top-right-radius: 4px;
        -moz-top-left-radius: 4px;
        -moz-top-right-radius: 4px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        
        em {
            margin: 0 9px;
        }
    }
    
    // Close Button
    .close {
        margin-top: -45px;
        margin-right: 15px;
        text-decoration: none;
    }
    
    // Alert Message
    .alert-msg-container {
        border-bottom: 1px solid #eee;
        padding: 25px 50px;
        
        .alert-msg-title {
            font-size: 1.35em;
            margin: 0 0 10px 0;
            padding: 0;
        }
        
        .alert-msg-meta {
            font-size: .8em;
        }

        .alert-msg {
            font-size: 0.9em;
            line-height: 1.6em;
        }
        
        // Mobile View
        @media only screen and (max-width: 480px) {
            padding: 15px 25px;
        }
    }

    // Alert Header (alert tab only)
    .alert-modal-header {
        border-bottom: 1px solid #eee;
        color: $gray;
        padding: 12px 0;
        text-align: center;
        
        a {
            color: $gray-9;
            font-size: 1.618em;
            text-decoration: none;
            
            &:hover {
                color: $gray;
            }
        }
    }
    
    // Alert Footer
    .alert-modal-footer {
        text-align: center;
        padding: 12px 0;
        
        a {
            font-size: 0.8em;
            color: $gray-9;
            text-decoration: none;
            
            &:hover {
                color: $gray;
            }
        }
    }
    
    // Red Alert
    &.msg-red-alert {
        @include alert-bar-message($red, $white);
    }
    
    // Yellow Alert
    &.msg-yellow-alert {
        @include alert-bar-message(Yellow, $black);
    }
    
    // Orange Alert
    &.msg-orange-alert {
        @include alert-bar-message($orange, $white);
    }
    
    // Green Alert
    &.msg-green-alert {
        @include alert-bar-message($green-bright, $white);
    }
    
    // No Alert
    &.msg-no-alert {
        @include alert-bar-message(#99ccff, $black);
    }
}


// Old IE
// -------------------------------------------------------

.lt-ie9 {
    #alert-container {
        display: block;
        margin: auto 0;
        width: $container-classic;
    }
    
    // Alert Icon Fix
    // Display solid colored square block
    .btn-alert-bar {
        
        em {
            display: inline-block;
            width: 10px;
            height: 10px;
            position: relative;
            *top: -2px;
            _top: -2px;
        }
        
        // Red Alert Button
        &.red-alert { 
            @include alert-bar-button($red, $white); 
        }
        
        // Yellow Alert Button
        &.yellow-alert { 
            @include alert-bar-button(Yellow, $black); 
        }
        
        // Orange Alert Button
        &.orange-alert {
            @include alert-bar-button($orange, $white); 
        }
        
        // Green Alert Button
        &.green-alert {
            @include alert-bar-button($green-bright, $white);
        }
        
        // No Alert Button
        &.no-alert { 
            @include alert-bar-button(#99ccff, $black);
        }
    }
}
