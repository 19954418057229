// News List
// 


// Lists
.news-list {

    ul.news-items {
        margin: 0;
        padding: 0;
        list-style: none;

        > li {
            margin: 0;
            padding: 0;
        }
    }

    .news-item {
        //@extend .uk-article;
        @extend .uk-card;
        @include clearfix;
        margin: 10px 0 20px;
        padding: 0;
    }

    &.news-item-card {
        .news-item {
            @extend .uk-card-default;
            @extend .uk-card-body;
            margin: 0;
        }
        li > .news-item {
            margin: 10px 0 20px;
        }
    }

    

    &.news-item-divider {
        .news-item {
            border-top: 1px solid $gray-uk;
            margin: 12px 0;
            padding: 15px 0 12px;
        }

        ul.news-items {
            > li:first-child {
                .news-item {
                    border-top: 0;
                    padding-top: 0;
                }
            }
        }
    }

    .news-article-title {
        @extend .uk-card-title;
        margin: 0 0 10px;
        //@extend .uk-article-title;
        // font-size: 2.625rem;
        // line-height: 1.2;
        // margin-top: 40px;
        a {
            //@extend .uk-link-reset;
            text-decoration: underline;

            &:hover,
            &:active,
            &:focus {
                color: $red;
                text-decoration: underline;
                border-bottom: 1px solid $red;
            }
        }
    }
    // title small
    &.item-title-small .news-article-title {
        font-size: 1.25rem;
    }
    // title medium
    &.item-title-medium .news-article-title {
        font-size: 2.21rem;
    }
    // title large
    &.item-title-large .news-article-title {
        font-size: 2.625rem;
    }

    .news-meta {
        @extend .uk-article-meta;
        margin: 10px 0 15px;
    }

    .news-meta-footer {
        @extend .uk-article-meta;
        margin: 10px 0 15px;
        font-size: .75rem;
    }

    .news-meta-link {
        a {
            // @extend .uk-button;
            // @extend .uk-button-text;
            color: $gray;
            text-decoration: underline;

            &:hover,
            &:active,
            &:focus {
                color: $red;
                text-decoration: underline;
                border-bottom: 1px solid $red;
            }
        }
    }

    .news-comments {
        margin-left: 10px;
    }

    .news-content p {
        margin-bottom: 15px;
    }

    p.lead {
        @extend .uk-text-lead;
    }

    div[data-sf-role="comments-count-wrapper"],
    .sf-Comment-count {
        margin-left: 0;
    }

    .sf-icon-comment {
        display: none;
    }

    img {
        //@extend .uk-border-rounded;
        //@extend .uk-border-circle;
        @extend .uk-card;
        @extend .uk-card-default;
        //margin-top: 5px;
        padding: 5px;
    }

    .news-image-top {
        img {
            margin-bottom: 10px
        }
    }
    // Top Fives - Three Layouts
    &.news-top-five-01,
    &.news-top-five-02,
    &.news-top-five-03 {
        .featured-article {
            .news-article-title {
                @extend .uk-article-title;
            }
        }

        .secondary-article {
            .news-article-title {
                font-size: 1.678rem;
            }
        }
    }

    &.news-top-five-01 {
    }

    &.news-top-five-02 {
    }

    &.news-top-five-03 {
    }
}



/* Mobile Handling For Left Handed Images */

@media only screen and (max-width: $utmb-small-max) {
    .news-list {
        img {
            margin-bottom: 10px
        }
        .uk-width-1-5 {
            width: 100%;
        }
        .uk-width-1-4 {
            width: 100%;
        }
    }
}





/*// ALL: Make news List <UL> = list-unstyled

ul.news-items {
    margin: 0;
    padding: 0;
    list-style: none;
    
    li {
        margin: 0;
        padding: 0;
        
        .news-item {
            @include clearfix;

            .news-article-title {
                a {
                    text-decoration: none;

                    &:hover, 
                    &:active, 
                    &:focus {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}


// ALL: News meta (date, author, etc)

.news-meta {
  font-size: 0.85em;
}

// ALL: Full article link 

.news-goto-article {
    font-size: 0.85em;
    //margin-top: 5px;
    display: inline-block;
    
    span {
        color: #cccccc;
        margin-right: 5px;
        text-decoration: none;
    }
}

// Pagination

ul.pagination {
    li {
        float: left;
    }
}

// News content text

.news-content {
    margin-top: 10px;
    margin-bottom: 12px;
    line-height: 1.6em;
}

// Old IE 

.lt-ie9 .news-goto-article span {
  display: none;
}*/
