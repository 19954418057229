// UTMB Web Framework Sass
// UTMB Health, The University of Texas Medical Branch
// @authors Mike Cooper and Melissa Harman

// Bootstrap Sass v3.3.7 -- #1
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables"; //*temp

// UTMB Variables & Mixins
@import "utmb-variables";
@import "utmb-mixins";

// Bootstrap Sass v3.3.7 -- #2
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins"; //*temp

// Boostrap Must Haves - ever-supported components
@import "vendor/bootstrap/alerts";
// @import "vendor/bootstrap/buttons";
@import "vendor/bootstrap/carousel";
@import "vendor/bootstrap/container";
@import "vendor/bootstrap/forms";
@import "vendor/bootstrap/jumbotron";
@import "vendor/bootstrap/pagination";
// @import "vendor/bootstrap/responsive-embed"; // -- moved to "components/video";
@import "vendor/bootstrap/tables";
@import "vendor/bootstrap/typography";

// UTMB UIKit *see: -https://getuikit.com/docs/sass
@import "vendor/uikit/utmb-uikit-variables"; // 1. Custom variables
@import "vendor/uikit/uikit-variables-theme"; // 2. Default variables
@import "vendor/uikit/uikit-mixins-theme"; // 3.  Available mixins
@import "vendor/uikit/utmb-uikit-mixin-hooks";// 4. Custom mixin hooks (overrides)
@import "./node_modules/uikit/src/scss/uikit-theme"; // 5. Import UIkit from NPM
@import "vendor/uikit/utmb-uikit-overrides";// additional overrides

// Foundation
// @import "vendor/foundation/flex-video"; // -- moved to "components/video";

// UTMB Base
//@import "base/preloader"; // *est 3.3.0 - moved to Master in-page HTML to avoid latency
@import "base/fonts";
@import "base/typography";
@import "base/colors";
@import "base/borders";
@import "base/global";
@import "base/margin";
@import "base/maxwidth";
@import "base/padding";
@import "base/patterns";
@import "base/placeholder-regions";
@import "base/skins";
@import "base/visibility";

// UTMB Components
@import "components/_dev-demos";
@import "components/alert-bar";
@import "components/alert-tab";
@import "components/breadcrumb";
@import "components/buttons";
@import "components/card-grid";
@import "components/color-swatch-book";
@import "components/footer";
@import "components/mobi";
@import "components/mobi-button";
@import "components/section-box";
@import "components/hero-box";
@import "components/hero-overlap";
@import "components/hero-overlay";
@import "components/panels";
@import "components/photos";
@import "components/social-icons";
@import "components/skip-links";
@import "components/sticky-anchors";
@import "components/superhero-box";
@import "components/tables";
@import "components/tabs";
@import "components/video";
@import "components/wells";
@import "components/last-modified";

// UTMB "Feature Box" SplideJs Carousel -- *see:-https://splidejs.com/
// @import "vendor/splide/splide.min.css";
// @import "vendor/splide/themes/splide-skyblue.min.css";
@import "vendor/splide/splide-min";
@import "vendor/splide/themes/splide-skyblue-min";
@import "vendor/splide/utmb-feature-box-splide";

// UTMB Design Elements
@import "design-utmb/utmb-card";
@import "design-utmb/utmb-dei";
@import "design-utmb/utmb-heading-bullet";
@import "design-utmb/utmb-lead";
@import "design-utmb/utmb-page-title";
@import "design-utmb/utmb-section-info";
@import "design-utmb/utmb-section-subtitle";
@import "design-utmb/utmb-section-title";
@import "design-utmb/utmb-subsection-title";

// Design Mode: Classic
@import "design-mode/classic/content-layout";
@import "design-mode/classic/content-oldie";
@import "design-mode/classic/header";
@import "design-mode/classic/footer";
@import "design-mode/classic/navigate-main";
@import "design-mode/classic/navigate-side";
@import "design-mode/classic/explore";
@import "design-mode/classic/search";
@import "design-mode/classic/theme-light";
@import "design-mode/classic/theme-dark";

// Design Mode: Modern
@import "design-mode/modern/content-layout";
@import "design-mode/modern/header";
@import "design-mode/modern/footer";
@import "design-mode/modern/navigate";
@import "design-mode/modern/explore";
@import "design-mode/modern/search";
@import "design-mode/modern/theme-dark";

// Accordion (Desktop) To Tabs (Mobile)
@import "components/accordion-to-tabs";

// UTMB Print
@import "base/print";


*:focus {
    border: 1px dashed $red !important;
    outline: 1px solid $red !important;
}



// Navigation carets
.uk-nav-default > li.uk-active > a {
    color: $red;
}

@media only screen and (min-width: 992px) {
    // .nav-vertical ul li a {

    // }
    .uk-nav-parent-icon > .uk-parent > a:after {
        position: relative;
    }
    .uk-nav-primary .uk-nav-header, 
    .uk-nav-primary > li.uk-active > a {
        color: $red;
        text-decoration: underline;
    }
}

// bs and uk grid fix
.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.row.uk-grid>* {
    padding-left:0px !important;
}
