﻿// Nav Vertical -- .nav-vertical > .uk-nav-default
// Overlay Design for UIKit Vertical Nav
// *See ./src/assets/scss/vendor/uikit/_utmb-uikit-variables.scss

// .nav-vertical {

// }


// Mobile Handling
// A Dropdown menu is created for each instance
// Dropdown is displayed for small screens (sweetspot-max) and hidden at desktop (sweetspot)
// Vertical menu is hidden for small screens (sweetspot-max) and displayed at desktop (sweetspot)

@media only screen and (max-width: $utmb-sweetspot-max) {
    .nav-vertical {
        .nav-mobile-dropdown {
            display: block;
            padding: 15px 0;
        }

        ul {
            display: none;
        }
        // mobile override
        &.no-mobile-menu {
            .nav-mobile-dropdown {
                display: none;
            }

            ul {
                display: block;
                //display: flex;
            }
        }
    }
}

@media only screen and (min-width: $utmb-sweetspot) {
    .nav-vertical {
        .nav-mobile-dropdown {
            display: none;
        }

        ul {
            //display: block;
            li {
                a {
                    font-family: $font-family-condensed;
                    font-size: 1.125rem;
                    color: $gray-4;
                    transition: font-size .5s;
                    line-height: 1.5;
                    position: relative;

                    .caret {
                        color: $red;
                        border-left: 4px solid;
                        border-left: 4px solid;
                        border-top: 4px solid transparent;
                        border-bottom: 4px solid transparent;
                        position: absolute;
                        top: 21px;
                        right: 4px;
                    }

                    &:hover {
                        font-size: 1.35rem;
                    }
                }

                &.uk-parent {
                    > a {
                        padding-right: 18px;
                    }
                }

                .uk-nav-sub {
                    li {
                        a {
                            font-size: 1rem;
                            line-height: 1.5;

                            .caret {
                                top: 15px;
                            }

                            &:hover {
                                color: $red;
                            }
                        }
                    }
                }

                &.uk-parent.uk-open,
                &.uk-parent.uk-active.uk-open {
                    > a {
                        .caret {
                            border-top: 4px dashed;
                            border-top: 4px solid;
                            border-right: 4px solid transparent;
                            border-left: 4px solid transparent;
                        }
                    }
                }
            }
        }

        // // caret
        // .uk-nav-parent-icon > .uk-parent > a:after {
        //     content: '';
        //     // width: 1.5em;
        //     // height: 1.5em;
        //     position: absolute;
        //     top: 15px;
        //     right: 7px;
        //     margin-left: 5px;
        //     background: yellow;

        //     // caret
        //     color: $red;
        //     display: inline-block;
        //     width: 0;
        //     height: 0;
        //     margin-left: 2px;
        //     vertical-align: middle;
        //     // caret right
        //     border-left: 4px dashed;
        //     border-left: 4px solid;
        //     border-top: 4px solid transparent;
        //     border-bottom: 4px solid transparent;
        // }
        // .uk-nav-parent-icon > .uk-parent > a:hover:after {
        //     color: $red;
        // }

        
    }
}