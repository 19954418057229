// Pixels to rem converter
// see: -https://dev.to/nikolab/convert-px-to-rem-using-sass-3-methods-4ep2

// example usage:
// div {
//    @include convertPixelsToRem_width(400);    
//    @include convertPixelsToRem_fontSize(18); // output: font-size: ($value / 16) + rem;
//}

@mixin convertPixelsToRem_width($value) {
    width: calc($value / 18) + rem;
}

@mixin convertPixelsToRem_fontSize($value) {
    font-size: calc($value / 18) + rem;
}