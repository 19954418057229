// Card Block

.utmbsf-card-block {
    padding: 0;
    margin: 0 0 20px 0;
    text-align: center;
    border: 1px solid #ddd;
    -webkit-border-radius: 4px; 
    -moz-border-radius: 4px; 
    border-radius: 4px;
    
    a {
        display: block;
        padding: 15px;
        text-decoration: none;
        //@include clearfix;
        white-space: normal;

        .banner {
            margin-bottom: 15px;

            img {
                max-width: 100%;

                &.img-responsive {
                    max-width: 100%;
                }
            }
        }

        .caption {
            h3 { 
                margin-bottom: 8px; 
            }
            p { 
                color: $gray-4;
                margin: 0;
                padding: 0;
                //word-wrap: break-word;
            }
        }


        &:hover,
        &:active,
        &:focus {
            background-color: #eee;

            .caption {
                h3 {}
                p {}
            }
        }

    }
    
    // .utmbsf-card-block.img-hide
    @include img-hide;
    
    // .utmbsf-card-block.img-border
    @include img-border;
    
    
}