// Gallery


// Overlay (Our New Default SF Gallery)

.sf-Gallery-thumbs-container {
    .sf-Gallery-thumbs {
        a {
            @extend .uk-card;
            @extend .uk-card-default;
            border: 0;
            cursor: pointer;
            display: inline-block;
            margin: 0 15px 15px 0;
            overflow: hidden;
            padding: 5px;
            //width: 130px;
            //height: 130px;
            vertical-align: middle;

            &.is-selected {
                border: 2px solid $gray; // #428BCA;
            }

            > img {
                vertical-align: middle;
            }
        }
    }
}


// Thumbnail Strip (uses same thumbs container)

.sf-Gallery--strip {
    .sf-Gallery-image {
        position: relative;

        img {
            @extend .uk-card;
            @extend .uk-card-default;
            margin-bottom: 15px;
            padding: 5px;
        }
    }

    .sf-Gallery-thumbs-container {
        .sf-Gallery-thumbs {
            a {
                width: 120px;
            }
        }
    }

    .sf-Gallery-next,
    .sf-Gallery-prev {
        cursor: pointer;
        position: absolute;
        //height: 600px;
        height: 100%;
        width: 50%;
        //padding: 0 50px;
        //left: 77px;
        top: 0;
        //top: 50%;
        //transform: translateY(-50%);
        z-index: 1;

        > span {
            color: #000;
            display: inline-block;
            font-family: Verdana;
            //font-size: 40px;
            @include convertPixelsToRem_fontSize(40); // output: font-size: ($value / 16) + rem;
            //line-height: 600px;
            //line-height: 100%;
            opacity: 1;
            position: absolute;
            text-align: left;
            text-shadow: 0 0 5px #fff;
            top: 50%;
            transform: translateY(-50%);
            transition: opacity .3s ease;
            //background: rgba(0,0,0,.1);
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        &:hover {
            > span {
                opacity: 1;
                color: #000;
            }
        }
    }

    .sf-Gallery-prev {
        //background: linear-gradient(to right, rgba(0,0,0,.1) 0%,rgba(0,0,0,0) 100%);
        left: 0;

        > span {
            left: 77px;
        }
    }

    .sf-Gallery-next {
        //background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,.1) 100%);
        right: 0;

        > span {
            right: 77px;
        }
    }
}


// Old Image Carousel Fix

.img-gallery-slide-carousel-wrapper {
    .sf-Gallery--strip {
        .sf-Gallery-thumbs-container {
            .sf-Gallery-thumbs {
                a {
                    width: 120px;
                }
            }
        }
    }
}



/*.sf-Gallery {
	h2 {
		margin-top: 10px;
    }
}

.sf-Gallery-thumbs-container {
	overflow: hidden;
}

.sf-Gallery-thumbs {
	margin: 0 -5px;
	a {
		display: inline-block;
		margin: 0 5px 10px;
		border: 5px solid transparent;
		width: 130px;
		height: 130px;
		line-height: 120px;
		overflow: hidden;
		cursor: pointer;

		&.is-selected {
			border-color: #428BCA;
        }
        
		> img {
			vertical-align: middle;
        }
    }
}

.sf-Gallery-image {
	position: relative;
	height: 600px;
	line-height: 600px;
	margin-top: 30px;
	text-align: center;
	background: #E4E4E4;
	border: 1px solid #E4E4E4;

	> img {
		max-height: 100%;
		width: auto;
		display: inline-block;
    }
}

.sf-Gallery-next,
.sf-Gallery-prev {
	cursor: pointer;
	position: absolute;
	height: 600px;
	width: 50%;
	padding: 0 50px;
	left: 0;
	top: 0;
	text-align: left;
	font-family: Verdana;
	color: #000;
	font-size: 40px;
	line-height: 600px;
	text-shadow: 0 0 3px #fff;
	opacity: 0;
	transition: opacity .2s ease;
	background: rgba(0,0,0,.1);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&:hover {
		opacity: 1;
		color: #000;
    }
}

.sf-Gallery-prev {
	background: linear-gradient(to right, rgba(0,0,0,.1) 0%,rgba(0,0,0,0) 100%);
}

.sf-Gallery-next {
	background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,.1) 100%);
	left: auto;
	right: 0;
	text-align: right;
}

.sf-Gallery-navigation {
	margin: 5px 0 30px;
}

//Video gallery
.sf-Gallery-thumbs.sf-Gallery-thumbs--video {
	
    > div {
		display: inline-block;
		margin: 0 5px 10px;
		border: 5px solid transparent;
		width: 160px;
		height: 170px;
		cursor: pointer;
    }
	
    a {
		display: block;
		margin: 0;
		border: 0;
		width: 160px;
		height: 130px;
		overflow: hidden;
		cursor: pointer;
    }
	
    h3 {
		margin-top: 10px;
		font-size:16px;
		font-weight: bold;
		white-space: nowrap;
		overflow: hidden;
        text-overflow: ellipsis;
    }
}*/
