// 
// UTMB Navigate Menu: "Main Nav" for Classic Design Mode
// 

// Design Mode: Classic -- below Desktop view (991px-)
body.design-mode-classic.nav-main #navigate {
    display: none;
}

// Design Mode: Classic -- "Sweetspot" Desktop view and up (992px+)
@media only screen and (min-width: 992px) {
    body.design-mode-classic.nav-main {

        // #maincontent -- was #content (body container) - push page down for exp-mega > #explore
        //#content { padding-top: 42px; } 
        //#maincontent { padding-top: 42px; } 
        #maincontent { padding-top: 45px; } 

        // //#maincontent -- was #content container - push page down - for exp-mega > #explore or exp-dropdown
        // &.exp-mega #maincontent,
        // &.exp-mega-full #maincontent,
        // &.exp-dropdown #maincontent { 
        //     //padding-top: 84px;
        //     padding-top: 45px;
        // }
        
        //#content container - push page down - for exp-mega > #explore or exp-dropdown
        &.utmb-light.exp-mega #navigate,
        &.utmb-light.exp-mega-full #navigate,
        &.utmb-light.exp-dropdown #navigate {
            // Change #navigate menu when #explore menu is present
            //@include nav-alt-theme-1(); //#f2f2f2+0,ffffff
            @include nav-alt-theme-2(); //#e2e2e2+0,f2f2f2
        }

        

        // remove border from explore
        &.exp-mega #explore {
            border-bottom: 0px;
        }

        
        // #navigate <nav>
        #navigate { 
            @include clearfix;
            background-color: $white; //#444;
            border-bottom: 2px solid $gray-8;
            border-top: 1px solid $gray-e;
            display: block;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: $zindex-navigate-main; // 9001
            
            // Tandem nav inner wrapper / collapse toggle
            // show collapsible tandem nav menu
            #menu-navigate {
                display: block;
                height: auto !important; // *required so mobile nav js doesn't cause unwanted overflow - it has style="height:800px" thing
                
                // Title
                .title-area {
                    border: 0 none;
                    clip: rect(0px, 0px, 0px, 0px);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }

                // Menu Container Section
                .menu-container {
                    @include page-container-classic();
                    
                    // Site Nav Menu
                    ul.nav-menu {
                        float: left;
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        
                        // Tier 1
                        > li {
                            //list-style-type: none;
                            float: left;
                            margin: 0;
                            padding: 0;

                            > a {
                                //border-left: 1px solid #eee;
                                background-color: transparent;
                                color: $nav-link-color;
                                display: block;
                                //font-size: .925em;
                                font-size: .85em; // .95em
                                //font-weight: 500;
                                //font-size: 1em; // *utmb-modern
                                height: 45px; // 55px *utmb-modern
                                line-height: 45px; // 55px *utmb-modern
                                //margin-bottom: 2px;
                                padding-top: 0 !important;
                                padding-bottom: 0 !important;
                                padding-left: 20px; //13px;
                                padding-right: 20px; //13px;
                                text-decoration: none;
                                //text-transform: uppercase;
                                transition: background-color 0.5s ease;

                                .caret {
                                    color: #999;
                                    //display: none;
                                    margin-left: 5px;
                                }
                                
                                // &:active,
                                // &:focus,
                                // &:hover {
                                //     background-color: $gray-e; //$red;
                                    
                                // }
                                
                                // &.active,
                                // &.current,
                                // &[aria-expanded="true"] {
                                //     background-color: $gray-1; //$orange;
                                //     color: $white;

                                //     &:hover {
                                //         background-color: $gray-1; //$gray-e; //$red;
                                //     }
                                // }

                                &:hover {
                                    background-color: $red;
                                    color: #ffffff;
        
                                    .caret {
                                        color: #ffffff;
                                    }
                                }
        
                                &.active,
                                &.current,
                                &[aria-expanded="true"] {
                                    background-color: #111111;
                                    color: #ffffff;
        
                                    .caret {
                                        color: #ffffff;
                                    }
                                }
                            }
                            
                            // li.has-submenu
                            &.has-submenu {
                                position: relative;
                            }
                            
                            // Tier 2
                            // First div.submenu (nested child menus)
                            > div.submenu {
                                background: $gray-1;
                                box-shadow: 0 6px 12px rgba(0,0,0,.175); // match Bootstrap dropdowns
                                clear: both;
                                color: #fff;
                                display: none;
                                float: none;
                                max-width: 230px;
                                position: absolute;
                                top: 45px;
                                width: 230px;
                                z-index: $zindex-explore-submenu; //1

                                &.active {
                                    display: block;
                                }
                                
                                ul.nav-menu {
                                    clear: both;
                                    display: block;
                                    float: none;
                                    list-style: none;
                                    line-height: normal;
                                    margin: 0;
                                    padding: 0px; //0 10px 0 0;

                                    li {
                                        clear: both;
                                        float: none;
                                        color: $white;
                                        margin: 0;
                                        padding: 0;

                                        a {
                                            border-top: 1px solid #222222;
                                            color: $white;
                                            display: block;
                                            font-size: .8em;
                                            height: auto;
                                            line-height: normal; //1.618em; //auto;
                                            padding: 9px 5px 9px 15px !important;
                                            text-decoration: none;

                                            &:active,
                                            &:focus,
                                            &:hover {
                                                background: $black;
                                                color: $white;
                                            } 
                                            
                                            // caret right
                                            .caret {
                                                border-left: 4px dashed;
                                                border-left: 4px solid;
                                                border-top: 4px solid transparent;
                                                border-bottom: 4px solid transparent;
                                                border-right: 0;
                                                color: $gray-9;
                                                position: absolute;
                                                right: 15px;
                                                top: 16px;
                                            }

                                            // flip caret
                                            &:active,
                                            //&:focus,
                                            &.active,
                                            &.current,
                                            &[aria-expanded="true"] {

                                                // current / active / expanded
                                                background-color: $black;

                                                // caret down
                                                .caret {
                                                    border-top: 4px dashed;
                                                    border-top: 4px solid;
                                                    border-right: 4px solid transparent;
                                                    border-left: 4px solid transparent;
                                                    border-bottom: 0;
                                                    top: 18px;
                                                }
                                            }
                                        }


                                        // The Deep Nest...
                                        // Tier 3
                                        //&.has-submenu {
                                        div.submenu {
                                            position: relative;
                                            top: 0;
                                            width: auto;

                                            ul.nav-menu {
                                                padding: 7px 0 10px 7px;

                                                li {
                                                    a {
                                                        border-top: 1px solid #323232;
                                                        background-color: #222222;

                                                        &:hover {
                                                            background-color: #323232;
                                                        }
                                                    }

                                                    // Tier 4
                                                    div.submenu {
                                                        background-color: #222222;

                                                        ul.nav-menu {
                                                            li {
                                                                a {
                                                                    background-color: #323232;
                                                                    border-top: 1px solid #222222;

                                                                    &:hover {
                                                                        background-color: #424242;
                                                                    }
                                                                }

                                                                // Tier 5
                                                                div.submenu {
                                                                    background-color: #323232;

                                                                    ul.nav-menu {
                                                                        li {
                                                                            a {
                                                                                border-top: 1px solid #525252;
                                                                                background-color: #424242;

                                                                                &:hover {
                                                                                    background-color: #525252;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    } // end/ li

                                    li a.nav-menu-title,
                                    li.nav-menu-spacer a {
                                        background-image: none;
                                        color: $white; //$orange; //#EC7A08;
                                        font-weight: bold;
                                        font-style: normal;
                                        padding: 9px 0 9px 0;
                                    }

                                    li.no-link a,
                                    li.no-link a:hover,
                                    li.nav-menu-spacer a:hover {
                                        cursor: default;
                                        text-decoration: none;
                                    }

                                }
                            }
                        } // end/ Tier 1
                    } // end/ .nav-menu
                } // end/ .menu-container
            }
        } 


        // UTMB Dark
        // &.utmb-dark.exp-mega #navigate,
        // &.utmb-dark.exp-mega-full #navigate,
        // &.utmb-dark.exp-dropdown #navigate { 
        //     //top: 42px;
        //     top: 0;
            
        //     // Change #navigate menu when #explore menu is present
        //     //@include nav-alt-theme-1(); //#f2f2f2+0,ffffff
        //     //@include nav-alt-theme-2(); //#e2e2e2+0,f2f2f2
        //     background-color: #222222;
        //     //border-bottom: 2px solid #555;
        //     color: #fff;

        //     // Tandem nav inner wrapper / collapse toggle
        //     // show collapsible tandem nav menu
        //     #menu-navigate {
        //         // Menu Container 
        //         .menu-container {
        //             // Site Nav Menu
        //             > ul.nav-site {
        //                 > li {
        //                     > a {
        //                         color: #fff !important;

        //                         &:hover {
        //                             background-color: #000;
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }



    
        // Overrides
        // ===========================================
        
        &.nav-menu-caret-hide {
            #navigate .nav-menu > li > a .caret {
                display: none;
            }
        }

    }
}




@media only screen and (min-width: 992px) {
    body.design-mode-classic.utmb-dark.nav-main #navigate #menu-navigate .menu-container ul.nav-menu>li>a {
        color: #ffffff;
    }
}
