/* eslint-disable */
/* 
    Header for Modern Design Mode
    Mobile-First --> Desktop
*/
body.design-mode-modern {
    
    #header {
        @include clearfix;
        // background-color: #ffffff;
        // box-shadow: 0 2px 3px 0 rgba(0,0,0,0.16), 0 0 0 0 rgba(0,0,0,0.04);
        width: 100%;

        // Above Masthead for Modern
        #hgroup {
            @include clearfix;
            background-color: #333;
            color: #fff;
            padding: 0 15px; //4px 15px;
            position: relative;

            .header-site-title {
                @extend .uk-h1;
                font-family: $font-family-condensed;
                color: #fff;
                display: inline-block;
                font-size: 1em;
                //line-height: 30px;
                min-height: 44px;
                line-height: 44px;
                margin: 0 12px 0 0;
                padding: 0;
                text-decoration: inherit;

                &:active,
                &:hover,
                &:focus {
                    color: inherit;
                    border-bottom: 0 !important;
                    text-decoration: underline double;
                }
            }

            .header-site-subtitle {
                color: #ddd;
                display: inline-block;
                font-size: .775em; //.875em;
                //font-style: italic;
                // line-height: 1em;
                min-height: 44px;
                line-height: 44px;
                margin: 0;
                padding: 0;
            }
        }

        .masthead-wrapper {
            background-color: #ffffff;
            box-shadow: 0 2px 3px 0 rgba(0,0,0,0.16), 0 0 0 0 rgba(0,0,0,0.04);
            width: 100%;

            // Sticky Masthead
            &.uk-active.uk-sticky-fixed {
                //border-top: 1px solid #333;

                #masthead {
                    padding: 1.15em 1em 1.15em 1em;
                }
                #navigate {
                    top: 23px;
                }
            }
        }
    
        #masthead {
            margin: 0 auto;
            // padding: 1em;
            padding: 1.65em 1em 1.65em 1em;
            position: relative;
            transition: padding 0.5s ease;
            @include page-container-modern();
    
            &:before,
            &:after {
                content: " ";
                display: table;
            }
            &:after {
                clear: both;
            }
        }
    
        a.branding {
            color: #111111;
            display: block;
            float: left;
            height: 46px;
            //margin-bottom: 0.3em;
            //overflow: hidden;
            width: 157px;
    
            span {
                display: block;
                width: 157px;
                height: 46px;
                text-indent: -99999px;
                // background-image: url('../img/logos/UTMBHealth_logo___no_tagline.svg');
                background-image: $utmb-logo-svg; //url('../img/logos/UTMBHealth_logo_reg___no_tagline.svg'); // 10/15/2024
                background-repeat: no-repeat;
            }
        }

        
    }
}