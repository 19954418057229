﻿// Photos
// See: http://csswizardry.com/2011/06/annotated-figures-in-html5-and-css/
// ----------------------------------------


// Example Image:
// <img class="photo-right" src="photo.jpg" title="..." alt="..." />


// Image Only

img {
    box-sizing: border-box;

    // Card Styling
    &.photo,
    &.photo-caption,
    &.photo-left,
    &.photo-left-caption,
    &.photo-right,
    &.photo-right-caption {
        @extend .uk-card;
        @extend .uk-card-default;
        padding: 5px;
    }

    &.photo,
    &.photo-caption {
        display: inline;
        margin: 0 0 15px;
    }

    &.photo-left,
    &.photo-left-caption {
        float: left;
        margin: 0 18px 15px 0;
    }

    &.photo-right,
    &.photo-right-caption {
        float: right;
        margin: 0 0 15px 18px;
    }

    @media only screen and (min-width: $utmb-xsmall) {
        &.photo-left-xsmall,
        &.photo-left-caption-xsmall {
            float: left;
            margin: 0 18px 15px 0;
        }

        &.photo-right-xsmall,
        &.photo-right-caption-xsmall {
            float: right;
            margin: 0 0 15px 18px;
        }
    }
    @media only screen and (min-width: $utmb-small) {
        &.photo-left-small,
        &.photo-left-caption-small {
            float: left;
            margin: 0 18px 15px 0;
        }

        &.photo-right-small,
        &.photo-right-caption-small {
            float: right;
            margin: 0 0 15px 18px;
        }
    }
    @media only screen and (min-width: $utmb-sweetspot) {
        &.photo-left-desktop,
        &.photo-left-caption-desktop {
            float: left;
            margin: 0 18px 15px 0;
        }

        &.photo-right-desktop,
        &.photo-right-caption-desktop {
            float: right;
            margin: 0 0 15px 18px;
        }
    }
    @media only screen and (min-width: $utmb-large) {
        &.photo-left-desktop-large,
        &.photo-left-caption-large {
            float: left;
            margin: 0 18px 15px 0;
        }

        &.photo-right-large,
        &.photo-right-caption-large {
            float: right;
            margin: 0 0 15px 18px;
        }
    }

    // old look
    &.photo-frame {
        background: #fff;
        border: 1px solid #ccc;
        padding: 10px;
    }
    
}
// end/ image




// Automated Output Example:
// <figure class="photo-right">
//      <img src="photo.jpg" title="This is the photo caption" alt="my photo" />
//      <figcaption>This is the photo caption</figcaption>
// </figure>


// Figure Wrapper Image classes

figure {
    &.figure-photo,
    &.figure-photo-left,
    &.figure-photo-right,
    &.figure-photo-left-xsmall,
    &.figure-photo-right-small,
    &.figure-photo-left-desktop,
    &.figure-photo-right-large {
        @extend .uk-card;
        @extend .uk-card-default;
        box-sizing: border-box;
        display: inline-block;
        padding: 5px;
        /*white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;*/
        img {
            //margin: 0px;
            //padding: 4px;
            //border: 1px solid #ccc;
            //background: #fff;
        }

        figcaption {
            box-sizing: border-box;
            font-size: .789em;
            padding: 10px 5px 5px;
            //background: #eee;
            line-height: normal;
            text-align: center;
            white-space: pre-wrap;
            white-space: -moz-pre-wrap;
            white-space: -pre-wrap;
            white-space: -o-pre-wrap;
            word-wrap: break-word;
        }
    }

    &.figure-photo {
        //display:inline;
        margin: 0 0 15px 0;
        padding: 0;
    }

    &.figure-photo-left {
        float: left;
        margin: 0 18px 15px 0;
    }

    &.figure-photo-right {
        float: right;
        margin: 0 0 15px 18px;
    }

    @media only screen and (min-width: $utmb-xsmall) {
        &.figure-photo-left-xsmall {
            float: left;
            margin: 0 18px 15px 0;
        }
    
        &.figure-photo-right-xsmall {
            float: right;
            margin: 0 0 15px 18px;
        }
    }
    @media only screen and (min-width: $utmb-small) {
        &.figure-photo-left-small {
            float: left;
            margin: 0 18px 15px 0;
        }
    
        &.figure-photo-right-small {
            float: right;
            margin: 0 0 15px 18px;
        }
    }
    @media only screen and (min-width: $utmb-sweetspot) {
        &.figure-photo-left-desktop {
            float: left;
            margin: 0 18px 15px 0;
        }
    
        &.figure-photo-right-desktop {
            float: right;
            margin: 0 0 15px 18px;
        }
    }
    @media only screen and (min-width: $utmb-large) {
        &.figure-photo-left-large {
            float: left;
            margin: 0 18px 15px 0;
        }
    
        &.figure-photo-right-large {
            float: right;
            margin: 0 0 15px 18px;
        }
    }
}



// Image Styles (w/ Bootstrap Overrides)

img {
    
    &.img {
        margin: 0 0 15px 0;
    }

    &.img-right {
        float: right;
        margin: 0 0 15px 18px;
    }

    &.img-left {
        float: left;
        margin: 0 18px 15px 0;
    }

    &.img-right {
        float: right;
        margin: 0 0 15px 18px;
    }

    &.img-rounded {
        @extend .uk-border-rounded;
    }

    &.img-circle {
        @extend .uk-border-circle;
    }

    &.img-pill {
        @extend .uk-border-pill;
    }

    &.img-thumbnail {
        @extend .uk-card;
        @extend .uk-card-default;
        border: 0;
        padding: 5px;
    }
}



// Image Thumbnails

/*.th,
.th-left,
.th-right {
    line-height: 0;
    display: inline-block;
    border: solid 4px #ffffff;
    max-width: 100%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
    transition: all 200ms ease-out;

    &:hover,
    &:focus {
        box-shadow: 0 0 6px 1px rgba(61, 126, 219, 0.5);
    }

    &.radius {
        border-radius: 3px !important;
    }
}

.th-left {
    float: left;
    margin: 0 14px 10px 0;
}

.th-right {
    float: right;
    margin: 0 0 10px 14px;
}*/



// UTMB Sitefinity Photo Classes
// Sitefinity wraps images in a span (Grrr....)
// ---------------------------------------------------


// Sitefinity Image Only

span {

    &.photo,
    &.photo-caption {
        img {
            display: inline;
            margin: 0;
            padding: 10px;
            border: 1px solid #ccc;
            background: #fff;
        }
    }

    &.photo-left,
    &.photo-left-caption {
        img {
            float: left;
            margin: 0 14px 10px 0;
            padding: 10px;
            border: 1px solid #ccc;
            background: #fff;
        }
    }

    &.photo-right,
    &.photo-right-caption {
        img {
            float: right;
            margin: 0 0 10px 14px;
            padding: 10px;
            border: 1px solid #ccc;
            background: #fff;
        }
    }
}
// end/ image


// Sitefinity Image Thumbnails

span.th,
span.th-left,
span.th-right {
    img {
        line-height: 0;
        display: inline-block;
        border: solid 4px #ffffff;
        max-width: 100%;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
        transition: all 200ms ease-out;

        &:hover,
        &:focus {
            box-shadow: 0 0 6px 1px rgba(61, 126, 219, 0.5);
        }
    }

    &.radius {
        img {
            border-radius: 3px !important;
        }
    }
}

span.th-left {
    img {
        float: left;
        margin: 0 14px 10px 0;
    }
}

span.th-right {
    img {
        float: right;
        margin: 0 0 10px 14px;
    }
}
