﻿// Blog Posts List
//


.blog-posts-list {

    .blog-post-items {
        margin: 0;
        padding: 0;
        list-style: none;

        > div {
            margin: 0;
            padding: 0;
        }
    }

    .blog-post-item {
        @extend .uk-card;
        @include clearfix;
    }

    .blog-post-item {
        margin: 10px 0 20px;
        padding: 0;
    }

    .list-item-icon {
        color: #111111;
        font-size: 369%;
        margin: 5px 0 10px;
    }
    

    &.blog-post-link-card {
        .blog-post-item {
            display: block;
            text-decoration: none;

            .blog-post-title {
                text-decoration: underline;
            }

            .blog-post-read-more span {
                text-decoration: underline;
            }

            &:hover,
            &:focus,
            &:active
            {
                cursor:pointer;
                border-bottom: 1px solid $red;
                box-shadow: 0 14px 25px rgba(0, 0, 0, .16);
                text-decoration: none;

                .blog-post-title {
                    text-decoration: underline;
                    color: $red !important;
                }

                .blog-post-read-more span {
                    text-decoration: underline;
                    color: $red;
                }
            }
        }
    }

    &.blog-post-item-card {
        .blog-post-item {
            @extend .uk-card-default;
            @extend .uk-card-body;
        }
        div > .blog-post-item {
            //margin: 10px 0 12px;
            margin: 8px 8px 10px;
        }
    }

    &.blog-post-item-divider {
        .blog-post-item {
            border-top: 1px solid $gray-uk;
            margin: 12px 0;
            padding: 15px 0 12px;
        }

        .blog-post-items {
            > div:first-child,
            > div:nth-child(1) {
                .blog-post-item {
                    border-top: 0;
                    padding-top: 0;
                }
            }
        }
    }

    .blog-post-image-top {
        img {
            margin-bottom: 20px;
        }
    }

    .blog-post-title {
        @extend .uk-card-title;
        margin: 0 0 10px;
        a {
            text-decoration: underline;

            &:hover,
            &:active,
            &:focus {
                color: $red;
                text-decoration: underline;
                border-bottom: 1px solid $red;
            }
        }
    }
    // title small
    &.item-title-small .blog-post-title {
        font-size: 1.25rem;
    }
    // title medium
    &.item-title-medium .blog-post-title {
        font-size: 2.21rem;
    }
    // title large
    &.item-title-large .blog-post-title {
        font-size: 2.625rem;
    }

    .blog-post-meta {
        @extend .uk-article-meta;
        margin: 10px 0 15px;
    }

    .blog-post-meta-footer {
        @extend .uk-article-meta;
        margin: 10px 0 15px;
        font-size: .75rem;
    }

    .blog-post-meta-link {
        a {
            color: $gray;
            text-decoration: underline;

            &:hover,
            &:active,
            &:focus {
                color: $red;
                text-decoration: underline;
                border-bottom: 1px solid $red;
            }
        }
    }

    .blog-post-comments {
        margin-left: 10px;
    }

    .blog-post-content p {
        margin-bottom: 15px;
    }

    p.lead {
        @extend .uk-text-lead;
    }

    div[data-sf-role="comments-count-wrapper"],
    .sf-Comment-count {
        margin-left: 0;
    }

    .sf-icon-comment {
        display: none !important;
    }

    img {
        @extend .uk-card;
        @extend .uk-card-default;
        padding: 5px;
    }

    .blog-post-top {
        img {
            margin-bottom: 10px
        }
    }

}





/* Mobile Handling For Left Handed Images */

@media only screen and (max-width: $utmb-small-max) {
    .blog-posts-list {
        img {
            margin-bottom: 10px
        }
        .uk-width-1-5 {
            width: 100%;
        }
        .uk-width-1-4 {
            width: 100%;
        }
    }
}



// Icon Colors
.blog-posts-list.list-item-icon-red .blog-post-items .list-item-icon,
.blog-posts-list .blog-post-items .list-item-icon-red .list-item-icon {
    color: $red !important;
}
.blog-posts-list.list-item-icon-gray .blog-post-items .list-item-icon,
.blog-posts-list .blog-post-items .list-item-icon-gray .list-item-icon {
    color: $gray !important;
}
.blog-posts-list.list-item-icon-gray-light .blog-post-items .list-item-icon,
.blog-posts-list .blog-post-items .list-item-icon-gray-light .list-item-icon {
    color: $gray-light !important;
}
.blog-posts-list.list-item-icon-teal .blog-post-items .list-item-icon,
.blog-posts-list .blog-post-items .list-item-icon-teal .list-item-icon {
    color: $teal !important;
}
.blog-posts-list.list-item-icon-teal-dark .blog-post-items .list-item-icon,
.blog-posts-list .blog-post-items .list-item-icon-teal-dark .list-item-icon {
    color: $teal-dark !important;
}
.blog-posts-list.list-item-icon-teal-light .blog-post-items .list-item-icon,
.blog-posts-list .blog-post-items .list-item-icon-teal-light .list-item-icon {
    color: $teal-light !important;
}
.blog-posts-list.list-item-icon-blue .blog-post-items .list-item-icon,
.blog-posts-list .blog-post-items .list-item-icon-blue .list-item-icon {
    color: $blue !important;
}
.blog-posts-list.list-item-icon-blue-dark .blog-post-items .list-item-icon,
.blog-posts-list .blog-post-items .list-item-icon-blue-dark .list-item-icon {
    color: $blue-dark !important;
}
.blog-posts-list.list-item-icon-blue-light .blog-post-items .list-item-icon,
.blog-posts-list .blog-post-items .list-item-icon-blue-light .list-item-icon {
    color: $blue-light !important;
}
.blog-posts-list.list-item-icon-orange .blog-post-items .list-item-icon,
.blog-posts-list .blog-post-items .list-item-icon-orange .list-item-icon {
    color: $orange !important;
}
.blog-posts-list.list-item-icon-orange-mid .blog-post-items .list-item-icon,
.blog-posts-list .blog-post-items .list-item-icon-orange-mid .list-item-icon {
    color: $orange-mid !important;
}
.blog-posts-list.list-item-icon-orange-light .blog-post-items .list-item-icon,
.blog-posts-list .blog-post-items .list-item-icon-orange-light .list-item-icon {
    color: $orange-light !important;
}
.blog-posts-list.list-item-icon-green .blog-post-items .list-item-icon,
.blog-posts-list .blog-post-items .list-item-icon-green .list-item-icon {
    color: $green !important;
}
.blog-posts-list.list-item-icon-green-dark .blog-post-items .list-item-icon,
.blog-posts-list .blog-post-items .list-item-icon-green-dark .list-item-icon {
    color: $green-dark !important;
}
.blog-posts-list.list-item-icon-green-light .blog-post-items .list-item-icon,
.blog-posts-list .blog-post-items .list-item-icon-green-light .list-item-icon {
    color: $green-light !important;
}



// Custom Item Alignments
//
    //blog-post-title
    //blog-post-meta
    //blog-post-content-summary
    //blog-post-content-full
    //item-content-cats
    //item-content-tags
//

// Titles
.blog-posts-list.title-left .blog-post-title,
.blog-posts-list .blog-post-items .title-left .blog-post-title {
    text-align: left !important;
}
.blog-posts-list.title-right .blog-post-title,
.blog-posts-list .blog-post-items .title-right .blog-post-title {
    text-align: right !important;
}
.blog-posts-list.title-center .blog-post-title,
.blog-posts-list .blog-post-items .title-center .blog-post-title {
    text-align: center !important;
}
// Meta
.blog-posts-list.meta-left .blog-post-meta,
.blog-posts-list .blog-post-items .meta-left .blog-post-meta {
    text-align: left !important;
}
.blog-posts-list.meta-right .blog-post-meta,
.blog-posts-list .blog-post-items .meta-right .blog-post-meta {
    text-align: right !important;
}
.blog-posts-list.meta-center .blog-post-meta,
.blog-posts-list .blog-post-items .meta-center .blog-post-meta {
    text-align: center !important;
}
// Cats
.blog-posts-list.cats-left .item-content-cats,
.blog-posts-list .blog-post-items .cats-left .item-content-cats {
    text-align: left !important;
}
.blog-posts-list.cats-right .item-content-cats,
.blog-posts-list .blog-post-items .cats-right .item-content-cats {
    text-align: right !important;
}
.blog-posts-list.cats-center .item-content-cats,
.blog-posts-list .blog-post-items .cats-center .item-content-cats {
    text-align: center !important;
}
// Tags
.blog-posts-list.tags-left .item-content-tags,
.blog-posts-list .blog-post-items .tags-left .item-content-tags {
    text-align: left !important;
}
.blog-posts-list.tags-right .item-content-tags,
.blog-posts-list .blog-post-items .tags-right .item-content-tags {
    text-align: right !important;
}
.blog-posts-list.tags-center .item-content-tags,
.blog-posts-list .blog-post-items .tags-center .item-content-tags {
    text-align: center !important;
}
//Full Content and Summary
.blog-posts-list.content-left .blog-post-content,
.blog-posts-list .blog-post-items .content-left .blog-post-content {
    text-align: left !important;
    p, div {
        text-align: left !important;
    }
}
.blog-posts-list.content-right .blog-post-content,
.blog-posts-list .blog-post-items .content-right .blog-post-content {
    text-align: right !important;
    p, div {
        text-align: right !important;
    }
}
.blog-posts-list.content-center .blog-post-content,
.blog-posts-list .blog-post-items .content-center .blog-post-content {
    text-align: center !important;
    p, div {
        text-align: center !important;
    }
}