// Sticky Anchors

// Usage: 
//
// <a href="#myTarget" title="So Sticky">My anchor target on a sticky header page</a>
//
// <div class="sticky-anchor" id="myTarget" name="myTarget"></div> <!-- Option One - use if option two doesn't work as expected -->
// <div class="sticky-anchor" id="myTarget"> <!-- Option Two - preferred method, conflicts with some CSS -->
//    Hello, Sticky World! =)
// </div>


// default
.sticky-anchor::before 
{
    content: '';
    display: block;
    height: 175px !important; 
    margin-top: -175px !important;
    visibility: hidden;
    position: relative;
}


// Desktop: 100, 125, 150, 175, 200, 250, 300, 350

.sticky-anchor-100::before 
{
    content: '';
    display: block;
    height: 100px !important; 
    margin-top: -100px !important; 
    visibility: hidden;
    position: relative;
    z-index: 0 !important;
}

.sticky-anchor-125::before  
{
    content: '';
    display: block;
    height: 125px !important; 
    margin-top: -125px !important; 
    visibility: hidden;
}

.sticky-anchor-150::before 
{
    content: '';
    display: block;
    height: 150px !important; 
    margin-top: -150px !important; 
    visibility: hidden;
}

.sticky-anchor-175::before  
{
    content: '';
    display: block;
    height: 175px !important; 
    margin-top: -175px !important; 
    visibility: hidden;
}

.sticky-anchor-200::before 
{
    content: '';
    display: block;
    height: 200px !important; 
    margin-top: -200px !important; 
    visibility: hidden;
}

.sticky-anchor-250::before 
{
    content: '';
    display: block;
    height: 250px !important; 
    margin-top: -250px !important; 
    visibility: hidden;
}

.sticky-anchor-300::before 
{
    content: '';
    display: block;
    height: 300px !important; 
    margin-top: -300px !important; 
    visibility: hidden;
}

.sticky-anchor-350::before 
{
    content: '';
    display: block;
    height: 300px !important; 
    margin-top: -300px !important;
    visibility: hidden;
}


// Mobile: 100, 125, 150, 175, 200, 250, 300, 350
// Mobile View ONLY
@media only screen and (max-width: $utmb-small-max) {
    .sticky-anchor-xs-100::before 
    {
        content: '';
        display: block;
        height: 100px !important; 
        margin-top: -100px !important; 
        visibility: hidden;
    }

    .sticky-anchor-xs-125::before  
    {
        content: '';
        display: block;
        height: 125px !important; 
        margin-top: -125px !important; 
        visibility: hidden;
    }

    .sticky-anchor-xs-150::before 
    {
        content: '';
        display: block;
        height: 150px !important; 
        margin-top: -150px !important; 
        visibility: hidden;
    }

    .sticky-anchor-xs-175::before  
    {
        content: '';
        display: block;
        height: 175px !important; 
        margin-top: -175px !important; 
        visibility: hidden;
    }

    .sticky-anchor-xs-200::before 
    {
        content: '';
        display: block;
        height: 200px !important; 
        margin-top: -200px !important; 
        visibility: hidden;
    }

    .sticky-anchor-xs-250::before 
    {
        content: '';
        display: block;
        height: 250px !important; 
        margin-top: -250px !important; 
        visibility: hidden;
    }

    .sticky-anchor-xs-300::before 
    {
        content: '';
        display: block;
        height: 300px !important; 
        margin-top: -300px !important; 
        visibility: hidden;
    }

    .sticky-anchor-xs-350::before 
    {
        content: '';
        display: block;
        height: 300px !important; 
        margin-top: -300px !important;
        visibility: hidden;
    }
}




/* SF page editor override */
.sfPageEditor .sticky-anchor::before,
.sfPageEditor .sticky-anchor-100::before,
.sfPageEditor .sticky-anchor-125::before,
.sfPageEditor .sticky-anchor-150::before,
.sfPageEditor .sticky-anchor-175::before,
.sfPageEditor .sticky-anchor-200::before,
.sfPageEditor .sticky-anchor-250::before,
.sfPageEditor .sticky-anchor-300::before,
.sfPageEditor .sticky-anchor-350::before 
{
    content: '';
    display: none;
    height: 0 !important; 
    margin-top: 0 !important;
    visibility: hidden;
    position: static;
}
// SF page editor override Mobile: 100, 125, 150, 175, 200, 250, 300, 350
// SF page editor override Mobile View ONLY -- $utmb-small-max = 767px
@media only screen and (max-width: $utmb-small-max) {
    .sfPageEditor .sticky-anchor-xs-100::before,
    .sfPageEditor .sticky-anchor-xs-125::before,
    .sfPageEditor .sticky-anchor-xs-150::before,
    .sfPageEditor .sticky-anchor-xs-175::before,
    .sfPageEditor .sticky-anchor-xs-200::before,
    .sfPageEditor .sticky-anchor-xs-250::before,
    .sfPageEditor .sticky-anchor-xs-300::before,
    .sfPageEditor .sticky-anchor-xs-350::before {
        content: '';
        display: none;
        height: 0 !important; 
        margin-top: 0 !important;
        visibility: hidden;
        position: static;
    }
}