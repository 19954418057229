// Blog Posts Slider
//


/*
        MOBILE-FIRST
        $screen-xs-min: 480px;
        $screen-sm-min: 768px;
        $screen-md-min: 992px;
        $screen-lg-min: 1200px;
    */

    .utmb-blog-post-slider {
        position: relative;
        background: #444444;
        background: -webkit-gradient(linear,left top,left bottom,from(#444444),to(#111111));
    }

        .utmb-blog-post-slider .splide__arrow svg {
            width: 2.5em;
            height: 2.5em;
            fill: #ffffff;
            -webkit-transition: fill .2s linear;
            transition: fill .2s linear;
        }

    .blog-post-slide-parent-link {
        display: block;
        /*height: 450px;*/
        position: relative;
        cursor: pointer;
    }

    .blog-post-slide-item {
        display: block;
        /*background: black;*/
        height: 500px;
        width: 100%;
        margin: 0;
        padding: 0;
        position: relative;
        z-index: 0;
    }
    /* slider height */
    .utmb-blog-post-slider.height-300 .blog-post-slide-item {
        height: 300px;
    }
    .utmb-blog-post-slider.height-350 .blog-post-slide-item {
        height: 350px;
    }
    .utmb-blog-post-slider.height-400 .blog-post-slide-item {
        height: 400px;
    }
    .utmb-blog-post-slider.height-450 .blog-post-slide-item {
        height: 450px;
    }
    .utmb-blog-post-slider.height-500 .blog-post-slide-item {
        height: 500px;
    }
    .utmb-blog-post-slider.height-550 .blog-post-slide-item {
        height: 550px;
    }
    .utmb-blog-post-slider.height-600 .blog-post-slide-item {
        height: 600px;
    }
    .utmb-blog-post-slider.height-vh .blog-post-slide-item {
        height: 100vh;
    }

        .blog-post-slide-item img {
            width: 100%;
            height: 100%;
        }
            .blog-post-slide-item img.img-fill {
                object-fit: fill;
            }
            .blog-post-slide-item img.img-contain {
                object-fit: contain;
            }
            .blog-post-slide-item img.img-cover {
                object-fit: cover;
            }

        .blog-post-slide-item .blog-slide-container {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 1;
            top: 0;
            left: 0;
        }

        .blog-post-slide-item .blog-slide-content-wrapper {
            max-width: 1100px;
            margin: 0 auto 0;
            /*border: 1px solid yellow;*/
            position: relative;
            height: 100%;
        }

    .blog-slide-content {
        /*border: 1px solid red;*/
    }

        /* blog slide content box box */
        .blog-slide-content.content-width-30 {
            width: 30%;
        }

        .blog-slide-content.content-width-40 {
            width: 40%;
        }

        .blog-slide-content.content-width-50 {
            width: 50%;
        }

        .blog-slide-content.content-width-60 {
            width: 60%;
        }

        .blog-slide-content.content-width-70 {
            width: 70%;
        }

        .blog-slide-content.content-width-80 {
            width: 80%;
        }

        .blog-slide-content.content-width-90 {
            width: 90%;
        }

        .blog-slide-content.content-width-100 {
            width: 100%;
        }

    /* titles - headline sizes */
    .blog-post-slide-title.h1,
    .blog-post-slide-title.title-h1 {
        //font-size: 42px;
        @include convertPixelsToRem_fontSize(42);
    }
    .blog-post-slide-title.h2,
    .blog-post-slide-title.title-h2 {
        //font-size: 32px;
        @include convertPixelsToRem_fontSize(32);
    }
    .blog-post-slide-title.h3,
    .blog-post-slide-title.title-h3 {
        //font-size: 25.888px;
        @include convertPixelsToRem_fontSize(25.888);
    }
    .blog-post-slide-title.h4,
    .blog-post-slide-title.title-h4 {
        //font-size: 20px;
        @include convertPixelsToRem_fontSize(20);
    }
    .blog-post-slide-title.h5,
    .blog-post-slide-title.title-h5 {
        //font-size: 18px;
        @include convertPixelsToRem_fontSize(18);
    }
    .blog-post-slide-title.h6,
    .blog-post-slide-title.title-h6 {
        //font-size: 14px;
        @include convertPixelsToRem_fontSize(14);
    }
    /* blog post summary */
    .blog-post-slide-content-summary.summary-large {
        //font-size: 26px;
        @include convertPixelsToRem_fontSize(26);
    }

    .blog-post-slide-content-summary.summary-medium {
        //font-size: 22px;
        @include convertPixelsToRem_fontSize(22);
    }

    .blog-post-slide-content-summary.summary-small {
        //font-size: 18px;
        @include convertPixelsToRem_fontSize(18);
    }

    .blog-post-slide-item .blog-slide-content .btn {
        margin-top: 15px;
    }

    .blog-post-slide-item .blog-slide-content .btn-default {
        border: 1px solid #fff;
        color: #fff;
    }

        .blog-post-slide-item .blog-slide-content .btn-default:hover,
        .blog-post-slide-item .blog-slide-content .btn-default:active,
        .blog-post-slide-item .blog-slide-content .btn-default:focus {
            background-color: #da1f12;
        }


    .utmb-blog-post-slider .splide__pagination__page:hover {
        cursor: pointer;
        background: #94cacd;
    }

    .utmb-blog-post-slider .splide__pagination__page.is-active {
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
        background: #94cacd;
    }

    .utmb-blog-post-slider .splide__progress__bar {
        height: 3px;
        background: #94cacd;
    }

    /*
        $screen-xs-min: 480px;
        $screen-sm-min: 768px;
        $screen-md-min: 992px;
        $screen-lg-min: 1200px;
    */

    /* "Mobile" Mobile only view (991px-) */
    @media only screen and (max-width: 991px) {

    }

    /* "Small" Tablet view and up (768px+) */
    @media only screen and (min-width: 768px) {
        /* slider height */
        .utmb-blog-post-slider.height-sm-300 .blog-post-slide-item {
            height: 300px;
        }

        .utmb-blog-post-slider.height-sm-350 .blog-post-slide-item {
            height: 350px;
        }

        .utmb-blog-post-slider.height-sm-400 .blog-post-slide-item {
            height: 400px;
        }

        .utmb-blog-post-slider.height-sm-450 .blog-post-slide-item {
            height: 450px;
        }

        .utmb-blog-post-slider.height-sm-500 .blog-post-slide-item {
            height: 500px;
        }

        .utmb-blog-post-slider.height-sm-550 .blog-post-slide-item {
            height: 550px;
        }

        .utmb-blog-post-slider.height-sm-600 .blog-post-slide-item {
            height: 600px;
        }

        .utmb-blog-post-slider.height-sm-vh .blog-post-slide-item {
            height: 100vh;
        }
        /* blog slide image handling */
        .blog-post-slide-item img.img-sm-fill {
            object-fit: fill;
        }

        .blog-post-slide-item img.img-sm-contain {
            object-fit: contain;
        }

        .blog-post-slide-item img.img-sm-cover {
            object-fit: cover;
        }
        /* blog slide content box box */
        .blog-slide-content.content-width-sm-30 {
            width: 30%;
        }

        .blog-slide-content.content-width-sm-40 {
            width: 40%;
        }

        .blog-slide-content.content-width-sm-50 {
            width: 50%;
        }

        .blog-slide-content.content-width-sm-60 {
            width: 60%;
        }

        .blog-slide-content.content-width-sm-70 {
            width: 70%;
        }

        .blog-slide-content.content-width-sm-80 {
            width: 80%;
        }

        .blog-slide-content.content-width-sm-90 {
            width: 90%;
        }

        .blog-slide-content.content-width-sm-100 {
            width: 100%;
        }
        /* titles - headline sizes */
        .blog-post-slide-title.title-sm-h1 {
            //font-size: 42px;
            @include convertPixelsToRem_fontSize(42);
        }

        .blog-post-slide-title.title-sm-h2 {
            //font-size: 32px;
            @include convertPixelsToRem_fontSize(32);
        }

        .blog-post-slide-title.title-sm-h3 {
            //font-size: 25.888px;
            @include convertPixelsToRem_fontSize(25.888);
        }

        .blog-post-slide-title.title-sm-h4 {
            //font-size: 20px;
            @include convertPixelsToRem_fontSize(20);
        }

        .blog-post-slide-title.title-sm-h5 {
            //font-size: 18px;
            @include convertPixelsToRem_fontSize(18);
        }

        .blog-post-slide-title.title-sm-h6 {
            //font-size: 14px;
            @include convertPixelsToRem_fontSize(14);
        }
        /* blog slide summary */
        .blog-post-slide-content-summary.summary-sm-large {
            //font-size: 26px;
            @include convertPixelsToRem_fontSize(26);
        }

        .blog-post-slide-content-summary.summary-sm-medium {
            //font-size: 22px;
            @include convertPixelsToRem_fontSize(22);
        }

        .blog-post-slide-content-summary.summary-sm-small {
            //font-size: 18px;
            @include convertPixelsToRem_fontSize(18);
        }
    }

    /* "Medium / Sweetspot" Desktop view and up (992px+) */
    @media only screen and (min-width: 992px) {
        /* slider height */
        .utmb-blog-post-slider.height-md-300 .blog-post-slide-item {
            height: 300px;
        }

        .utmb-blog-post-slider.height-md-350 .blog-post-slide-item {
            height: 350px;
        }

        .utmb-blog-post-slider.height-md-400 .blog-post-slide-item {
            height: 400px;
        }

        .utmb-blog-post-slider.height-md-450 .blog-post-slide-item {
            height: 450px;
        }

        .utmb-blog-post-slider.height-md-500 .blog-post-slide-item {
            height: 500px;
        }

        .utmb-blog-post-slider.height-md-550 .blog-post-slide-item {
            height: 550px;
        }

        .utmb-blog-post-slider.height-md-600 .blog-post-slide-item {
            height: 600px;
        }

        .utmb-blog-post-slider.height-md-vh .blog-post-slide-item {
            height: 100vh;
        }
        /* blog slide image handling */
        .blog-post-slide-item img.img-md-fill {
            object-fit: fill;
        }

        .blog-post-slide-item img.img-md-contain {
            object-fit: contain;
        }

        .blog-post-slide-item img.img-md-cover {
            object-fit: cover;
        }
        /* blog slide content box box */
        .blog-slide-content.content-width-md-30 {
            width: 30%;
        }

        .blog-slide-content.content-width-md-40 {
            width: 40%;
        }

        .blog-slide-content.content-width-md-50 {
            width: 50%;
        }

        .blog-slide-content.content-width-md-60 {
            width: 60%;
        }

        .blog-slide-content.content-width-md-70 {
            width: 70%;
        }

        .blog-slide-content.content-width-md-80 {
            width: 80%;
        }

        .blog-slide-content.content-width-md-90 {
            width: 90%;
        }

        .blog-slide-content.content-width-md-100 {
            width: 100%;
        }
        /* titles - headline sizes */
        .blog-post-slide-title.title-md-h1 {
            //font-size: 42px;
            @include convertPixelsToRem_fontSize(42);
        }

        .blog-post-slide-title.title-md-h2 {
            //font-size: 32px;
            @include convertPixelsToRem_fontSize(32);
        }

        .blog-post-slide-title.title-md-h3 {
            //font-size: 25.888px;
            @include convertPixelsToRem_fontSize(25.888);
        }

        .blog-post-slide-title.title-md-h4 {
            //font-size: 20px;
            @include convertPixelsToRem_fontSize(20);
        }

        .blog-post-slide-title.title-md-h5 {
            //font-size: 18px;
            @include convertPixelsToRem_fontSize(18);
        }

        .blog-post-slide-title.title-md-h6 {
            //font-size: 14px;
            @include convertPixelsToRem_fontSize(14);
        }
        /* blog slide summary */
        .blog-post-slide-content-summary.summary-md-large {
            //font-size: 26px;
            @include convertPixelsToRem_fontSize(26);
        }

        .blog-post-slide-content-summary.summary-md-medium {
            //font-size: 22px;
            @include convertPixelsToRem_fontSize(22);
        }

        .blog-post-slide-content-summary.summary-md-small {
            //font-size: 18px;
            @include convertPixelsToRem_fontSize(18);
        }
    }

    /* "Large" Larger Desktop view and up (1200px+) */
    @media only screen and (min-width: 1200px) {
        /* slider height */
        .utmb-blog-post-slider.height-lg-300 .blog-post-slide-item {
            height: 300px;
        }

        .utmb-blog-post-slider.height-lg-350 .blog-post-slide-item {
            height: 350px;
        }

        .utmb-blog-post-slider.height-lg-400 .blog-post-slide-item {
            height: 400px;
        }

        .utmb-blog-post-slider.height-lg-450 .blog-post-slide-item {
            height: 450px;
        }

        .utmb-blog-post-slider.height-lg-500 .blog-post-slide-item {
            height: 500px;
        }

        .utmb-blog-post-slider.height-lg-550 .blog-post-slide-item {
            height: 550px;
        }

        .utmb-blog-post-slider.height-lg-600 .blog-post-slide-item {
            height: 600px;
        }

        .utmb-blog-post-slider.height-lg-vh .blog-post-slide-item {
            height: 100vh;
        }
        /* blog slide image handling */
        .blog-post-slide-item img.img-lg-fill {
            object-fit: fill;
        }

        .blog-post-slide-item img.img-lg-contain {
            object-fit: contain;
        }

        .blog-post-slide-item img.img-lg-cover {
            object-fit: cover;
        }
        /* blog slide content box box */
        .blog-slide-content.content-width-lg-30 {
            width: 30%;
        }

        .blog-slide-content.content-width-lg-40 {
            width: 40%;
        }

        .blog-slide-content.content-width-lg-50 {
            width: 50%;
        }

        .blog-slide-content.content-width-lg-60 {
            width: 60%;
        }

        .blog-slide-content.content-width-lg-70 {
            width: 70%;
        }

        .blog-slide-content.content-width-lg-80 {
            width: 80%;
        }

        .blog-slide-content.content-width-lg-90 {
            width: 90%;
        }

        .blog-slide-content.content-width-lg-100 {
            width: 100%;
        }
        /* titles - headline sizes */
        .blog-post-slide-title.title-lg-h1 {
            //font-size: 42px;
            @include convertPixelsToRem_fontSize(42);
        }

        .blog-post-slide-title.title-lg-h2 {
            //font-size: 32px;
            @include convertPixelsToRem_fontSize(32);
        }

        .blog-post-slide-title.title-lg-h3 {
            //font-size: 25.888px;
            @include convertPixelsToRem_fontSize(25.888);
        }

        .blog-post-slide-title.title-lg-h4 {
            //font-size: 20px;
            @include convertPixelsToRem_fontSize(20);
        }

        .blog-post-slide-title.title-lg-h5 {
            //font-size: 18px;
            @include convertPixelsToRem_fontSize(18);
        }

        .blog-post-slide-title.title-lg-h6 {
            //font-size: 14px;
            @include convertPixelsToRem_fontSize(14);
        }
        /* blog slide summary */
        .blog-post-slide-content-summary.summary-lg-large {
            //font-size: 26px;
            @include convertPixelsToRem_fontSize(26);
        }

        .blog-post-slide-content-summary.summary-lg-medium {
            //font-size: 22px;
            @include convertPixelsToRem_fontSize(22);
        }

        .blog-post-slide-content-summary.summary-lg-small {
            //font-size: 18px;
            @include convertPixelsToRem_fontSize(18);
        }
    }



    /* Hide pagination for single slide */

    [utmb-slide-count="1"].utmb-blog-post-slider .splide__arrow,
    [utmb-slide-count="1"].utmb-blog-post-slider .splide__pagination {
        display: none;
    }