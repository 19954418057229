﻿// Bootstrap Container


.row,
.row .container,
.container {
    @include clearfix;
}

// Classic Design Mode (1100px Max Width)
body.design-mode-classic .container {
    @include page-container-classic();
}

// Modern Design Mode (1400px Max Width)
body.design-mode-modern .container {
    @include page-container-modern();
}
