// NewUTMB  Feature Box
// Splide JS Slideshow Carousel



/* Feature Box */
.utmb-feature-box {
    position: relative;

    // caption 
    .uk-overlay.uk-overlay-primary,
    .uk-overlay.uk-overlay-primary p {
        color: rgba(255,255,255,1) !important;
    }

    .splide__arrow svg {
        width: 2.5em;
        height: 2.5em;
        fill: $white;
        -webkit-transition: fill .2s linear;
        transition: fill .2s linear;
    }

    .utmb-feature-box-img {
        width: 100%;
    }
        .utmb-feature-box-img.has-link {
            cursor: pointer;
        }

    .splide__progress__bar {
        background: $gray-c;
    }

    .splide__pagination__page:hover {
        cursor: pointer;
        background: $white;
    }

    .splide__pagination__page.is-active {
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
        background: $white;
    }

    /*
        MOBILE-FIRST
        $screen-xs-min: 480px;
        $screen-sm-min: 768px;
        $screen-md-min: 992px;
        $screen-lg-min: 1200px;
    */

    // Single Slide Handling -- Hide arrows and pagination
    &.hide-arrows .splide__arrow {
        display: none;
    }
    &.hide-pagination .splide__pagination {
        display: none;
    }

    // Color Theme
    .splide__pagination__page:hover {
        cursor: pointer;
        background: $teal-light; //#94cacd;
    }
    .splide__pagination__page.is-active {
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
        background: $teal-light; //#94cacd;
    }
    .splide__progress__bar {
        height: 3px;
        background: $teal-light; //#94cacd;
    }
}
    


/*
    Feature Box Nav 
    Hide (Initially) Feature Box Nav Widget Menu
    Overlay feature box nav now works with the SF naivgation widget
    JavaScript grabs the nav widget and appends it to the .utmb-feature-box-nav-container
    Show SF naivgation widget once appended
*/
.utmb-feature-box-nav {
    display: none;
}

/* Nav Container Inside of the Feature Box Wrapper -- i.e. where we append the nav widget */
.utmb-feature-box-nav-container {
    position: relative;
    z-index: 1;
    top: 0;

    // Show Nav Widget WHEN IN PLACE
    .utmb-feature-box-nav {
        display: block;
    }

    // Hide Mobile Dropdown
    .nav-mobile-dropdown {
        display: none;
    }

    // Horizontal nav, Pills, Tabs
    .nav-horizontal .uk-navbar-container,
    .utmbsf-nav-pills .uk-navbar-container .utmb-feature-box-nav-container .nav-tab .uk-tab,
    .utmbsf-nav-top-bar .uk-navbar-container {
        background: transparent;
        border: 0;
        display: block;
        margin: 0;
        padding: 0;
        position: static;
    }
    .nav-horizontal .uk-navbar-nav,
    .utmbsf-nav-pills .uk-navbar-nav,
    .nav-tab .uk-tab,
    .utmbsf-nav-top-bar .uk-navbar-nav {
        display: block;
        margin-left: 0;
        margin-left: 0;
    }
    .nav-horizontal .uk-navbar-nav > li > a,
    .utmbsf-nav-pills .uk-navbar-nav > li > a,
    .nav-tab .uk-tab > li > a,
    .utmbsf-nav-top-bar .uk-navbar-nav > li > a {
        border: 1px solid $gray-e;
        border-left: 4px solid $red;
        display: block;
        font-size: 1.1rem;
        line-height: normal;
        margin: 2px 0;
        min-height: unset;
        padding: 11px 11px;
        text-transform: none;
        width: 100%;
    }
    .nav-horizontal .uk-navbar-nav > li > a:hover,
    .utmbsf-nav-pills .uk-navbar-nav > li > a:hover,
    .nav-tab .uk-tab > li > a:hover,
    .utmbsf-nav-top-bar .uk-navbar-nav > li > a:hover {
        color: $red;
    }
    .nav-horizontal .uk-navbar-nav > li > a:active,
    .nav-horizontal .uk-navbar-nav > li > a:focus,
    .utmbsf-nav-pills .uk-navbar-nav > li > a:active,
    .utmbsf-nav-pills .uk-navbar-nav > li > a:focus,
    .nav-tab .uk-tab > li > a:active,
    .nav-tab .uk-tab > li > a:focus,
    .utmbsf-nav-top-bar .uk-navbar-nav > li > a:active,
    .utmbsf-nav-top-bar .uk-navbar-nav > li > a:focus {
        background: $red;
        color: $white;
    }

    // Vertical Nav, List, List w/ Dropdowns, Nav List, Nav List Drop Downs w/ Dropdowns
    .nav-vertical .uk-nav,
    .utmbsf-nav-list .uk-nav,
    .nav-list-dropdowns ul,
    .utmbsf-nav-list-menu .uk-nav,
    .nav-list-menu-dropdowns .nav,
    .utmbsf-nav-sitemap .nav {
        background: transparent;
        border: 0;
        display: block;
        margin: 0;
        padding: 0;
        position: static;
    }
    .nav-vertical .uk-nav > li > a,
    .utmbsf-nav-list .uk-nav > li > a,
    .nav-list-dropdowns ul > li > a,
    .utmbsf-nav-list-menu .uk-nav > li > a,
    .nav-list-menu-dropdowns .nav > li > a,
    .utmbsf-nav-sitemap .nav > li > a {
        border: 1px solid $gray-e;
        border-left: 4px solid $red;
        display: block;
        font-size: 1.1rem;
        line-height: normal;
        margin: 2px 0;
        min-height: unset;
        padding: 11px 11px;
        text-transform: none;
    }
}


// "Mobile" Below desktop view (991px-)
@media only screen and (max-width: 991px) {
    .uk-navbar-left {
        display: block;
    }

    .nav-horizontal ul,
    .nav-vertical ul {
        display: block;
    }
}

/* "Sweetspot" Desktop view and up (992px+) */
@media only screen and (min-width: 992px) {
    /* Feature Box*/
    .utmb-feature-box-nav-wrapper {
        position: relative;
        max-width: $container-classic; //1100px;
        margin: 0 auto;
    }

    .utmb-feature-box-nav-container {
        position: absolute;
        z-index: 1;
        top: 20px;

        // Horizontal Nav, Pills, Tabs
        .nav-horizontal .uk-navbar-container,
        .utmbsf-nav-pills .uk-navbar-container .utmb-feature-box-nav-container .nav-tab .uk-tab,
        .utmbsf-nav-top-bar .uk-navbar-container {
            background: transparent;
            border: 0;
            position: relative;
            margin-left: 67%;
        }
        .nav-horizontal .uk-navbar-nav,
        .utmbsf-nav-pills .uk-navbar-nav,
        .nav-tab .uk-tab,
        .utmbsf-nav-top-bar .uk-navbar-nav {
            display: flex;
            margin-left: 0;
            margin-left: -74%;
        }
        .nav-horizontal .uk-navbar-nav > li > a,
        .utmbsf-nav-pills .uk-navbar-nav > li > a,
        .nav-tab .uk-tab > li > a,
        .utmbsf-nav-top-bar .uk-navbar-nav > li > a {
            display: flex;
            background-color: $white;
            background: white;
            background: rgba(255,255,255,0.77);
            border: 0;
            border-left: 4px solid $red;
            color: #222222;
            //font-family: Roboto, Helvetica Neue\ 2,Helvetica Neue,Helvetica,Arial,sans-serif;
            //font-family: Roboto Condensed,Helvetica Neue\ 2,Helvetica Neue,Helvetica,Arial,sans-serif;
            font-family: $font-family-condensed;
            font-size: 1.65em;
            padding: 10px 20px 10px 20px;
            margin: 8px 4px;
            text-align: left;
            text-transform: none;
        }
        .nav-tab .uk-tab::before {
            border: 0;
        }
        .nav-horizontal .uk-navbar-nav > li > a:hover,
        .nav-horizontal .uk-navbar-nav > li > a:active,
        .nav-horizontal .uk-navbar-nav > li > a:focus,
        .utmbsf-nav-pills .uk-navbar-nav > li > a:hover,
        .utmbsf-nav-pills .uk-navbar-nav > li > a:active,
        .utmbsf-nav-pills .uk-navbar-nav > li > a:focus,
        .nav-tab .uk-tab > li > a:hover,
        .nav-tab .uk-tab > li > a:active,
        .nav-tab .uk-tab > li > a:focus,
        .utmbsf-nav-top-bar .uk-navbar-nav > li > a:hover,
        .utmbsf-nav-top-bar .uk-navbar-nav > li > a:active,
        .utmbsf-nav-top-bar .uk-navbar-nav > li > a:focus {
            background-color: $white;
            background: $white;
            color: $red;
            -moz-box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.4);
            -webkit-box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.4);
            box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.4);
        }

        /* Vertical Nav, List, List w/ Dropdowns, Nav List, Nav List Drop Downs w/ Dropdowns */
        .nav-vertical .uk-nav,
        .utmbsf-nav-list .uk-nav,
        .nav-list-dropdowns ul,
        .utmbsf-nav-list-menu .uk-nav,
        .nav-list-menu-dropdowns .nav,
        .utmbsf-nav-sitemap .nav {
            background: transparent;
            border: 0;
            display: block;
            left: 50px;
            position: relative;
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
        .nav-vertical .uk-nav > li > a,
        .utmbsf-nav-list .uk-nav > li > a,
        .nav-list-dropdowns ul > li > a,
        .utmbsf-nav-list-menu .uk-nav > li > a,
        .nav-list-menu-dropdowns .nav > li > a,
        .utmbsf-nav-sitemap .nav > li > a {
            background-color: $white;
            //background: white;
            background: rgba(255,255,255,0.77);
            border: 0;
            border-left: 4px solid $red;
            color: #222222;
            display: block;
            //font-family: Roboto,Helvetica Neue\ 2,Helvetica Neue,Helvetica,Arial,sans-serif;
            //font-family: Roboto Condensed,Helvetica Neue\ 2,Helvetica Neue,Helvetica,Arial,sans-serif;
            font-family: $font-family-condensed;
            font-size: 1.65em;
            margin: 8px 4px;
            padding: 10px 30px 10px 20px;
            position: relative;
            text-decoration: none;
            width: 351px;
        }
        .nav-vertical .uk-nav > li > a .uk-icon,
        .utmbsf-nav-list .uk-nav > li > a .uk-icon,
        .nav-list-dropdowns ul > li > a .uk-icon,
        .utmbsf-nav-list-menu .uk-nav > li > a .uk-icon,
        .nav-list-menu-dropdowns .nav > li > a .uk-icon,
        .utmbsf-nav-sitemap .nav > li > a .uk-icon {
            color: $red;
            position: absolute;
            right: 0;
        }
        .utmbsf-nav-sitemap .nav > li > a strong {
            font-weight: normal;
        }
    }
            
    /* CENTER MENU
        .nav-center.utmb-feature-box.utmb-feature-box-has-nav .utmb-feature-box-nav-container {
            display: block;
            margin-left: 50%;
            position: absolute;
            white-space: nowrap;
        }
        .nav-center.utmb-feature-box.utmb-feature-box-has-nav .utmb-feature-box-nav-container .nav-horizontal .uk-navbar-nav,
        .nav-center.utmb-feature-box.utmb-feature-box-has-nav .utmb-feature-box-nav-container .nav-pills .uk-navbar-nav,
        .nav-center.utmb-feature-box.utmb-feature-box-has-nav .utmb-feature-box-nav-container .nav-tab .uk-tab,
        .nav-center.utmb-feature-box.utmb-feature-box-has-nav .utmb-feature-box-nav-container .nav-vertical .uk-nav,
        .nav-center.utmb-feature-box.utmb-feature-box-has-nav .utmb-feature-box-nav-container .utmbsf-nav-list .uk-nav,
        .nav-center.utmb-feature-box.utmb-feature-box-has-nav .utmb-feature-box-nav-container .nav-list-dropdowns ul,
        .nav-center.utmb-feature-box.utmb-feature-box-has-nav .utmb-feature-box-nav-container .utmbsf-nav-list-menu .uk-nav,
        .nav-center.utmb-feature-box.utmb-feature-box-has-nav .utmb-feature-box-nav-container .nav-list-menu-dropdowns .nav {
            margin-left: -50%;
            position: relative;
        }
    */

}