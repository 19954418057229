﻿// Typography


// UTMB Font Stack

$font-family-base: "Roboto", "Helvetica Neue 2", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
$font-family-condensed: "Roboto Condensed", "Helvetica Neue 2", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
$font-family-serif: "EB Garamond", "Minion Pro", "Minion", "Georgia", "Times New Roman", "Times", serif; 
$font-family-monospace: "Roboto Mono", Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-script: 'Dancing Script', cursive;
$font-family-headline: $font-family-base; //$font-family-condensed;

$global-font-size: 1rem;

/*$font-size-base: 1em; // 16px
$font-size-large: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small: ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1: floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2: floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3: ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5: $font-size-base !default;
$font-size-h6: ceil(($font-size-base * 0.85)) !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.618; //1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family: inherit !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.1 !default;
$headings-color: inherit !default;*/

// Headlines

//$font-size-h1:            30px; //floor(($font-size-base * 2.6)) !default; // ~36px
//$font-size-h2:            27px; //floor(($font-size-base * 2.15)) !default; // ~30px
//$font-size-h3:            25px; //ceil(($font-size-base * 1.7)) !default; // ~24px
//$font-size-h4:            20px; //ceil(($font-size-base * 1.25)) !default; // ~18px
//$font-size-h5:            20px; //$font-size-base !default;
//$font-size-h6:            16px; //ceil(($font-size-base * 0.85)) !default; // ~12px