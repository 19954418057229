﻿// UTMB Design Styles for Sitefinity Draggable Layout Grids
//



// Page Layout Grid w/ Sidebar Options
.page-layout-grid {
    padding-bottom: 30px;
    padding-top: 30px;

    .utmb-page-title {
        margin-top: 0;
    }

    @media only screen and (min-width: $utmb-sweetspot) {
        .sidebar-left {
            padding-right: 44px;
            padding-bottom: 111px;
            padding-top: 22px;
        }

        .sidebar-right {
            padding-left: 44px;
            padding-bottom: 111px;
            padding-top: 22px;
        }

        padding-bottom: 111px;
    }
}



// Gutter Classes +Add 30px respectively
.gutter-top {
    padding-top: 30px;
}
.gutter-bottom {
    padding-bottom: 30px;
}
.gutter-left {
    padding-left: 30px;
}
.gutter-right {
    padding-right: 30px;
}








    /*.layout-col-content {
    margin-top: 30px;
}

.layout-col-right {
    margin-left: 30px;
    margin-top: 30px;
}

.layout-col-left {
    margin-right: 30px;
    margin-top: 30px;
}*/
    /*.row.layout-modern {
    .col-md-9 {
    }

    .col-md-3 {
    }
}*/


