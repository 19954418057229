.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-muted {
    color: $gray-5 !important;
}


.list-unstyled {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0;
}

    .list-unstyled li {
        list-style-type: none;
        margin: 0 0 5px;
        padding: 0 15px;
    }