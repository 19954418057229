﻿// UTMB Social Media Icons
// ------------------------------------------------

/*
<div class="clearfix">
    <div class="social-icons"><a class="icon-facebook" href="https://www.facebook.com/UTMB.edu" title="Follow UTMB on Facebook" data-sf-ec-immutable=""><span class="sr-only">UTMB on Facebook</span></a>
        <a class="icon-twitter" href="https://twitter.com/utmbhealth/" title="Follow UTMB on Twitter" data-sf-ec-immutable=""><span class="sr-only">UTMB on Twitter</span></a>
        <a class="icon-flickr" href="https://www.instagram.com/utmbhealth/" title="Follow UTMB on Instagram" data-sf-ec-immutable=""><span class="sr-only">UTMB on Instagram</span></a>
        <a class="icon-youtube" href="http://www.youtube.com/UTMBGalveston" title="Follow UTMB on YouTube" data-sf-ec-immutable=""><span class="sr-only">UTMB on YouTube</span></a>
        <a class="icon-rss" href="http://www.utmb.edu/newsroom/rss/TopStories.aspx" title="Subscribe to the UTMB Newsroom RSS Feeds" data-sf-ec-immutable=""><span class="sr-only">UTMB Newsroom RSS Feeds</span></a>
    </div>
</div>
*/

.social-icons {
    float: right;

    a {
        background-image: #{$social-icons-sprite};
        background-position: 0 -8px;
        background-repeat: no-repeat;
        height: 35px;
        width: 35px;
        display: inline-block;
        text-indent: -999999px;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        -moz-box-shadow: 2px 2px 3px 0px #666; // horizontal, vertical, blur, spread
        -webkit-box-shadow: 2px 2px 3px 0px #666;
        box-shadow: 2px 2px 3px 0px #666;

        &:hover,
        &:active {
            -moz-box-shadow: inset 1px 1px 2px 1px #444;
            -webkit-box-shadow: inset 1px 1px 2px 1px #444;
            box-shadow: inset 1px 1px 2px 1px #444;
        }

        &.icon-facebook {
            background-position: -97px -8px;
        }

        &.icon-twitter {
            background-position: -7px -8px;
        }

        &.icon-instagram {
            background-position: -186px -53px;
        }

        &.icon-flickr {
            background-position: -186px -53px;
        }

        &.icon-youtube {
            background-position: -97px -53px;
        }

        &.icon-wordpress {
            background-position: -230px -97px;
        }

        &.icon-rss {
            background-position: -52px -8px;
        }
    }
}



//@media only screen and (min-width: 48em) {
//    .social-icons {
//        position: absolute;
//        top: -58px;
//        right: -15px;
//    }
//}

