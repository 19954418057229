﻿// Team Member Detail Page
//

$img-large:  280px; //280px (default)
$img-medium: 250px;
$img-small:  220px;
$img-xsmall: 190px;

$col-large:  300px;
$col-medium: 270px;
$col-small:  240px;

.team-member-detail {

    // article (inner wrapper)
    .team-member {
        @extend .uk-article;
        font-size: 1rem;
    }

    // flexbox grid (mobile first)
    .team-member-profile {
        width: 100%;
        font-size: 1rem;
        

        .team-member-profile-col-01 {
            font-size: 1rem;

            // mobile styles
            @media only screen and (max-width: 692px) {
                .team-member-media {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    .team-member-photo {
                        margin: 0 auto 30px;
                    }
                }
            }
        }
        .team-member-profile-col-02 {
            font-size: 1rem;

            .team-member-identity-wrapper {
                padding-bottom: 30px;
            }

            // mobile styles
            @media only screen and (max-width: 692px) {
                .team-member-identity-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    .team-member-identity {
                        display: inline-block;
                        margin: 0 auto 30px;
                    }
                }
            }
        }

        // desktop styles
        @media only screen and (min-width: 693px) {
            display: flex;
            flex-flow: row;

            .team-member-profile-col-01 {
                order: 0;
                width: $col-large;
                min-width: $col-large;
            }
            .team-member-profile-col-02 {
                order: 0;
                align-self: stretch;
                flex-grow: 1;
                padding: 0 20px;
            }
        }
    }

    // column (1) size (desktop)
    @media only screen and (min-width: 693px) {
        // col-large
        &.col-large {
            .team-member-profile .team-member-profile-col-01 {
                width: $col-large;
                min-width: $col-large;
                .team-member-contacts-wrapper {
                    .team-member-contacts {
                        max-width: $img-large;
                    }
                }
            }
        }
        // col-medium
        &.col-medium {
            .team-member-profile .team-member-profile-col-01 {
                width: $col-medium;
                min-width: $col-medium;
                .team-member-contacts-wrapper {
                    .team-member-contacts {
                        max-width: $img-medium;
                    }
                }
            }
        }
        // col-small
        &.col-small {
            .team-member-profile .team-member-profile-col-01 {
                width: $col-small;
                min-width: $col-small;
                .team-member-contacts-wrapper {
                    .team-member-contacts {
                        max-width: $img-small;
                    }
                }
            }
        }
    }

    // photo
    .team-member-photo {
        width: $img-large;
        margin-bottom: 25px;
        img {
            // padding: 5px;
            border: 8px solid #fff;
            box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
            width: 100%;
        }
    }

    // img-large
    &.img-large {
        .team-member-photo {
            width: $img-large; 
        }
    }
    // img-medium
    &.img-medium {
        .team-member-photo {
            width: $img-medium;
        }
    }
    // img-small
    &.img-small {
        .team-member-photo {
            width: $img-small;
        }
    }
    // img-xsmall
    &.img-xsmall {
        .team-member-photo {
            width: $img-xsmall;
        }
    }


    // circle photo (img-circle)
    &.img-circle {
        .team-member-photo {
            width: $img-large;
            height: $img-large;
            overflow: hidden;
            border-radius: 50%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 25px;
            border: 8px solid #fff;
            box-shadow: 0 5px 15px rgb(0 0 0 / 8%);

            img {
                padding: 0;
                margin: 0;
                border: 0;
                box-shadow: 0 !important;
                width: 100%;
            }
        }
        // img-large
        &.img-large {
            .team-member-photo {
                height: $img-large;
                width: $img-large;
            }
        }
        // img-medium
        &.img-medium {
            .team-member-photo {
                height: $img-medium;
                width: $img-medium;
            }
        }
        // img-small
        &.img-small {
            .team-member-photo {
                height: $img-small;
                width: $img-small;
            }
        }
        // img-xsmall
        &.img-xsmall {
            .team-member-photo {
                height: $img-xsmall;
                width: $img-xsmall;
            }
        }

        

        @media only screen and (min-width: 693px) {
            // contacts (swtich to flex and center for image circle)
            .team-member-contacts-wrapper[utmb-position="desktop-col-01"] {
                display: flex;
                align-items: center;
                justify-content: center;

                .team-member-contacts {
                    display: inline-block;
                }
            }
        }
    }
    
    // icons
    i {
        margin-right: 2px;
    }

    // Name / Title
    .team-member-title {
        @extend .uk-article-title;
        margin-bottom: 10px;

        .acadmemic-suffix {
            font-size: 0.8em;
        }
    }

    // position
    .team-member-position {
        font-size: 1rem;
        p {
            font-size: 1rem;
            margin: 0;
            padding: 0;
        }
    }

    // additional info
    .team-member-additional-info {
        padding-top: 15px;

        .team-member-entity {
            font-size: 0.8rem;
        }
        .team-member-department {
            font-size: 0.8rem;
        }
        .team-member-division {
            font-size: 0.8rem;
        }
    }

    // contacts (dekstop col 01)
    .team-member-contacts-wrapper[utmb-position="desktop-col-01"] {
        width: 100%;

        .team-member-contacts {
            font-size: 0.85rem;
            padding: 5px;
        }
        .team-member-basic-info {
            padding-bottom: 15px;
        }
        .team-member-pro-info {
            padding: 0 0 15px;
        }
        .team-member-links {
            padding: 0 0 15px;
        }
        .team-member-address,
        .team-member-address p {
            font-size: 0.85rem;
            margin: 0;
            padding: 0;
        }

        .team-member-physical-address,
        .team-member-mailing-address {
            padding: 0 0 15px;

            .address-label {
                font-size: 0.8rem;
            }
        }

        // mobile styles (hide)
        @media only screen and (max-width: 692px) {
            display: none;
        }
    }

    // pro links (desktop top col 02)
    .team-member-pro-info[utmb-position="desktop-col-02-top"] {
        padding-bottom: 30px;
        
        div {
            display: inline-block;
            font-size: .9rem;
            

            // not buttons
            .lnk-btn-txt {
                display: inline-block;
                margin-right: 4px;

                &:hover,
                &:active,
                &:focus {
                    border: 0;
                    text-decoration: double underline;
                }
            }
        }

        // mobile styles (hide)
        @media only screen and (max-width: 692px) {
            display: none;
        }
    }

    // contacts mobile (footer)
    .team-member-contacts-wrapper[utmb-position="mobile"] {
        margin-top: 40px;

        // desktop styles (hide)
        @media only screen and (min-width: 693px) {
            display: none;
        }
    }


    // link buttons
    .btn {
        margin-top: 2px;
        margin-bottom: 2px;
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }
    .link-buttons-left .btn {
        text-align: left;
    }
    .link-buttons-right .btn {
        text-align: right;
    }
    .link-buttons-center .btn {
        text-align: center;
    }
    


    // meta (cats & tags)
    .item-content-meta {
        border-top: 1px #eee solid;
        padding-top: 5px;
        margin-top: 80px;
        margin-bottom: 20px;

        .item-content-cats,
        .item-content-tags {
            margin: 8px 0 0 0;
        }

        a:hover,
        a:active,
        a:focus {
            border-bottom: 0;
            text-decoration: double underline;
        }
    }
    
    // meta alignments
    // meta (container)
    &.meta-left .item-content-meta {
        text-align: left;
    }
    &.meta-right .item-content-meta {
        text-align: right;
    }
    &.meta-center .item-content-meta {
        text-align: center;
    }
    // cats
    &.cats-left .item-content-cats {
        text-align: left;
    }
    &.cats-right .item-content-cats {
        text-align: right;
    }
    &.cats-center .item-content-cats {
        text-align: center;
    }
    // tags
    &.tags-left .item-content-tags {
        text-align: left;
    }
    &.tags-right .item-content-tags {
        text-align: right;
    }
    &.tags-center .item-content-tags {
        text-align: center;
    }


    // Pubications RSS Feed
    .feed-container {
        margin-top: 40px;

        .feed-item {
            border-top: 1px solid #eee;
            padding-top: 15px;
            padding-bottom: 15px;
        }
        .feed-article-title {
            margin: 0 0 10px 0;
            font-size: 1.25rem;
        }
        .feed-date {
            color: #7f8c8d;
            font-size: 0.8rem
        }
        .feed-description {
            margin: 10px 0;
            color: #444;
            font-size: 0.85rem
        }
    }
    
    
    
       
    







    

    // .team-member-meta {
    //     @extend .uk-article-meta;
    // }

    // .team-member-meta-link {
    //     a {
    //         @extend .uk-button;
    //         @extend .uk-button-text;
    //     }
    // }

    // .team-member-content {
    //     .team-member-content-summary {
    //         @extend .uk-text-lead;
    //         padding-left: calc(5px + 0.2em);
    //         /*border-left: calc(5px + 0.1em) solid #e5e5e5;*/
    //         border-left: 5px solid $red;
    //     }
    // }

    // p.lead {
    //     @extend .uk-text-lead;
    // }

    // .sf-icon-comment {
    //     display: none !important;
    // }

    // img {
    //     //@extend .uk-border-rounded;
    //     //@extend .uk-border-circle;
    //    // @extend .uk-card;
    //     //@extend .uk-card-default;
    //     //margin-top: 5px;
    //     padding: 5px;
    // }

    /*.comments-count-wrapper {
        a {
            @extend .uk-button;
            @extend .uk-button-text;
        }
    }

    .sf-Comments-header {
        margin-bottom: 0;
        margin-top: 100px;

        a[data-sf-role="comments-new-form-button"] {
            @extend .uk-button;
            @extend .uk-button-text;
        }

        .pull-right {
            @extend .uk-article-meta;
        }

        a[data-sf-role="comments-sort-old-button"],
        a[data-sf-role="comments-sort-new-button"] {
            @extend .uk-button;
            @extend .uk-button-text;
            margin-left: 15px;
            text-transform: none;

            &.selected {
                color: $red;
            }
        }
    }

    div[data-sf-role="comments-container"],
    .sf-Comments-list {

        .sf-media {
            @extend .uk-article-meta; //font-size: .875rem;
            border-top: 1px solid #e5e5e5;
            margin: 15px 0px;
            padding: 25px 0 12px;

            &:first-child {
                border-top: 0;
                padding-top: 0;
            }


            .sf-img-thmb {
                min-width: 60px;

                img {
                    width: 40px;
                    height: 40px;
                }
            }

            .sf-media-body {
                span[data-sf-role="comment-name"],
                .sf-Comments-list-author {
                    color: $gray;
                    font-weight: bold;
                    padding-right: 10px;
                    margin-right: 5px;
                }

                span[data-sf-role="comment-date"] {
                }

                p[data-sf-role="comment-message"] {
                    color: $gray;
                    margin: 0;
                    margin-top: 15px;
                    padding: 0;
                }
            }
        }
    }

    .sf-Comments-form {

        div[data-sf-role="comments-new-pending-approval-message"],
        .alert.alert-warning {
        }

        .has-error {
            .help-block {
                color: $red;
                font-size: .8rem;
            }
        }

        .form-group {
            textarea,
            textarea.form-control,
            textarea[data-sf-role="comments-new-message"] {
                @extend .uk-textarea;
            }
        }

        input,
        input.form-control,
        input[data-sf-role="comments-new-name"],
        input[data-sf-role="comments-new-email"] {
            @extend .uk-input;
        }

        button,
        button.btn.btn-primary,
        button[data-sf-role="comments-new-submit-button"] {
            @extend .uk-button;
            @extend .uk-button-default;
            border-radius: 0;

            &:hover,
            &:active,
            &:focus {
                background-color: #338042; //$green;
                border-color: #338042;
                color: #fff;
            }
        }
    }*/
}




// Tab to Accordion
// .utmb-tabcordion {
//     &.has-tabs {
//         .utmb-tabcordion--tabs {
//             opacity: 1;
//             height: auto;
//             visibility: visible;
//         }
//         .utmb-tabcordion--entry {
//             min-height: 0;

//             &::before {
//                 display: none;
//             }

//             &.is-active {
//                 .utmb-tabcordion--entry-content {
//                     opacity: 1;
//                     transition: opacity 400ms ease-in-out;
//                 }
//             }

//             .utmb-tabcordion--entry-content {
//                 opacity: 0;
//                 transition: opacity 400ms ease-in-out;
//             }
//         }
//     }

//     // tabs
//     .utmb-tabcordion--tabs {
//         opacity: 0;
//         height: 0;
//         visibility: hidden;
//         display: flex;
//         flex-flow: wrap;
//         margin: 0;
//         list-style: none;
//         padding: 0;
//         position: relative;
//         z-index: 1;

//         .tab {
//             border: 1px solid #e5e5e5;
//             padding: 10px 13px 7px;
//             line-height: 1.5;
//             text-transform: none;
//             font-size: 1em;
//             background-color: #457a82;
//             color: #fff;
//             padding: 15px;
//             margin-right: 5px;
//             outline: none;

//             &:hover,
//             &:focus {
//                 background: #444;
//             }

//             &.is-active {
//                 border-bottom: 1px solid #fff !important;
//                 background-color: #fff !important;
//                 background: #fff !important;
//                 color: #da1f12;
//                 font-weight: bold;

//                 &:hover,
//                 &:focus {
//                     background-color: #fff !important;
//                     background: #fff !important;
//                 }
//             }
//         }
//     }

//     // section
//     .utmb-tabcordion--entry {
//         overflow: hidden;
//         margin-top: -1px;
//         outline: none !important;
//         //border: 3px double #da1f12 !important;
//         //border: 1px solid #eeeeee !important;
//         border: 0 !important;
//         overflow: hidden;

//         &::before {
//             position: relative;
//             content: attr(data-title);
//             cursor: pointer;
//             z-index: 1;
//             background: #444;
//             color: #fff;
//             width: 100%;
//             display: block;
//         }

//         &:hover,
//         &:focus {
//             outline: none !important;

//             &::before,
//             &::before {
//                 background: #444;
//                 outline: none !important;
//             }
//         }

//         &.is-active {
//             .utmb-tabcordion--entry-content {
//                 margin-top: 0;
//                 height: auto;
//                 opacity: 1;
//                 transition: margin 400ms ease-out -100ms;
//                 outline: none !important;
//             }

//             &::before {
//                 background: #fff;
//                 color: $red;
//             }

//             &:hover::before,
//             &:focus::before {
//                 background: #fff;
//                 color: $red;
//             }
//         }

//         // entry container
//         .utmb-tabcordion--entry-container {
//             overflow: hidden;
//             margin-bottom: 1px;
//             outline: none !important;

//             // entry content
//             .utmb-tabcordion--entry-content {
//                 position: relative;
//                 margin-top: -100%;
//                 height: 0;
//                 opacity: 0;
//                 transition: margin 500ms ease-in;
//                 padding: 1.5rem 1rem;

//                 &::before {
//                     content: "";
//                     position: absolute;
//                     top: 0;
//                     left: 0;
//                     right: 0;
//                     border-top: 1px solid #e5e5e5;
//                 }
//             }
//         }
//     }

//     // // Mobile / Accordion Mode
//     // &.utmb-tabcordion.accordion-mode {

//     // }


// }


// .utmb-tabcordion.has-tabs .utmb-tabcordion--tabs {
//     opacity: 1;
//     height: auto;
//     visibility: visible;
// }

// .utmb-tabcordion.has-tabs .utmb-tabcordion--entry {
//     min-height: 0;
// }

    // .utmb-tabcordion.has-tabs .utmb-tabcordion--entry::before {
    //     display: none;
    // }

    // .utmb-tabcordion.has-tabs .utmb-tabcordion--entry.is-active .utmb-tabcordion--entry-content {
    //     opacity: 1;
    //     transition: opacity 400ms ease-in-out;
    // }

    // .utmb-tabcordion.has-tabs .utmb-tabcordion--entry .utmb-tabcordion--entry-content {
    //     opacity: 0;
    //     transition: opacity 400ms ease-in-out;
    // }


// .utmb-tabcordion--tabs {
//     opacity: 0;
//     height: 0;
//     visibility: hidden;
//     display: flex;
//     flex-flow: wrap;
//     margin: 0;
//     list-style: none;
//     padding: 0;
//     position: relative;
//     z-index: 1;
// }

    // .utmb-tabcordion--tabs .tab {
    //     border: 1px solid #e5e5e5;
    //     padding: 10px 13px 7px;
    //     line-height: 1.5;
    //     text-transform: none;
    //     font-size: 1em;
    //     background-color: #457a82;
    //     color: #fff;
    //     padding: 15px;
    //     margin-right: 5px;
    //     outline: none;
    // }

        // .utmb-tabcordion--tabs .tab:hover,
        // .utmb-tabcordion--tabs .tab:focus {
        //     background: #444;
        // }

        // .utmb-tabcordion--tabs .tab.is-active {
        //     border-bottom: 1px solid #fff !important;
        //     background-color: #fff !important;
        //     background: #fff !important;
        //     color: #da1f12;
        //     font-weight: bold;
        // }

            // .utmb-tabcordion--tabs .tab.is-active:hover,
            // .utmb-tabcordion--tabs .tab.is-active:focus {
            //     background-color: #fff !important;
            //     background: #fff !important;
            // }

// .utmb-tabcordion--entry {
//     overflow: hidden;
//     margin-top: -1px;
//     outline: none !important;
//     /*border: 3px double #da1f12 !important;*/
//     /*border: 1px solid #eeeeee !important;*/
//     border: 0 !important;
// }

    // .utmb-tabcordion--entry::before {
    //     position: relative;
    //     content: attr(data-title);
    //     cursor: pointer;
    //     z-index: 1;
    //     background: #444;
    //     color: #fff;
    //     width: 100%;
    //     display: block;
    // }

    // .utmb-tabcordion--entry:hover,
    // .utmb-tabcordion--entry:focus {
    //     outline: none !important;
    // }

        // .utmb-tabcordion--entry:hover::before,
        // .utmb-tabcordion--entry:focus::before {
        //     background: #444;
        //     outline: none !important;
        // }

// .utmb-tabcordion--entry {
//     overflow: hidden;
// }

    // .utmb-tabcordion--entry.is-active .utmb-tabcordion--entry-content {
    //     margin-top: 0;
    //     height: auto;
    //     opacity: 1;
    //     transition: margin 400ms ease-out -100ms;
    //     outline: none !important;
    // }

    // .utmb-tabcordion--entry.is-active::before {
    //     background: #fff;
    //     color: red;
    // }

    // .utmb-tabcordion--entry.is-active:hover::before,
    // .utmb-tabcordion--entry.is-active:focus::before {
    //     background: #fff;
    //     color: red;
    // }

// .utmb-tabcordion--entry-container {
//     overflow: hidden;
//     margin-bottom: 1px;
//     outline: none !important;
// }

// .utmb-tabcordion--entry-content {
//     position: relative;
//     margin-top: -100%;
//     height: 0;
//     opacity: 0;
//     transition: margin 500ms ease-in;
//     padding: 1.5rem 1rem;
// }

    // .utmb-tabcordion--entry-content::before {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     border-top: 1px solid #e5e5e5;
    // }


