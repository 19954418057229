//
// UTMB App Theme: "UTMB Light" for Classic Design Mode
// body.utmb-light
//

// Combining utmb-light and utmb-dark
// Coverting utmb-dark into

/* Mobile- First:
    - body
    - wrapper form
    - header
    - maincontent (was #content)
    - primarycontent (was #main) 
    - footer
    - back to top (mobile)
    - Tablet and Mobile Only
    - Tablet view and up
    - Desktop
*/

// body

body.design-mode-classic {

    &.utmb-light,
    &.utmb-dark {
        background: $utmb-light-body-bg;

        // wrapper form
        form#appForm {
            background: $utmb-light-content-bg;
        }

        // page wrapper
        /*#page {
            // add bottom margin for artboard dropshadow
            margin-bottom: 5px;
        }*/

        // header
        #header {
            background: $utmb-light-header-bg;
            border-bottom: $utmb-light-header-border;

            // hgroup
            .hgroup,
            hgroup {
                min-width: auto;

                h2 {
                    color: $utmb-light-header-h2-color;

                    .accent {
                        color: #111;
                    }
                }

                h3,
                p {
                    color: $utmb-light-header-h3-color;
                }
            }
        }

        &.exp-mega #header,
        &.exp-mega-full #header,
        &.exp-dropdown #header,
        &.nav-main #header {
            border-bottom: 0;
        }

        // maincontent (was #content)
        #maincontent {
            background: $utmb-light-content-bg;
        }

        // primarycontent (was #main)
        // use artboard - ie: white background on #main
        #primarycontent {
            background: #fff;
            min-height: 400px;
            padding-bottom: 50px; // the space between breadcrumbs and artboard
            margin-bottom: 10px; //15px;
            margin-top: 10px; //15px;
        }

        // Footer (now that modern footer is activated)
        /*#footer {
            font-size: .8em;
        }*/
        /*// Tablet view and up
        @media only screen and (min-width: $utmb-small) {

        }*/

        // Desktop
        @media only screen and (min-width: $utmb-sweetspot) {
            &.exp-mega {
                #explore {
                    background-color: $white;
                }
            }
        }

        // Overrides
        // ===========================================================
        // DO NOT use artboard - ie: artboard is the white background on #main
        &.no-artboard #primarycontent,
        &.no-artboard #primarycontent {
            background: transparent !important;
            background-color: transparent !important;
            // rounded corners and drop shadow
            border: 0 !important;
            -webkit-border-radius: 0px !important;
            -moz-border-radius: 0px !important;
            border-radius: 0px !important;
            -moz-box-shadow: none !important;
            -webkit-box-shadow: none !important;
            box-shadow: none;
        }

    } // end/ body.utmb-light

}



//
// OLD IE -- UTMB Content Region
// --------------------------------------------------

// !IMPORTANT -- Had to moved old-ie theme handling to /scss/utmb/base/content