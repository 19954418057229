// Mobile Accordion to Desktop Tabs
// see: https://www.gsarigiannidis.gr/tabs-on-desktop-accordion-on-mobile/
// see: Example HTML usage below CSS

// @use 'sass:math';

/*p.warning {
    text-align: center;
    line-height: 2;
    margin-bottom: 2rem;
    a {
      background: #3bacff;
      color: #fff;
      padding: .2rem .7rem;
      border-radius: 3px;
      white-space: nowrap;
      text-decoration: none;
      &.more {
        background: #45ba04;
      }
    }
  }*/
  
  $atc_color__tab: #444;
  $atc_color__tab-active: #3bacff;
  //$atc_background__main: #dce2e2;
  $atc_color__light: #fff;
  $atc_width__max: 680px;
  $atc_padding: 1rem;
  $atc_border__radius: 3px;
  $atc_lighten__level: 6;
  $atc_darken__level: 6;
  $atc_font__family: 'Roboto', sans-serif;
  $atc_font__weight: 300;
  $atc_font__size: 16px;

  @mixin tab-active() {
    background:$atc_color__tab-active;
    color: $atc_color__light;
  }
  
  


.utmb-tabcordion.has-tabs .utmb-tabcordion--tabs {
    opacity: 1;
    height: auto;
    visibility: visible;
}

.utmb-tabcordion.has-tabs .utmb-tabcordion--entry {
    min-height: 0;
}

    .utmb-tabcordion.has-tabs .utmb-tabcordion--entry::before {
        display: none;
    }

    .utmb-tabcordion.has-tabs .utmb-tabcordion--entry.is-active .utmb-tabcordion--entry-content {
        opacity: 1;
        transition: opacity 400ms ease-in-out;
    }

    .utmb-tabcordion.has-tabs .utmb-tabcordion--entry .utmb-tabcordion--entry-content {
        opacity: 0;
        transition: opacity 400ms ease-in-out;
    }


.utmb-tabcordion--tabs {
    opacity: 0;
    height: 0;
    visibility: hidden;
    display: flex;
    flex-flow: wrap;
    margin: 0;
    list-style: none;
    padding: 0;
    position: relative;
    z-index: 1;
}

    .utmb-tabcordion--tabs .tab {
        border: 1px solid #e5e5e5;
        padding: 10px 13px 7px;
        line-height: 1.5;
        text-transform: none;
        font-size: 1em;
        background-color: $gray;
        color: #fff;
        padding: 15px;
        margin-right: 5px;
        outline: none;
    }

        .utmb-tabcordion--tabs .tab:hover,
        .utmb-tabcordion--tabs .tab:focus {
            background: $gray-1;
        }

        .utmb-tabcordion--tabs .tab.is-active {
            border-bottom: 1px solid #fff !important;
            background-color: #fff !important;
            background: #fff !important;
            color: $gray;
            font-weight: bold;
        }

            .utmb-tabcordion--tabs .tab.is-active:hover,
            .utmb-tabcordion--tabs .tab.is-active:focus {
                background-color: #fff !important;
                background: #fff !important;
            }

.utmb-tabcordion--entry {
    overflow: hidden;
    margin-top: -1px;
    outline: none !important;
    /*border: 3px double #da1f12 !important;*/
    /*border: 1px solid #eeeeee !important;*/
    /*border: 0 !important;*/
}

.utmb-tabcordion.accordion-mode .utmb-tabcordion--entry {
    overflow: hidden;
    margin-top: -1px;
    outline: none !important;
    /*border: 3px double #da1f12 !important;*/
    border: 1px solid #eeeeee !important;
    /*border: 0 !important;*/
}

    .utmb-tabcordion--entry::before {
        position: relative;
        content: attr(data-title);
        cursor: pointer;
        z-index: 1;
        /*background: #444;
        color: #fff;*/
        width: 100%;
        display: block;
    }

    /*.utmb-tabcordion--entry:hover,
    .utmb-tabcordion--entry:focus {
        outline: none !important;
    }*/

        /*.utmb-tabcordion--entry:hover::before,
        .utmb-tabcordion--entry:focus::before {
            background: #444;
            outline: none !important;
        }*/

.utmb-tabcordion.accordion-mode .utmb-tabcordion--entry {
    position: relative;
}

    .utmb-tabcordion.accordion-mode .utmb-tabcordion--entry::before {
        background: #fff;
        color: #444;
        margin-bottom: 2px;
        margin-top: 3px;
        margin: 1px;
        /*padding-bottom: 0px;*/
        padding-left: 45px;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(229, 229, 229);
        border-image: initial;
        border: 0;
        height: 44px;
        line-height: 44px;
        /* icon */
        background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23444444%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23444444%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E);
        background-repeat: no-repeat;
        background-position-x: 24px;
        background-position-y: 15px;
    }

    .utmb-tabcordion.accordion-mode .utmb-tabcordion--entry.is-active::before,
    .utmb-tabcordion.accordion-mode .utmb-tabcordion--entry.is-active:active::before,
    .utmb-tabcordion.accordion-mode .utmb-tabcordion--entry.is-active:hover::before,
    .utmb-tabcordion.accordion-mode .utmb-tabcordion--entry.is-active:focus::before {
        background: #fff;
        color: #da1f12;
        margin-bottom: 2px;
        margin-top: 3px;
        margin: 1px;
        /*padding-bottom: 0px;*/
        padding-left: 45px;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(229, 229, 229);
        border-image: initial;
        border: 0;
        height: 44px;
        line-height: 44px;
        /* icon */
        background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23444444%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E);
        background-repeat: no-repeat;
        background-position-x: 24px;
        background-position-y: 15px;
    }

.utmb-tabcordion--entry-container {
    overflow: hidden;
    margin-bottom: 1px;
    outline: none !important;
}




.utmb-tabcordion--entry {
    overflow: hidden;
}

    .utmb-tabcordion--entry.is-active .utmb-tabcordion--entry-content {
        margin-top: 0;
        height: auto;
        opacity: 1;
        transition: margin 400ms ease-out -100ms;
        outline: none !important;
    }

    /*.utmb-tabcordion--entry.is-active::before {
        background: #fff;
        color: red;
    }

    .utmb-tabcordion--entry.is-active:hover::before,
    .utmb-tabcordion--entry.is-active:focus::before {
        background: #fff;
        color: red;
    }*/

.utmb-tabcordion--entry-container {
    overflow: hidden;
    margin-bottom: 1px;
    outline: none !important;
}

.utmb-tabcordion--entry-content {
    position: relative;
    margin-top: -100%;
    height: 0;
    opacity: 0;
    transition: margin 500ms ease-in;
    padding: 1.5rem 1rem;
}

    .utmb-tabcordion--entry-content::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-top: 1px solid #e5e5e5;
    }




// end/ UTMB



  // Example 1 with A11Y enhancements
/*
<p class="warning">
  A tabbed component that turns into an accordion when tabs run out of space, using CSS and vanilla JavaScript. <a class="more" href="https://www.gsarigiannidis.gr/tabs-on-desktop-accordion-on-mobile/" target="_blank">Read more</a>
</p>
<h2>Bigger tabs</h2>
<article class="ootb-tabcordion">
  <div class="ootb-tabcordion--tabs" role="tablist" aria-label="TabCordion">
    <button class="tab is-active" role="tab" aria-selected="true" aria-controls="tab1-tab" id="tab1">Tab 1 with big text</button>
    <button class="tab" role="tab" aria-selected="false" aria-controls="tab2-tab" id="tab2" tabindex="-1">Tab 2 with big text</button>
    <button class="tab" role="tab" aria-selected="false" aria-controls="tab3-tab" id="tab3" tabindex="-1">Tab 3 with big text</button>
  </div>
  <section id="tab1-tab" class="ootb-tabcordion--entry is-active" data-title="Tab 1 with big text" tabindex="0" role="tabpanel" aria-labelledby="tab1">
    <div class="ootb-tabcordion--entry-container">
      <div class="ootb-tabcordion--entry-content">
        <p>A simple solution for having tabbed content on a large screen but turn it into accordion on a smaller
          one when the time is right. Just resize your screen to see the tabbed interface transforming into an
          accordion.</p>
        <p>In order to keep the HTML as clean as possible, without duplicating elements, we use element
          attributes as CSS content to display the accordion tabs. <a class="more" href="https://www.gsarigiannidis.gr/tabs-on-desktop-accordion-on-mobile/" target="_blank">Read more</a></p>
      </div>
    </div>
  </section>
  <section id="tab2-tab" class="ootb-tabcordion--entry" data-title="Tab 2 with big text" tabindex="-1" role="tabpanel" aria-labelledby="tab2">
    <div class="ootb-tabcordion--entry-container">
      <div class="ootb-tabcordion--entry-content">
        <p>Tab 2 content. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat
        mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit,
        posuere a, pede. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit,
        posuere a, pede.</p>
      </div>
    </div>
  </section>
  <section id="tab3-tab" class="ootb-tabcordion--entry" data-title="Tab 3 with big text" tabindex="-1" role="tabpanel" aria-labelledby="tab3">
    <div class="ootb-tabcordion--entry-container">
      <div class="ootb-tabcordion--entry-content">
        <p>Tab 3 content. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat
        mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit,
        posuere a, pede. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat
        mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit,
        posuere a, pede. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat
        mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit,
        posuere a, pede.</p>
      </div>
    </div>
  </section>
</article>
*/

// Example 2 with A11Y enhancements
/*
  <article class="ootb-tabcordion">
  <div class="ootb-tabcordion--tabs" role="tablist" aria-label="Demo">
    <button class="tab is-active" role="tab" aria-selected="true" aria-controls="tab1-tab" id="tab1">Tab 1</button>
    <button class="tab" role="tab" aria-selected="false" aria-controls="tab2-tab" id="tab2" tabindex="-1">Tab 2</button>
  </div>
  <section id="tab1-tab" class="ootb-tabcordion--entry is-active" data-title="Tab 1" tabindex="0" role="tabpanel" aria-labelledby="tab1">
    <div class="ootb-tabcordion--entry-container">
      <div class="ootb-tabcordion--entry-content">
        <p>Tab 1 content</p>
      </div>
    </div>
  </section>
  <section id="tab2-tab" class="ootb-tabcordion--entry" data-title="Tab 2" tabindex="-1" role="tabpanel" aria-labelledby="tab2">
    <div class="ootb-tabcordion--entry-container">
      <div class="ootb-tabcordion--entry-content">
        <p>Tab 2 content.</p>
      </div>
    </div>
  </section>
</article>
*/