﻿//
// Wells: UTMB Branding Color Palette
// --------------------------------------------------


// UTMB Panel Mixin

//@mixin utmb-panels($panel-font-color, $panel-bg-color, $panel-border-color) {
//    //background-color: $panel-bg-color;
//    //border: 1px solid $panel-border-color;
//    //color: $panel-font-color;
//
//    margin-bottom: $line-height-computed;
//    background-color: $panel-bg;
//    border: 1px solid transparent;
//    border-radius: $panel-border-radius;
//    @include box-shadow(0 1px 1px rgba(0,0,0,.05));
//
//    p, h1, h2, h3, h4, h5 {
//        //color: $panel-font-color;
//    }
//
//    // Optional heading
//    .panel-heading {
//      padding: $panel-heading-padding;
//      border-bottom: 1px solid transparent;
//      @include border-top-radius(($panel-border-radius - 1));
//
//      > .dropdown .dropdown-toggle {
//        color: inherit;
//      }
//    }
//}



// Panels

.panel {
    border-radius: 0;
    border: 1px solid $gray-d;

    // Heading
    .panel-heading {
        border-radius: 0;
        background-color: $white;
        color: $gray;
        padding: 10px 15px;
        border-bottom: 1px solid $gray-d;

        // Title
        .panel-title {
            text-transform: none;
            margin: 0;
            padding: 0;

            > a,
            > small,
            > .small,
            > small > a,
            > .small > a {
                color: inherit;
                text-decoration: none;
            }
        }

        > .dropdown .dropdown-toggle {
            color: inherit;
        }
    }

    // Panel contents
    .panel-body {
        border-radius: 0;
        background-color: $white;
        color: $gray;
        padding: 15px;
    }

    // Footer
    .panel-footer {
        border-radius: 0;
        color: $gray;
        padding: 10px 15px;
        background-color: $white;
        border-top: 1px solid $gray-d;
    }

    // Hide Header
    &.title-hide {
        .panel-heading {
            @extend .sr-only;
        }
    }

    // Hide Footer
    &.footer-hide {
        .panel-footer {
            @extend .sr-only;
        }
    }

    // callout
    &.callout {
        border: 1px solid $gray-2;

        .panel-heading {
            background-color: $gray-2;
            border-bottom: 1px solid $gray-2;
            color: $white;
            
            .panel-title {
                color: $white;
            }
        }

        .panel-footer {
            background-color: $gray-2;
            border-top: 1px solid $gray-2;
            color: $white;
        }
    }


    // UTMB Colors - Contextual variations

    // Red
    &.panel-red {
        border: 1px solid $red;

        .panel-heading {
            background-color: $white;
            border-bottom: 1px solid $red;
            color: $red;
            
            .panel-title {
                color: $red;
            }
        }

        .panel-footer {
            background-color: $white;
            border-top: 1px solid $red;
            color: $red;
        }

        &.callout {
            border: 1px solid $red;

            .panel-heading {
                background-color: $red;
                border-bottom: 1px solid $red;
                color: $white;
                
                .panel-title {
                    color: $white;
                }
            }

            .panel-footer {
                background-color: $red;
                border-top: 1px solid $red;
                color: $white;
            }
        }
    }

    // Gray
    &.panel-gray {
        border: 1px solid $gray;

        .panel-heading {
            background-color: $white;
            border-bottom: 1px solid $gray;
            color: $gray;
            
            .panel-title {
                color: $gray;
            }
        }

        .panel-footer {
            background-color: $white;
            border-top: 1px solid $gray;
            color: $gray;
        }

        &.callout {
            border: 1px solid $gray;

            .panel-heading {
                background-color: $gray;
                border-bottom: 1px solid $gray;
                color: $white;
                
                .panel-title {
                    color: $white;
                }
            }

            .panel-footer {
                background-color: $gray;
                border-top: 1px solid $gray;
                color: $white;
            }
        }
    }

    // Blue
    &.panel-blue {
        border: 1px solid $blue;

        .panel-heading {
            background-color: $white;
            border-bottom: 1px solid $blue;
            color: $blue;
            
            .panel-title {
                color: $blue;
            }
        }

        .panel-footer {
            background-color: $white;
            border-top: 1px solid $blue;
            color: $blue;
        }

        &.callout {
            border: 1px solid $blue;

            .panel-heading {
                background-color: $blue;
                border-bottom: 1px solid $blue;
                color: $white;
                
                .panel-title {
                    color: $white;
                }
            }

            .panel-footer {
                background-color: $blue;
                border-top: 1px solid $blue;
                color: $white;
            }
        }
    }

    // Orange
    &.panel-orange {
        border: 1px solid $orange;

        .panel-heading {
            background-color: $white;
            border-bottom: 1px solid $orange;
            color: $blue;
            
            .panel-title {
                color: $orange;
            }
        }

        .panel-footer {
            background-color: $white;
            border-top: 1px solid $orange;
            color: $orange;
        }

        &.callout {
            border: 1px solid $orange;

            .panel-heading {
                background-color: $orange;
                border-bottom: 1px solid $orange;
                color: $white;
                
                .panel-title {
                    color: $white;
                }
            }

            .panel-footer {
                background-color: $orange;
                border-top: 1px solid $orange;
                color: $white;
            }
        }
    }

    // Green
    &.panel-green {
        border: 1px solid $green;

        .panel-heading {
            background-color: $white;
            border-bottom: 1px solid $green;
            color: $blue;
            
            .panel-title {
                color: $green;
            }
        }

        .panel-footer {
            background-color: $white;
            border-top: 1px solid $green;
            color: $green;
        }

        &.callout {
            border: 1px solid $green;

            .panel-heading {
                background-color: $green;
                border-bottom: 1px solid $green;
                color: $white;
                
                .panel-title {
                    color: $white;
                }
            }

            .panel-footer {
                background-color: $green;
                border-top: 1px solid $green;
                color: $white;
            }
        }
    }

    // Teal
    &.panel-teal {
        border: 1px solid $teal;

        .panel-heading {
            background-color: $white;
            border-bottom: 1px solid $teal;
            color: $teal;
            
            .panel-title {
                color: $teal;
            }
        }

        .panel-footer {
            background-color: $white;
            border-top: 1px solid $teal;
            color: $teal;
        }

        &.callout {
            border: 1px solid $teal;

            .panel-heading {
                background-color: $teal;
                border-bottom: 1px solid $teal;
                color: $white;
                
                .panel-title {
                    color: $white;
                }
            }

            .panel-footer {
                background-color: $teal;
                border-top: 1px solid $teal;
                color: $white;
            }
        }
    }


    // Bootstrap Utility Colors

    // Default
    &.panel-default {
        border-radius: 0;
        border: 1px solid $gray-d;
        color: $gray;

        .panel-heading {
            background-color: $gray-e;
            border-bottom: 1px solid $gray-d;
            color: $gray;
            
            .panel-title {
                color: $gray;
            }
        }

        .panel-footer {
            background-color: $gray-e;
            border-top: 1px solid $gray-d;
            color: $gray;
        }
    }

    // Primary
    &.panel-primary {
        border-radius: 0;
        border: 1px solid $red;

        .panel-heading {
            background-color: $red;
            border-bottom: 1px solid $red;
            color: $white;
            
            .panel-title {
                color: $white;
            }
        }

        .panel-footer {
            background-color: $red;
            border-top: 1px solid $red;
            color: $white;
        }
    }

    // Secondary
    &.panel-secondary {
        border-radius: 0;
        border: 1px solid $gray;

        .panel-heading {
            background-color: $gray;
            border-bottom: 1px solid $gray;
            color: $white;
            
            .panel-title {
                color: $white;
            }
        }

        .panel-footer {
            background-color: $gray;
            border-top: 1px solid $gray;
            color: $white;
        }
    }

    // Info
    &.panel-info {
        border-radius: 0;
        border: 1px solid $blue;

        .panel-heading {
            background-color: $blue;
            border-bottom: 1px solid $blue;
            color: $white;
            
            .panel-title {
                color: $white;
            }
        }

        .panel-footer {
            background-color: $blue;
            border-top: 1px solid $blue;
            color: $white;
        }
    }

    // Danger
    &.panel-danger {
        border-radius: 0;
        border: 1px solid $red;

        .panel-heading {
            background-color: $red;
            border-bottom: 1px solid $red;
            color: $white;
            
            .panel-title {
                color: $white;
            }
        }

        .panel-footer {
            background-color: $red;
            border-top: 1px solid $red;
            color: $white;
        }
    }

    // Warning
    &.panel-warning {
        border-radius: 0;
        border: 1px solid $orange;

        .panel-heading {
            background-color: $orange;
            border-bottom: 1px solid $orange;
            color: $white;
            
            .panel-title {
                color: $white;
            }
        }

        .panel-footer {
            background-color: $orange;
            border-top: 1px solid $orange;
            color: $white;
        }
    }

    // Success
    &.panel-success {
        border-radius: 0;
        border: 1px solid $green;

        .panel-heading {
            background-color: $green;
            border-bottom: 1px solid $green;
            color: $white;
            
            .panel-title {
                color: $white;
            }
        }

        .panel-footer {
            background-color: $green;
            border-top: 1px solid $green;
            color: $white;
        }
    }


}
