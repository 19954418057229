//
// Content Layout - by Design Mode
//

// Classic Mode
@mixin classic-content-desktop-view() {
    
    // Mobile Menu Fix - @mgcooper 4/25/2014
    #maincontent {
        position: relative;
    }
    
    // #article 
    #article {
        position: relative;
        display: block;
        width: 100%;
        left: 0;
        right: auto;
        top: 0;

        .padding,
        #bodycontent {
            padding: 25px 30px 15px 30px;
        }
    }
    
    // #aside (hide by default)
    #aside {
        display: none;   
    }
    
    // #aside (show)
    body.aside {
        // #article adjustments
        #article {
            position: relative;
            display: block;
            float: left;
            width: 72%;
            left: 0;
            right: auto;
            top: 0;

            .padding,
            #bodycontent {
                padding: 25px 30px 15px 30px;
            }
        }
        // #aside layout
        #aside {
            position: relative;
            display: block;
            float: left;
            width: 28%;
            left: 0;
            right: auto;
            top: 0;

            .padding,
            #asidecontent {
                padding: 25px 15px 15px 15px;
            }
        }
    }
}