// Nav Sidebar -- ie .nav-vertical > .uk-nav-primary
// Overlay Design for UIKit Vertical Nav w/ Sidebar Styling
// *See ./src/assets/scss/vendor/uikit/_utmb-uikit-variables.scss


// Desktop Design 
// Extends the uk-nav-primary styles
@media only screen and (min-width: $utmb-sweetspot) {
    .uk-nav-primary {
        color: $gray;
    }

        .uk-nav-primary li a {
            border-top: 1px solid #e5e5e5;
            padding-left: 6px;
            padding-left: 6px;
            line-height: 1.5;
            //font-size: 1.25rem;
            //transition: font-size .5s;
        }
        .uk-nav-primary li a:hover {
            //font-size: 1.333rem;
            line-height: 1.5;
            color: #FFFFFF !important;
            background-color: $red;

            .caret {
                color: #FFFFFF;
            }
        }

        .uk-nav-primary > li:first-child > a {
            border-top: 0;
        }

    .uk-nav-primary > li > a {
        font-size: 1.25rem !important;
        line-height: 1.5;
        color: $gray;
        transition: font-size .5s;

        &:hover {
            font-size: 1.4rem !important;
        }
    }

        .uk-nav-primary .uk-nav-sub a {
            color: $gray;
            font-size: 1rem;
            line-height: 1.5;
        }
        .uk-nav-primary .uk-nav-sub a:hover {
            font-size: 1rem;
        }

    .uk-nav-primary .uk-nav-sub li.uk-active > a {
        color: $red;
        text-decoration: underline;
    }
        .uk-nav-primary .uk-nav-sub li > a:hover {
            color: #FFFFFF;
        }

        .uk-nav-primary .uk-nav-sub li.uk-active > a:hover {
            color: #FFFFFF;
        }

    .uk-nav-primary .uk-nav-header, 
    .uk-nav-primary > li.uk-active > a {
        color: $red;
        text-decoration: underline;
    }
        .uk-nav-primary .uk-nav-header:hover,
        .uk-nav-primary > li.uk-active > a:hover {
            color: #FFFFFF;
        }

    .uk-nav-parent-icon > .uk-parent > a {
        position: relative;
    }

    // caret
    .uk-nav-parent-icon > .uk-parent > a:after {
        width: 1.5em;
        height: 1.5em;
        position: absolute;
        top: 6px;
        right: 0;
        margin-left: 5px;
    }
    .uk-nav-parent-icon > .uk-parent > a:hover:after {
        color: $white;
    }


    //&.nav-mini {
    .nav-mini {
        .uk-nav-primary > li > a {
            font-size: 1rem !important;
            line-height: 1.5;
            color: $gray;
            transition: font-size .5s;
    
            &:hover {
                font-size: 1.111rem !important;
            }
        }
    }
}