﻿// UTMB Hero Box
// For #atopcontent region
// ------------------------------------------------



// Classic Design Mode Defaults
body.design-mode-classic {
    .hero-box,
    .row.hero-box {
        .hero-container,
        .container.hero-container {
            //max-width: $page-max-width;
            max-width: $container-classic;
        }
    }
}
// Modern Design Mode Defaults
body.design-mode-modern {
    .hero-box,
    .row.hero-box {
        .hero-container,
        .container.hero-container {
            //max-width: $page-max-width;
            max-width: $container-modern;
        }
    }
}




.hero-box,
.row.hero-box {
    //background-color: #000;
    //width: 100%;
    //max-width: 100%;
    //padding: 0;
    //margin: 0;
    .hero-container,
    .container.hero-container {
        //max-width: $page-max-width;
        margin: 0 auto;
        position: relative;
        @include clearfix;

        .hero,
        .sf_colsIn.col-md-12.hero {
            padding: 0;
            margin: 0;
            width: 100%;
            max-width: 100%;
        }
    }
    // More Width
    &.more-width {
        .hero-container,
        .container.hero-container {
            max-width: 1870px;
        }
    }
    // Full Width Feature
    &.full-width {
        .hero-container,
        .container.hero-container {
            max-width: 100%;
            margin: 0 auto;
            position: relative;
            //@include clearfix;
            // .hero,
            // .sf_colsIn.col-md-12.hero {
            // }
        }
    }
}
