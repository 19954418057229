// Max-Width

.max-width-50 {
    max-width: 100px;
}
.max-width-100 {
    max-width: 100px;
}
.max-width-150 {
    max-width: 150px;
}
.max-width-200 {
    max-width: 200px;
}
.max-width-250 {
    max-width: 250px;
}
.max-width-300 {
    max-width: 300px;
}
.max-width-350 {
    max-width: 350px;
}
.max-width-400 {
    max-width: 400px;
}
.max-width-450 {
    max-width: 450px;
}
.max-width-500 {
    max-width: 500px;
}
@media only screen and (min-width: $utmb-xsmall) {
    .max-width-50-xsmall {
        max-width: 100px;
    }
    .max-width-100-xsmall {
        max-width: 100px;
    }
    .max-width-150-xsmall {
        max-width: 150px;
    }
    .max-width-200-xsmall {
        max-width: 200px;
    }
    .max-width-250-xsmall {
        max-width: 250px;
    }
    .max-width-300-xsmall {
        max-width: 300px;
    }
    .max-width-350-xsmall {
        max-width: 350px;
    }
    .max-width-400-xsmall {
        max-width: 400px;
    }
    .max-width-450-xsmall {
        max-width: 450px;
    }
    .max-width-500-xsmall {
        max-width: 500px;
    }
}
@media only screen and (min-width: $utmb-small) {
    .max-width-100-small {
        max-width: 100px;
    }
    .max-width-50-small {
        max-width: 100px;
    }
    .max-width-150-small {
        max-width: 150px;
    }
    .max-width-200-small {
        max-width: 200px;
    }
    .max-width-250-small {
        max-width: 250px;
    }
    .max-width-300-small {
        max-width: 300px;
    }
    .max-width-350-small {
        max-width: 350px;
    }
    .max-width-400-small {
        max-width: 400px;
    }
    .max-width-450-small {
        max-width: 450px;
    }
    .max-width-500-small {
        max-width: 500px;
    }
}
@media only screen and (min-width: $utmb-sweetspot) {
    .max-width-50-desktop {
        max-width: 100px;
    }
    .max-width-100-desktop {
        max-width: 100px;
    }
    .max-width-150-desktop {
        max-width: 150px;
    }
    .max-width-200-desktop {
        max-width: 200px;
    }
    .max-width-250-desktop {
        max-width: 250px;
    }
    .max-width-300-desktop {
        max-width: 300px;
    }
    .max-width-350-desktop {
        max-width: 350px;
    }
    .max-width-400-desktop {
        max-width: 400px;
    }
    .max-width-450-desktop {
        max-width: 450px;
    }
    .max-width-500-desktop {
        max-width: 500px;
    }
}
@media only screen and (min-width: $utmb-large) {
    .max-width-50-large {
        max-width: 100px;
    }
    .max-width-100-large {
        max-width: 100px;
    }
    .max-width-150-large {
        max-width: 150px;
    }
    .max-width-200-large {
        max-width: 200px;
    }
    .max-width-250-large {
        max-width: 250px;
    }
    .max-width-300-large {
        max-width: 300px;
    }
    .max-width-350-large {
        max-width: 350px;
    }
    .max-width-400-large {
        max-width: 400px;
    }
    .max-width-450-large {
        max-width: 450px;
    }
    .max-width-500-large {
        max-width: 500px;
    }
}