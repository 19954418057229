﻿// Content Layout Grids
// SF GridSystem Widgets
//


// Container Basic (100% width)
.container-basic {
    @include clearfix;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
    @media only screen and (max-width: 1400px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

// Container Body (body content area max-width)
// *Classic Design Mode
body.design-mode-classic {
    .container-body {
        @include clearfix;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        max-width: $container-classic; //1100px as of v4.0
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 0;
        padding-right: 0;
        @media only screen and (max-width: 1400px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}
// *Modern Design Mode
body.design-mode-modern {
    .container-body {
        @include clearfix;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        max-width: $container-modern; //1400px as of v4.0
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 0;
        padding-right: 0;
        @media only screen and (max-width: 1400px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

// Container Reading (800px max-width)
.container-reading-area {
    @include clearfix;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
    @media only screen and (max-width: 1400px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}


// Content Accordion
.content-accordion {
    a {
        text-decoration: none;
        -o-transition: .5s;
        -ms-transition: .5s;
        -moz-transition: .5s;
        -webkit-transition: .5s;
        /* ...and now for the proper property */
        transition: .5s;

        &:hover, 
        &:active, 
        &:focus {
            color: $red;
        }
    }
}

// Content Tabs
.content-tabs {
    ul.tabs.uk-tab {
        border-bottom: 0; // override bootstrap bottom border (just in case)
        margin: 0;
        
        li {
            padding-left: 0px;
            padding-right: 5px;

            a {
                border: 1px solid #e5e5e5;
                //border-radius: 4px 4px 0 0;
                padding: 10px 13px 7px;
                text-decoration: none;
                text-transform: none;
                font-size: 1em;
                background-color: $teal;
                color: #fff;
                padding: 15px;

                &:hover,
                &:active {
                    background: $gray;
                }

                &.active {
                    border-bottom: 1px solid #ffffff;
                    background-color: #fff;
                    color: $red;
                    font-weight: bold;
                }
            }

            &.active, 
            &.uk-active {
                a {
                    border-bottom-color: #ffffff !important;
                    background-color: #fff !important;
                    color: $red !important;
                    font-weight: bold;
                }
            }
        }
    }


    // List Tabs Colors
    &.tabs-red {
        // Tabs: Buttons - Classic Folder Tab Style
        ul.tabs.uk-tab li {
            a {
                background-color: $red;
                &:hover,
                &:active {
                    background: $gray;
                    color: #fff;
                }
            }
        }
    }
    &.tabs-gray {
        // Tabs: Buttons - Classic Folder Tab Style
        ul.tabs.uk-tab li {
            a {
                background-color: $gray;
                &:hover,
                &:active {
                    background: $gray-1;
                    color: #fff;
                }
            }
        }
    }
    &.tabs-gray-light {
        // Tabs: Buttons - Classic Folder Tab Style
        ul.tabs.uk-tab li {
            a {
                color: $gray;
                background-color: $gray-light;
                &:hover,
                &:active {
                    background: $gray;
                    color: #fff;
                }
            }
        }
    }
    &.tabs-teal {
        // Tabs: Buttons - Classic Folder Tab Style
        ul.tabs.uk-tab li {
            a {
                background-color: $teal;
                &:hover,
                &:active {
                    background: $gray;
                    color: #fff;
                }
            }
        }
    }
    &.tabs-teal-dark {
        // Tabs: Buttons - Classic Folder Tab Style
        ul.tabs.uk-tab li {
            a {
                background-color: $teal-dark;
                &:hover,
                &:active {
                    background: $gray;
                    color: #fff;
                }
            }
        }
    }
    &.tabs-teal-light {
        // Tabs: Buttons - Classic Folder Tab Style
        ul.tabs.uk-tab li {
            a {
                color: $gray;
                background-color: $teal-light;
                &:hover,
                &:active {
                    background: $gray;
                    color: #fff;
                }
            }
        }
    }
    &.tabs-blue {
        // Tabs: Buttons - Classic Folder Tab Style
        ul.tabs.uk-tab li {
            a {
                background-color: $blue;
                &:hover,
                &:active {
                    background: $gray;
                    color: #fff;
                }
            }
        }
    }
    &.tabs-blue-dark {
        // Tabs: Buttons - Classic Folder Tab Style
        ul.tabs.uk-tab li {
            a {
                background-color: $blue-dark;
                &:hover,
                &:active {
                    background: $gray;
                    color: #fff;
                }
            }
        }
    }
    &.tabs-blue-light {
        // Tabs: Buttons - Classic Folder Tab Style
        ul.tabs.uk-tab li {
            a {
                color: $gray;
                background-color: $blue-light;
                &:hover,
                &:active {
                    background: $gray;
                    color: #fff;
                }
            }
        }
    }
    &.tabs-orange {
        // Tabs: Buttons - Classic Folder Tab Style
        ul.tabs.uk-tab li {
            a {
                background-color: $orange;
                &:hover,
                &:active {
                    background: $gray;
                    color: #fff;
                }
            }
        }
    }
    &.tabs-orange-dark {
        // Tabs: Buttons - Classic Folder Tab Style
        ul.tabs.uk-tab li {
            a {
                background-color: $orange-dark;
                &:hover,
                &:active {
                    background: $gray;
                    color: #fff;
                }
            }
        }
    }
    &.tabs-orange-mid {
        // Tabs: Buttons - Classic Folder Tab Style
        ul.tabs.uk-tab li {
            a {
                color: $gray;
                background-color: $orange-mid;
                &:hover,
                &:active {
                    background: $gray;
                    color: #fff;
                }
            }
        }
    }
    &.tabs-orange-light {
        // Tabs: Buttons - Classic Folder Tab Style
        ul.tabs.uk-tab li {
            a {
                color: $gray;
                background-color: $orange-light;
                &:hover,
                &:active {
                    background: $gray;
                    color: #fff;
                }
            }
        }
    }
    &.tabs-green {
        // Tabs: Buttons - Classic Folder Tab Style
        ul.tabs.uk-tab li {
            a {
                background-color: $green;
                &:hover,
                &:active {
                    background: $gray;
                    color: #fff;
                }
            }
        }
    }
    &.tabs-green-dark {
        // Tabs: Buttons - Classic Folder Tab Style
        ul.tabs.uk-tab li {
            a {
                background-color: $green-dark;
                &:hover,
                &:active {
                    background: $gray;
                    color: #fff;
                }
            }
        }
    }
    &.tabs-green-light {
        // Tabs: Buttons - Classic Folder Tab Style
        ul.tabs.uk-tab li {
            a {
                color: $gray;
                background-color: $green-light;
                &:hover,
                &:active {
                    background: $gray;
                    color: #fff;
                }
            }
        }
    }

}