.sf-icon-blogger {
	background-image: url(../img/sprite.png);
	background-position: 0px -36px;
	width: 16px;
	height: 16px;
}
.sf-icon-comment {
	background-image: url(../img/sprite.png);
	background-position: -16px -52px;
	width: 17px;
	height: 15px;
}
.sf-icon-delicious {
	background-image: url(../img/sprite.png);
	background-position: 0px -20px;
	width: 16px;
	height: 16px;
}
.sf-icon-digg {
	background-image: url(../img/sprite.png);
	background-position: -16px -20px;
	width: 16px;
	height: 16px;
}
.sf-icon-email {
	background-image: url(../img/sprite.png);
	background-position: -33px -52px;
	width: 19px;
	height: 13px;
}
.sf-icon-facebook {
	background-image: url(../img/sprite.png);
	background-position: -36px -16px;
	width: 16px;
	height: 16px;
}
.sf-icon-feed {
	background-image: url(../img/sprite.png);
	background-position: 0px 0px;
	width: 20px;
	height: 20px;
}
.sf-icon-google {
	background-image: url(../img/sprite.png);
	background-position: -20px 0px;
	width: 16px;
	height: 16px;
}
.sf-icon-linkedin {
	background-image: url(../img/sprite.png);
	background-position: -32px -36px;
	width: 16px;
	height: 16px;
}
.sf-icon-mailto {
	background-image: url(../img/sprite.png);
	background-position: -52px 0px;
	width: 16px;
	height: 16px;
}
.sf-icon-myspace {
	background-image: url(../img/sprite.png);
	background-position: -52px -16px;
	width: 16px;
	height: 16px;
}
.sf-icon-reddit {
	background-image: url(../img/sprite.png);
	background-position: -52px -32px;
	width: 16px;
	height: 16px;
}
.sf-icon-stumble-upon {
	background-image: url(../img/sprite.png);
	background-position: -16px -36px;
	width: 16px;
	height: 16px;
}
.sf-icon-tumblr {
	background-image: url(../img/sprite.png);
	background-position: -36px 0px;
	width: 16px;
	height: 16px;
}
.sf-icon-twitter {
	background-image: url(../img/sprite.png);
	background-position: 0px -52px;
	width: 16px;
	height: 16px;
}
