// UTMB Lead

.utmb-lead {
    @extend .lead;

    strong, b {
        color: $red;
        font-weight: bold;
    }

    em, i {
        color: $gray;
    }

    a {
        color: $red;
        //display: inline-block;
        position: relative;
        text-decoration: none;

        // Animated Hovers
        // see: -http://tobiasahlin.com/blog/css-trick-animating-link-underlines/
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: $red;
            visibility: hidden;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transition: all 0.3s ease-in-out 0s;
            transition: all 0.3s ease-in-out 0s;
        }

        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
        }

        &:hover:before,
        &:hover:active,
        &:hover:focus {
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }
}