//
// UTMB App Theme: "UTMB Light" for Modern Design Mode
// body.utmb-dark
//


body.design-mode-modern {

    &.utmb-dark {
        #header {
            #hgroup {
                background-color: #111111;
            }
            .masthead-wrapper {
                background: $utmb-dark-header-bg;
            }
            a.branding {
                color: #ffffff;
        
                span {
                    background-image: $utmb-logo-white-svg;
                }
            }

            #navigate {
                #menu-navigate {
                    .menu-container {
                        > ul.nav-menu {
                            > li {
                                > a {
                                    color: #ffffff;
        
                                    &.active {
                                        color: #ffffff;
                                        &:before {
                                            visibility: visible;
                                            -webkit-transform: scaleX(1);
                                            transform: scaleX(1);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}