// Search Results


// UTMB Search Widget Styling
// SEE UTMB News
// -https://www.utmb.edu/news
// .utmb-search-results em,
// .utmb-search-results .sf-search-results .media.sf-media .media-body.sf-media-body p strong {
//     color: #da1f12;
// }

// .form-inline .form-group {
//     display: block !important;
// }


.utmbsf-search-results {
    em, .utmb-search-results .sf-search-results .media.sf-media .media-body.sf-media-body p strong {
        color: $red;
    }
    .search-results-title {
        h1 em, h2 em, h3 em, h4 em, h5 em, h6 em, p em, div em {
            color: $red !important;
        }
    }
    .search-results-sort {
        padding-bottom: 30px;
    }
    .search-results-item {
        border-top: 1px solid $gray-e;
        padding-bottom: 15px;
        padding-top: 20px;
        margin-top: 15px;
    }
    .search-results-item-title {
        h1, h2, h3, h4, h5, h6, p, div {
            margin-bottom: 11px;
        }
    }
    .search-results-item-content {
        p {
            font-size: .9rem;
            margin-bottom: 11px;
            strong.sfHighlight {
                color: $red;
            }
        }
    }
    .search-results-item-url {
        a {
            font-size: .777rem;
        }
    }
}



// General Search Results

// .utmbsf-search-results {
//     padding-top: 20px;
    
//     .row {
//         .col-md-8 {
//             h1 {
//                 font-size: $font-size-h3;
//                 em {
//                     color: $orange;
//                 }
//             }
//         }
//         .col-md-4 {}
//     }
// }
    
//     .sf-search-results {
//         margin-bottom: 20px;
    
//        // &.media-list {
            
            
//             .media.sf-media {
//                 padding-top: 16px;
//                 padding-bottom: 16px;
//                 border-top: 1px solid #eee;
//                 margin: 0;
//                 @include clearfix;
                
//                 .media-left.sf-img-thmb {
                    
//                 }
                
//                 .media-left {
//                     .sf-video-thmb {
//                         a {
//                             img {}
//                             .sf-icon-play {}
//                         }
//                     }
//                 }
                
//                 // Search Results Body
//                 .media-body.sf-media-body {
                    
//                     // title
//                     h3 {
//                         font-size: 1.35em;
//                         font-weight: bold;
//                         margin-bottom: 10px !important;
                        
//                         a {
//                             color: $gray-dark;
                            
//                             &:hover,
//                             &:active,
//                             &:focus {
//                              //color: $brand-primary;   
//                             }
//                         }
//                     }
                    
//                     // link
//                     a {
//                         font-size: 0.85em;
//                         display: inline-block;

//                         span {
//                             color: #cccccc;
//                             margin-right: 5px;
//                             text-decoration: none;
//                         }
//                     }
                    
//                     // link w/ wrapper
//                     .utmbsf-search-results-page-url a {
//                         a {
//                             font-size: 0.85em;
//                             display: inline-block;
//                             color: #cccccc;
//                             margin-bottom: 10px !important;

//                             span {
//                                 color: #cccccc;
//                                 margin-right: 5px;
//                                 text-decoration: none;
//                             }
//                         }
//                     }
                    
//                     p {
//                         strong {
//                             color: $orange;
//                         }
//                         margin: 0;
//                         padding: 0 !important;
//                         font-size: $font-size-small;
//                     }
                    
//                 }
//             }
//         //} 
        
//         em.text-muted {
            
//         }
//     }


