// UTMB Section Subtitle

.utmb-section-subtitle {
    background-color: #111111; //$red;
    border-left: 5px solid $red;
    line-height: 1.618em;
    margin: 50px 0 40px -30px;
    padding: 15px 25px 15px 30px;
    position: relative;
    @extend .uk-box-shadow-small; //box-shadow: 0 2px 8px rgba(0,0,0,.08);

    h1, h2, h3, h4, h5, h6 {
        //font-size: 0.963rem;
        font-family: $font-family-base;
        //color: #cccccc;
        color: #fff;
        margin: 0;
        padding: 0;
        // text-transform: uppercase;
    }

    h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong {
        color: #ffffff;
    }

    p {
        color: #fff;
        font-size: .765rem;
        line-height: 1.5em;
        margin: 0;
        padding: 5px 0 0 0;
    }

    .subtitle {
        //color: #eeeeee;
        display: inline;
        color: #fff;
    }

    small {
        border-top: 1px solid #333333;
        color: #eeeeee;
        color: #fff;
        display: block;
        margin-top: 6px;
        padding-top: 3px;

        code {
            background-color: transparent;
            color: $orange-light;
            color: #fff;
            padding: 0 0 0 3px;
        }
    }
    /*// down arrow
    &:before {
        content: '';
        display: block;
        position: absolute;
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        border-top: 23px solid #ffffff; //$red;
        border-bottom: 0;
        bottom: -23px;
        left: 50px;
        //@extend .uk-box-shadow-medium;
    }*/
}