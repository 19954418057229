// Color Swatch Book
// See Web Resources > Style Guide > Color


// .color-swatch-book {
//     .color-swatch {
//         .color-chip {
//             height: 77px;
//         }

//         p.color-name {
//             font-family: $font-family-condensed;
//             font-size: 1.618rem;
//             margin: 7px 0 12px;
//             padding: 0;
//         }

//         ul.color-mix {
//             @extend .uk-article-meta;
//             display: inline-block;
//             font-size: .825rem;
//             list-style-type: none;
//             margin: 0 auto;
//             padding: 0;

//             li {
//                 padding: 1px;
//                 text-align: left;

//                 code {
//                     background-color: transparent;
//                     color: $pink;
//                     padding: 0 0 0 3px;
//                 }
//             }
//         }
//     }
// }


.color-chip {
    height: 77px;
    margin-bottom: 11px;
}

p.color-name {
    font-family: $font-family-condensed;
    font-size: 1.618rem;
    margin: 7px 0 12px;
    padding: 0;
}

ul.color-mix {
    @extend .uk-article-meta;
    display: inline-block;
    font-size: .825rem;
    list-style-type: none;
    margin: 0 auto;
    padding: 0;

    li {
        padding: 1px;
        text-align: left;

        code {
            background-color: transparent;
            color: $pink;
            padding: 0 0 0 3px;
        }
    }
}