//
// Event List Default by @mgcooper
// -----------------------------------------

// misc
.k-other-month {
    background: #f8f8f8;
    color: #555555;
}

.event-list {

    ul.event-items {
        margin: 0;
        padding: 0;
        list-style: none;

        > li {
            margin: 0;
            padding: 0;
        }
    }

    .event-item {
        //@extend .uk-article;
        @extend .uk-card;
        @include clearfix;
        margin: 10px 0 20px;
        padding: 0;
    }

    .list-item-icon {
        color: #111111;
        font-size: 369%;
        margin: 5px 0 10px;
    }

    &.event-link-card {
        .event-item {
            display: block;
            text-decoration: none;

            .event-article-title {
                text-decoration: underline;
            }

            // .blog-post-read-more span {
            //     text-decoration: underline;
            // }

            &:hover,
            &:focus,
            &:active
            {
                cursor:pointer;
                border-bottom: 1px solid $red;
                box-shadow: 0 14px 25px rgba(0, 0, 0, .16);
                text-decoration: none;

                .event-article-title {
                    text-decoration: underline;
                    color: $red !important;
                }

                // .blog-post-read-more span {
                //     text-decoration: underline double;
                //     color: $red;
                // }
            }
        }
    }

    &.event-item-card {
        .event-item {
            @extend .uk-card-default;
            @extend .uk-card-body;
            margin: 10px 0 12px;
        }
    }

    &.event-item-divider {
        .event-item {
            border-top: 1px solid $gray-uk;
            margin: 12px 0;
            padding: 15px 0 12px;
        }

        ul.event-items {
            > li:first-child {
                .event-item {
                    border-top: 0;
                    padding-top: 0;
                }
            }
        }
    }

    .event-article-title {
        @extend .uk-card-title;
        margin: 0 0 10px;
        //@extend .uk-article-title;
        // font-size: 2.625rem;
        // line-height: 1.2;
        // margin-top: 40px;
        a {
            //@extend .uk-link-reset;
            text-decoration: underline;

            &:hover,
            &:active,
            &:focus {
                color: $red;
                text-decoration: underline;
                border-bottom: 1px solid $red;
            }
        }
    }
    // title small
    &.item-title-small .event-article-title {
        font-size: 1.25rem;
    }
    // title medium
    &.item-title-medium .event-article-title {
        font-size: 2.21rem;
    }
    // title large
    &.item-title-large .event-article-title {
        font-size: 2.625rem;
    }

    .event-meta {
        @extend .uk-article-meta;
        margin: 10px 0 15px;
        line-height: 1.5;

        > span {
            display: block;
            margin-bottom: 5px;

        }
    }

    .event-meta-footer {
        @extend .uk-article-meta;
        margin: 10px 0 15px;
        font-size: .75rem;
    }

    .event-meta-link {
        a {
            // @extend .uk-button;
            // @extend .uk-button-text;
            color: $gray;
            text-decoration: underline;

            &:hover,
            &:active,
            &:focus {
                color: $red;
                text-decoration: underline;
                border-bottom: 1px solid $red;
            }
        }
    }

    .meta-icon {
        margin-right: 5px;
    }

    .meta-link {
        color: $gray-light;
    }

    .event-comments {
        margin-left: 10px;
    }

    .event-content p {
        margin-bottom: 0px;
    }

    p.lead {
        @extend .uk-text-lead;
    }

    div[data-sf-role="comments-count-wrapper"],
    .sf-Comment-count {
        margin-left: 0;
    }

    .sf-icon-comment {
        display: none;
    }

    img {
        //@extend .uk-border-rounded;
        //@extend .uk-border-circle;
        @extend .uk-card;
        @extend .uk-card-default;
        //margin-top: 5px;
        padding: 5px;
    }

    .news-image-top {
        img {
            margin-bottom: 10px
        }
    }

    &.icon-hide {
        .utmbsf-event-meta {
            i {
                display: none;
            }
        }
    }

    &.label-hide {
        .utmbsf-event-meta {
            // Label (sr-only)
            .meta-label {
                border: 0 none;
                clip: rect(0px, 0px, 0px, 0px);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }
        }
    }
}




/* Mobile Handling For Left Handed Images */

@media only screen and (max-width: $utmb-small-max) {
    .event-list {
        img {
            margin-bottom: 10px
        }
        .uk-width-1-5 {
            width: 100%;
        }
        .uk-width-1-4 {
            width: 100%;
        }
    }
}



// Icon Colors
.event-list.list-item-icon-red .event-items .list-item-icon,
.event-list .event-items .list-item-icon-red .list-item-icon {
    color: $red !important;
}
.event-list.list-item-icon-gray .event-items .list-item-icon,
.event-list .event-items .list-item-icon-gray .list-item-icon {
    color: $gray !important;
}
.event-list.list-item-icon-gray-light .event-items .list-item-icon,
.event-list .event-items .list-item-icon-gray-light .list-item-icon {
    color: $gray-light !important;
}
.event-list.list-item-icon-teal .event-items .list-item-icon,
.event-list .event-items .list-item-icon-teal .list-item-icon {
    color: $teal !important;
}
.event-list.list-item-icon-teal-dark .event-items .list-item-icon,
.event-list .event-items .list-item-icon-teal-dark .list-item-icon {
    color: $teal-dark !important;
}
.event-list.list-item-icon-teal-light .event-items .list-item-icon,
.event-list .event-items .list-item-icon-teal-light .list-item-icon {
    color: $teal-light !important;
}
.event-list.list-item-icon-blue .event-items .list-item-icon,
.event-list .event-items .list-item-icon-blue .list-item-icon {
    color: $blue !important;
}
.event-list.list-item-icon-blue-dark .event-items .list-item-icon,
.event-list .event-items .list-item-icon-blue-dark .list-item-icon {
    color: $blue-dark !important;
}
.event-list.list-item-icon-blue-light .event-items .list-item-icon,
.event-list .event-items .list-item-icon-blue-light .list-item-icon {
    color: $blue-light !important;
}
.event-list.list-item-icon-orange .event-items .list-item-icon,
.event-list .event-items .list-item-icon-orange .list-item-icon {
    color: $orange !important;
}
.event-list.list-item-icon-orange-mid .event-items .list-item-icon,
.event-list .event-items .list-item-icon-orange-mid .list-item-icon {
    color: $orange-mid !important;
}
.event-list.list-item-icon-orange-light .event-items .list-item-icon,
.event-list .event-items .list-item-icon-orange-light .list-item-icon {
    color: $orange-light !important;
}
.event-list.list-item-icon-green .event-items .list-item-icon,
.event-list .event-items .list-item-icon-green .list-item-icon {
    color: $green !important;
}
.event-list.list-item-icon-green-dark .event-items .list-item-icon,
.event-list .event-items .list-item-icon-green-dark .list-item-icon {
    color: $green-dark !important;
}
.event-list.list-item-icon-green-light .event-items .list-item-icon,
.event-list .event-items .list-item-icon-green-light .list-item-icon {
    color: $green-light !important;
}