/* eslint-disable */
/* 
    Header for Classic Design Mode
    Mobile-First --> Desktop
*/
body.design-mode-classic {

    #header {
        background-color: #ffffff;
        clear: both;
        float: none;
        width: 100%;
    
        #masthead {
            margin: 0 auto;
            padding: 1em;
    
            &:before,
            &:after {
                content: " ";
                display: table;
            }
            &:after {
                clear: both;
            }
        }
    
        a.branding {
            color: #111111;
            display: block;
            float: left;
            height: 55px;
            margin-bottom: 0.3em;
            overflow: hidden;
            width: 157px;
    
            span {
                display: block;
                width: 157px;
                height: 75px;
                text-indent: -99999px;
                //background-image: url('../img/logos/UTMBHealth_logo.png');
                //background-image: url('../img/logos/UTMBHealth_logo.svg');
                background-image: $utmb-logo-svg; //url('../img/logos/UTMBHealth_logo_reg___no_tagline.svg'); // 10/15/2024
            }
        }

        .head {
            @include page-container-classic();
        }

        .hgroup {
            clear: left;
            display: block;
            padding: .618em 0 0;
            border-top: 1px solid #eee;
            text-align: center;

            h1 {
                color: #333333;
                font-size: 1.5em;
                font-weight: normal; 
                line-height: normal;
                margin: 0;
                padding: 0;
            }

            p {
                color: #555555;
                font-size: 1em;
                font-weight: normal; 
                line-height: normal;
                margin: 0;
                padding: 0;
            }
        }
    }

    // Design Mode: Classic -- Tablet view and up (768px+)
    @media only screen and (min-width: 768px) {
        #header {
            .head {
                padding: 1em 1.618em 1.618em 1em;
                position: relative;
            }

            a.branding {
                width: 157px;
                height: 75px;
                margin-bottom: 0.4em;
                margin: 12px 0 0 0;
                float: left !important;
            }

            .hgroup {
                clear: none;
                float: left !important;
                margin: 12px 0 0 30px;
                border-left: 1px solid #eee;
                border-top: 0;
                max-width: 500px;
                padding: 18px 0 12px 30px;
                text-align: left;

                h1 {
                    color: #333333;
                    font-size: 1.7em;
                }
    
                p {
                    color: #555555;
                    /* font-size: 1.3em; */
                    margin-top: 5px;
                }
            }
        }
    }
}


/* Do we still need this? */
/* Design Mode: Classic -- "Sweetspot" Desktop view and up (992px+) */
/* Mobile Menu Fix - @mgcooper 4/25/2014 
@media only screen and (min-width: 992px) {
    body.design-mode-classic #header .head {
      position: relative;
  }
}*/


/* OLD IE -- Design Mode: Classic -- UTMB Header Region */
.lt-ie9 {
    body.design-mode-classic {
        #header {
            padding-bottom: 10px;

            .head {
                position: relative;
                width: $container-classic;
            }

            a.branding {
                width: 157px;
                height: 75px;
                margin-bottom: 0.4em;
                margin: 12px 0 0 0;
                float: left !important;
            }

            .hgroup {
                clear: none;
                float: left !important;
                margin: 12px 0 0 20px;
                padding: 20px 0 12px 20px;
                border-left: 1px solid #eee;
                border-top: 0;
                max-width: 500px;

                p {
                    margin-top: 5px;
                }
            }
        }
    }
}