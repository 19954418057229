// Search Box


// UTMB Search Widget Styling
// SEE UTMB News
// -https://www.utmb.edu/news
// .sidebar-search {
// 	display: block !important;
//     //padding-bottom: 15px;

// 	.form-control { 
// 		border: 1px solid rgb(229, 229, 229);
// 		width: 100%;
// 		padding: 0 10px;
// 		font-size: 16px;
// 		line-height: 26.224px;
// 		height: 40px;
// 	}
// }


.utmbsf-search-box,
.utmbsf-search-box.form-inline {
	.btn.btn-primary {
		width: 100%;
		margin-top: 4px;
	}
}



.utmbsf-search-box.form-inline .form-group {
    display: block !important;

	.form-control { 
		border: 1px solid rgb(229, 229, 229);
		width: 100%;
		padding: 0 10px;
		//font-size: 16px;
		@include convertPixelsToRem_fontSize(16); // output: font-size: ($value / 16) + rem;
		line-height: 26.224px;
		height: 40px;
	}
}



// .utmbsf-search-box {
//     &.form-inline {
        
//         .form-group {
//             input {}
//         }
        
//         .btn.btn-primary {
            
//         }
//     }
// }


ul.sf-autocomplete {
	position: absolute;
	padding: 0;
	background: #fff;
	border:1px solid #ccc;
	list-style: none;

	a {
		padding:3px;
		display: block;
		color: #000;

		&.ui-state-focus {
			background-color: #eee;
        }

		&:hover {
			cursor:pointer;
			text-decoration: none;
        }
    }

}



