// BUTTONS
// Using both Bootstrap and UIKit (also Foundation) classes


// Bootstrap + UIKit Button Classnames
.btn,
.button,
.btn-default,
.button-default {
    @extend .uk-button;
    @extend .uk-button-default;
    border-radius: 0 !important;
    border-style: solid;
    white-space: normal;

    &:hover, 
    &:active,
    &:focus {
        border-style: solid;
        color: $red;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

.btn-block {
    display: block !important;
    width: 100%;
}

.btn-lg {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0;
}

.btn-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: 0;
}

.btn-text,
.button-text {
    @extend .uk-button-text;
    border: 0;

    &.button-hover-color {
        color: $global-link-hover-color;
    }
}

.btn-link,
.button-link {
    @extend .uk-button-link;
    border: 0;
    
    &:hover, 
    &:active,
    &:focus {
        text-decoration: underline;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

.btn-primary,
.button-primary {
    @extend .uk-button-primary;

    &:hover, 
    &:active,
    &:focus {
        color: $white;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

.btn-secondary,
.button-secondary {
    @extend .uk-button-secondary;

    &:hover, 
    &:active,
    &:focus {
        color: $white;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

.btn-info,
.button-info {
    @extend .uk-button-secondary;
    background-color: $global-tertiary-background;

    &:hover,
    &:active,
    &:focus {
        background-color: darken($global-tertiary-background, 4%);
        color: $white;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

.btn-danger,
.button-danger {
    @extend .uk-button-danger;
    background-color: $global-danger-background;

    &:hover,
    &:active,
    &:focus {
        background-color: darken($global-danger-background, 4%);
        color: $white;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

.btn-warning,
.button-warning {
    @extend .uk-button-secondary;
    background-color: $global-warning-background;
    //color: $gray-2;
    color: #fff;

    &:hover,
    &:active,
    &:focus {
        background-color: darken($global-warning-background, 4%);
        color: $white;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

.btn-success,
.button-success {
    @extend .uk-button-secondary;
    background-color: $global-success-background;

    &:hover,
    &:active,
    &:focus {
        background-color: darken($global-success-background, 4%);
        color: $white;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

// UTMB Palette

// red
.btn-red {
    // @include button-variant($white, $red, $red);
    color: $white !important;
    background-color: $red !important;
    border-color: $red !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: $white !important;
        background-color: $gray !important; //darken($red, 15%) !important;
        border-color: $gray !important; //darken($red, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

// gray
.btn-gray {
    //@include button-variant($white, $gray, $gray);
    color: $white !important;
    background-color: $gray !important;
    border-color: $gray !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: $white !important;
        background-color: $red !important; //darken($gray, 10%) !important;
        border-color: $red !important; //darken($gray, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

// gray-light
.btn-gray-light {
    //@include button-variant($black, $coolgray3, $coolgray3);
    color: $gray-3 !important;
    background-color: $gray-light !important;
    border-color: $gray-light !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: $white !important;
        background-color: $red !important; //darken($coolgray3, 10%) !important;
        border-color: $red !important; //darken($coolgray3, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

// gray-dark
.btn-gray-dark {
    //@include button-variant($black, $coolgray3, $coolgray3);
    color: $white !important;
    background-color: $gray-dark !important;
    border-color: $gray-dark !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: $white !important;
        background-color: $red !important; //darken($coolgray3, 10%) !important;
        border-color: $red !important; //darken($coolgray3, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}




// blue
.btn-blue {
    //@include button-variant($white, $blue, $blue);
    color: $white !important;
    background-color: $blue !important;
    border-color: $blue !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: $white !important;
        background-color: darken($blue, 10%) !important;
        border-color: darken($blue, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

// blue light
.btn-blue-light {
    //@include button-variant($white, $blue, $blue);
    color: darken($blue, 30%) !important;
    background-color: $blue-light !important;
    border-color: $blue-light !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: darken($blue, 30%) !important;
        background-color: darken($blue-light, 10%) !important;
        border-color: darken($blue-light, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

// blue dark
.btn-blue-dark {
    //@include button-variant($white, $blue, $blue);
    color: $white !important;
    background-color: $blue-dark !important;
    border-color: $blue-dark !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: $white !important;
        background-color: darken($blue-dark, 10%) !important;
        border-color: darken($blue-dark, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

// green
.btn-green {
    //@include button-variant($white, $green, $green);
    color: $white !important;
    background-color: $green !important;
    border-color: $green !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: $white !important;
        background-color: darken($green, 10%) !important;
        border-color: darken($green, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

// green light
.btn-green-light {
    //@include button-variant($white, $green, $green);
    color: darken($green, 30%) !important;
    background-color: $green-light !important;
    border-color: $green-light !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: darken($green, 30%) !important;
        background-color: darken($green-light, 10%) !important;
        border-color: darken($green-light, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

// green dark
.btn-green-dark {
    //@include button-variant($white, $green, $green);
    color: $white !important;
    background-color: $green-dark !important;
    border-color: $green-dark !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: $white !important;
        background-color: darken($green-dark, 10%) !important;
        border-color: darken($green-dark, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

// orange
.btn-orange {
    // @include button-variant($white, $orange, $orange);
    color: $white !important;
    background-color: $orange !important;
    border-color: $orange !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: $white !important;
        background-color: darken($orange, 10%) !important;
        border-color: darken($orange, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

// orange mid
.btn-orange-mid {
    // @include button-variant($white, $orange, $orange);
    color: $gray-2 !important;
    background-color: $orange-mid !important;
    border-color: $orange-mid !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: $white !important;
        background-color: darken($orange-mid, 10%) !important;
        border-color: darken($orange-mid, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

// orange light
.btn-orange-light {
    // @include button-variant($white, $orange, $orange);
    color: darken($orange, 30%) !important;
    background-color: $orange-light !important;
    border-color: $orange-light !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: darken($orange, 30%) !important;
        background-color: darken($orange-light, 10%) !important;
        border-color: darken($orange-light, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

// orange dark
.btn-orange-dark {
    // @include button-variant($white, $orange, $orange);
    color: $white !important;
    background-color: $orange-dark !important;
    border-color: $orange-dark !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: $white !important;
        background-color: darken($orange-dark, 10%) !important;
        border-color: darken($orange-dark, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}



// purple
.btn-purple {
    //@include button-variant($white, $purple, $purple);
    color: $white !important;
    background-color: $purple !important;
    border-color: $purple !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: $white !important;
        background-color: darken($purple, 10%) !important;
        border-color: darken($purple, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

// teal
.btn-teal {
    //@include button-variant($white, $teal, $teal);
    color: $white !important;
    background-color: $teal !important;
    border-color: $teal !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: $white !important;
        background-color: darken($teal, 10%) !important;
        border-color: darken($teal, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

// teal light
.btn-teal-light {
    //@include button-variant($white, $teal, $teal);
    color: darken($teal, 30%) !important;
    background-color: $teal-light !important;
    border-color: $teal-light !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: darken($teal, 30%) !important;
        background-color: darken($teal-light, 10%) !important;
        border-color: darken($teal-light, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

// teal dark
.btn-teal-dark {
    //@include button-variant($white, $teal, $teal);
    color: $white !important;
    background-color: $teal-dark !important;
    border-color: $teal-dark !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: $white !important;
        background-color: darken($teal-dark, 10%) !important;
        border-color: darken($teal-dark, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}


// coolgray3
.btn-coolgray3 {
    //@include button-variant($black, $coolgray3, $coolgray3);
    color: $gray-3 !important;
    background-color: $coolgray3 !important;
    border-color: $coolgray3 !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: $gray-3 !important;
        background-color: darken($coolgray3, 10%) !important;
        border-color: darken($coolgray3, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

// black
.btn-black {
    //@include button-variant($white, $black, $black);
    color: $white !important;
    background-color: $black !important;
    border-color: $black !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: $white !important;
        background-color: $red !important; //darken($black, 10%) !important;
        border-color: $red !important; //darken($black, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}

// white
.btn-white {
    //@include button-variant($black, $white, $white);
    color: $gray !important;
    background-color: $white !important;
    border-color: $gray-c !important;
    border-style: solid !important;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
        color: $white !important;
        background-color: $red !important; //darken($blue, 10%) !important;
        border-color: $red !important; //darken($blue, 12%) !important;
        border-style: solid !important;
        text-decoration: none !important;
    }
    &:focus {
        outline: 1px solid $red;
    }
}





// Back to Top Sticky Button (1 per page)
// usage: <a class="btn-back-to-top-sticky" href="#top" title="">Top</a>

a.btn-back-to-top-sticky {
    background-color: $gray-2;
    border-radius: 50%;
    color: $white;
    display: none;
    font-size: .888rem;
    padding: 4px 8px;
    position: fixed;
    bottom: 30px;
    right: 20px;
    text-decoration: none;
}
a.btn-back-to-top-sticky:hover,
a.btn-back-to-top-sticky:active,
a.btn-back-to-top-sticky:focus {
    background-color: $red;
    color: $white;
    text-decoration: none;
}