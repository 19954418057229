/* 
    Mobile Menu
    Mobile-First --> Desktop
*/

#mobi {
    // UIKit Menu Container
    .uk-offcanvas-bar {
        background-color: #222;
        //left: -396px;
        width: 100%;
    }
    .uk-offcanvas-bar svg {
        color: #fff;
    }
    // Menu Titles (Search, Navigate, Explore UTMB)
    .menu-title {
        //background: #000000;
        color: #ccc;
        display: block;
        font-size: .65em;
        font-weight: bold;
        letter-spacing: .618em;
        margin: 0;
        padding: 34px 18px 18px 0;
        text-align: left;
        text-transform: uppercase;
        width: 100%;
    }
    // Search
    .search-input-group {
        border-collapse: separate;
        display: inline-table;
        padding: 0; //0 10px;
        position: relative;
        vertical-align: middle;
        width: 100%;

        input {
            background-color: #fff;
            background-image: none;
            border: 1px solid #ccc;
            border-radius: 0;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
            color: #555;
            display: table-cell;
            position: relative;
            float: left;
            font-family: inherit;
            //font-size: 14px;
            @include convertPixelsToRem_fontSize(14); // output: font-size: ($value / 16) + rem;
            height: 34px;
            line-height: 1.42857143;
            margin: 0;
            padding: 6px 12px;
            transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
            width: 100%;
            vertical-align: middle;
            z-index: 2;
        }

        .search-input-group-btn {
            display: table-cell;
            margin: 0;
            position: relative;
            font-size: 0;
            vertical-align: middle;
            white-space: nowrap;
            width: 22%; //33%;
            .search-btn {
                background-color: #ef463b;
                background-image: none;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                border: 1px solid #ef463b;
                color: #ffffff;
                cursor: pointer;
                display: block;
                float: left;
                //font-size: 14px;
                @include convertPixelsToRem_fontSize(14); // output: font-size: ($value / 16) + rem;
                font-family: inherit;
                font-weight: 400;
                line-height: 1.42857143;
                /* margin-bottom: 4px; */
                margin-left: -1px;
                overflow: visible;
                padding: 6px 12px;
                position: relative;
                text-align: center;
                text-decoration: none;
                text-transform: none;
                transition: background-color 0.2s ease;
                touch-action: manipulation;
                /* vertical-align: middle; */
                user-select: none;
                /* width: 180px; */
                width: 100%;
                white-space: normal !important;
                word-wrap: break-word;
                z-index: 2;
                -webkit-appearance: button;

                .search-btn-text {
                    display: inline-block;
                    margin: 0 2px;
                    display: none;
                }
            }
        }
    }
    // Nav Menus
    .nav-menu {
        box-sizing: border-box;
        display: block;
        list-style: none;
        margin: 0;
        /* padding: 15px 30px 100px 30px; */
        /* padding: 15px 30px 100px 30px; */
        padding: 0 0 15px 0;
        width: 100%;

        li {
            border-radius: 0;
            margin: 0;
            padding: 0;

            a {
                border-top: 1px solid #444;
                color: #fff;
                display: block;
                font-size: .9em;
                text-decoration: none;
                position: relative;
                padding: 11px 9px;

                &:hover,
                &:active,
                &:focus {
                    background-color: #000000;
                }

                /*&.current {
                    background-color: $red;
                }*/
            }

            > div.submenu {
                display: none;
                font-size: .963em;
                padding-left: 15px;

                &.active {
                    display: block;
                }
            }
        }

        > li {
            position: relative;
            display: block;
        }
    }
}



/* Tablet view and up (768px+) */
@media only screen and (min-width: 555px) {
    #mobi.uk-offcanvas.uk-open {
        // UIKit Menu Container
        .uk-offcanvas-bar {
            width: 369px;
        }
    }
}

/* "Sweetspot" Desktop view and up (992px+) */
@media only screen and (min-width: 992px) {
    #mobi {
        border: 0 none;
        clip: rect(0px, 0px, 0px, 0px);
        color: #111;
        font-size: 0.9em;
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}