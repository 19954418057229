//
// UTMB Content Placeholder Regions
// Custom overrides for content placeholder regions - e.g. to hide the topbar region if you never use it
// Handy for Sitefinty but could be used in any app
// --------------------------------------------------


// Topbar Region
body.no-region-topbar-content {
    #header .head #topbarcontent,
    #topbarcontent {
        display: none;
    }
}

// Atop Region
body.no-region-atop-content {
    #atopcontent {
        display: none;
    }
}

// Feature Region
body.no-region-feature-content {
    #featurecontent {
        display: none;
    }
}

// Article Region
body.no-region-article-content {
    #article {
        display: none;
    }
}

// Base Region
body.no-region-base-content {
    #basecontent {
        display: none;
    }
}

// Sub Region
body.no-region-sub-content {
    #subcontent {
        display: none;
    }
}