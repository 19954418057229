// borders

// default
.border {
    border: 1px solid $gray-e;
}
.border-top {
    border-top: 1px solid $gray-e;
}
.border-right {
    border-right: 1px solid $gray-e;
}
.border-bottom {
    border-bottom: 1px solid $gray-e;
}
.border-left {
    border-left: 1px solid $gray-e;
}

// style
.border-solid {
    border-style: solid;
}
.border-dashed {
    border-style: dashed;
}
.border-dotted {
    border-style: dotted;
}
.border-double {
    border-style: double;
}

// remove border
.no-border {
    border: 0;
}
.no-border-top {
    border-top-width: 0;
}
.no-border-right {
    border-right-width: 0;
}
.no-border-bottom {
    border-bottom-width: 0;
}
.no-border-left {
    border-left-width: 0;
}

// border space
.border-space {
    margin-top: 7px !important;
    padding-top: 7px !important;
}