// 
// Navigate Main Classic Design Fill 
// 

// #f2f2f2+0,ffffff
@mixin nav-alt-theme-1() {
    // Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f2f2f2+0,ffffff+21
    background: rgb(242,242,242); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(242,242,242,1) 0%, rgba(255,255,255,1) 21%); // FF3.6-15
    background: -webkit-linear-gradient(top,  rgba(242,242,242,1) 0%,rgba(255,255,255,1) 21%); // Chrome10-25,Safari5.1-6
    background: linear-gradient(to bottom,  rgba(242,242,242,1) 0%,rgba(255,255,255,1) 21%); // W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}

// #e2e2e2+0,f2f2f2
@mixin nav-alt-theme-2() {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e2e2e2+0,f2f2f2+21 */
    background: rgb(226,226,226); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(226,226,226,1) 0%, rgba(242,242,242,1) 21%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(226,226,226,1) 0%,rgba(242,242,242,1) 21%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(226,226,226,1) 0%,rgba(242,242,242,1) 21%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#f2f2f2',GradientType=0 ); /* IE6-9 */
    
    #menu-navigate {
        border-top: 1px solid $gray-c;

        .menu-container {
            ul.nav-menu {
                //display: flex;

                // All Tiers
                li {
                    a {
                        border-left: 0;
                        border-right: 0;
                    }
                }

                // Tier 1
                > li {
                    > a {
                        color: $nav-link-color;

                        &:active,
                        &:focus,
                        &:hover {
                            background: #e2e2e2; //$gray-e; //$red;
                        }

                        &.active,
                        &.current,
                        &[aria-expanded="true"] {
                            background-color: $gray-1; //$orange;
                            color: $white;
        
                            &:hover {
                                background-color: $gray-1; //$gray-e; //$red;
                            }
                        }
                    }
                    
                    &:first-child > a {
                        border-left: 0;
                    }
                }
            } // end/ Site Nav Menu
        }
    }
}