﻿// Images
// ===========================================

// Branding

//$utmb-logo-png: url('../img/logos/UTMBHealth_logo.png');
$utmb-logo-svg: url('../img/logos/UTMBHealth_logo_reg___no_tagline.svg'); // 10/15/2024 //url('../img/logos/UTMBHealth_logo.svg');
//$utmb-logo-white-png: url('../img/logos/UTMBHealth_logo_white.png');
$utmb-logo-white-svg: url('../img/logos/UTMBHealth_logo_reg_white___no_tagline.svg'); // 10/15/2024 //url('../img/logos/UTMBHealth_logo_white.svg');
//$utmb-logo-large-png: url('../img/logos/UTMBHealth_logo_large.png');

// Mobile Menu Icons (Skip/Jump Nav)

// $utmb-skip-nav-icons-png:               url('../img/nav/skip-nav-icons.png');
// $utmb-skip-nav-icon-svg-navigate:       url(../img/nav/skip-nav-navigate.svg);
// $utmb-skip-nav-icon-svg-navigate-white: url(../img/nav/skip-nav-navigate-white.svg);
// $utmb-skip-nav-icon-svg-explore:        url(../img/nav/skip-nav-explore.svg);
// $utmb-skip-nav-icon-svg-explore-white:  url(../img/nav/skip-nav-explore-white.svg);
// $utmb-skip-nav-icon-svg-search:         url(../img/nav/skip-nav-search.svg);
// $utmb-skip-nav-icon-svg-search-white:   url(../img/nav/skip-nav-search-white.svg);

// Search Options Toogle Icon

// $utmb-search-options-toggle-png:        url(../img/nav/options-toggle.png);

// Nav Arrow Icons

// $utmb-nav-icon-arrow-white:             url(../img/nav/nav-icon-arrow-white.png);
// $utmb-nav-icon-arrow-gray:              url(../img/nav/nav-icon-arrow-gray.png);
// $utmb-nav-icon-arrow-yellow:            url(../img/nav/nav-icon-arrow-yellow.png);

// Nav Hamburger Icon

// $utmb-nav-icon-hamburger-white:         url(../img/nav/nav-hamburger-white.png);

// Split Button Arrow Icons (Old IE)

// $utmb-nav-icon-arrow-down-white:        url(../img/nav/btn-arrow-down-white.png);
// $utmb-nav-icon-arrow-down-black:        url(../img/nav/btn-arrow-down-black.png);

// Social Media Icons

// $social-icons-sprite: url(../img/social/social-icons-sprite.png);
$social-icons-sprite: url(../img/social-icons-sprite.png);