// Card Grid

.card-item-grid {
    @extend .uk-grid-match;

    &.text-center {
        text-align: center;
    }

    &.text-left {
        text-align: left;
    }

    .card-item {
        @extend .uk-card;
        @extend .uk-card-default;
        @extend .uk-card-small;
        @extend .uk-card-body;

        h1, h2, h3, h4, h5, .card-title {
            font-family: $font-family-condensed;
            font-size: 1.618rem;
            margin: 7px 0 12px;
            padding: 0;

            small {
                color: #757575;
            }
        }
    }

    &.card-item-info-list {
        .card-item {
            ul {
                @extend .uk-article-meta;
                display: inline-block;
                font-size: .825rem;
                list-style-type: none;
                margin: 0 auto;
                padding: 0;

                li {
                    padding: 1px;
                    text-align: left;

                    code {
                        background-color: transparent;
                        padding: 0 0 0 3px;
                    }
                }
            }
        }
    }
}