﻿// UTMB branding and complimentary colors
// First UTMB A11Y Web Colors added Feb and March 2019
// New UTMB A11Y Web Colors added Feb 2022
// Using https://webaim.org/resources/contrastchecker/

$black: #000000;
$white: #ffffff;

// Feb 2022
$red: #da1f12; // rgba(255,31,46,1);
$gray: #444444; // rgba(68,68,68,1);
$gray-light: #c9cac8; // rgba(201,202,200,1);

$teal: #457a82; // rgba(69,122,130,1);
$teal-dark: #005760; // rgba(0,87,96,1);
$teal-light: #c4e1de; //  rgba(196,225,222,1);

$blue: #1b5196; // rgba(27,81,150,1);
$blue-dark: #1c3a56; // rgba(28,58,86,1);
$blue-light:  #c0e1ed; // rgba(192,225,237,1);

$orange: #bf5700; // rgba(191,87,0,1);
$orange-dark: #bf5700; // rgba(191,87,0,1);
$orange-mid: #Eba149; // rgba(235,161,173,1);
$orange-light: #f5d7a5; // rgba(245,215,165,1);

$green: #626e25; // rgba(98,110,37,1);
$green-dark: #414823; // rgba(65,72,35,1);
$green-light: #c2ca95; // rgba(194,202,149,1);

// Supplementary Colors

$gray-mid: #555555; 
$gray-dark: #333333;
$blue-mid: $blue; //#3366cc;
$blue-navy: $blue-dark;
$green-mid: #397c02; //#338042; // UTMB Site Settings Green
$green-bright: $green-mid; // -- mini-url button green

// What about these UTMB colors?

$yellow: yellow; //#ffc40d; //---*UNOFFICIAL (NOT UTMB)
$purple: #641f45; //---*DEPRECATED? (NOT RECOMMENDED)
$pink: #c3325f; //---*UNOFFICIAL (NOT UTMB)
$coolgray3: $gray-light;
$tan: #dcd6b2;
$beige: #ecdebb;

// Foundation Colors - *overwriting scss/settings

//$primary-color:       $blue; //#2ba6cb;
//$secondary-color:     #e9e9e9; //$gray; //#e7e7e7;
//$alert-color:         #c60f13; //$red; //#f04124;
//$success-color:       #5da423; //$green; //#43AC6A;
//$warning-color:       #f08a24;
//$info-color:          #a0d3e8;

// Overwriting Bootstrap Variables

$brand-primary: $blue; //darken($blue, 6.5%); //darken($blue, 13%); //darken($blue, 6.5%); // #2ba6cb // darken(#428bca, 6.5%); // #337ab7
//$brand-primary:         #2C74D8; // a11y: #2C74D8 is the lightest shade of primary blue for both large and small text
$brand-success: $green-bright; //#5da423; // #5cb85c;
$brand-info: $blue-light; //#a0d3e8; // #5bc0de;
$brand-warning: #f08a24; // #f0ad4e;
$brand-danger: #c60f13; // #d9534f;
$brand-secondary: #e9e9e9;

// Gray Text Color Helpers

$gray-1: #111111;
$gray-2: #222222;
$gray-3: #333333;
$gray-4: #444444;
$gray-5: #555555;
$gray-6: #666666;
$gray-7: #777777;
$gray-8: #888888;
$gray-9: #999999;
$gray-a: #aaaaaa;
$gray-b: #bbbbbb;
$gray-c: #cccccc;
$gray-d: #dddddd;
$gray-e: #eeeeee;
$gray-bs: #f5f5f5; // Bootstrap light gray
$gray-uk: #e5e5e5; // UIKit light gray

// Link colors

$link-color: $brand-primary;
$link-hover-color: darken($brand-primary, 6.5%);
$link-decoration: 'underline';
$link-hover-decoration: 'underline';

// Nav Colors

$nav-link-color: $gray-1;
$nav-link-color-2: $gray-2;