// 
// UTMB Brand Fonts est 2019 - Roboto, Roboto Condensed, EB Garamond
// Self-Hosted at https://cdn.utmb.edu/web/fonts/utmb/utmb-fonts.css
// Google fonts acquired using https://google-webfonts-helper.herokuapp.com/fonts 
// ROBOTO, ROBOTO CONDENSED, EB GARAMOND

/* ORIG -- roboto-regular - latin */
// @font-face {
//     font-family: 'Roboto';
//     font-style: normal;
//     font-weight: 400;
//     src: url('https://cdn.utmb.edu/web/fonts/utmb/roboto-v18-latin/roboto-v18-latin-regular.eot'); /* IE9 Compat Modes */
//     src: local('Roboto'), local('Roboto-Regular'),
//         url('https://cdn.utmb.edu/web/fonts/utmb/roboto-v18-latin/roboto-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//         url('https://cdn.utmb.edu/web/fonts/utmb/roboto-v18-latin/roboto-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
//         url('https://cdn.utmb.edu/web/fonts/utmb/roboto-v18-latin/roboto-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
//         url('https://cdn.utmb.edu/web/fonts/utmb/roboto-v18-latin/roboto-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
//         url('https://cdn.utmb.edu/web/fonts/utmb/roboto-v18-latin/roboto-v18-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
// }s

/* roboto-100 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-100.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-100.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-300 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-300.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-100italic - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-100italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-100italic.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-100italic.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-300italic - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-300italic.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-regular - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-500 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-500.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-italic - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-500italic - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-500italic.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-700 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-700.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-700italic - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-900 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-900.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-900italic - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-900italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-900italic.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-v29-latin/roboto-v29-latin-900italic.svg#Roboto') format('svg'); /* Legacy iOS */
  }




/* ORIG -- roboto-condensed-regular - latin */
// @font-face {
//     font-family: 'Roboto Condensed';
//     font-style: normal;
//     font-weight: 400;
//     src: url('https://cdn.utmb.edu/web/fonts/utmb/roboto-condensed-v16-latin/roboto-condensed-v16-latin-regular.eot'); /* IE9 Compat Modes */
//     src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
//         url('https://cdn.utmb.edu/web/fonts/utmb/roboto-condensed-v16-latin/roboto-condensed-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//         url('https://cdn.utmb.edu/web/fonts/utmb/roboto-condensed-v16-latin/roboto-condensed-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
//         url('https://cdn.utmb.edu/web/fonts/utmb/roboto-condensed-v16-latin/roboto-condensed-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
//         url('https://cdn.utmb.edu/web/fonts/utmb/roboto-condensed-v16-latin/roboto-condensed-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
//         url('https://cdn.utmb.edu/web/fonts/utmb/roboto-condensed-v16-latin/roboto-condensed-v16-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
// }

/* roboto-condensed-300 - latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-300.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-300.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }
  /* roboto-condensed-300italic - latin */
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 300;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-300italic.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-300italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }
  /* roboto-condensed-regular - latin */
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }
  /* roboto-condensed-italic - latin */
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 400;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }
  /* roboto-condensed-700 - latin */
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-700.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }
  /* roboto-condensed-700italic - latin */
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 700;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/roboto-condensed-v24-latin/roboto-condensed-v24-latin-700italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }




/* ORIG -- eb-garamond-regular - latin */
// @font-face {
//     font-family: 'EB Garamond';
//     font-style: normal;
//     font-weight: 400;
//     src: url('https://cdn.utmb.edu/web/fonts/utmb/eb-garamond-v9-latin/eb-garamond-v9-latin-regular.eot'); /* IE9 Compat Modes */
//     src: local('EB Garamond Regular'), local('EBGaramond-Regular'),
//         url('https://cdn.utmb.edu/web/fonts/utmb/eb-garamond-v9-latin/eb-garamond-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//         url('https://cdn.utmb.edu/web/fonts/utmb/eb-garamond-v9-latin/eb-garamond-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
//         url('https://cdn.utmb.edu/web/fonts/utmb/eb-garamond-v9-latin/eb-garamond-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
//         url('https://cdn.utmb.edu/web/fonts/utmb/eb-garamond-v9-latin/eb-garamond-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
//         url('https://cdn.utmb.edu/web/fonts/utmb/eb-garamond-v9-latin/eb-garamond-v9-latin-regular.svg#EBGaramond') format('svg'); /* Legacy iOS */
// }

/* eb-garamond-regular - latin */
@font-face {
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 400;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-regular.svg#EBGaramond') format('svg'); /* Legacy iOS */
  }
  /* eb-garamond-500 - latin */
  @font-face {
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 500;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-500.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-500.svg#EBGaramond') format('svg'); /* Legacy iOS */
  }
  /* eb-garamond-600 - latin */
  @font-face {
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 600;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-600.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-600.svg#EBGaramond') format('svg'); /* Legacy iOS */
  }
  /* eb-garamond-700 - latin */
  @font-face {
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 700;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-700.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-700.svg#EBGaramond') format('svg'); /* Legacy iOS */
  }
  /* eb-garamond-800 - latin */
  @font-face {
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 800;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-800.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-800.svg#EBGaramond') format('svg'); /* Legacy iOS */
  }
  /* eb-garamond-italic - latin */
  @font-face {
    font-family: 'EB Garamond';
    font-style: italic;
    font-weight: 400;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-italic.svg#EBGaramond') format('svg'); /* Legacy iOS */
  }
  /* eb-garamond-500italic - latin */
  @font-face {
    font-family: 'EB Garamond';
    font-style: italic;
    font-weight: 500;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-500italic.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-500italic.svg#EBGaramond') format('svg'); /* Legacy iOS */
  }
  /* eb-garamond-600italic - latin */
  @font-face {
    font-family: 'EB Garamond';
    font-style: italic;
    font-weight: 600;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-600italic.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-600italic.svg#EBGaramond') format('svg'); /* Legacy iOS */
  }
  /* eb-garamond-700italic - latin */
  @font-face {
    font-family: 'EB Garamond';
    font-style: italic;
    font-weight: 700;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-700italic.svg#EBGaramond') format('svg'); /* Legacy iOS */
  }
  /* eb-garamond-800italic - latin */
  @font-face {
    font-family: 'EB Garamond';
    font-style: italic;
    font-weight: 800;
    src: url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-800italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-800italic.woff') format('woff'), /* Modern Browsers */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('https://utmb-cdn.azureedge.net/web/fonts/google/eb-garamond-v24-latin/eb-garamond-v24-latin-800italic.svg#EBGaramond') format('svg'); /* Legacy iOS */
  }
   /* dancing script */
   @font-face {
     font-family: 'Dancing Script';
     font-style:normal;
     font-weight: 400;
     src: url('https://utmb-cdn.azureedge.net/web/fonts/google/Dancing_Script/DancingScript-VariableFont_wght.ttf'); /* IE9 Compat Modes */
     src: local(''),
          url('https://utmb-cdn.azureedge.net/web/fonts/google/Dancing_Script/DancingScript-VariableFont_wght.ttf') format('truetype');
   }