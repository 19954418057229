// Image Border Mixin

@mixin img-border() {
    &.img-border {
        .banner {
            img {
                border: 1px solid #ddd;
                padding: 3px;
                background: #fff;
            }
        }
    }
}