// UTMB Section Title

.utmb-section-title {
    background-color: #ffffff; //$red;
    border-top: 2px solid $red;
    line-height: 1.618em;
    margin: 50px 0 40px -30px;
    padding: 30px 25px 20px 30px;
    position: relative;
    @extend .uk-box-shadow-large;

    h1, h2, h3, h4, h5, h6 {
        //font-size: 1.25em;
        //color: #ffffff;
        margin: 0;
        padding: 0;
        // text-transform: uppercase;
    }

    h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong {
        color: $red;
    }

    p {
        //color: $red; //#ffffff;
        font-size: .9rem;
        line-height: 1.5em;
        margin: 0;
        padding: 7px 0 0 0;
    }
    // down arrow
    &:before {
        content: '';
        display: block;
        position: absolute;
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        border-top: 23px solid #ffffff; //$red;
        border-bottom: 0;
        bottom: -23px;
        left: 50px;
        //@extend .uk-box-shadow-medium;
    }
}


@media only screen and (min-width: $utmb-sweetspot) {
    .utmb-section-title {
        margin-left: -70px;
        padding-left: 70px;
    }
}