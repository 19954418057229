// misc uikit overrides - accessibility

// uk-navbar override
.uk-navbar-nav {
    display: flex;
    flex-wrap: wrap;
}

// tables

.uk-table th, 
table th {
    text-transform: none;
    font-weight: bold;
}

// accordion indicator

.uk-accordion-title::before {
    float: left;
}

// @include convertPixelsToRem_fontSize(500); <!-- convert pixels to rem mixin

// updated uikit node_modules here:
// uikit > scss > components > headings.scss
// uikit > scss > components > form.scss
// uikit > scss > components > tooltip.scss
// uikit > scss > components > nav.scss
// uikit > scss > components > navbar.scss
// uikit > scss > components > dropdown.scss



// .uk-form-custom input[type=file] {
//     //font-size: 500px;
//     @include convertPixelsToRem_fontSize(500);
//     overflow: hidden;
// }
// .uk-tooltip {
//     display: none;
//     position: absolute;
//     z-index: 1030;
//     --uk-position-offset: 10px;
//     --uk-position-viewport-offset: 10;
//     top: 0;
//     box-sizing: border-box;
//     max-width: 200px;
//     padding: 3px 6px;
//     background: #666;
//     border-radius: 2px;
//     color: #fff;
//     //font-size: 12px;
//     @include convertPixelsToRem_fontSize(12);
// }
// .uk-nav-default .uk-nav-subtitle {
//     //font-size: 12px;
//     @include convertPixelsToRem_fontSize(12);
// }

// .uk-navbar-dropdown-nav .uk-nav-subtitle {
//     //font-size: 12px;
//     @include convertPixelsToRem_fontSize(12);
// }
// .uk-dropdown-nav .uk-nav-subtitle {
//     //font-size: 12px
//     @include convertPixelsToRem_fontSize(12);
// }