// UTMB Subsection Title

.utmb-subsection-title {
    h1, h2, h3, h4, h5, h6 {
        @extend .uk-heading-bullet;
        //font-size: 1.618rem;
        font-weight: 700;
        color: $gray-1;
        margin: 0 0 15px;
        padding: 0;
        // text-transform: none;
    }
}