.alert,
.uk-alert {
    background: white;
    border: 1px solid transparent;
    border-color: $gray-d;
    border-radius: 0; //4px;
    font-size: 0.936em;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 15px;

    strong, b {
        font-weight: bold;
    }

    a {
        text-decoration: underline;
        color: $red;

        &:active,
        &:focus,
        &:hover {
            text-decoration: underline;
            border-bottom: 1px solid $red;
            color: $red;
        }
    }

    .uk-alert-close {
        opacity: 1;

        &:active,
        &:focus,
        &:hover {
            opacity: 1;
        }
    }
}

.alert-primary,
.uk-alert-primary {
    background-color: $white !important;
    border-color: $red !important;
    color: $red !important;

    h1, h2, h3, h4, h5, h6, p, strong, b {
        color: $red;
    }

    a {
        color: $red;

        &:active,
        &:focus,
        &:hover {
            border-bottom: 1px solid $gray;
            color: $gray;
        }
    }
}

.alert-secondary,
.uk-alert-secondary {
    background-color: $white !important;
    border-color: $gray !important;
    color: $gray !important;

    h1, h2, h3, h4, h5, h6, p, strong, b {
        color: $gray;
    }

    a {
        color: $red;

        &:active,
        &:focus,
        &:hover {
            border-bottom: 1px solid $red;
            color: $red;
        }
    }
}

.alert-success,
.uk-alert-success {
    background-color: $green !important;
    border-color: $green !important;
    color: $white !important;

    h1, h2, h3, h4, h5, h6, p, strong, b {
        color: $white;
    }

    a {
        color: $white;

        &:active,
        &:focus,
        &:hover {
            border-bottom: 1px solid $white;
            color: $white;
        }
    }
}

.alert-danger,
.uk-alert-danger {
    //background-color: lighten($red, 44%);
    background-color: $red !important;
    border-color: $red !important;
    color: $white !important;

    h1, h2, h3, h4, h5, h6, p, strong, b {
        color: $white;
    }

    a {
        color: $white;

        &:active,
        &:focus,
        &:hover {
            border-bottom: 1px solid $white;
            color: $white;
        }
    }
}

.alert-warning,
.uk-alert-warning {
    background-color: $orange !important;
    border-color: $orange !important;
    color: $white !important;

    h1, h2, h3, h4, h5, h6, p, strong, b {
        color: $white;
    }

    a {
        color: $white;

        &:active,
        &:focus,
        &:hover {
            border-bottom: 1px solid $white;
            color: $white;
        }
    }
}

.alert-info,
.uk-alert-info {
    background-color: $white !important;
    border-color: $blue !important;
    color: $blue !important;

    h1, h2, h3, h4, h5, h6, p, strong, b {
        color: $blue;
    }

    a {
        color: $blue;

        &:active,
        &:focus,
        &:hover {
            border-bottom: 1px solid $blue;
            color: $blue;
        }
    }
}

.alert-light {
    background-color: $gray-light;
    border-color: $gray;
    color: $gray;
}

.alert-dark {
    background-color: $gray;
    border-color: $gray;
    color: $white;

    h1, h2, h3, h4, h5, h6, p, strong, b {
        color: $white;
    }

    a {
        color: $white;

        &:active,
        &:focus,
        &:hover {
            border-bottom: 1px solid $white;
            color: $white;
        }
    }
}
