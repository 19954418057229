// 
// Search - for Classic Design Mode
// 

body.design-mode-classic {

    // Design Mode: Classic -- below Desktop view (991px-)
    #search {
        display: none;
    }

    // Design Mode: Classic -- "Sweetspot" Desktop view and up (992px+)
    @media only screen and (min-width: 992px) {

        #search {
            display: block;
            position: absolute;
            right: 15px;
            top: 2.5em;

            #menu-search {
                display: block;
            }

            .title-area {
                border: 0 none;
                clip: rect(0px, 0px, 0px, 0px);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            .search-form {
                .search-input-group {
                    border-collapse: separate;
                    box-sizing: border-box;
                    display: inline-table;
                    margin: 0;
                    position: relative;
                    vertical-align: middle;
                }

                .search-label {
                    border: 0 none;
                    clip: rect(0px, 0px, 0px, 0px);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }

                input {
                    background-color: #fff;
                    background-image: none;
                    border: 1px solid #ccc;
                    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
                    box-sizing: border-box;
                    color: #555;
                    display: table-cell;
                    float: left;
                    font: inherit;
                    height: 34px;
                    padding: 6px 12px;
                    //font-size: 14px;
                    @include convertPixelsToRem_fontSize(14); // output: font-size: ($value / 16) + rem;
                    line-height: 1.42857143;
                    margin: 0;
                    position: relative;
                    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
                    width: 100%;
                    vertical-align: middle;
                    z-index: 2;
                }

                .search-input-group-btn {
                    box-sizing: border-box;
                    display: table-cell;
                    font-size: 0;
                    margin: 0;
                    position: relative;
                    vertical-align: middle;
                    width: auto;
                    white-space: nowrap;

                    &:last-child>.search-btn-group {
                        z-index: 2;
                        margin-left: -1px;
                    }
                }

                .search-btn-group {
                    box-sizing: border-box;
                    display: inline-block;
                    margin: 0;
                    position: relative;
                    vertical-align: middle;
                }

                .search-button {
                    background-color: #fff;
                    background-image: none;
                    border: 1px solid transparent;
                    border-color: #ccc;
                    border-radius: 0;
                    color: #333;
                    cursor: pointer;
                    display: inline-block;
                    /* float: left; */
                    //font-size: 14px;
                    @include convertPixelsToRem_fontSize(14); // output: font-size: ($value / 16) + rem;
                    font-weight: 400;
                    line-height: 1.42857143;
                    margin: 0;
                    padding: 6px 12px;
                    position: relative;
                    text-align: center;
                    text-decoration: none;
                    touch-action: manipulation;
                    user-select: none;
                    vertical-align: middle;
                    white-space: normal !important;
                    word-wrap: break-word;
                }

                .search-button-text {
                    border: 0 none;
                    clip: rect(0px, 0px, 0px, 0px);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }
            }
        }

    }
}
