// A11Y Skip Links


// Main Skip Links (Before Top of Page Template)
#main-skip-links {
    text-align: center;

    .skip-wrap {
        //tabindex: 0;

        a.hidden-skip-link {
            //display: inline-block
            @extend .uk-button;
            @extend .uk-button-default;
            border-radius: 0 !important;
            border-style: solid;
            white-space: normal;

            span {
                display: inline-block;
                margin-left: 4px;
                font-size: 1.25em;
                color: $red;
            }

            &:hover, 
            &:active,
            &:focus {
                border-style: solid;
                color: $red;
                text-decoration: none !important;
            }
            &:focus {
                outline: 1px solid $red;
            }
        }

        &:focus-within {
            background: #fff;
            padding: 10px;
            
            a.hidden-skip-link {
                display: inline-block;
                position: static;
                width: unset;
                height: unset;
                //padding: unset;
                margin: unset;
                overflow:unset;
                clip:unset;
                border:unset;
                //color: red;

                // Styled...
                @extend .uk-button;
                @extend .uk-button-default;
                border-radius: 0 !important;
                border-style: solid;
                white-space: normal;

                background-color: transparent;
                border: 1px solid #e5e5e5;
                color: #333;
                border-radius: 0 !important;
                white-space: normal;

                &:hover, 
                &:active,
                &:focus {
                    border-style: solid;
                    color: $red;
                    text-decoration: none !important;

                    background-color: $red;
                    border: 1px solid darken($red, 12%);
                    color: $white;

                    span {
                        color: $white;
                    }
                }
                &:focus {
                    outline: 1px solid $red !important;
                }
            }
        }
    }
}


// Skip Links for All Navigation Widgets
a.hidden-skip-nav-link:focus {
    display: inline-block;
    position: static;
    width: unset;
    height: unset;
    //padding: unset;
    margin: unset;
    overflow:unset;
    clip:unset;
    border:unset;
    //color: red;

    // Styled...
    @extend .uk-button;
    @extend .uk-button-default;
    border-radius: 0 !important;
    border-style: solid;
    white-space: normal;

    background-color: $white;
    border: 1px solid #e5e5e5;
    color: #333;
    border-radius: 0 !important;
    white-space: normal;
    border-style: solid;
    color: $red;
    text-decoration: none !important;
    outline: 1px solid $red !important;

    margin-left: 2px;
    margin-bottom: 4px;
}
a.hidden-skip-nav-link:focus:hover,
a.hidden-skip-nav-link:focus:active {
    background-color: $red;
    border: 1px solid darken($red, 12%);
    color: $white;
}