// 
// Footer
// Mobile-First --> Desktop
//

#footer {
    background-color: #333333;
    clear: both;
    color: #ffffff;
    float: none;
    font-size: .8em;
    line-height: 1.5;
    height: auto;

    a {
        color: #ffffff;
        text-decoration: underline;
        border-bottom: 0;

        &:hover,
        &:focus,
        &:active {
            border-bottom: 0;
            text-decoration: underline double;
        }
    }

    // Back To Top
    .back-to-top-container {
        margin: 0 auto;
        text-align: right;
        padding: 6px 15px;
        text-align: center;

        a.back-to-top {
            color: #fff;
            display: inline-block;
            border-bottom: 0 !important;

            .caret {
                border-bottom-color: #fff;
                border-top: 0;
                border-bottom: 4px dashed;
                border-bottom: 4px solid;
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: 2px;
                vertical-align: middle;
            }

            &:hover {
                border-bottom: 0 !important;
                text-decoration: underline double;
            }
        }
    }

    // Foot
    .foot {
        line-height: 2.125em;
        margin: 15px auto 0 auto;
        padding: 15px; /* mobile only */
        text-align: center; /* mobile only */

        a {
            display: inline-block;
        }

        // Footer Links
        .footer-links {
            margin: 0;

            a {
                font-family: "Roboto Condensed", "Helvetica Neue 2", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
                font-size: 1.35em; /* <!-- FOR ROBOTO */
                /*font-size: 1.2em;  <!-- FOR ARIAL */
                text-decoration: underline;
                //border-bottom: 1px dashed #777;
                border-bottom: 0;
                margin-right: 10px;

                &:hover,
                &:focus,
                &:active {
                    // text-decoration: none !important;
                    //border-bottom: 1px solid #ef463b;
                    border-bottom: 0;
                    text-decoration: underline double;
                }

                &.p-name,
                &.u-url {
                    padding: 0;
                }
            }
        }

        // Footer Copy
        .footer-copy {
            margin-top: 7px;
            color: #fff;
        }

        // Footer Logo
        .footer-logo {
            text-align: center;

            a.branding {
                // overflow: hidden;
                display: inline-block;
                color: #ffffff;
                width: 157px;
                height: 46px; //75px; //(75px w/ tagline
                margin: 30px auto 15px auto;

                span {
                    display: block;
                    width: 157px;
                    height: 46px; //75px; //(75px w/ tagline
                    text-indent: -99999px;
                    // background-image: url('../img/logos/UTMBHealth_logo_white.svg'); // w/ tagline
                    background-image: $utmb-logo-white-svg; //url('../img/logos/UTMBHealth_logo_reg_white___no_tagline.svg'); // 10/15/2024
                    background-repeat: no-repeat;
                }
            }
        }

        // Footer Login
        .utmbsf-footer-login {
            a {
                // border-bottom: 1px dashed #777;
                text-decoration: underline;

                &:hover,
                &:focus,
                &:active {
                    //text-decoration: none !important;
                    //border-bottom: 1px solid #ef463b;
                    border-bottom: 0;
                    text-decoration: underline double;
                }
            }
        }

    }


    // Tablet view and up (768px+)
    @media only screen and (min-width: 768px) {
        .back-to-top-container {
            text-align: right;
        }
        .foot {
            position: relative;
            text-align: left;
        }
        .footer-links {
            margin-right: 180px;
        }

        .footer-copy {
            margin-right: 180px;
        }

        .footer-logo {
            position: absolute;
            top: 0px;
            right: 15px;
        }
    }
}


// Sticky Footer
// Tablet view and up (768px+)
@media only screen and (min-width: 768px) {

    // Sticky Footer: #pagewrapper 
    #pagewrapper {
        min-height: 100%;
        margin-bottom: 0px;
    }
    #pagewrapper:after {
        content: "";
        display: block;
        height: 200px;
    }

    // Sticky Footer: #footer
    #footer {
        bottom: 0;
        margin: -200px auto 0 auto;
        min-height: 200px;
        position: relative;
    }
}
