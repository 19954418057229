﻿// Nav Pills
// Overlay Design for UIKit Horizontal Nav Bar
// *See ./src/assets/scss/vendor/uikit/_utmb-uikit-variables.scss


.nav-pills {
    .uk-navbar-container:not(.uk-navbar-transparent) {
        background: transparent;
        border: 0;
    }

    .uk-navbar-item,
    .uk-navbar-nav > li > a,
    .uk-navbar-toggle {
        border-radius: 30px;
        margin: 5px 0;
        min-height: 1px;
        padding: 6px 15px;
        white-space: nowrap;
    }

    .uk-navbar-nav > li {
        > a {
            background-color: #ffffff;
            border: 1px solid #eeeeee;
            text-transform: none;
            font-size: .875rem;
            display: inline-block;
            white-space: nowrap;

            &:active,
            &:focus,
            &:hover {
                color: $red;
            }

            &:focus {
                border: 1px dashed $red;
                outline: 1px solid $red;
            }
        }
        ul.uk-navbar-dropdown-nav,
        ul.uk-nav-sub {
            li {
                a {
                    font-size: .875rem !important;
                    white-space: normal;
                }
            }
        }
        
    }

    .uk-navbar-nav > li.uk-active {
        > a {
            background-color: $red;
            border: 1px solid $red;
            color: #ffffff !important;
    
            .caret {
                color: #fff;
            }
    
            &:focus {
                border: 1px dashed $red;
                outline: 1px solid $red;
            }
        }
    }
}


