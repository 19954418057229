//
// UTMB Print Styles
// --------------------------------------------------


@media print {
	
	.no-print,
	#header,
	#breadcrumbs,
	#explore,
	#navigate,
	#search,
	#kudos,
	#footer {display: none !important;}

	#aside {display:none; }
	.aside #aside {display:block;}
}