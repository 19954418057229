//
// Content: Classic Design Mode
//

body.design-mode-classic {

    // MOBILE VIEW
    // ----------------------------------

    // page content wrapper
    #pagewrapper {
        @include clearfix;
        opacity: 1; // for fade-in
        // -webkit-transition: opacity 0.5s ease, padding 0.5s ease;
        // -moz-transition: opacity 0.5s ease, padding 0.5s ease;
        // -o-transition: opacity 0.5s ease, padding 0.5s ease;
        // transition: opacity 0.5s ease, padding 0.5s ease;
    }

    // footer
    #footer {
        @include clearfix;
        opacity: 1; // for fade-in
        // -webkit-transition: opacity 1s ease;
        // -moz-transition: opacity 1s ease;
        // -o-transition: opacity 1s ease;
        // transition: opacity 1s ease;
    }

    // Sitefinity Public Wrapper (first element inside #page wrapper)
    #PublicWrapper {
        @include clearfix;
    }

    // main content region content wrapper (width 100%)
    #maincontent {
        @include clearfix;
        clear: both;
        float: none;
        margin: 0 auto;
        padding: $content-padding;
        width: 100%;
    }

    // primary content aka "artboard" -- inner content containers (max-width 1170px(-ish))
    #primarycontent {
        @include clearfix;
        @include page-container-classic();
    }

    // #article
    #article {
        .padding,
        #bodycontent {
            @include clearfix;
            padding: 20px; //15px;
        }
    }

    // breadcrumbs
    #breadcrumbs {
        .breadcrumb {
            background-color: transparent;
            font-size: .7em;
            line-height: 1.618em;
            margin-bottom: 25px;
            //padding: 0;
            //padding: 15px 0 0 15px;
            
            a {
                text-decoration: none;
                
                &:hover,
                &:focus,
                &:active {
                    text-decoration: underline;
                }
            }
        }
    }

    // aside (hide by default)
    #aside {
        display: none;
    }
    // body.aside (show #aside)
    body.aside { 
        #aside { 
            display: block;
            .padding,
            #asidecontent {
                padding: 20px; //15px;
            }
        }
    } 

    // Special Content Placeholder Regions
    #atopcontent,
    #featurecontent,
    #basecontent,
    #subcontent {
        clear:both;
        float:none;
        @include clearfix;
    }
    #atopcontent {
        margin: 0 -0.5em !important;
    }


    // TABLET VIEW
    // ----------------------------------

    
    //@media only screen and (min-width: $utmb-small) {}


    // DESKTOP VIEW
    // ----------------------------------

    @media only screen and (min-width: $utmb-sweetspot) {
        @include classic-content-desktop-view;
    }

}
