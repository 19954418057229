// Profile

.sf-profile {
	.sf-profile-avatar {
		position: relative;
		display: inline-block;

		.glyphicon-remove-sign {
			position:absolute;
			top: 5px;
			right: 5px;
			text-decoration: none;
			color: #999;
			background: #fff;
			border-radius: 50%;
        }
    }
}