// 
// Background Gradiant
// 


@mixin bg-gradiant($color1, $color2) {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor */
    background: $color1; /* Old browsers */
    background: -moz-linear-gradient(top,  $color1 0%, $color2 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  $color1 0%,$color2 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  $color1 0%,$color2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0 ); /* IE6-9 */
}

@mixin bg-gradiant-horizontal($color1, $color2, $color3) {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor */
background: $color2; /* Old browsers */
background: -moz-linear-gradient(left,  $color1 0%, $color2 38%, $color3 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  $color1 0%,$color2 38%,$color3 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  $color1 0%,$color2 38%,$color3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color3',GradientType=1 ); /* IE6-9 */

}

@mixin bg-gradiant-3color($color1, $color2, $color3) {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor */
    background: $color2; /* Old browsers */
    background: -moz-linear-gradient(top, $color1 0%, $color2 44%, $color3 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, $color1 0%,$color2 44%,$color3 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, $color1 0%,$color2 44%,$color3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color3',GradientType=0 ); /* IE6-9 */
}

@mixin bg-gradiant-diagonal($color1, $color2, $color3) {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor */
    background: $color2; /* Old browsers */
    background: -moz-linear-gradient(-45deg, $color1 0%, $color2 43%, $color3 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, $color1 0%,$color2 43%,$color3 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, $color1 0%,$color2 43%,$color3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color3',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}



// Background Gradiant Builder

@mixin bg-gradiant-builder($color) {
    &.bg-gradiant {
        @include bg-gradiant($color, darken($color, 33%));
    }

    &.bg-gradiant-horizontal {
        @include bg-gradiant-horizontal(darken($color, 22%), $color, darken($color, 33%));
    }

    &.bg-gradiant-diagonal {
        @include bg-gradiant-diagonal(darken($color, 22%), $color, darken($color, 33%));
    }

    &.bg-gradiant-bright {
        @include bg-gradiant(lighten($color, 22%), $color);
    }

    &.bg-gradiant-bright-horizontal {
        @include bg-gradiant-horizontal(lighten($color, 33%), $color, lighten($color, 22%));
    }

    &.bg-gradiant-bright-diagonal {
        @include bg-gradiant-diagonal(lighten($color, 33%), $color, lighten($color, 22%));
    }
}