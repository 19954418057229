// UTMB Section Info

.utmb-section-info {
    @extend .uk-card;
    @extend .uk-card-default;
    background-color: #ffffff;
    font-size: 1.618rem;
    margin: 0 0 15px;
    padding: 7px;

    pre {
        background-color: #efefef;
    }

    code {
        background-color: transparent;
        color: $pink;
        padding: 0 0 0 3px;
    }
}