.sf-Ratings {
    
	.sf-Ratings-stars {
		display: inline-block;
		vertical-align: middle;

		span {
			//font-size: 24px;
			@include convertPixelsToRem_fontSize(24); // output: font-size: ($value / 16) + rem;
			color: #ccc;
			cursor: pointer;

			&.hover,
			&.on {
				color: #006cd8;
            }
        }
        
		&.sf-Ratings-stars--ronly {
			span {
				cursor: default;
				&.on {
					color: #fc0;
                }
            }
        }
    }

	.sf-Ratings-average,
	.sf-Ratings-count {
		//font-size: 16px;
		@include convertPixelsToRem_fontSize(16); // output: font-size: ($value / 16) + rem;
		vertical-align: middle;
    }
    
	.sf-Ratings-count {
		span {
			vertical-align: middle;
        }
    }
}