﻿// Brand Colors
// text, backgrounds, borders
//

// red

.red {
    color: $red;
}
.red-bg {
    background-color: $red;
    @include bg-gradiant-builder($red);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.red-border {
    @include border-builder($red);
}


// gray

.gray {
    color: $gray;
}
.gray-bg {
    background-color: $gray;
    @include bg-gradiant-builder($gray);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.gray-border {
    @include border-builder($gray);
}


// gray light

.gray-light {
    color: $gray-light;
}
.gray-light-bg {
    background-color: $gray-light;
    @include bg-gradiant-builder($gray-light);
}
.gray-light-border {
    @include border-builder($gray-light);
}


// gray mid

.gray-mid {
    color: $gray-mid;
}
.gray-mid-bg {
    background-color: $gray-mid;
    @include bg-gradiant-builder($gray-mid);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.section-box {
    &.bg-gray-mid {
        &.arrow-below::after {
            border-top: 20px solid $gray-mid;
        }
    }
    &.arrow-gray-mid .arrow {
        border-top: 20px solid $gray-mid;
    }
}
.gray-mid-border {
    @include border-builder($gray-mid);
}


// gray dark

.gray-dark {
    color: $gray-dark;
}
.gray-dark-bg {
    background-color: $gray-dark;
    @include bg-gradiant-builder($gray-dark);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.gray-dark-border {
    @include border-builder($gray-dark);
}


// blue

.blue {
    color: $blue;
}
.blue-bg {
    background-color: $blue;
    @include bg-gradiant-builder($blue);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.blue-border {
    @include border-builder($blue);
}


// blue light

.blue-light {
    color: $blue-light;
}
.blue-light-bg {
    background-color: $blue-light;
    @include bg-gradiant-builder($blue-light);
}
.blue-light-border {
    @include border-builder($blue-light);
}


// blue mid

.blue-mid {
    color: $blue-mid;
}
.blue-mid-bg {
    background-color: $blue-mid;
    @include bg-gradiant-builder($blue-mid);
}
.blue-mid-border {
    @include border-builder($blue-mid);
}


// blue navy

.blue-navy {
    color: $blue-navy;
}
.blue-navy-bg {
    background-color: $blue-navy;
    @include bg-gradiant-builder($blue-navy);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.blue-navy-border {
    @include border-builder($blue-navy);
}


// blue dark

.blue-dark {
    color: $blue-dark;
}
.blue-dark-bg {
    background-color: $blue-dark;
    @include bg-gradiant-builder($blue-dark);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.blue-dark-border {
    @include border-builder($blue-dark);
}


// teal

.teal {
    color: $teal;
}
.teal-bg {
    background-color: $teal;
    @include bg-gradiant-builder($teal);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.teal-border {
    @include border-builder($teal);
}


// teal light

.teal-light {
    color: $teal-light;
}
.teal-light-bg {
    background-color: $teal-light;
    @include bg-gradiant-builder($teal-light);
}
.teal-light-border {
    @include border-builder($teal-light);
}

// teal dark

.teal-dark {
    color: $teal-dark;
}
.teal-dark-bg {
    background-color: $teal-dark;
    @include bg-gradiant-builder($teal-dark);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.teal-dark-border {
    @include border-builder($teal-dark);
}


// orange

.orange {
    color: $orange;
}
.orange-bg {
    background-color: $orange;
    @include bg-gradiant-builder($orange);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.orange-border {
    @include border-builder($orange);
}


// orange light

.orange-light {
    color: $orange-light;
}
.orange-light-bg {
    background-color: $orange-light;
    @include bg-gradiant-builder($orange-light);
}
.orange-light-border {
    @include border-builder($orange-light);
}

// orange mid

.orange-mid {
    color: $orange-mid;
}
.orange-mid-bg {
    background-color: $orange-mid;
    @include bg-gradiant-builder($orange-mid);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.orange-mid-border {
    @include border-builder($orange-mid);
}

// orange dark

.orange-dark {
    color: $orange-dark;
}
.orange-dark-bg {
    background-color: $orange-dark;
    @include bg-gradiant-builder($orange-dark);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.orange-dark-border {
    @include border-builder($orange-dark);
}


// green

.green {
    color: $green;
}
.green-bg {
    background-color: $green;
    @include bg-gradiant-builder($green);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.green-border {
    @include border-builder($green);
}


// green light

.green-light {
    color: $green-light;
}
.green-light-bg {
    background-color: $green-light;
    @include bg-gradiant-builder($green-light);
}
.green-light-border {
    @include border-builder($green);
}


// green bright

.green-bright {
    color: $green-mid; // deprecated
}
.green-bright-bg {
    background-color: $green-mid; // deprecated
    @include bg-gradiant-builder($green-mid);
}
.green-bright-border {
    @include border-builder($green-mid);
}


// green mid

.green-mid {
    color: $green-mid;
}
.green-mid-bg {
    background-color: $green-mid;
    @include bg-gradiant-builder($green-mid);
}
.green-mid-border {
    @include border-builder($green-mid);
}

// green dark

.green-dark {
    color: $green-dark;
}
.green-dark-bg {
    background-color: $green-dark;
    @include bg-gradiant-builder($green-dark);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.green-dark-border {
    @include border-builder($green-dark);
}


// purple

.purple {
    color: $purple;
}
.purple-bg {
    background-color: $purple;
    @include bg-gradiant-builder($purple);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.purple-border {
    @include border-builder($purple);
}


// yellow

.yellow {
    color: $yellow;
}
.yellow-bg {
    background-color: $yellow;
    @include bg-gradiant-builder($yellow);
}
.yellow-border {
    @include border-builder($yellow);
}


// pink

.pink {
    color: $pink;
}
.pink-bg {
    background-color: $pink;
    @include bg-gradiant-builder($pink);
}
.pink-border {
    @include border-builder($pink);
}


// cool gray 3

.coolgray3 {
    color: $coolgray3;
}
.coolgray3-bg {
    background-color: $coolgray3;
    @include bg-gradiant-builder($coolgray3);
}
.coolgray3-border {
    @include border-builder($coolgray3);
}


// tan

.tan {
    color: $tan;
}
.tan-bg {
    background-color: $tan;
    @include bg-gradiant-builder($tan);
}
.tan-border {
    @include border-builder($tan);
}


// beige

.beige {
    color: $beige;
}
.beige-bg {
    background-color: $beige;
    @include bg-gradiant-builder($beige);
}
.beige-border {
    @include border-builder($beige);
}




// Grayscale text helpers
// ===================================================


// black

.black {
    color: $black;
}
.black-bg {
    background-color: $black;
    @include bg-gradiant-builder($black);
}
.black-border {
    @include border-builder($black);
}


// white 

.white {
    color: $white;
}
.white-bg {
    background: $white;
    @include bg-gradiant-builder($white);
}
.white-border {
    @include border-builder($white);
}


// gray 1

.gray-1 {
    color: $gray-1;
}
.gray-1-bg {
    background-color: $gray-1;
    @include bg-gradiant-builder($gray-1);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.gray-1-border {
    @include border-builder($gray-1);
}


// gray 2

.gray-2 {
    color: $gray-2;
}
.gray-2-bg {
    background-color: $gray-2;
    @include bg-gradiant-builder($gray-2);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.gray-2-border {
    @include border-builder($gray-2);
}


// gray 3

.gray-3 {
    color: $gray-3;
}
.gray-3-bg {
    background-color: $gray-3;
    @include bg-gradiant-builder($gray-3);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.gray-3-border {
    @include border-builder($gray-3);
}


// gray 4

.gray-4 {
    color: $gray-4;
}
.gray-4-bg {
    background-color: $gray-4;
    @include bg-gradiant-builder($gray-4);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.gray-4-border {
    @include border-builder($gray-4);
}


// gray 5

.gray-5 {
    color: $gray-5;
}
.gray-5-bg {
    background-color: $gray-5;
    @include bg-gradiant-builder($gray-5);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.gray-5-border {
    @include border-builder($gray-5);
}


// gray 6

.gray-6 {
    color: $gray-6;
}
.gray-6-bg {
    background-color: $gray-6;
    @include bg-gradiant-builder($gray-6);
    .list-title {
        color: $white !important;
    }
    .content-toggle a {
        color: $white !important;
    }
}
.gray-6-border {
    @include border-builder($gray-6);
}


// gray 7

.gray-7 {
    color: $gray-7;
}
.gray-7-bg {
    background-color: $gray-7;
    @include bg-gradiant-builder($gray-7);
}
.gray-7-border {
    @include border-builder($gray-7);
}


// gray 8

.gray-8 {
    color: $gray-8;
}
.gray-8-bg {
    background-color: $gray-8;
    @include bg-gradiant-builder($gray-8);
}
.gray-8-border {
    @include border-builder($gray-8);
}


// gray 9

.gray-9 {
    color: $gray-9;
}
.gray-9-bg {
    background-color: $gray-9;
    @include bg-gradiant-builder($gray-9);
}
.gray-9-border {
    @include border-builder($gray-9);
}


// gray a

.gray-a {
    color: $gray-a;
}
.gray-a-bg {
    background-color: $gray-a;
    @include bg-gradiant-builder($gray-a);
}
.gray-a-border {
    @include border-builder($gray-a);
}


// gray b

.gray-b {
    color: $gray-b;
}
.gray-b-bg {
    background-color: $gray-b;
    @include bg-gradiant-builder($gray-b);
}
.gray-b-border {
    @include border-builder($gray-b);
}


// gray c

.gray-c {
    color: $gray-c;
}
.gray-c-bg {
    background-color: $gray-c;
    @include bg-gradiant-builder($gray-c);
}
.gray-c-border {
    @include border-builder($gray-c);
}


// gray d

.gray-d {
    color: $gray-d;
}
.gray-d-bg {
    background-color: $gray-d;
    @include bg-gradiant-builder($gray-d);
}
.gray-d-border {
    @include border-builder($gray-d);
}


// gray e

.gray-e {
    color: $gray-e;
}
.gray-e-bg {
    background-color: $gray-e;
    @include bg-gradiant-builder($gray-e);
}
.gray-e-border {
    @include border-builder($gray-e);
}


// gray bs

.gray-bs {
    color: $gray-bs;
}
.gray-bs-bg {
    background-color: $gray-bs;
    @include bg-gradiant-builder($gray-bs);
}
.gray-bs-border {
    @include border-builder($gray-bs);
}
