//
// UTMB App Base
// author Mike Cooper <mgcooper@utmb.edu>
// --------------------------------------------------



* {
    margin: 0;
    box-sizing: border-box;
}
*:focus {
    border: 1px dashed $red;
    outline: 1px solid $red;
}

[v-cloak] { display: none; }

html,
body {
    color: $gray;
    font-size: 18px; //16px;
    font-size: 1.1rem;
    height: 100% !important;
    min-height: 100% !important;
    font-family: $font-family-base;
    margin: 0 !important;
    padding: 0 !important;
    scroll-behavior: smooth;
}

form#aspnetForm {
    height: 100%;
    min-height: 100%;
    margin: 0 !important;
    padding: 0 !important;
}


// Top and Bot Links

.top-links {
    float: right;
}
.bot-links {
    clear: both;
    padding: 30px;
    text-align: center;
}

// Carets

.caret,
.caret-up,
.caret-down,
.caret-left,
.caret-right {
    color: #999;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
}
.caret {
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
.caret-up {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid;
}
.caret-down {
    border-top: 4px dashed;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
.caret-left {
    border-right: 4px dashed;
    border-right: 4px solid;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
}
.caret-right {
    border-left: 4px dashed;
    border-left: 4px solid;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
}

// Display
.display-block {
    display: block;
}
.display-inline {
    display: inline;
}
.display-inline-block {
    display: inline-block;
}

// Floats
.float-left {
    float: left;
}
.float-right {
    float: right;
}
.clear-float {
    clear: both;
    float: none;
}
@media only screen and (min-width: $utmb-xsmall) {
    .float-left-xsmall {
        float: left;
    }
    .float-right-xsmall {
        float: right;
    }
    .clear-float-xsmall {
        clear: both;
        float: none;
    }
}
@media only screen and (min-width: $utmb-small) {
    .float-left-small {
        float: left;
    }
    .float-right-small {
        float: right;
    }
    .clear-float-small {
        clear: both;
        float: none;
    }
}
@media only screen and (min-width: $utmb-sweetspot) {
    .float-left-desktop {
        float: left;
    }
    .float-right-desktop {
        float: right;
    }
    .clear-float-desktop {
        clear: both;
        float: none;
    }
}
@media only screen and (min-width: $utmb-large) {
    .float-left-large {
        float: left;
    }
    .float-right-large {
        float: right;
    }
    .clear-float-large {
        clear: both;
        float: none;
    }
}


// Screen Reader Only

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

// Some Intitial IE Handling

.js .skip,
.title-area,
.back-link {
  *display: none;
  _display: none;
}

// Pipe
.pipe {
    color: #e2e2e2;
    display: inline-block;
    padding: 0 7px;

    &.pipe-mid {
        color: #757575;
    }

    &.pipe-dark {
        color: $gray;
    }
}



