// =============================================================
// FOR STATIC UIKIT DEMOS
// =============================================================

.demo {
    @include clearfix;
    border: 2px solid #eee;
    margin: 30px auto 20px;
    padding: 30px;
    position: relative;

    &:before {
        content: 'CORE';
        background: $gray;
        color: #fff;
        display: block;
        float: left;
        font-size: .75em;
        margin-left: -20px;
        margin-top: -45px;
        padding: 5px 15px;
        text-transform: uppercase;
    }
    // UTMB Core
    &.demo-utmb {
        border: 2px solid $red;

        &:before {
            content: 'UTMB';
            background: $red;
        }
    }
    // UIKit Demos
    &.demo-uikit {
        border: 2px solid #2ba3d4;

        &:before {
            content: 'UIKit';
            background: #2ba3d4;
        }
    }
}

body.design-mode-classic {
    .demo {
        max-width: 1100px;
    }
}

body.design-mode-modern {
    .demo {
        max-width: 1100px;
    }
}