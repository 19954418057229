// UTMB Sitefinity Sass
// UTMB Web Framework for the Sitefinity CMS
// UTMB Health, The University of Texas Medical Branch
// @authors Mike Cooper, Melissa Harman, Chris Tucker

// UTMB Web Sass
@import "utmb-web.scss";

// Bootstrap Sass - v3.3.7 Grid
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";

// UTMB Sitefinity Sass
@import "vendor/sitefinity/sf-variables.scss";
@import "vendor/sitefinity/sf-mixins.scss";

// App (misc global)
@import "vendor/sitefinity/app/common";
@import "vendor/sitefinity/app/loading";
//@import "vendor/sitefinity/app/sf-utility"; // eh?

// Blogs
@import "vendor/sitefinity/blogs/blog-posts-detail";
@import "vendor/sitefinity/blogs/blog-posts-list";
@import "vendor/sitefinity/blogs/blog-posts-slider";

// Breadcrumbs
@import "vendor/sitefinity/breadcrumbs/breadcrumbs";

// Card
@import "vendor/sitefinity/card/card-default"; // *includes: img-hide, img-border
@import "vendor/sitefinity/card/card-block"; // *includes: img-hide, img-border
@import "vendor/sitefinity/card/card-panel"; // *includes: img-hide, img-border
@import "vendor/sitefinity/card/card-simple"; // *includes: img-hide, img-border
@import "vendor/sitefinity/card/card-hero";

// Comments
@import "vendor/sitefinity/comments/comments";
@import "vendor/sitefinity/comments/comments-ui";
@import "vendor/sitefinity/comments/ratings";

// Content Block
@import "vendor/sitefinity/content-block/content-block";

// Document List
@import "vendor/sitefinity/document-list/document-list";

// Events
@import "vendor/sitefinity/events/event-detail";
@import "vendor/sitefinity/events/event-list";
@import "vendor/sitefinity/events/event-scheduler";

// Feature Box - moved to "utmb-web.scss" (core)

// Forms
@import "vendor/sitefinity/forms/forms";

// Gallery
@import "vendor/sitefinity/gallery/gallery";

// Grid
@import "vendor/sitefinity/grid/grid";
@import "vendor/sitefinity/grid/grid-helpers"; // *New to UTMB Modern, easy way to add margin to draggable layout grids

// Content Layout Grids (GridSystem Widgets)
@import "vendor/sitefinity/content-layouts/content-layout-grids";

// Icons
@import "vendor/sitefinity/icons/icons";
@import "vendor/sitefinity/icons/sf-icon-font";

// Lists
@import "vendor/sitefinity/lists/list-all";
@import "vendor/sitefinity/lists/list-detail";

// Navigation
@import "vendor/sitefinity/navigation/nav-horizontal";
@import "vendor/sitefinity/navigation/nav-horizontal-pills";
@import "vendor/sitefinity/navigation/nav-horizontal-mini";
@import "vendor/sitefinity/navigation/nav-horizontal-large";
@import "vendor/sitefinity/navigation/nav-tabs";
@import "vendor/sitefinity/navigation/nav-vertical";
@import "vendor/sitefinity/navigation/nav-sidebar";

// News 
@import "vendor/sitefinity/news/news-detail";
@import "vendor/sitefinity/news/news-list";

// Pagination
@import "vendor/sitefinity/pagination/pagination";

// Profile
@import "vendor/sitefinity/profile/profile";

// Search
@import "vendor/sitefinity/search/search-box";
@import "vendor/sitefinity/search/search-results";

// Site Selector
@import "vendor/sitefinity/site-selector/site-selector";

// Social Share
@import "vendor/sitefinity/social-share/social-share";

// Sprite
@import "vendor/sitefinity/sprite/sprite";

// Taxonomies
@import "vendor/sitefinity/taxonomies/taxonomies";

// Forum (WebForms)
/*@import "vendor/sitefinity/forums/webforms-forum";*/
//---@import "vendor/sitefinity/forums/forum";

// Taxonomies
@import "vendor/sitefinity/taxonomies/taxonomies";

// Team Profiles
@import "vendor/sitefinity/team-profiles/team-member-detail";
@import "vendor/sitefinity/team-profiles/team-members-list";

.sitefinity-only {
    background-color: yellow;
    color: green;
}


// global 

.uk-article-title, 
.news-list.news-top-five-01 .featured-article .news-article-title, 
.news-list.news-top-five-02 .featured-article .news-article-title, 
.news-list.news-top-five-03 .featured-article .news-article-title, 
.news-detail .news-article .news-article-title, 
.event-detail .event-article .event-article-title, 
.blog-posts-detail .blog-post .blog-post-title {
    font-size: 2rem;

    @media (min-width: 960px) {
        font-size: 2rem;
    }
}

//the H1 class line height needs to be 1.5 or more. Also:
.event-detail .event-article .event-info-title, 
.event-detail .event-article .event-contacts-title {
    line-height: 1.5;
}


// A11Y Overrides

*:focus,
input:focus,
select:focus,
option:focus,
select option:focus,
textarea:focus,
input:focus,
input.uk-input:focus,
select.uk-select:focus,
textarea.uk-textarea:focus,
input.uk-radio:focus,
input.uk-checkbox:focus,
input.uk-range:focus,
a:focus,
a.btn:focus,
.uk-button:focus,
.uk-button.uk-button-default:focus,
.uk-button.uk-button-primary:focus,
.uk-button.uk-button-secondary:focus,
.uk-button.uk-button-danger:focus,
.uk-button.uk-button-text:focus,
.uk-button.uk-button-link:focus,
.uk-button-default:focus,
.uk-button-primary:focus,
.uk-button-secondary:focus,
.uk-button-danger:focus,
.uk-button-text:focus,
.uk-button-link:focus,
button:focus {
    border: 3px double $red !important;
    outline: 1px dashed $red !important;
}

.blog-posts-detail .blog-post .blog-post-meta,
.blog-posts-list .blog-post-meta,
.blog-posts-list .blog-post-meta-footer,
.card-item-grid.card-item-info-list .card-item ul,
.categories-list,
.event-detail .event-article .event-meta,
.event-list .event-meta,
.event-list .event-meta-footer,
.news-detail .news-article .news-meta,
.news-list .news-meta,
.news-list .news-meta-footer,
.sf-Comments-header .pull-right,
.sf-Comments-list .sf-media,
.sfforumPostBreabcrumbWrp,
.sfforumReplyPostWrp .sfforumSubscribeWrp,
.sfforumRssLnk,
.sfforumsFilterWrp,
.tags-cloud-list,
.tags-simple-list,
.tags-tag-cloud,
.uk-article-meta,
div[data-sf-role=comments-container] .sf-media,
ol.sfforumThreadPostList li.sfforumThreadPost .sfforumThreadPostContentWrp .sfforumLastModified,
ol.sfforumThreadPostList li.sfforumThreadPost .sfforumThreadPostContentWrp .sfforumPostAge,
ol.sfforumThreadPostList li.sfforumThreadPost .sfforumThreadPostContentWrp .sfforumPostPermalink,
ol.sfforumThreadPostList li.sfforumThreadPost .sfforumThreadPostContentWrp a.sfEditBtn,
ol.sfforumThreadPostList li.sfforumThreadPost .sfforumThreadPostContentWrp a.sfforumPostMarkFeatured,
ol.sfforumThreadPostList li.sfforumThreadPost .sfforumThreadPostUser,
table.sfforumsList td.sfforumLastPostWrp,
table.sfforumsList td.sfforumPostsCountWrp,
table.sfforumsList td.sfforumThreadsCountWrp,
table.sfforumsList td.sfforumTitleWrp p.sfforumDescription,
table.sfforumThreadsList .sfforumThreadLastPostWrp,
table.sfforumThreadsList .sfforumThreadPostsWrp,
table.sfforumThreadsList .sfforumThreadViewsWrp,
table.sfforumThreadsList .sfforumThreadWrp,
ul.color-mix {
    font-size: .875rem;
    line-height: 1.5;
    color: $gray;
}

h4, 
.uk-h4, 
.h4, 
.uk-accordion-title,
.lists.list-people .list .list-people-container .list-people-title, 
.lists.list-panel-list .list .list-panel-list-container .list-panel-title, 
.lists.list-link-card .list-item-title, 
.lists.list-expanded .list li .list-item-title, 
.lists.list-anchor .list-anchor-content .list li .list-item-title {
    line-height: 1.5 !important;
}

// bs and uk grid fix
.row.uk-grid>* {
    padding-left:0px !important;
}