// 
// Loading
//

%sf-loading-line {
	background-color: #9cc5f7;
	height: 30px;
	width: 6px;
	display: inline-block;
	-webkit-animation: stretchdelay 1.1s infinite ease-in-out;
	animation: stretchdelay 1.1s infinite ease-in-out;
}

.sf-loading {
	height: 30px;
	width: 6px;
	position: relative;
	margin: -5px 0 0 12px;

	&:before,
	&:after {
		@extend %sf-loading-line;
    }
    
	&:before,
	&:after {
		content: " ";
		position: absolute;
		top: 0;
    }
    
	&:before {
		-webkit-animation-delay: -1.2s;
		animation-delay: -1.2s;
		left: -10px;
    }
    
	&:after {
		-webkit-animation-delay: -1.0s;
		animation-delay: -1.0s;
		right: -10px;
    }
    
	> span {
		@extend %sf-loading-line;
    }
}

.sf-loading-wrapper {
	position: absolute;
	z-index: 902;
	background: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	.sf-loading {
		position: absolute;
		left: 50%;
		top: 50%;
		margin: -15px 0 0 -15px;
    }
}

@-webkit-keyframes stretchdelay {
	0%, 40%, 100% {
		-webkit-transform: scaleY(0.4);
    }

	20% {
		-webkit-transform: scaleY(1.0);
		background-color: #105ab5;
    }
}

@keyframes stretchdelay {
	0%, 40%, 100% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
    }
    
	20% {
		transform: scaleY(1.0);
		-webkit-transform: scaleY(1.0);
		background-color: #105ab5;
    }
}