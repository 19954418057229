// Generated by grunt-webfont
// Based on https://github.com/endtwist/fontcustom/blob/master/lib/fontcustom/templates/fontcustom.css

//@font-face {
//  font-family:"sf-icon-font";
//  src:url("../fonts/sf-icon-font.eot?b965b029509990d4490a2549c56d6627");
//  src:url("../fonts/sf-icon-font.eot?#iefix") format("embedded-opentype"),
//		url("../fonts/sf-icon-font.woff?b965b029509990d4490a2549c56d6627") format("woff"),
//		url("../fonts/sf-icon-font.ttf?b965b029509990d4490a2549c56d6627") format("truetype"),
//		url("../fonts/sf-icon-font.svg?b965b029509990d4490a2549c56d6627#sf-icon-font") format("svg");
//  font-weight:normal;
//  font-style:normal;
//}

// @font-face {
//     font-family:"sf-icon-font";
//     src:url("https://cdn.utmb.edu/web/fonts/sitefinity/sf-icon-font.eot?b965b029509990d4490a2549c56d6627");
//     src:url("https://cdn.utmb.edu/web/fonts/sitefinity/sf-icon-font.eot?#iefix") format("embedded-opentype"),
//           url("https://cdn.utmb.edu/web/fonts/sitefinity/sf-icon-font.woff?b965b029509990d4490a2549c56d6627") format("woff"),
//           url("https://cdn.utmb.edu/web/fonts/sitefinity/sf-icon-font.ttf?b965b029509990d4490a2549c56d6627") format("truetype"),
//           url("https://cdn.utmb.edu/web/fonts/sitefinity/sf-icon-font.svg?b965b029509990d4490a2549c56d6627#sf-icon-font") format("svg");
//     font-weight:normal;
//     font-style:normal;
//   }

  @font-face {
    font-family:"sf-icon-font";
    src:url("https://utmb-cdn.azureedge.net/web/fonts/sitefinity/sf-icon-font.eot?b965b029509990d4490a2549c56d6627");
    src:url("https://utmb-cdn.azureedge.net/web/fonts/sitefinity/sf-icon-font.eot?#iefix") format("embedded-opentype"),
          url("https://utmb-cdn.azureedge.net/web/fonts/sitefinity/sf-icon-font.woff?b965b029509990d4490a2549c56d6627") format("woff"),
          url("https://utmb-cdn.azureedge.net/web/fonts/sitefinity/sf-icon-font.ttf?b965b029509990d4490a2549c56d6627") format("truetype"),
          url("https://utmb-cdn.azureedge.net/web/fonts/sitefinity/sf-icon-font.svg?b965b029509990d4490a2549c56d6627#sf-icon-font") format("svg");
    font-weight:normal;
    font-style:normal;
  }
  
  
  
  // Bootstrap Overrides
  [class="icon- -single"],
  [class*=" icon-"]:before,
  [class*=" icon-"]:after,
  [class^="icon-"]:before,
  [class^="icon-"]:after {
    font-family: "sf-icon-font";
    vertical-align: middle;
    font-weight: normal;
    font-style: normal;
    speak: none;
    text-decoration: inherit;
    text-transform: none;
    text-rendering:  geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  
  
  
  // Icons
  .icon-file-single {content:"\b1";}
  .icon-no-image-single {content:"\b2";}
  
  // Icons
  .icon-file:before {content:"\b1";}
  .icon-no-image:before {content:"\b2";}
  
  
  
  .icon-item-file:after {
    @extend .icon-file-single
  }
  
  .icon-item-no-image:after {
    @extend .icon-no-image-single
  }
  
  