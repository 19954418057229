// Breadcrumbs


#breadcrumbs {
    .breadcrumb {
        background-color: transparent;
        font-size: .7em;
        line-height: 1.618em;
        list-style: none;
        margin: 15px 0 25px 15px;
        padding: 0;
        //padding: 15px 0 0 15px;

        > li {
            display: inline-block;
        }

        >li+li:before {
            padding: 0 5px;
            color: #ccc;
            content: "/\00a0";
        }

        a {
            text-decoration: none;
        }
    }
}




// .breadcrumb {
//     padding: 8px 15px;
//     margin-bottom: 20px;
//     list-style: none;
//     background-color: #f5f5f5;
//     border-radius: 4px;
// }

// ol, ul {
//     margin-bottom: 0;
//     margin-top: 0;
// }

// address, dl, fieldset, figure, ol, p, pre, ul {
//     margin: 0 0 20px 0;
// }

// .breadcrumb > li {
//     display: inline-block;
// }

// body.design-mode-classic #breadcrumbs .breadcrumb a {
//     text-decoration: none;
// }
