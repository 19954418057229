// Table

table {
    @extend .uk-table;

    td {
        font-size: 0.9rem;
    }

    &.table-divider {
        @extend .uk-table-divider;
    }

    &.table-striped {
        @extend .uk-table-striped;
    }

    &.table-small {
        @extend .uk-table-small;
    }

    &.table-hover {
        @extend .uk-table-hover;
    }

    &.table-responsive {
        @extend .uk-table-responsive;
    }

    &.table-border {
        border: 1px solid #e5e5e5;
    }

}

.table-overflow {
    @extend .uk-overflow-auto;
}


.uk-table th, table th {
    color: $gray;
}