//
// Background Patterns
// 


/* 
    Topo Pattern 
    See: -https://heropatterns.com/

    NOTE: the "#" must be replaced with "%23" for svg - TF?
    Doing this manually now, but need an automated solution
*/

.bg-pattern-topo-white {
    @include bg-pattern-topo-builder('%23ffffff');
}

// Teal Topo
.bg-pattern-topo-teal {
    @include bg-pattern-topo-builder('%23457a82'); // $teal // '%23457a82' // str-replace($teal, '#', '%23')
}
.bg-pattern-topo-teal-light {
    @include bg-pattern-topo-builder('%23c4e1de'); // $teal-light // '%23c4e1de' // str-replace($teal-light, '#', '%23')
}
.bg-pattern-topo-teal-dark {
    @include bg-pattern-topo-builder('%23005760'); // $teal-dark // '%23005760' // str-replace($teal-dark, '#', '%23')
}

// Blue Topo
.bg-pattern-topo-blue {
    @include bg-pattern-topo-builder('%231b5196'); // $blue // '%231b5196' // str-replace($blue, '#', '%23')
}
.bg-pattern-topo-blue-light {
    @include bg-pattern-topo-builder('%23c0e1ed'); // $blue-light // '%23c0e1ed' // str-replace($blue-light, '#', '%23')
}
.bg-pattern-topo-blue-dark {
    @include bg-pattern-topo-builder('%231c3a56'); // $blue-dark // '%231c3a56' // str-replace($blue-dark, '#', '%23')
}

// Gray Topo
.bg-pattern-topo-gray {
    @include bg-pattern-topo-builder('%23444444'); // $gray // '%23444444' // str-replace($gray, '#', '%23')
}
.bg-pattern-topo-gray-light {
    @include bg-pattern-topo-builder('%23c9cac8'); // $gray-light // '%23c9cac8' // str-replace($gray-light, '#', '%23')
}
.bg-pattern-topo-gray-dark {
    @include bg-pattern-topo-builder('%23333333'); // $gray-dark // '%23333333' // str-replace($gray-dark, '#', '%23')
}

