// Social Share

.sf-social-share {

	li {
		display: inline-block;
		vertical-align: top;
    }
	span {
		display: inline-block;
    }
}

.sf-share-button {
	display: inline-block;
	padding: 5px 5px 0 2px;
	border: 1px solid #d3d3d3;
	border-radius: 3px;
	vertical-align: top;
	//font-size: 12px;
	@include convertPixelsToRem_fontSize(12); // output: font-size: ($value / 16) + rem;
	color: #000;
	line-height: 1;
	transition: background .2s ease;

	&:hover,
	&:focus {
		text-decoration: none;
		background: #efefef;
		color: #000;
    }
	> span {
		margin-top: -3px;
    }

	> strong {
		vertical-align: top;
		font-weight: 400;
    }
}

