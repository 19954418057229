// Siteifnity / Bootstrap Grid



// Classic Design Mode - Bootstrap Container Override
.design-mode-classic {
    .container {
        width: auto;
        max-width: $container-classic;
    }

    @media (min-width: 1200px) {
        .container {
            width: auto;
            max-width: $container-classic;
        }
    }

    @media (min-width: 992px) {
        .container {
            width: auto;
            max-width: $container-classic;
        }
    }

    @media (min-width: 768px) {
        .container {
            width: auto;
            max-width: $container-classic;
        }
    }
}

// Modern Design Mode - Bootstrap Container Override
.design-mode-modern {
    .container {
        width: auto;
        max-width: $container-modern;
    }

    @media (min-width: 1200px) {
        .container {
            width: auto;
            max-width: $container-modern;
        }
    }

    @media (min-width: 992px) {
        .container {
            width: auto;
            max-width: $container-modern;
        }
    }

    @media (min-width: 768px) {
        .container {
            width: auto;
            max-width: $container-modern;
        }
    }
}



// Sitefinity Grid
.sf_cols { 
    margin: 0; 
    padding: 0; 
    width: 100%;
    -ms-zoom: 1; 
    zoom: 1; 
}
.sf_cols:before, 
.sf_cols:after { 
    display: table; 
    content: ""; 
}
.sf_cols:after { 
    clear: both; 
}
.sf_colsOut + .sf_colsOut { 
    *margin-left: -1px!important; 
}
.sf_block { 
    display: block; 
}
.sf_1col_1_100 { 
    float: none; 
    margin: 0; 
}

.sf_5cols_1_20, 
.sf_5cols_2_20, 
.sf_5cols_3_20, 
.sf_5cols_4_20, 
.sf_5cols_5_20 { 
    float: left; 
    margin: 0; 
    width: 20%; 
}
.sf_2cols_1_25, 
.sf_3cols_1_25, 
.sf_4cols_1_25, 
.sf_4cols_2_25, 
.sf_4cols_3_25, 
.sf_4cols_4_25, 
.sf_3cols_3_25 { 
    float: left; 
    margin: 0; 
    width: 25%; 
}

.sf_2cols_2_25 { 
    float: right; 
    margin: 0; 
    width: 25%; 
}
.sf_3cols_1_33, 
.sf_3cols_3_33, 
.sf_2cols_1_33 { 
    float: left; 
    margin: 0; 
    width: 33%; 
}
.sf_2cols_2_33 { 
    float: right; 
    margin: 0; 
    width: 33%; 
}
.sf_3cols_2_34 { 
    float: left; 
    margin: 0; 
    width: 34%; 
}
.sf_3cols_2_50, 
.sf_2cols_1_50 { 
    float: left; 
    margin: 0; 
    width: 50%; 
}
.sf_2cols_2_50 { 
    float: right; 
    margin: 0; 
    width: 50%; 
}
.sf_2cols_1_67 { 
    float: left; 
    margin: 0; 
    width: 67%; 
}
.sf_2cols_2_67 { 
    float: right; 
    margin: 0; 
    width: 67%; 
}
.sf_2cols_1_75 { 
    float: left; 
    margin: 0; 
    width: 75%; 
}
.sf_2cols_2_75 { 
    float: right; 
    margin: 0; 
    width: 75%; 
}

.sf_2cols_2_75 .sf_2cols_2in_75, 
.sf_2cols_2_67 .sf_2cols_2in_67, 
.sf_2cols_2_50 .sf_2cols_2in_50, 
.sf_2cols_2_33 .sf_2cols_2in_33, 
.sf_2cols_2_25 .sf_2cols_2in_25, 
.sf_3cols_2_34 .sf_3cols_2in_34, 
.sf_3cols_3_33 .sf_3cols_3in_33, 
.sf_3cols_2_50 .sf_3cols_2in_50, 
.sf_3cols_3_25 .sf_3cols_3in_25, 
.sf_4cols_2_25 .sf_4cols_2in_25, 
.sf_4cols_3_25 .sf_4cols_3in_25, 
.sf_4cols_4_25 .sf_4cols_4in_25, 
.sf_5cols_2_20 .sf_5cols_2in_20, 
.sf_5cols_3_20 .sf_5cols_3in_20, 
.sf_5cols_4_20 .sf_5cols_4in_20, 
.sf_5cols_5_20 .sf_5cols_5in_20 { 
    margin-left: 12px; 
}

.sf_3cols_3_25, 
.sf_3cols_3_33, 
.sf_4cols_4_25, 
.sf_5cols_5_20 { 
    _margin-left: -1px!important; 
}



//@media only screen and (max-width: $utmb-small-max) {
//    .sf_1col_1_100,
//    .sf_5cols_1_20, 
//    .sf_5cols_2_20, 
//    .sf_5cols_3_20, 
//    .sf_5cols_4_20, 
//    .sf_5cols_5_20,
//    .sf_2cols_1_25, 
//    .sf_3cols_1_25, 
//    .sf_4cols_1_25, 
//    .sf_4cols_2_25, 
//    .sf_4cols_3_25, 
//    .sf_4cols_4_25, 
//    .sf_3cols_3_25,
//    .sf_2cols_2_25,
//    .sf_3cols_1_33, 
//    .sf_3cols_3_33, 
//    .sf_2cols_1_33,
//    .sf_2cols_2_33,
//    .sf_3cols_2_34,
//    .sf_3cols_2_50, 
//    .sf_2cols_1_50,
//    .sf_2cols_2_50,
//    .sf_2cols_1_67,
//    .sf_2cols_2_67,
//    .sf_2cols_1_75,
//    .sf_2cols_2_75,
//    .sf_2cols_2_75 .sf_2cols_2in_75, 
//    .sf_2cols_2_67 .sf_2cols_2in_67, 
//    .sf_2cols_2_50 .sf_2cols_2in_50, 
//    .sf_2cols_2_33 .sf_2cols_2in_33, 
//    .sf_2cols_2_25 .sf_2cols_2in_25, 
//    .sf_3cols_2_34 .sf_3cols_2in_34, 
//    .sf_3cols_3_33 .sf_3cols_3in_33, 
//    .sf_3cols_2_50 .sf_3cols_2in_50, 
//    .sf_3cols_3_25 .sf_3cols_3in_25, 
//    .sf_4cols_2_25 .sf_4cols_2in_25, 
//    .sf_4cols_3_25 .sf_4cols_3in_25, 
//    .sf_4cols_4_25 .sf_4cols_4in_25, 
//    .sf_5cols_2_20 .sf_5cols_2in_20, 
//    .sf_5cols_3_20 .sf_5cols_3in_20, 
//    .sf_5cols_4_20 .sf_5cols_4in_20, 
//    .sf_5cols_5_20 .sf_5cols_5in_20,
//    .sf_3cols_3_25, 
//    .sf_3cols_3_33, 
//    .sf_4cols_4_25, 
//    .sf_5cols_5_20 { 
//        float: none;
//        clear: both;
//        width: 100%;
//        display: block;
//        margin: 0;
//    }
//}



// CSS3 [attribute^=value] Selector
// http://www.w3schools.com/cssref/sel_attr_begin.asp
// http://stackoverflow.com/questions/13352080/match-all-class-selectors-that-begin-with

@media only screen and (max-width: $utmb-small-max) {
    div[class^='sf_1col'], div[class*=' sf_1col'],
    div[class^='sf_2cols'], div[class*=' sf_2cols'],
    div[class^='sf_3cols'], div[class*=' sf_3cols'],
    div[class^='sf_4cols'], div[class*=' sf_4cols'],
    div[class^='sf_5cols'], div[class*=' sf_5cols'],
    div[class^='sf_6cols'], div[class*=' sf_6cols'],
    div[class^='sf_7cols'], div[class*=' sf_7cols'],
    div[class^='sf_8cols'], div[class*=' sf_8cols'],
    div[class^='sf_9cols'], div[class*=' sf_9cols'],
    div[class^='sf_10cols'], div[class*=' sf_10cols'],
    div[class^='sf_11cols'], div[class*=' sf_11cols'],
    div[class^='sf_12cols'], div[class*=' sf_12cols'] {
        float: none !important;
        clear: both !important;
        width: 100% !important;
        display: block !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        //margin: 0 !important;
    }
}
