﻿//
// Carousel
// --------------------------------------------------


// Make .carousel images fluid
// http://stackoverflow.com/questions/10459740/make-bootstrap-carousel-fluid

.carousel img {
    width: 100%;
    max-width: 100%;
}
