// 
// UTMB Explore (Insitutional) Menu: "Modern Menu" for Modern Design Mode
//


// Design Mode: Modern -- below Desktop view (991px-)
body.design-mode-modern {
    #explore {
        display: none;
    }
}

// Design Mode: Modern -- "Sweetspot" Desktop view and up (992px+)
@media only screen and (min-width: 992px) {
    body.design-mode-modern {
        #explore {
            display: block;
            float: right;

            .tandem-nav {
                display: block;
            }

            .title-area {
                border: 0 none;
                clip: rect(0px, 0px, 0px, 0px);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            .menu-container {
                display: block;
            }

            .nav-menu {
                float: left;
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    float: left;
                    margin: 0;
                }

                >li {
                    position: relative;
                    display: block;
                    line-height: 1.33333;

                    a {
                        border: 2px solid transparent;
                        border-left: 3px solid transparent;
                        color: #ffffff;
                        display: block;
                        font-family: "Roboto Condensed", "Helvetica Neue 2", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
                        font-size: .85em;
                        // line-height: 30px;
                        min-height: 44px;
                        line-height: 44px;
                        padding: 0 9px;
                        text-decoration: none;
                        //text-transform: uppercase;

                        &:hover,
                        &:active,
                        &:focus {
                            background-color: #111111;
                            background-color: $red;
                            //border-left: 2px solid $white;
                            //border-bottom: 2px solid $white;
                            text-decoration: none;
                        }
                    }

                    // Alert Site Link
                    &.utmb-alert-site {
                        a {
                            border: 2px solid $red;
                            &:hover,
                            &:active,
                            &:focus {
                                background-color: $red;
                                // border-left: 2px solid $white;
                                // border-bottom: 2px solid $white;
                            }
                        }
                    }

                    &[data-item-id="exploreSearch"] a {
                        min-width: 44px;
                        text-align: center;
                    }
                }

                // li#exploreSearch {
                //     text-indent: -99999px;
                // }
            }
        }
    }
}