//
// Content: Modern Design Mode
//

body.design-mode-modern {

    // MOBILE VIEW
    // ----------------------------------

    // page content wrapper
    #pagewrapper {
        @include clearfix;
        opacity: 1; // for fade-in
        // -webkit-transition: opacity 0.5s ease, padding 0.5s ease;
        // -moz-transition: opacity 0.5s ease, padding 0.5s ease;
        // -o-transition: opacity 0.5s ease, padding 0.5s ease;
        // transition: opacity 0.5s ease, padding 0.5s ease;
    }

    // footer
    #footer {
        @include clearfix;
        opacity: 1; // for fade-in
        // -webkit-transition: opacity 1s ease;
        // -moz-transition: opacity 1s ease;
        // -o-transition: opacity 1s ease;
        // transition: opacity 1s ease;
    }

    // Sitefinity Public Wrapper (first element inside #page wrapper)
    #PublicWrapper {
        @include clearfix;
    }

    // main content region content wrapper (width 100%)
    #maincontent {
        @include clearfix;
        clear: both;
        float: none;
        margin: 0 auto;
        padding: 0; //$content-padding;
        width: 100%;
    }

    // primary content aka "artboard" -- inner content containers (max-width 1400px(-ish))
    #primarycontent {
        @include clearfix;

        //@include page-container-modern(); full width for all body content - sectional based design
    }

    // #article
    #article {
        // *Sticky Anchors for Sticky Headers ;P
        // main-content named anchor / main skip link
        #main-content {
            scroll-margin-top: 111px;
        }
        .padding,
        #bodycontent {
            @include clearfix;
            padding: 0; //20px; //15px;
        }
    }

}