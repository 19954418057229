﻿// Team Members List
//

// div.team-members-list
//   div.team-member-items (uk-grid)
//     div.hentry.h-entry (itemCss)
//      (article || a).team-member-item
//       div.team-member-item-contents (flexbox wrapper)
//         div.team-member-media.team-member-media-(top || left)
//           div.team-member-photo (where round wrapper is applied)
//              img.team-member-photo team-member-photo-(top || left)
//         div.team-member-info
//           div.item-content-cats cats-top (optional)
//           (h1-div).team-member-title.team-member-name
//           div.team-member-position
//           div.team-member-additional-info
//             div.team-member-phone
//             div.team-member-department
//             div.team-member-division
//           div.team-member-contacts-wrapper
//             div.team-member-contacts
//               div.team-member-phone
//               div.team-member-mobile
//               div.team-member-fax
//               div.team-member-email
//         div.team-member-media.team-member-media-(right)
//           img.team-member-photo team-member-photo-(right)
//
//

$img-large:  150px;
$img-medium: 120px;
$img-small:  90px;
$img-xsmall:  60px;

// list widget container
.team-members-list {

    // inner list container (where uk-grid is applied)
    .team-member-items {
        margin: 0;
        padding: 0;
        list-style: none;

        > div {
            margin: 0;
            padding: 0;
        }
    }

    // (article || a).team-member-item (where grid item columns are applied)
    .team-member-item {
        @extend .uk-card;
        @include clearfix;
        margin: 10px 0 20px;
        padding: 0;
    }

    // item card
    &.item-card {
        .team-member-item {
            @extend .uk-card-default;
            @extend .uk-card-body; // 40px
        }
        div > .team-member-item {
            //margin: 10px 0 12px;
            margin: 8px 8px 10px;
        }

        // medium card
        &.item-card-medium {
            .team-member-item {
                padding: 30px;
            }
        }

        // small card
        &.item-card-small {
            .team-member-item {
                padding: 20px;
            }
        }

        // xsmall card
        &.item-card-xsmall {
            .team-member-item {
                padding: 10px 20px;
            }
        }
    }

    // item divider
    &.item-divider {
        .team-member-item {
            border-top: 1px solid $gray-uk;
            margin: 12px 0;
            padding: 15px 0 12px;
        }
        .team-member-items {
            > div:first-child,
            > div:nth-child(1) {
                .team-member-item {
                    border-top: 0;
                    padding-top: 0;
                }
            }
        }
    }

    // photo
    .team-member-photo {
        text-align: center;
        img {
            @extend .uk-card;
            @extend .uk-card-default;
            padding: 5px;
            margin: 0 auto;
        }
    }
    // photo sizes (non-circle)
    // img-large (150px widht)(default)
    &.img-large {
        .team-member-photo {
            width: $img-large; //150px;
        }
    }
    // img-medium (120px width)
    &.img-medium {
        .team-member-photo {
            width: $img-medium; //120px;
        }
    }
    // img-small (90px width)
    &.img-small {
        .team-member-photo {
            width: $img-small;
        }
    }
    // img-small (60px width)
    &.img-xsmall {
        .team-member-photo {
            width: $img-xsmall;
        }
    }
    

    // flexbox wrapper (img-left, img-right, img-top) - (default) img-large (150px)
    .team-member-item-contents {
        width: 100%;
        font-size: 1rem;
        // display: flex;
        // flex-flow: row;

        // Mobile (stacked)
        @media only screen and (max-width: 767px) {
            // image
            .team-member-media-img-left,
            .team-member-media-img-right {
                font-size: 1rem;
                display: block;
                width: 100%;
                // order: 0;
                // width: $img-large; //150px;
                // min-width: $img-large; //150px;
                .team-member-photo {
                    width: $img-large; //150px;
                    // min-width: $img-large; //150px;
                    margin: 0 auto;
                }
            }
            // info
            .team-member-info {
                // order: 0;
                // align-self: stretch;
                // flex-grow: 1;
                // font-size: 1rem;
                padding: 0 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;

                .team-member-info-contents {
                    display: inline-block;
                    margin: 0 auto;
                    text-align: center;

                    .team-members-contacts-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        .team-member-contacts {
                            display: inline-block;
                            text-align: left;
                        }
                    }
                }
            }
        }

        // Desktop (flex)
        @media only screen and (min-width: 768px) {
            display: flex;
            flex-flow: row;

            // inner flex box (strechy box with detail info)
            .team-member-info {
                order: 0;
                align-self: stretch;
                flex-grow: 1;
                font-size: 1rem;
                padding: 0 20px;
            }

            // outer flex boxes - left right media columns
            .team-member-media-img-left,
            .team-member-media-img-right {
                order: 0;
                width: $img-large; //150px;
                min-width: $img-large; //150px;
                font-size: 1rem;
            }
        }
    }
    // large image flex grid
    &.img-large {
        .team-member-item-contents {
            .team-member-media-img-left,
            .team-member-media-img-right {
                // Desktop (flex)
                @media only screen and (min-width: 768px) {
                    width: $img-large; //150px;
                    min-width: $img-large; //150px;
                }
                // // Mobile (stacked)
                // @media only screen and (max-width: 767px) {
                //     .team-member-photo {
                //         width: $img-large; //150px;
                //         height: $img-large; //150px;
                //         margin: 0 auto;
                //     }
                // }
            }
        }
    }
    // image large flex grid
    &.img-medium {
        .team-member-item-contents {
            .team-member-media-img-left,
            .team-member-media-img-right {
                // Desktop (flex)
                @media only screen and (min-width: 768px) {
                    width: $img-medium; //120px;
                    min-width: $img-medium; //120px;
                }
                // // Mobile (stacked)
                // @media only screen and (max-width: 767px) {
                //     .team-member-photo {
                //         width: $img-medium; //120px;
                //         height: $img-medium; //120px;
                //         margin: 0 auto;
                //     }
                // }
            }
        }
    }
    // small large flex grid
    &.img-small {
        .team-member-item-contents {
            .team-member-media-img-left,
            .team-member-media-img-right {
                // Desktop (flex)
                @media only screen and (min-width: 768px) {
                    width: $img-small; //90px;
                    min-width: $img-small; //90px;
                }
                // // Mobile (stacked)
                // @media only screen and (max-width: 767px) {
                //     .team-member-photo {
                //         width: $img-small; //90px;
                //         height: $img-small; //90px;
                //         margin: 0 auto;
                //     }
                // }
            }
        }
    }
    // xsmall large flex grid
    &.img-xsmall {
        .team-member-item-contents {
            .team-member-media-img-left,
            .team-member-media-img-right {
                // Desktop (flex)
                @media only screen and (min-width: 768px) {
                    width: $img-xsmall; //60px;
                    min-width: $img-xsmall; //60px;
                }
                // Mobile (stacked)
                @media only screen and (max-width: 767px) {
                    .team-member-photo {
                        width: $img-xsmall; //60px;
                        height: $img-xsmall; //60px;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
    // image top - override flex
    &.img-top {
        .team-member-item-contents {
            display: block;
            .team-member-info {
                display: block;
                padding: 0;
            }
            .team-member-media-img-top,
            .team-member-media-img-top {
                width: 100%; //90px;
                min-width: 100%; //90px;
                margin-bottom: 10px;
                display: block;
                .team-member-photo {
                    margin: 0 auto;
                }
            }
        }
        // image top custom alignment
        &.img-align-left {
            .team-member-item-contents {
                .team-member-media-img-top,
                .team-member-media-img-top {
                    .team-member-photo {
                        margin: 0;
                    }
                }
            }
        }
    }



    // circle photo - (default) img-large (150px)
    &.img-circle {
        .team-member-photo {
            width: $img-large; // 150px
            height: $img-large; // 150px
            position: relative;
            overflow: hidden;
            border-radius: 50%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 5px;
            border: 5px solid #fff;
            box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
            img {
                padding: 0;
                box-shadow: 0;
                width: 100%;
            }
        }
        // img-large (150px)(default)
        &.img-large {
            .team-member-item-contents {
                .team-member-media-img-left,
                .team-member-media-img-right,
                .team-member-media-img-top {
                    .team-member-photo {
                        width: $img-large; //150px;
                        height: $img-large; //150px;
                    }
                }
            }
        }
        // img-medium (120px)
        &.img-medium {
            .team-member-item-contents {
                .team-member-media-img-left,
                .team-member-media-img-right,
                .team-member-media-img-top {
                    .team-member-photo {
                        width: $img-medium; //120px;
                        height: $img-medium; //120px;
                    }
                }
            }
        }
        // img-small (90px)
        &.img-small {
            .team-member-item-contents {
                .team-member-media-img-left,
                .team-member-media-img-right,
                .team-member-media-img-top {
                    .team-member-photo {
                        width: $img-small;
                        height: $img-small;
                    }
                }
            }
        }
        // img-xsmall (60px)
        &.img-xsmall {
            .team-member-item-contents {
                .team-member-media-img-left,
                .team-member-media-img-right,
                .team-member-media-img-top {
                    .team-member-photo {
                        width: $img-xsmall;
                        height: $img-xsmall;
                    }
                }
            }
        }
    }
    


    // Name (h1-div)
    .team-member-title {
        @extend .uk-card-title;
        margin: 0;
        a {
            text-decoration: underline;

            &:hover,
            &:active,
            &:focus {
                color: $red;
                text-decoration: underline;
                border-bottom: 1px solid $red;
            }
        }
    }
    // full name / title small
    &.item-title-small .team-member-title {
        font-size: 1.25rem;
    }
    // full name / title medium
    &.item-title-medium .team-member-title {
        font-size: 2.21rem;
    }
    // full name / title large
    &.item-title-large .team-member-title {
        font-size: 2.625rem;
    }

    // position / job title
    .team-member-position,
    .team-member-position p {
        font-size: 1rem;
        margin: 0;
        padding: 10px 0 0 0;
    }

    // additional info section
    .team-member-additional-info {
        padding-top: 15px;
        font-size: 1rem;
        .team-member-entity,
        .team-member-department,
        .team-member-division {
            font-size: .8rem;
        }
    }

    // contacts
    .team-member-contacts {
        padding-top: 15px;
        font-size: 1rem;
        .team-member-phone,
        .team-member-mobile,
        .team-member-fax,
        .team-member-email {
            font-size: .8rem;
        }
    }


    // Link Card
    &.team-member-link-card {
        .team-member-item {
            display: block;
            text-decoration: none;

            .team-member-title {
                text-decoration: underline;
            }

            .team-member-read-more span {
                text-decoration: underline;
            }

            &:hover,
            &:focus,
            &:active
            {
                cursor:pointer;
                border-bottom: 1px solid $red;
                box-shadow: 0 14px 25px rgba(0, 0, 0, .16);
                text-decoration: none;

                .team-member-title {
                    text-decoration: underline;
                    color: $red !important;
                }

                .team-member-read-more span {
                    text-decoration: underline;
                    color: $red;
                }
            }
        }
    }


    // info content vertical alignment (img-left & img-right)
    &.info-valign-center {
        .team-member-item {
            .team-member-info {
                display: flex;
                justify-content: left;
                align-items: center;
            }
        }
    }



    // Alignment Classes
    // =================================

    // Title/Name Alignment
    &.title-left .team-member-item .team-member-title,
    &.name-left .team-member-item .team-member-title {
        text-align: left;
    }
    &.title-right .team-member-item .team-member-title,
    &.name-right .team-member-item .team-member-title {
        text-align: right;
    }
    &.title-center .team-member-item .team-member-title,
    &.name-center .team-member-item .team-member-title {
        text-align: center;
    }
    // Position Alignment
    &.position-left .team-member-position,
    &.position-left .team-member-position p {
        text-align: left;
    }
    &.position-right .team-member-position,
    &.position-right .team-member-position p {
        text-align: right;
    }
    &.position-center .team-member-position,
    &.position-center .team-member-position p {
        text-align: center;
    }
    // Additional Info Alignment
    &.add-info-left .team-member-additional-info {
        text-align: left;
    }
    &.add-info-right .team-member-additional-info {
        text-align: right;
    }
    &.add-info-center .team-member-additional-info {
        text-align: center;
    }
    // Contacts Alignment
    &.contacts-left .team-member-contacts {
        text-align: left;
    }
    &.contacts-right .team-member-contacts {
        text-align: right;
    }
    &.center,
    &.text-center,
    &.contacts-center {
        .team-members-contacts-wrapper {
            display: flex;
            align-items: center;
        }
        .team-member-contacts {
            display: inline-block;
            margin: auto auto !important;

            .team-member-phone,
            .team-member-fax,
            .team-member-mobile,
            .team-member-email {
                text-align: left;
            }
        }
    }
    &.contacts-items-center,
    &.contacts-item-center {
        .team-members-contacts-wrapper {
            .team-member-contacts {
                .team-member-phone,
                .team-member-fax,
                .team-member-mobile,
                .team-member-email {
                    text-align: center !important;
                }
            }
        }
    }
    &.contacts-inline .team-member-contacts div {
        display: inline-block;
        margin: 0 2px 0 0;
    }




}





/* Mobile Handling For Left Handed Images */

// @media only screen and (max-width: $utmb-small-max) {
//     .team-members-list {
//         img {
//             margin-bottom: 10px
//         }
//         .uk-width-1-5 {
//             width: 100%;
//         }
//         .uk-width-1-4 {
//             width: 100%;
//         }
//     }
// }



// // Icon Colors
// .team-members-list.list-item-icon-red .team-member-items .list-item-icon,
// .team-members-list .team-member-items .list-item-icon-red .list-item-icon {
//     color: $red !important;
// }
// .team-members-list.list-item-icon-gray .team-member-items .list-item-icon,
// .team-members-list .team-member-items .list-item-icon-gray .list-item-icon {
//     color: $gray !important;
// }
// .team-members-list.list-item-icon-gray-light .team-member-items .list-item-icon,
// .team-members-list .team-member-items .list-item-icon-gray-light .list-item-icon {
//     color: $gray-light !important;
// }
// .team-members-list.list-item-icon-teal .team-member-items .list-item-icon,
// .team-members-list .team-member-items .list-item-icon-teal .list-item-icon {
//     color: $teal !important;
// }
// .team-members-list.list-item-icon-teal-dark .team-member-items .list-item-icon,
// .team-members-list .team-member-items .list-item-icon-teal-dark .list-item-icon {
//     color: $teal-dark !important;
// }
// .team-members-list.list-item-icon-teal-light .team-member-items .list-item-icon,
// .team-members-list .team-member-items .list-item-icon-teal-light .list-item-icon {
//     color: $teal-light !important;
// }
// .team-members-list.list-item-icon-blue .team-member-items .list-item-icon,
// .team-members-list .team-member-items .list-item-icon-blue .list-item-icon {
//     color: $blue !important;
// }
// .team-members-list.list-item-icon-blue-dark .team-member-items .list-item-icon,
// .team-members-list .team-member-items .list-item-icon-blue-dark .list-item-icon {
//     color: $blue-dark !important;
// }
// .team-members-list.list-item-icon-blue-light .team-member-items .list-item-icon,
// .team-members-list .team-member-items .list-item-icon-blue-light .list-item-icon {
//     color: $blue-light !important;
// }
// .team-members-list.list-item-icon-orange .team-member-items .list-item-icon,
// .team-members-list .team-member-items .list-item-icon-orange .list-item-icon {
//     color: $orange !important;
// }
// .team-members-list.list-item-icon-orange-mid .team-member-items .list-item-icon,
// .team-members-list .team-member-items .list-item-icon-orange-mid .list-item-icon {
//     color: $orange-mid !important;
// }
// .team-members-list.list-item-icon-orange-light .team-member-items .list-item-icon,
// .team-members-list .team-member-items .list-item-icon-orange-light .list-item-icon {
//     color: $orange-light !important;
// }
// .team-members-list.list-item-icon-green .team-member-items .list-item-icon,
// .team-members-list .team-member-items .list-item-icon-green .list-item-icon {
//     color: $green !important;
// }
// .team-members-list.list-item-icon-green-dark .team-member-items .list-item-icon,
// .team-members-list .team-member-items .list-item-icon-green-dark .list-item-icon {
//     color: $green-dark !important;
// }
// .team-members-list.list-item-icon-green-light .team-member-items .list-item-icon,
// .team-members-list .team-member-items .list-item-icon-green-light .list-item-icon {
//     color: $green-light !important;
// }



