﻿// UTMB Screen Size Helpers
// *See ./src/assets/scss/variables/_screens.scss

$utmb-xsmall: $screen-xs-min; // 480px - Mobile
$utmb-small: $screen-sm-min; // 768px - Desktop/Tablet
$utmb-sweetspot: $screen-md-min; // 992px - Desktop - **Where the magic happens ... err ... the UTMB nav changes! - $mgcooper
$utmb-large: $screen-lg-min; // 1200px - Desktop Large

// So media queries don't overlap when required, provide a maximum

$utmb-xsmall-max: ($utmb-xsmall - 1); // 479px
$utmb-small-max: ($utmb-small - 1); // 767px
$utmb-sweetspot-max: ($utmb-sweetspot - 1); // 991px
$utmb-large-max: ($utmb-large - 1); // 1199px


// Visiblity
.show {
    display: '';
}
.hide {
    display: none;
}


// HIDE
@media only screen and (max-width: $utmb-xsmall-max) {
    .hide-xsmall {
        display: none !important;
    }
}
@media only screen and (max-width: $utmb-small-max) {
    .hide-small {
        display: none !important;
    }
}
@media only screen and (max-width: $utmb-sweetspot-max) {
    .hide-sweetspot,
    .hide-desktop {
        display: none !important;
    }
}
@media only screen and (max-width: $utmb-large-max) {
    .hide-large {
        display: none !important;
    }
}


// SHOW
@media only screen and (min-width: $utmb-xsmall) {
    .show-xsmall {
        display: '' !important;
    }
}
@media only screen and (min-width: $utmb-small) {
    .show-small {
        display: '' !important;
    }
}
@media only screen and (min-width: $utmb-sweetspot) {
    .show-sweetspot,
    .show-desktop {
        display: '' !important;
    }
}
@media only screen and (min-width: $utmb-large) {
    .show-large {
        display: '' !important;
    }
}