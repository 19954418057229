//
// Page Containers - by Design Mode
//

// Classic Design Mode
@mixin page-container-classic() {
    @include clearfix;
    margin: 0 auto;
    max-width: $container-classic;
}

// Modern Design Mode
@mixin page-container-modern() {
    @include clearfix;
    margin: 0 auto;
    max-width: $container-modern;
}
