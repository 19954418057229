﻿// Coments UI using UIKit
//

.comments-count-wrapper {
    a {
        @extend .uk-button;
        @extend .uk-button-text;
    }
}

.sf-Comments-header {
    margin-bottom: 0;
    margin-top: 100px;

    a[data-sf-role="comments-new-form-button"] {
        @extend .uk-button;
        @extend .uk-button-text;
    }

    .pull-right {
        @extend .uk-article-meta;
    }

    a[data-sf-role="comments-sort-old-button"],
    a[data-sf-role="comments-sort-new-button"] {
        @extend .uk-button;
        @extend .uk-button-text;
        margin-left: 15px;
        text-transform: none;

        &.selected {
            color: $red;
        }
    }
}

div[data-sf-role="comments-container"],
.sf-Comments-list {

    .sf-media {
        @extend .uk-article-meta; //font-size: .875rem;
        border-top: 1px solid #e5e5e5;
        margin: 15px 0px;
        padding: 25px 0 12px;

        &:first-child {
            border-top: 0;
            padding-top: 0;
        }


        .sf-img-thmb {
            min-width: 60px;

            img {
                width: 40px;
                height: 40px;
            }
        }

        .sf-media-body {
            span[data-sf-role="comment-name"],
            .sf-Comments-list-author {
                color: $gray;
                font-weight: bold;
                padding-right: 10px;
                margin-right: 5px;
            }

            span[data-sf-role="comment-date"] {
            }

            p[data-sf-role="comment-message"] {
                color: $gray;
                margin: 0;
                margin-top: 15px;
                padding: 0;
            }
        }
    }
}

.sf-Comments-form {

    div[data-sf-role="comments-new-pending-approval-message"],
    .alert.alert-warning {
    }

    .has-error {
        .help-block {
            color: $red;
            font-size: .8rem;
        }
    }

    .form-group {
        textarea,
        textarea.form-control,
        textarea[data-sf-role="comments-new-message"] {
            @extend .uk-textarea;
        }
    }

    input,
    input.form-control,
    input[data-sf-role="comments-new-name"],
    input[data-sf-role="comments-new-email"] {
        @extend .uk-input;
    }

    button,
    button.btn.btn-primary,
    button[data-sf-role="comments-new-submit-button"] {
        @extend .uk-button;
        @extend .uk-button-default;
        border-radius: 0;

        &:hover,
        &:active,
        &:focus {
            background-color: #338042; //$green;
            border-color: #338042;
            color: #fff;
        }
    }
}