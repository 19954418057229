﻿// List All
// *see base/typography.scss for list styles
//


/*
    All lists
        Title Hide (item-title-hide)

    Accordion (list-accordion)
        Accordion Image Right (list-item-image-right)
        Accordion Image Left (list-item-image-left)
        Accordion Panel (list-panel)

    Anchor List (list-anchor)

    Expandable List (list-expandable)

    Expanded List (list-expanded)
        Divider (list-divider)
        Striped (list-striped)

    Link Card (list-link-card)
        Title Medium (list-item-title-medium)
        Title Small (list-item-title-small)
        Icon Right (list-item-icon-right)
        Icon Left (list-item-icon-left)
        Image Right (list-item-image-right)
        Image Left (list-item-image-left)
        Image Max Width 50 (list-item-image-max-50)
        Image Max Width 100 (list-item-image-max-100)
        Image Max Width 150 (list-item-image-max-150)
        Image Max Width 200 (list-item-image-max-200)
        Image Max Width 250 (list-item-image-max-250)
        Image Max Width 300 (list-item-image-max-300)

    Link List (list-link-list)
        Icon Top (list-icon-top)
        Image Top (list-image-top)
        Button (list-btn)
            Block (list-btn-block)
            Red (list-btn-red)
            Gray (list-btn-gray)
            Blue (list-btn-blue)
            Green (list-btn-green)
            Orange (list-btn-orange)
            Purple (list-btn-purple)
            Teal (list-btn-teal)
            CoolGray3 (list-btn-coolgray3)
            Black (list-btn-black)
            White (list-btn-white)

    Pages List (list-pages)

    Panel List (list-panel-list)

    People List (list-people)
        Title Show (list-item-title-show)

    Simple List (list-simple)

    Tabs List
        Buttons (tabs-buttons - default)
        Modern (tabs-modern)
        Image Right (list-item-image-right)
        Image Left (list-item-image-left)
        Image Card (list-item-image-card)
*/



// All list widgetsf
.lists {

    // center grid childrenz
    .uk-grid-small,
    .uk-grid-medium,
    .uk-grid-large,
    .uk-grid-collapse {
        justify-content: center;
    }
    
    // title
    .list-title {
        font-family: $font-family-condensed;
        font-size: 1.5rem;
        @extend .sr-only; // hide from view
    }

    // hide title
    &.item-title-hide,
    &.list-item-title-hide {
        .list-item-title {
            display: none !important;
        }
    }

    // list item title sizing
    // 1.25rem (small)
    // 1.618rem (regular)
    // 2.21rem (medium)
    // 2.625rem (large)
    &.item-title-small {
        .list-item-title {
            font-size: 1.25rem !important;
        }
    }
    &.item-title-regular {
        .list-item-title {
            font-size: 1.618rem !important;
        }
    }
    &.item-title-medium {
        .list-item-title {
            font-size: 2.21rem !important;
        }
    }
    &.item-title-large {
        .list-item-title {
            font-size: 2.625rem !important;
        }
    }

    // UK-Grid First Column Fix
    .list.uk-grid {
        .uk-first-column:first-child {
            margin-top: 0; // 10px;
        }
    }

    

    // Accordion
    &.list-accordion {
        .content-toggle {
            text-align: right;
            margin-bottom: 15px;
            font-size: .888rem;
        }
        li {
            > a {
                font-size: 1rem;
                text-decoration: none;
                -o-transition: .5s;
                -ms-transition: .5s;
                -moz-transition: .5s;
                -webkit-transition: .5s;
                transition: .5s;

                &:hover,
                &:active,
                &:focus {
                    color: $red;
                    border: 0;
                }

                h1, h2, h3, h4, h5, h6 {
                    margin: 0 !important;
                    padding: 0 !important;
                    font-size: inherit !important;
                    font-family: inherit !important;
                    color: inherit !important;
                }
            }

            .list-accordion-content {
                margin-top: 0;
                margin-bottom: 4px;
                padding: 18px;
            }

            .list-item-content {
                margin-bottom: 30px;

                ul, li {
                    border: 0;

                    a {
                        text-decoration: underline;
                        padding: 0;
                    
                        &:hover,
                        &:active {
                            color: $global-link-hover-color;
                            text-decoration: underline;
                            border-bottom: 1px solid $red;
                        }
                    }
                }
            }

            .list-item-media-container {
                display: block;
                margin-bottom: 18px;

                img {
                    @extend .uk-card;
                    @extend .uk-card-default;
                    margin-bottom: 15px;
                    padding: 5px;
                }
            }
        }

        &.list-item-image-right {
            li {
                .list-item-media-container {
                    img {
                        float: right;
                        margin-bottom: 15px;
                        margin-left: 15px;
                    }
                }
            }
        }

        &.list-item-image-left {
            li {
                .list-item-media-container {
                    img {
                        float: left;
                        margin-bottom: 15px;
                        margin-right: 15px;
                    }
                }
            }
        }
        // Accordion Panel
        &.list-panel {
            li {
                border: 1px solid #e5e5e5;
                margin-bottom: 2px;
                margin-top: 3px;
                padding-bottom: 0px;

                > a {
                    padding: 12px 12px;
                }

                &.uk-open {
                    > a {
                        border-bottom: 1px solid #e5e5e5;
                        padding: 8px 15px;

                        &:active,
                        &:focus {
                            border-color: $red;
                        }
                    }
                }

                ul {
                    border: 0;
                    li {
                        border: 0;
                        a {
                            padding: 0;
                            border: 0;
                        }
                    }
                }
            }
        }
    }
    // Anchor List
    &.list-anchor {
        .list-anchor-links {
            margin-bottom: 70px;

            .list {
                //@extend .uk-list-bullet;
                list-style-type:disc;
                padding-left: 30px;

                li {
                    a {
                        -o-transition: .5s;
                        -ms-transition: .5s;
                        -moz-transition: .5s;
                        -webkit-transition: .5s;
                        transition: .5s;

                        &:hover,
                        &:active,
                        &:focus {
                            color: $red;
                        }
                    }
                }
            }
        }

        .list-anchor-content {
            .list {
                li {
                    margin-top: 20px;

                    .list-item-title {
                        @extend .h4;
                    }

                    // .list-item-content {
                    // }

                    .back-to-top {
                        font-size: 0.8rem;
                        margin-top: 10px;
                        text-align: right;
                    }
                }
            }
        }

        &.list-divider {
            .list-anchor-content {
                .list {
                    li {
                        margin-top: 20px;
                        padding-top: 20px;
                        border-top: 1px solid #e5e5e5;

                        ul {
                            li {
                                padding-top: 0;
                                border-top: 0;
                            }
                        }
                    }
                }
            }
            .list {
                li {
                    ul {
                        li {
                            padding-top: 0;
                            border-top: 0;
                        }
                    }
                }
            }
        }
    }
    // Expandable List
    &.list-expandable {
        .content-toggle {
            text-align: right;
            margin-bottom: 15px;
            font-size: .888rem;
        }
        .list-accordion-content {
            a,
            ul li a,
            ul.list li a,
            ul.uk-list li a {
                text-decoration: underline;
            
                &:hover,
                &:active {
                    color: $global-link-hover-color;
                    text-decoration: underline;
                    border-bottom: 1px solid $red;
                }
            }
            ul, ol,
            ul li, ol li {
                border: 0;
            }
        }
    }
    // Expanded List
    &.list-expanded {
        .list {
            li {
                margin-top: 20px;
                line-height: 1.5;

                &:first-child {
                    margin-top: 0;
                }

                .list-item-title {
                    @extend .h4;
                }
            }

            &.list-divider {
                li {
                    margin-top: 20px;
                    padding-top: 20px;

                    &:first-child {
                        margin-top: 0;
                        padding-top: 0;
                    }
                }
            }

            &.list-striped {
                li {
                    padding: 20px 18px;
                }
            }
        }
    }
    // Link Card (uses divs for grid)
    &.list-link-card {
        .list-link-card-container {
            display: block;
            margin-bottom: 20px;
            text-decoration: none;

            .list-item-icon {
                color: #111111;
                font-size: 369%;
                margin: 5px 0 10px;
            }

            img {
                max-width: 100%;
                margin: 5px 0 10px;
            }
        }

        a.list-link-card-container {
            color: #333333;
            display: block;
            text-decoration: none;

            &:hover,
            &:active,
            &:focus {
                color: #111111;

                .list-item-icon {
                    color: $red;
                }
            }
        }

        .list-link-card-inner-container {
            @include clearfix();
        }

        // .list-item-content {
        // }

        .list-item-title {
            @extend .h4;
            display: block;
            font-size: 1.618rem; //FYI
            margin: 0;
            padding: 0;
        }

        &.list-item-title-medium {
            .list-item-title {
                font-size: 1.11rem;
            }
        }

        &.list-item-title-small {
            .list-item-title {
                font-size: .936rem;
            }
        }

        .list-item-content.item-details {
            display: block;
            margin-bottom: 0 !important;
        }

        .list-item-content-text {
            display: block;
            padding-top: 15px;
        }

        .list-item-content-html {
            display: block;
            padding-top: 15px;
        }

        // Icon Right
        &.list-item-icon-right {
            .list-link-card-container {
                .list-item-icon {
                    float: right;
                    margin-bottom: 20px;
                    margin-left: 20px;
                }
            }
        }

        // Icon Left
        &.list-item-icon-left {
            .list-link-card-container {
                .list-item-icon {
                    float: left;
                    margin-bottom: 20px;
                    margin-right: 20px;
                }
            }
        }

        // Image Right
        &.list-item-image-right {
            .list-link-card-container {
                img {
                    float: right;
                    margin-bottom: 20px;
                    margin-left: 20px;
                }
            }
        }

        // Image Left
        &.list-item-image-left {
            .list-link-card-container {
                img {
                    float: left;
                    margin-bottom: 20px;
                    margin-right: 20px;
                }
            }
        }

        // Image Max Widths
        &.list-item-image-max-50 {
            .list-link-card-container {
                img {
                    max-width: 50px;
                }
            }
        }
        &.list-item-image-max-100 {
            .list-link-card-container {
                img {
                    max-width: 100px;
                }
            }
        }
        &.list-item-image-max-150 {
            .list-link-card-container {
                img {
                    max-width: 150px;
                }
            }
        }
        &.list-item-image-max-200 {
            .list-link-card-container {
                img {
                    max-width: 200px;
                }
            }
        }
        &.list-item-image-max-250 {
            .list-link-card-container {
                img {
                    max-width: 250px;
                }
            }
        }
        &.list-item-image-max-300 {
            .list-link-card-container {
                img {
                    max-width: 300px;
                }
            }
        }
    }
    // Link List
    &.list-link-list {

        .list-item-title {
            font-family: $font-family-base;
            font-size: 1rem;
            margin-bottom: 0;
            color: inherit;
        }

        // No Link
        .list-link-list-container {
            color: #333333;
            text-decoration: none;

            i {
                color: #111111;
                font-size: 1.25em;
                margin-right: 4px;
            }

            img {
                float: left;
                margin-right: 13px;
                max-width: 70px !important;
            }

            .list-item-title {
                display: inline-block;
            }
        }

        // Link
        a.list-link-list-container {
            text-decoration: none;
            
            .list-item-title {
                text-decoration: underline;
                display: inline-block;
            }
            i, em {
                color: inherit;
            }

            &:hover,
            &:focus,
            &:active {
                text-decoration: none;

                .list-item-title {
                    color: $global-link-hover-color;
                    text-decoration: underline;
                    // border-bottom: 1px solid $red;
                }

                i {
                    text-decoration: none;
                }
            }
        }

        // Inline List
        &.list-inline {
            ul.list,
            li {
                display: inline;

                &:before,
                &:after {
                    display: inline;
                }
            }
        }

        // Inline List + Button
        &.list-inline.list-btn {
            .list-link-list-container,
            a.list-link-list-container {
                margin: 3px 4px 6px 0;
                text-decoration: none;
                .list-item-title {
                    text-decoration: none;
                }
                i, em {
                    color: inherit;
                }
            }
        }

        // Button
        &.list-btn {
            .list-link-list-container,
            a.list-link-list-container {
                @extend .btn;
                text-decoration: none;
                line-height: normal;
                padding: 15px;
                .list-item-title {
                    text-decoration: none;
                }
                i, em {
                    color: inherit;
                }

                &:hover, 
                &:active,
                &:focus {
                    text-decoration: none !important;

                    .list-item-title {
                        //color: $red;
                        text-decoration: none !important;
                        border-bottom: 0 !important;
                    }
                }

                &.disabled {
                    cursor: default;
                    .list-item-title {
                        text-decoration: none;
                    }
    
                    &:hover,
                    &:focus,
                    &:active {
                        border-color: transparent;
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            border-bottom: 0;
                        }
                    }
                }
            }


            // Button Block
            &.list-btn-block {
                .list-link-list-container,
                a.list-link-list-container {
                    display: block;
                }
            }

            // Button Red
            &.list-btn-red {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-red;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            color: $white;
                            text-decoration: none;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Gray
            &.list-btn-gray {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-gray;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            color: $white;
                            text-decoration: none;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Gray Light
            &.list-btn-gray-light {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-gray-light;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            color: $gray-3;
                            text-decoration: none;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Blue
            &.list-btn-blue {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-blue;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $white;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Blue Light
            &.list-btn-blue-light {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-blue-light;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: darken($blue, 30%);
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Blue Dark
            &.list-btn-blue-dark {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-blue-dark;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $white;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Green
            &.list-btn-green {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-green;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $white;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Green Dark
            &.list-btn-green-dark {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-green-dark;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $white;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Green Light
            &.list-btn-green-light {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-green-light;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: darken($green, 30%);
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Orange
            &.list-btn-orange {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-orange;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $white;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Orange Mid
            &.list-btn-orange-mid {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-orange-mid;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $white;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Orange Light
            &.list-btn-orange-light {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-orange-light;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: darken($orange, 30%);
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Purple
            &.list-btn-purple {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-purple;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $white;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Teal
            &.list-btn-teal {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-teal;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $white;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Teal Dark
            &.list-btn-teal-dark {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-teal-dark;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $white;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Teal Light
            &.list-btn-teal-light {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-teal-light;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: darken($teal, 30%);
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button CoolGray3
            &.list-btn-coolgray3 {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-coolgray3;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }

                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $black;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Black
            &.list-btn-black {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-black;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $white;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button White
            &.list-btn-white {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-white;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $black;
                            border-bottom: 0;
                        }
                    }
                }
            }

            //
            // List Item Button Color Override
            //

            // Button Red
            .list-item-btn-red {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-red;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $white;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Gray
            .list-item-btn-gray {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-gray;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $white;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Blue
            .list-item-btn-blue {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-blue;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $white;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Green
            .list-item-btn-green {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-green;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $white;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Orange
            .list-item-btn-orange {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-orange;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }

                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $white;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Purple
            .list-item-btn-purple {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-purple;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $white;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Teal
            .list-item-btn-teal {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-teal;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $white;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button CoolGray3
            .list-item-btn-coolgray3 {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-coolgray3;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $black;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button Black
            .list-item-btn-black {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-black;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $white;
                            border-bottom: 0;
                        }
                    }
                }
            }

            // Button White
            .list-item-btn-white {
                .list-link-list-container,
                a.list-link-list-container {
                    @extend .btn-white;
                    text-decoration: none;
                    .list-item-title {
                        text-decoration: none;
                    }
                    &:hover, 
                    &:active,
                    &:focus {
                        text-decoration: none;
                        .list-item-title {
                            text-decoration: none;
                            color: $black;
                            border-bottom: 0;
                        }
                    }
                }
            }
            
        }
        
        // Has Image
        .list-has-image {
            @include clearfix;
            margin-bottom: 18px;

            .list-item-title {
                font-weight: bold;
                margin-bottom: 7px;
            }
        }

        // Has Icon
        .list-has-icon {
            a.list-link-list-container {
                text-decoration: none !important;

                .list-item-title {
                    text-decoration: underline;
                }

                &:hover, 
                &:active,
                &:focus {
                    text-decoration: none !important;
                    border-bottom: 0 !important;

                    .list-item-title {
                        color: $global-link-hover-color;
                        text-decoration: underline;
                        border-bottom: 1px solid $red;
                    }
                }
            }
        }

        // Link List Icon Overrides
        &.list-icon-top,
        &.icon-top {
            i {
                //color: #111111;
                font-size: 369%;
                margin: 5px 0 10px;
            }
        }

        // Link List Image Overrides
        &.list-image-top,
        &.img-top {
            img {
                display: block;
                float: none;
                clear: both;
                margin-bottom: 5px;
                margin-right: 0;
                max-width: 100% !important;
            }
        }

    }
    // Pages List
    &.list-pages {

        .list-item-title {
            font-family: $font-family-base;
            font-size: 1rem;
            margin-bottom: 0;
            color: inherit;
        }

        a {
            text-decoration: underline;
            color: #333333;

            &:hover,
            &:active,
            &:focus {
                color: $red;
            }
        }
    }
    // Panel List (uses divs for grid)
    &.list-panel-list {
        .list {
            .list-panel-list-container {
                border: 1px solid #e5e5e5;
                

                &.uk-card.uk-card-default {
                    border: 0;
                }

                .list-item-media-container {
                    display: block;
                    margin-bottom: 18px;
                    text-align: center;

                    .list-item-icon {
                        color: #111111;
                        font-size: 369%;
                        margin: 5px 0 10px;
                    }

                    img {
                        max-width: 100%;
                    }

                    i {
                        //color: #111111;
                        font-size: 369%;
                        // margin: 5px 0 10px;
                    }

                    i, em {
                        color: inherit;
                    }
                }

                .list-panel-title {
                    @extend .h4;
                    border-bottom: 1px solid #e5e5e5;
                    margin: 0;
                    padding: 12px 18px;
                    //font-family: $font-family-base;
                    color: inherit;
                }

                .list-panel-content {
                    margin-top: 0;
                    margin-bottom: 4px;
                    padding: 18px;
                }
            }

            // &[grid],
            // &[grid="masonry: true"] {
            //     .list-panel-list-container {
            //     }
            // }

            &[data-no-grid] {
                .list-panel-list-container {
                    margin-bottom: 15px;
                    margin-top: 3px;
                }
            }
        }
    }
    // People List
    &.list-people {
        .list {
            .list-people-container {

                .list-people-title {
                    @extend .h4;
                    display: none;
                    margin: 0;
                    padding: 0;
                    color: inherit;
                }

                .list-item-media-container {
                    display: block;
                    margin-bottom: 18px;
                    text-align: center;

                    .list-item-icon,
                    i, em {
                        color: #111111;
                        color: inherit;
                        font-size: 369%;
                        margin: 5px 0 10px;
                    }

                    img {
                        max-width: 100%;
                    }
                }

                .list-people-content {

                    a {
                        //font-size: 13px;
                        @include convertPixelsToRem_fontSize(13); // output: font-size: ($value / 16) + rem;

                        span {
                            //font-size: 11px;
                            @include convertPixelsToRem_fontSize(11); // output: font-size: ($value / 16) + rem;
                        }

                        // strong {
                        // }

                        // &:hover,
                        // &:focus,
                        // &:active {
                        // }
                    }

                    .list-item-icon {
                        color: $gray-mid;
                        color: inherit;
                        display: block;
                        line-height: normal;
                        margin: 0;
                        padding-top: 3px;
                        //font-size: 10px;
                        @include convertPixelsToRem_fontSize(10); // output: font-size: ($value / 16) + rem;

                        a {
                            //font-size: 10px;
                            color: $gray-mid;

                            &:hover,
                            &:focus,
                            &:active {
                                color: $red;
                            }
                        }
                    }
                }

                &.uk-card.uk-card-default {
                    padding: 18px;
                }
            }

            // &[grid],
            // &[grid="masonry: true"] {
            //     .list-panel-list-container {
            //     }
            // }

            &[data-no-grid] {
                .list-people-container {
                    margin-bottom: 15px;
                    margin-top: 3px;
                }
            }
        }
        // show title
        &.list-item-title-show {
            .list {
                .list-people-container {
                    .list-people-title {
                        display: block;
                        padding: 0 0 7px;
                    }
                }
            }
        }
    }
    // Simple List
    &.list-simple {
        .list {
            .list-item-title {
                font-family: $font-family-base;
                font-size: 1rem;
                margin-bottom: 0;
                color: inherit;
            }
        }
    }
    // Tabs List
    &.list-tabs {
        // Classic Buttons
        &.tabs-buttons {
            .uk-tab {
                margin-left: 0;

                li {
                    padding-left: 0px;
                    padding-right: 5px;

                    a {
                        border: 1px solid #e5e5e5;
                        //border-radius: 4px 4px 0 0;
                        padding: 10px 13px 7px;
                        text-transform: none;
                        font-size: 1em;
                        background-color: $teal;
                        color: #fff;
                        padding: 15px;
    
                        &:hover,
                        &:active {
                            background: $gray;
                        }
    
                        &.active {
                            border-bottom: 1px solid #ffffff;
                            background-color: #fff !important;
                            color: $red;
                            font-weight: bold;
                        }
                    }

                    &.uk-active {
                        a {
                            border-bottom: 1px solid #ffffff !important;
                            background-color: #fff !important;
                            color: $red;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
        // Modern
        &.tabs-modern {
            .uk-tab > .uk-active > a {
                color: $red;
                border-bottom: 1px solid $red;
            }
        }

        &.list-nav-tab {
            &.list-nav-tabs {
                border-bottom: 0; // override bootstrap bottom border (just in case)
            }
        
            a {
                text-decoration: none;
            }
            // Tabs: Buttons - Classic Folder Tab Style
            &.tabs-buttons {
                .uk-tab {
                    li {
                        padding-left: 0px;
                        padding-right: 5px;
        
                        a {
                            border: 1px solid #e5e5e5;
                            //border-radius: 4px 4px 0 0;
                            padding: 10px 13px 7px;
                            text-transform: none;
        
                            &.active {
                                border-bottom: 1px solid #ffffff;
                            }
                        }
                    }
                }
            }
        }


        // Mobile Handling
        // A Dropdown menu is created for each instance
        // Dropdown is displayed for small screens (sweetspot-max) and hidden at desktop (sweetspot)
        // Horizontal menu is hidden for small screens (sweetspot-max) and displayed at desktop (sweetspot)

        @media only screen and (max-width: $utmb-sweetspot-max) {
            .list-nav-tab {
                .nav-mobile-dropdown {
                    // display: block;
                    // padding: 15px 0;
                    display: none;
                }

                // ul {
                //     display: none;
                // }
                // mobile override
                // &.no-mobile-menu {
                //     .nav-mobile-dropdown {
                //         display: none;
                //     }

                //     ul {
                //         //display: block;
                //         display: flex;
                //     }
                // }
            }
        }

        @media only screen and (min-width: $utmb-sweetspot) {
            .list-nav-tab {
                .nav-mobile-dropdown {
                    display: none;
                }

                // ul {
                //     //display: block;
                // }
            }
        }


        img {
            @extend .uk-card;
            @extend .uk-card-default;
            margin-bottom: 15px;
            padding: 5px;
        }

        &.list-item-image-right {
            img {
                float: right;
                margin-bottom: 15px;
                margin-left: 15px;
            }
        }

        &.list-item-image-left {
            img {
                float: left;
                margin-bottom: 15px;
                margin-right: 15px;
            }
        }

        &.list-item-image-card {
            @extend .uk-card;
            @extend .uk-card-default;
            padding: 5px;
        }

        // List Tabs Colors
        &.tabs-red {
            &.tabs-modern .uk-tab > .uk-active > a {
                color: $red;
                border-color: $red;
            }
            // Tabs: Buttons - Classic Folder Tab Style
            &.tabs-buttons .uk-tab li a {
                background-color: $red;
                &:hover,
                &:active {
                    background: $gray;
                }
            }
        }
        &.tabs-gray {
            &.tabs-modern .uk-tab > .uk-active > a {
                color: $gray;
                border-color: $gray;
            }
            // Tabs: Buttons - Classic Folder Tab Style
            &.tabs-buttons .uk-tab li a {
                background-color: $gray;
                &:hover,
                &:active {
                    background: $gray-1;
                }
            }
        }
        &.tabs-gray-light {
            &.tabs-modern .uk-tab > .uk-active > a {
                color: $gray;
                border-color: $gray-light;
            }
            // Tabs: Buttons - Classic Folder Tab Style
            &.tabs-buttons .uk-tab li a {
                color: $gray;
                background-color: $gray-light;
                &:hover,
                &:active {
                    background: $gray;
                }
            }
        }
        &.tabs-teal {
            &.tabs-modern .uk-tab > .uk-active > a {
                color: $teal;
                border-color: $teal;
            }
            // Tabs: Buttons - Classic Folder Tab Style
            &.tabs-buttons .uk-tab li a {
                background-color: $teal;
                &:hover,
                &:active {
                    background: $gray;
                }
            }
        }
        &.tabs-teal-dark {
            &.tabs-modern .uk-tab > .uk-active > a {
                color: $teal-dark;
                border-color: $teal-dark;
            }
            // Tabs: Buttons - Classic Folder Tab Style
            &.tabs-buttons .uk-tab li a {
                background-color: $teal-dark;
                &:hover,
                &:active {
                    background: $gray;
                }
            }
        }
        &.tabs-teal-light {
            &.tabs-modern .uk-tab > .uk-active > a {
                color: $teal;
                border-color: $teal-light;
            }
            // Tabs: Buttons - Classic Folder Tab Style
            &.tabs-buttons .uk-tab li a {
                color: $gray;
                background-color: $teal-light;
                &:hover,
                &:active {
                    background: $gray;
                }
            }
        }
        &.tabs-blue {
            &.tabs-modern .uk-tab > .uk-active > a {
                color: $blue;
                border-color: $blue;
            }
            // Tabs: Buttons - Classic Folder Tab Style
            &.tabs-buttons .uk-tab li a {
                background-color: $blue;
                &:hover,
                &:active {
                    background: $gray;
                }
            }
        }
        &.tabs-blue-dark {
            &.tabs-modern .uk-tab > .uk-active > a {
                color: $blue-dark;
                border-color: $blue-dark;
            }
            // Tabs: Buttons - Classic Folder Tab Style
            &.tabs-buttons .uk-tab li a {
                background-color: $blue-dark;
                &:hover,
                &:active {
                    background: $gray;
                }
            }
        }
        &.tabs-blue-light {
            &.tabs-modern .uk-tab > .uk-active > a {
                color: $blue;
                border-color: $blue-light;
            }
            // Tabs: Buttons - Classic Folder Tab Style
            &.tabs-buttons .uk-tab li a {
                color: $gray;
                background-color: $blue-light;
                &:hover,
                &:active {
                    background: $gray;
                }
            }
        }
        &.tabs-orange {
            &.tabs-modern .uk-tab > .uk-active > a {
                color: $orange;
                border-color: $orange;
            }
            // Tabs: Buttons - Classic Folder Tab Style
            &.tabs-buttons .uk-tab li a {
                background-color: $orange;
                &:hover,
                &:active {
                    background: $gray;
                }
            }
        }
        &.tabs-orange-dark {
            &.tabs-modern .uk-tab > .uk-active > a {
                color: $orange-dark;
                border-color: $orange-dark;
            }
            // Tabs: Buttons - Classic Folder Tab Style
            &.tabs-buttons .uk-tab li a {
                background-color: $orange-dark;
                &:hover,
                &:active {
                    background: $gray;
                }
            }
        }
        &.tabs-orange-mid {
            &.tabs-modern .uk-tab > .uk-active > a {
                color: $orange;
                border-color: $orange-mid;
            }
            // Tabs: Buttons - Classic Folder Tab Style
            &.tabs-buttons .uk-tab li a {
                color: $gray;
                background-color: $orange-mid;
                &:hover,
                &:active {
                    background: $gray;
                }
            }
        }
        &.tabs-orange-light {
            &.tabs-modern .uk-tab > .uk-active > a {
                color: $orange;
                border-color: $orange-light;
            }
            // Tabs: Buttons - Classic Folder Tab Style
            &.tabs-buttons .uk-tab li a {
                color: $gray;
                background-color: $orange-light;
                &:hover,
                &:active {
                    background: $gray;
                }
            }
        }
        &.tabs-green {
            &.tabs-modern .uk-tab > .uk-active > a {
                color: $green;
                border-color: $green;
            }
            // Tabs: Buttons - Classic Folder Tab Style
            &.tabs-buttons .uk-tab li a {
                background-color: $green;
                &:hover,
                &:active {
                    background: $gray;
                }
            }
        }
        &.tabs-green-dark {
            &.tabs-modern .uk-tab > .uk-active > a {
                color: $green-dark;
                border-color: $green-dark;
            }
            // Tabs: Buttons - Classic Folder Tab Style
            &.tabs-buttons .uk-tab li a {
                background-color: $green-dark;
                &:hover,
                &:active {
                    background: $gray;
                }
            }
        }
        &.tabs-green-light {
            &.tabs-modern .uk-tab > .uk-active > a {
                color: $green;
                border-color: $green-light;
            }
            // Tabs: Buttons - Classic Folder Tab Style
            &.tabs-buttons .uk-tab li a {
                color: $gray;
                background-color: $green-light;
                &:hover,
                &:active {
                    background: $gray;
                }
            }
        }
    }


    // Icon Colors
    // list
    &.list-item-icon-red,
    &.list-item-icon-red.list-link-card .list-link-card-container,
    &.list-item-icon-red.list-link-list .list-link-list-container,
    &.list-item-icon-red.list-panel-list,
    // item
    &.list-link-card .list-link-card-inner-container.list-item-icon-red,
    &.list-link-list .list-has-icon.list-item-icon-red,
    &.list-panel-list .list-item-icon-red {
        // icon
        .list-item-media-container i.list-item-icon,
        i.list-item-icon,
        i {
            color: $red !important;
        }
    }
    // list
    &.list-item-icon-gray,
    &.list-item-icon-gray.list-link-card .list-link-card-container,
    &.list-item-icon-gray.list-link-list .list-link-list-container,
    &.list-item-icon-gray.list-panel-list,
    // item
    &.list-link-card .list-link-card-inner-container.list-item-icon-gray,
    &.list-link-list .list-has-icon.list-item-icon-gray,
    &.list-panel-list .list-item-icon-gray {
        .list-item-media-container i.list-item-icon,
        i.list-item-icon, 
        i {
            color: $gray !important;
        }
    }
    // list
    &.list-item-icon-gray-light,
    &.list-item-icon-gray-light.list-link-card .list-link-card-container,
    &.list-item-icon-gray-light.list-link-list .list-link-list-container,
    &.list-item-icon-gray-light.list-panel-list,
    // item
    &.list-link-card .list-link-card-inner-container.list-item-icon-gray-light,
    &.list-link-list .list-has-icon.list-item-icon-gray-light,
    &.list-panel-list .list-item-icon-gray-light {
        .list-item-media-container i.list-item-icon,
        i.list-item-icon,
        i {
            color: $gray-light !important;
        }
    }
    // list
    &.list-item-icon-teal,
    &.list-item-icon-teal.list-link-card .list-link-card-container,
    &.list-item-icon-teal.list-link-list .list-link-list-container,
    &.list-item-icon-teal.list-panel-list,
    // item
    &.list-link-card .list-link-card-inner-container.list-item-icon-teal,
    &.list-link-list .list-has-icon.list-item-icon-teal,
    &.list-panel-list .list-item-icon-teal {
        .list-item-media-container i.list-item-icon,
        i.list-item-icon,
        i {
            color: $teal !important;
        }
    }
    // list
    &.list-item-icon-teal-dark,
    &.list-item-icon-teal-dark.list-link-card .list-link-card-container,
    &.list-item-icon-teal-dark.list-link-list .list-link-list-container,
    &.list-item-icon-teal-dark.list-panel-list,
    // item
    &.list-link-card .list-link-card-inner-container.list-item-icon-teal-dark,
    &.list-link-list .list-has-icon.list-item-icon-teal-dark,
    &.list-panel-list .list-item-icon-teal-dark {
        .list-item-media-container i.list-item-icon,
        i.list-item-icon,
        i {
            color: $teal-dark !important;
        }
    }
    // list
    &.list-item-icon-teal-light,
    &.list-item-icon-teal-light.list-link-card .list-link-card-container,
    &.list-item-icon-teal-light.list-link-list .list-link-list-container,
    &.list-item-icon-teal-light.list-panel-list,
    // item
    &.list-link-card .list-link-card-inner-container.list-item-icon-teal-light,
    &.list-link-list .list-has-icon.list-item-icon-teal-light,
    &.list-panel-list .list-item-icon-teal-light {
        .list-item-media-container i.list-item-icon,
        i.list-item-icon,
        i {
            color: $teal-light !important;
        }
    }
    // list
    &.list-item-icon-blue,
    &.list-item-icon-blue.list-link-card .list-link-card-container,
    &.list-item-icon-blue.list-link-list .list-link-list-container,
    &.list-item-icon-blue.list-panel-list,
    // item
    &.list-link-card .list-link-card-inner-container.list-item-icon-blue,
    &.list-link-list .list-has-icon.list-item-icon-blue,
    &.list-panel-list .list-item-icon-blue {
        .list-item-media-container i.list-item-icon,
        i.list-item-icon,
        i {
            color: $blue !important;
        }
    }
    // list
    &.list-item-icon-blue-dark,
    &.list-item-icon-blue-dark.list-link-card .list-link-card-container,
    &.list-item-icon-blue-dark.list-link-list .list-link-list-container,
    &.list-item-icon-blue-dark.list-panel-list,
    // item
    &.list-link-card .list-link-card-inner-container.list-item-icon-blue-dark,
    &.list-link-list .list-has-icon.list-item-icon-blue-dark,
    &.list-panel-list .list-item-icon-blue-dark {
        .list-item-media-container i.list-item-icon,
        i.list-item-icon,
        i {
            color: $blue-dark !important;
        }
    }
    // list
    &.list-item-icon-blue-light,
    &.list-item-icon-blue-light.list-link-card .list-link-card-container,
    &.list-item-icon-blue-light.list-link-list .list-link-list-container,
    &.list-item-icon-blue-light.list-panel-list,
    // item
    &.list-link-card .list-link-card-inner-container.list-item-icon-blue-light,
    &.list-link-list .list-has-icon.list-item-icon-blue-light,
    &.list-panel-list .list-item-icon-blue-light {
        .list-item-media-container i.list-item-icon,
        i.list-item-icon,
        i {
            color: $blue-light !important;
        }
    }
    // list
    &.list-item-icon-orange,
    &.list-item-icon-orange.list-link-card .list-link-card-container,
    &.list-item-icon-orange.list-link-list .list-link-list-container,
    &.list-item-icon-orange.list-panel-list,
    // item
    &.list-link-card .list-link-card-inner-container.list-item-icon-orange,
    &.list-link-list .list-has-icon.list-item-icon-orange,
    &.list-panel-list .list-item-icon-orange {
        .list-item-media-container i.list-item-icon,
        i.list-item-icon,
        i {
            color: $orange !important;
        }
    }
    // list
    &.list-item-icon-orange-mid,
    &.list-item-icon-orange-mid.list-link-card .list-link-card-container,
    &.list-item-icon-orange-mid.list-link-list .list-link-list-container,
    &.list-item-icon-orange-mid.list-panel-list,
    // item
    &.list-link-card .list-link-card-inner-container.list-item-icon-orange-mid,
    &.list-link-list .list-has-icon.list-item-icon-orange-mid,
    &.list-panel-list .list-item-icon-orange-mid {
        .list-item-media-container i.list-item-icon,
        i.list-item-icon,
        i {
            color: $orange-mid !important;
        }
    }
    // list
    &.list-item-icon-orange-light,
    &.list-item-icon-orange-light.list-link-card .list-link-card-container,
    &.list-item-icon-orange-light.list-link-list .list-link-list-container,
    &.list-item-icon-orange-light.list-panel-list,
    // item
    &.list-link-card .list-link-card-inner-container.list-item-icon-orange-light,
    &.list-link-list .list-has-icon.list-item-icon-orange-light,
    &.list-panel-list .list-item-icon-orange-light {
        .list-item-media-container i.list-item-icon,
        i.list-item-icon,
        i {
            color: $orange-light !important;
        }
    }
    // list
    &.list-item-icon-green,
    &.list-item-icon-green.list-link-card .list-link-card-container,
    &.list-item-icon-green.list-link-list .list-link-list-container,
    &.list-item-icon-green.list-panel-list,
    // item
    &.list-link-card .list-link-card-inner-container.list-item-icon-green,
    &.list-link-list .list-has-icon.list-item-icon-green,
    &.list-panel-list .list-item-icon-green {
        .list-item-media-container i.list-item-icon,
        i.list-item-icon,
        i {
            color: $green !important;
        }
    }
    // list
    &.list-item-icon-green-dark,
    &.list-item-icon-green-dark.list-link-card .list-link-card-container,
    &.list-item-icon-green-dark.list-link-list .list-link-list-container,
    &.list-item-icon-green-dark.list-panel-list,
    // item
    &.list-link-card .list-link-card-inner-container.list-item-icon-green-dark,
    &.list-link-list .list-has-icon.list-item-icon-green-dark,
    &.list-panel-list .list-item-icon-green-dark {
        .list-item-media-container i.list-item-icon,
        i.list-item-icon,
        i {
            color: $green-dark !important;
        }
    }
    // list
    &.list-item-icon-green-light,
    &.list-item-icon-green-light.list-link-card .list-link-card-container,
    &.list-item-icon-green-light.list-link-list .list-link-list-container,
    &.list-item-icon-green-light.list-panel-list,
    // item
    &.list-link-card .list-link-card-inner-container.list-item-icon-green-light,
    &.list-link-list .list-has-icon.list-item-icon-green-light,
    &.list-panel-list .list-item-icon-green-light {
        .list-item-media-container i.list-item-icon,
        i.list-item-icon,
        i {
            color: $green-light !important;
        }
    }
    // list
    &.list-item-icon-white,
    &.list-item-icon-white.list-link-card .list-link-card-container,
    &.list-item-icon-white.list-link-list .list-link-list-container,
    &.list-item-icon-white.list-panel-list,
    // item
    &.list-link-card .list-link-card-inner-container.list-item-icon-white,
    &.list-link-list .list-has-icon.list-item-icon-white,
    &.list-panel-list .list-item-icon-white {
        .list-item-media-container i.list-item-icon,
        i.list-item-icon,
        i {
            color: $white !important;
        }
    }
    // list
    &.list-item-icon-black,
    &.list-item-icon-black.list-link-card .list-link-card-container,
    &.list-item-icon-black.list-link-list .list-link-list-container,
    &.list-item-icon-black.list-panel-list,
    // item
    &.list-link-card .list-link-card-inner-container.list-item-icon-black,
    &.list-link-list .list-has-icon.list-item-icon-black,
    &.list-panel-list .list-item-icon-black {
        .list-item-media-container i.list-item-icon,
        i.list-item-icon,
        i {
            color: $black !important;
        }
    }


}



// Expandable List Nested ul/ol Link Formatting

.lists.list-accordion li.uk-open .list-accordion-content li a,
.lists.list-accordion.list-panel li.uk-open .list-accordion-content li a,
.lists.list-accordion li .list-accordion-content li a {
    padding: 0;
    border-bottom: 0;
}
    .lists.list-accordion li.uk-open .list-accordion-content li > a:hover,
    .lists.list-accordion.list-panel li.uk-open .list-accordion-content li > a:hover,
    .lists.list-accordion li .list-accordion-content li > a:hover,
    .lists.list-accordion li.uk-open .list-accordion-content li > a:active,
    .lists.list-accordion.list-panel li.uk-open .list-accordion-content li > a:active,
    .lists.list-accordion li .list-accordion-content li > a:active,

    .lists.list-accordion li.uk-open .list-accordion-content li a:hover,
    .lists.list-accordion.list-panel li.uk-open .list-accordion-content li a:hover,
    .lists.list-accordion li .list-accordion-content li a:hover,
    .lists.list-accordion li.uk-open .list-accordion-content li a:active,
    .lists.list-accordion.list-panel li.uk-open .list-accordion-content li a:active,
    .lists.list-accordion li .list-accordion-content li a:active {
        color: $red;
        text-decoration: underline;
        border-bottom: 1px solid $red !important;
    }




    // Custom Item Alignments
//
    //blog-post-title
    //blog-post-meta
    //blog-post-content-summary
    //blog-post-content-full
    //item-content-cats
    //item-content-tags
//

// Titles
.blog-posts-list.title-left .blog-post-title,
.blog-posts-list .blog-post-items .title-left .blog-post-title {
    text-align: left !important;
}
.blog-posts-list.title-right .blog-post-title,
.blog-posts-list .blog-post-items .title-right .blog-post-title {
    text-align: right !important;
}
.blog-posts-list.title-center .blog-post-title,
.blog-posts-list .blog-post-items .title-center .blog-post-title {
    text-align: center !important;
}
// Meta
.blog-posts-list.meta-left .blog-post-meta,
.blog-posts-list .blog-post-items .meta-left .blog-post-meta {
    text-align: left !important;
}
.blog-posts-list.meta-right .blog-post-meta,
.blog-posts-list .blog-post-items .meta-right .blog-post-meta {
    text-align: right !important;
}
.blog-posts-list.meta-center .blog-post-meta,
.blog-posts-list .blog-post-items .meta-center .blog-post-meta {
    text-align: center !important;
}
// Cats
.blog-posts-list.cats-left .item-content-cats,
.blog-posts-list .blog-post-items .cats-left .item-content-cats {
    text-align: left !important;
}
.blog-posts-list.cats-right .item-content-cats,
.blog-posts-list .blog-post-items .cats-right .item-content-cats {
    text-align: right !important;
}
.blog-posts-list.cats-center .item-content-cats,
.blog-posts-list .blog-post-items .cats-center .item-content-cats {
    text-align: center !important;
}
// Tags
.blog-posts-list.tags-left .item-content-tags,
.blog-posts-list .blog-post-items .tags-left .item-content-tags {
    text-align: left !important;
}
.blog-posts-list.tags-right .item-content-tags,
.blog-posts-list .blog-post-items .tags-right .item-content-tags {
    text-align: right !important;
}
.blog-posts-list.tags-center .item-content-tags,
.blog-posts-list .blog-post-items .tags-center .item-content-tags {
    text-align: center !important;
}
//Full Content and Summary
.blog-posts-list.content-left .blog-post-content,
.blog-posts-list .blog-post-items .content-left .blog-post-content {
    text-align: left !important;
    p, div {
        text-align: left !important;
    }
}
.blog-posts-list.content-right .blog-post-content,
.blog-posts-list .blog-post-items .content-right .blog-post-content {
    text-align: right !important;
    p, div {
        text-align: right !important;
    }
}
.blog-posts-list.content-center .blog-post-content,
.blog-posts-list .blog-post-items .content-center .blog-post-content {
    text-align: center !important;
    p, div {
        text-align: center !important;
    }
}