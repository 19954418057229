.sf-Comments {
	margin-top: 30px;

	[class^="sf-icon"] {
		display: inline-block;
    }
    
	.sf-loading {
		margin-bottom: 15px;
    }
}

.sf-Comment-count {
	display: inline-block;
	margin-left: 10px;

	[class^="sf-icon"] {
		display: inline-block;
		vertical-align: middle;
    }
}

// Hide elements before js initialization

[data-sf-role="error-message"],
[data-sf-role="list-loading-indicator"],
[data-sf-role="list-loading-indicator"],
[data-sf-role="comments-new-message-error"],
[data-sf-role="comments-new-logged-out-view"],
[data-sf-role="comments-new-name-error"],
[data-sf-role="captcha-container"],
[data-sf-role="single-comment-template"],
[data-sf-role="comments-new-pending-approval-message"],
[data-sf-role="comments-load-more-button"],
[data-sf-role="comments-new-form-button"],
[data-sf-role="comments-sort-new-button"],
[data-sf-role="comments-sort-old-button"],
[data-sf-role="comments-new-requires-authentication"],
[data-sf-role="list-rating-wrapper"],
[data-sf-role="review-new-form-replacement"] {
	display: none;
}

.sf-Comments-header {
	margin-bottom: 30px;

	h3 {
		display: inline-block;
		margin-top: 0;
		margin-right: 20px;
    }
    
	a {
		margin-top: 5px;
		margin-left: 5px;
    }
    
	.selected {
		color: #333;
    }
}

.sf-Comments-list {
	margin-bottom: 30px;

	.sf-Comments-list-author {
		padding-right: 5px;
		border-right: 1px solid #ccc;
    }
}

.sf-Comments-form {
	textarea {
		min-height: 90px;
    }
}

.sf-Comments-more {
	margin-bottom: 30px;
}

.sf-Comment-subscribe {
	margin-top: 15px;
	margin-left: 50px;
}

//Reviews

.sf-Review-already {
	margin-top: 20px;
}

.sf-Review-count {
	display: inline-block;
	margin-left: 10px;
}

//Comment count on new line

.sf-Comment-count--block {
	.sf-Comment-count,
	.sf-Review-count {
		display: block;
		margin-left: 0;
		margin-bottom: 10px;
    }
}