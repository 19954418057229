// Taxonomies
//

.categories-list,
.tags-simple-list,
.tags-cloud-list,
.tags-tag-cloud {
    @include clearfix;
    @extend .uk-article-meta;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            line-height: 1.639rem;
            font-size: .88rem;
            // a {
            //     @extend .uk-button;
            //     @extend .uk-button-text;
            //     text-transform: none;
            // }
            a {
                //@extend .uk-link-reset;
                //@extend .uk-button;
                color: $gray;
                text-decoration: underline;

                &.active {
                    color: $red;
                }
    
                &:hover,
                &:active,
                &:focus {
                    color: $red;
                    text-decoration: underline;
                    border-bottom: 1px solid $red;
                }
            }
        }
    }
}


.tags-cloud-list,
.tags-tag-cloud {
    ul {
        li {
            a {
                &.sf-Tags-size1 {
                    font-size: 0.8rem;
                }

                &.sf-Tags-size2 {
                    font-size: 0.875rem;
                }

                &.sf-Tags-size3 {
                    font-size: 1.125rem;
                }

                &.sf-Tags-size4 {
                    font-size: 1.25rem;
                }

                &.sf-Tags-size5 {
                    font-size: 1.5rem;
                }

                &.active {
                    color: $red;
                }
            }
        }
    }
}


.tags-tag-cloud {
    ul {
        li {
            display: inline-block;
            *display: inline;
            zoom: 1;

            a {
                &.active {
                    color: $red;
                }
            }
        }
    }
}



// cats and tags display for widgets
// i.e. blogs, lists

.item-content-cats { 
    display: block; 
    font-size: .8rem; 
    margin-bottom: 11px;

    > span {
        display:inline-block;
        margin-right:4px;

        a {
            display: inline-block;

            &.active {
                color: $red;
            }
        }
    }
}

.item-content-tags {
    display: block; 
    font-size: .7rem; 
    margin-top: 22px;

    > span {
        display:inline-block;
        margin-right:4px;

        a {
            display: inline-block;

            &.active {
                color: $red;
            }
        }
    }
}