﻿// Z-Index

// Bootstrap Z-index master list
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
// Note: These variables are not generated into the Customizer.

$zindex-navbar: 1000 !default;
$zindex-dropdown: 1000 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
$zindex-navbar-fixed: 1030 !default;
$zindex-modal-background: 1040 !default;
$zindex-modal: 1050 !default;

// UTMB Z-index master list

$zindex-explore-submenu: 9000 !default;
$zindex-navigate-main: 1 !default;
$zindex-mobi-menu: 1 !default;
$zindex-mobi-button: 2 !default;