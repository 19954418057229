//
// UTMB Layout Skins (Overrides)
// --------------------------------------------------


// DO NOT use artboard - ie: no background color on #main
body.no-artboard #primarycontent {
    background: transparent !important;
    background-color: transparent !important;
    border: 0 !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    border-radius: 0px !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none;
    }
  
  // DO NOT use .padding class ie: #main-grid > #article > .padding - AND - #main-grid > #aside > .padding
  body.no-article-padding,
  //body.no-article-padding.utmb-light,
  //body.no-article-padding.utmb-dark,
  body.no-article-padding.nav-side,
  body.no-article-padding.nav-side-right,
  body.no-article-padding.nav-main,
  body.no-article-padding.nav-top,
  body.no-article-padding.nav-sub {
      #primarycontent {
          #article {
              .padding,
              #bodycontent {
                padding: 0;
              }
          }
          #aside {
              .padding,
              #asidecontent {
                padding: 0;
              }
          }
      }
  }
  
  // SNAP grid-row() back to grid-row() (i.e. 1000px wide) - if [DEFAULT] overidden site wide
  body.snap #header .head,
  body.snap #breadcrumbs,
  body.snap #primarycontent,
  body.snap #navigate section,
  body.snap #explore section,
  body.snap #footer .foot,
  body.snap.utmb-light #footer .foot,
  body.snap.utmb-dark #footer .foot,
  body.snap .row {
    max-width: $container-classic !important; //snap to defined row width
  }
  
  // EXPAND grid-row() to Page (ie 100% of container)
  body.full #header .head,
  body.full #breadcrumbs,
  body.full #primarycontent,
  body.full #navigate section,
  body.full #explore section,
  body.full #footer .foot,
  body.full.utmb-light #footer .foot,
  body.full.utmb-dark #footer .foot,
  body.full .row,
  body.full.exp-mega #explore .menu-container,
  body.full.nav-top #navigate .collapse.tandem-nav .menu-container, 
  body.full.nav-sub #navigate .collapse.tandem-nav .menu-container, 
  body.full.nav-main #navigate .collapse.tandem-nav .menu-container {
    max-width: 100%;
    width: 100%;
  }
  
  
  
  // SNAP Page to grid-row() (i.e. 1000px wide)
  body.snap-page #pagewrapper {
    max-width: $container-classic; //snap to defined row width
  }
  
  // EXPAND #page wrapper to full width (i.e. 100% wide) - if [DEFAULT] overidden site wide
  body.full-page #pagewrapper {
    max-width: 100% !important;
  }
  
  /*// STICKY FOOTER - Tablet view and up
  @media only screen and (min-width: $utmb-small) {
  
      body.sticky-footer {
          
          // Sticky Footer: Page Wrapper
          #pagewrapper {
              min-height: 100%;
              margin-bottom: #{$sticky-footer-margin}; 
      
              &:after {
                  content: "";
                  display: block;
                  height: #{$sticky-footer-height};
              }
          }
      }
      
      // Sticky Footer: Footer
      #footer {
          height: $sticky-footer-height;
      }
  }*/
  
  
  //
  // OLD IE -- UTMB Layout Skins (Overrides)
  // --------------------------------------------------
  html.lt-ie9 {
      
      // SNAP grid-row() back to grid-row() (i.e. 1000px wide) - if [DEFAULT] overidden site wide
      body.snap #header .head,
      body.snap #breadcrumbs,
      body.snap #primarycontent,
      body.snap #navigate section,
      body.snap #explore section,
      body.snap #footer .foot,
      body.snap .row {
        width: $container-classic !important; //snap to defined row width
      }
  
      // SNAP Page to grid-row() (i.e. 1000px wide)
      body.snap-page #pagewrapper {
        width: $container-classic !important; //snap to defined row width
      }
      
  }
  
  