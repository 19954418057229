// Icon with text
.icon--text:before {
    vertical-align: baseline;
    margin-right: 0.3em;
  }
  .icon-align {
    position: relative;
    display: inline-block;
    margin-top: -4px;
    //font-size: 19px;
    @include convertPixelsToRem_fontSize(19); // output: font-size: ($value / 16) + rem;
    color: #3390CF;
  }
  
  .icon-no-image {
    //font-size: 70px;
    @include convertPixelsToRem_fontSize(70); // output: font-size: ($value / 16) + rem;
    color: #ddd;
  }
  .icon-txt {
    position: relative;
    display: inline-block;
    line-height: normal;
  
    &.icon-file {
      color: #999;
    }
        
    [class^="icon-txt-"] {
      position: absolute;
      top: 47%;
      right: -3%;
      padding:0.2em 0.4em;
      font-size: 0.25em;
      line-height: normal;
      height: auto;
      font-style: normal;
      font-weight: bold;
      text-transform: uppercase;
      background-color: #333;
      color: #fff;
    }
      
    .icon-txt-xlsx {
      background-color: #38ab63;
    }
    .icon-txt-doc,
    .icon-txt-docx {
      background-color: #1a8cff;
    }
    .icon-txt-ppt,
    .icon-txt-pptx {
      background-color: #ed8527;
    }
    .icon-txt-pdf {
      background-color: #ff4848;
    }
    .icon-txt-zip {
      background-color: #596c9b;
    }
  }
        
        
  //Icon sizes
  .icon-sm {
    //font-size: 22px;
    @include convertPixelsToRem_fontSize(22); // output: font-size: ($value / 16) + rem;
  
    [class^=icon-txt-] {
      top: 43%;
      right: -20%;
      padding: .1em .3em;
      font-size: .35em;
    }
  }
      
  .icon-md {
    //font-size: 38px;
    @include convertPixelsToRem_fontSize(38); // output: font-size: ($value / 16) + rem;
  }
  .icon-lg {
    //font-size: 48px;
    @include convertPixelsToRem_fontSize(48); // output: font-size: ($value / 16) + rem;
  }