﻿// UTMB SuperHero Box
// For #atopcontent region
// ------------------------------------------------



// Classic Design Mode Defaults
.design-mode-classic {
    .superhero-box,
    .row.superhero-box {

        .superhero-container {
            max-width: $container-classic;
        }
    }
}

// Modern Design Mode Defaults
.design-mode-modern {
    .superhero-box,
    .row.superhero-box {

        .superhero-container {
            max-width: $container-modern;
        }
    }
}



.superhero-box,
.row.superhero-box {
    background-color: #000;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;

    .superhero-container {
        //max-width: $page-max-width;
        margin: 0 auto;
        position: relative;
        @include clearfix;

        .superhero,
        .sf_colsIn.col-md-12.superhero {
            padding: 0;
            margin: 0;
            width: 100%;
            max-width: 100%;
        }
    }
    // More Width
    &.more-width {
        .superhero-container {
            max-width: 1870px;
        }
    }
    // Full Width Feature
    &.full-width {
        .superhero-container {
            max-width: 100%;
            margin: 0 auto;
            position: relative;
            @include clearfix;

            .superhero {
            }
        }
    }
}
