.k-button-group .k-button.k-state-active, 
.k-button-group .k-button.k-state-selected, 
.k-button-group .k-button:active, 
.k-button-group>input[type=radio]:checked+.k-button,
.k-button-group>input[type=checkbox]:checked+.k-button {
    border-color: $red;
    color: #fff;
    background-color: $red;
    box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.06);
}

.k-button.k-state-hover, 
.k-button:hover {
    border-color: #c7cdd5;
    color: #212529;
    background-color: #ced3db;
    border-color: #222;
    color: #fff;
    background-color: #222;

}

// .k-event, 
// kendo-scheduler .k-event {
//     border-radius: 0.25rem;
//     min-height: 25px;
//     box-sizing: border-box;
//     border-width: 0;
//     border-style: solid;
//     border-radius: 0.25rem;
//     text-align: left;
//     outline: 0;
//     cursor: default;
//     position: absolute;
//     overflow: hidden;
//     //overflow:visible;
//     padding-right: 1rem;
//     height: auto !important; // COOP
// }

.sf-event-link {
    font-size: .835rem;
    // display: inline-block;
    //display: inline;
    // margin: 4px;

}

// .k-scheduler-monthview .k-scheduler-content .k-scheduler-table-auto, 
// .k-scheduler-monthview .k-scheduler-table-auto, 
// .k-scheduler-monthview .k-scheduler-table-auto td,
// td.k-today {
//     height: auto !important;
//     overflow: visible !important;
//     position: relative;
// }



//https://www.utmb.edu/web/news-and-policy/known-issues


//Events: 
.k-button-solid-base.k-selected {
    border-color: #1c3a56;
    color: #fff;
    background-color: #1c3a56;
}
//Change to border and BG #1c3a56


.k-other-month {
    background: #111111;
    color: #FFF;
}
//Change to BG #111111


.k-scheduler-table td, .k-scheduler-table th {
    padding: .75rem .75rem;
    height: 1.5em;
    overflow: auto;
    white-space: nowrap;
    border-style: solid;
    border-width: 0 0 1px 1px;
    vertical-align: top;
    box-sizing: content-box;
}
//Change to overflow: auto;

