﻿// Nav Horizontal
// Overlay Design for UIKit Horizontal Nav Bar
// *See ./src/assets/scss/vendor/uikit/_utmb-uikit-variables.scss


.nav-horizontal {
    .uk-navbar-container:not(.uk-navbar-transparent) {
        border: 1px solid #eeeeee;
    }
    &.nav-transparent .uk-navbar-container {
        border: 0;
    }
    li {
        > a {
            font-size: .875rem;

            &:active,
            &:focus,
            &:hover {
                color: $red;
            }

            &:focus {
                border: 1px dashed $red;
                outline: 1px solid $red;
            }
        }
        ul.uk-navbar-dropdown-nav,
        ul.uk-nav-sub {
            li {
                a {
                    font-size: .875rem !important;

                    .caret, 
                    .caret-down, 
                    .caret-left, 
                    .caret-right, 
                    .caret-up {
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    // Institutional Nav
    &.utmb-institutional-menu-nav {
        li {
            > a {
                .caret, 
                .caret-down, 
                .caret-left, 
                .caret-right, 
                .caret-up {
                    margin-left: 5px;
                    color: $red;
                }
            }
        }

        .uk-navbar-dropdown-nav .uk-nav-header a {
            color: $teal;
            font-weight: 500;
            font-family: 'Roboto Condensed';
        }
        
        .uk-navbar-dropdown-nav .uk-nav-header {
            color: $teal;
            font-weight: 500;
            font-family: 'Roboto Condensed';
        }
        
        .uk-nav-header:not(:first-child) {
            margin-top: 0;
        }
        
        @media only screen and (max-width: 1210px) and (min-width: 977px) {
            .uk-navbar-item, .uk-navbar-nav > li > a, .uk-navbar-toggle {
                padding: 0 3px;
            }
        
            .uk-navbar-left {
                margin: 0 -20px;
            }
        }
        
        /*ADA fixes*/
        .uk-navbar-dropdown-nav > li > a {
            color: $gray-5 !important;
        }
        
        .uk-navbar-dropdown-nav > li > a:focus, 
        .uk-navbar-dropdown-nav > li > a:hover {
            color: $teal;
            text-decoration: underline;
        }
    }
}


// Mobile Handling
// A Dropdown menu is created for each instance
// Dropdown is displayed for small screens (sweetspot-max) and hidden at desktop (sweetspot)
// Horizontal menu is hidden for small screens (sweetspot-max) and displayed at desktop (sweetspot)

@media only screen and (max-width: $utmb-sweetspot-max) {
    .nav-horizontal {
        .nav-mobile-dropdown {
            display: block;
            padding: 15px 0;
        }

        ul {
            display: none;
        }
        // mobile override
        &.no-mobile-menu {
            .nav-mobile-dropdown {
                display: none;
            }

            ul {
                //display: block;
                display: flex;
            }
        }
    }
}

@media only screen and (min-width: $utmb-sweetspot) {
    .nav-horizontal {
        .nav-mobile-dropdown {
            display: none;
        }

        ul {
            //display: block;

            li {
                a {
                    .caret {
                        color: $red;
                    }
                }
            }
        }
    }
}



