// Diversity, Equity and Inclusion
// Template pre-footer


.utmb-dei {
    padding: 10px;
    background: #111;
    color: #fff;
    text-align: center;

    .btn.btn-text.btn-text-white,
    .btn-text-white,
    a {
        color: #fff;
        font-size: .777rem !important;
        line-height: 1.5 !important;
        text-decoration: underline !important;

        &:hover,
        &:active,
        &:focus {
            color: #fff;
            text-decoration: underline !important;
            border-bottom: 1px solid $red;
        }

        &:focus {
            border: 1px dashed $red !important;
            outline: 1px solid $red !important;
        }
    }
}
